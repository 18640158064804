import { CUSTOMURL1 } from '../../config';
import {
  SC_GET_ONE_PRODUCT
} from '../types/types';
import axios from 'axios';
const sc_token = JSON.parse(localStorage.getItem('sc_token'));


export const scGetOneProduct = (product_id, token) => (dispatch) => {
  return axios({
    method: 'GET',
    url: `${CUSTOMURL1}products/${product_id}`,
    headers: { Authorization: `Bearer ${sc_token ? sc_token : token}` },
    // data: { product_id }
  })
    .then((response) => {
      dispatch({
        type: SC_GET_ONE_PRODUCT,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
