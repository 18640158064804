import React, { useEffect, useState } from 'react';
import { useStepsForm } from 'sunflower-antd';
import { Button, Form, Input, message, Popover, Spin, Collapse, Image } from 'antd';
import '../../css/basicSetup.scss';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import placeholderImage from '../../images/placeholder-image.jpg';
import { getUserInfo } from '../../redux/actions/userInfo.action';
import axios from 'axios';
import { basicProfileImage } from '../../redux/actions/profileImageBasic.action';
import { notification } from 'antd';
import { SetLocalDescriptionTask } from 'amazon-chime-sdk-js';
import { basicPassChange } from '../../redux/actions/basicPassChange.action';
import { getSocialFollowers } from '../../redux/actions/socialFollowers.action ';
import { createBrowserHistory } from 'history';
import { updateProfileBio } from '../../redux/actions/basicProfileFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GroupIcon from '@mui/icons-material/Group';
import numeral from 'numeral';
import { store } from '../../redux/store';
import { AUTHENTICATE } from '../../redux/types/types';
import { visitorURL } from '../../config';
import { InstagramOutlined, TikTokOutlined } from '@ant-design/icons';
import InstaLinkinbio from "../../images/InstaLinkinbio.png"
import TiktokLinkinBio from "../../images/TiktokLinkinBio.jpg"
import { faXmark } from '@fortawesome/free-solid-svg-icons';


export const history = createBrowserHistory();
export default function BrandShop({ reRender }) {
  const { TextArea } = Input;
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const { userInfo, validate, socialMediaFollowers, checkInstagramConnection } = useSelector((state) => state);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [load, setLoad] = useState(false);
  const [instaData, setInstaData] = useState([]);
  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({});
  const [visible, setVisible] = useState(false);
  const [insType, setInsType] = useState('');
  const [activeKey, setActiveKey] = useState(null);


  const handlePanelChange = (key) => {
    setActiveKey(activeKey === key ? null : key); // Toggle the panel
  };

  const handlePopoverToggle = (type) => {
    if (insType === type) {
      console.log('if');

      // If the same type is clicked, toggle visibility
      setVisible(!visible);
    } else {
      console.log('else');
      // Set new type and keep popover open
      setInsType(type);
      setVisible(true);
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  const renderContent = () => {
    switch (insType) {
      case 'instagram':
        return (
          <>
            <div className="mb-2">
              1. Open the Instagram app on your device.<br />
              2. Tap your profile picture at the bottom right to go to your profile.<br />
              3. Tap the <strong>Edit profile</strong> button below your profile picture and details.<br />
              4. Tap <strong>Links</strong>, then tap <strong>Add external link.</strong><br />
              5. Type or paste your <strong>ORME Shop</strong> link in the URL field and provide a title.<br />
              6. Tap the blue tick or Done to save your changes.
            </div>
            <img src={InstaLinkinbio} alt="Instagram Instructions" />
          </>
        );
      case 'tiktok':
        return (
          <>
            <div className="mb-2">
              1. Open the TikTok app on your device.<br />
              2. Tap the profile icon at the bottom right to go to your profile.<br />
              3. Tap the <strong>Edit profile</strong> button below your profile picture and follower count.<br />
              4. Tap the <strong>Website</strong> section.<br />
              5. Type or paste your ORME Shop link in the URL field and tap Save.<br />
              Note: Adding a URL on TikTok is only available for qualified accounts.
            </div>
            <img src={TiktokLinkinBio} alt="Tiktok Instructions" />
          </>
        );
      default:
        return <div>Select a platform to see instructions.</div>;
    }
  };



  const handleValues = (e) => {
    form.setFieldsValue({ username: e.target.value });
  };


  useEffect(() => {
    // Add class to body
    document.body.classList.add('brandShop-popover');

    // Cleanup function to remove class when component unmounts
    return () => {
      document.body.classList.remove('brandShop-popover');
    };
  }, []);


  const formList = [
    <>
      <Form.Item
        label="Enter Name"
        name="username"
        labelCol={{ span: 24 }}
        initialValue={form.getFieldValue().username}
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please input your Name',
        //   },
        // ]}
        onChange={handleValues}
      >
        <Input size="large" placeholder="Enter Name" />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        label="Enter Bio"
        name="notes"
        initialValue={form.getFieldValue().notes}
        onChange={(e) => form.setFieldsValue({ notes: e.target.value })}
      >
        <TextArea
          showCount
          maxLength={200}
          className="dark-field"
          placeholder="Enter Bio"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 24 }}
        label="Enter Website Address"
        name="website_address"
        initialValue={form.getFieldValue().website_address}
        onChange={(e) => form.setFieldsValue({ website_address: e.target.value })}
        rules={[
          {
            type: 'url',
            // warningOnly: true,
          },
          // {
          //   type: 'string',
          //   min: 6,
          // },
        ]}
      >
        <Input size="large" placeholder="Enter Website Address" />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={loader}
        className="default-btn ml-0 fw-normal"
      // onClick={saveBioData}
      // disabled={!validURL}
      >
        Save
      </Button>
    </>,
  ];
  const onChangeInputImage = (e) => {
    if (e.target.files.length === 1) {
      if (
        e.target.files[0]?.type === 'image/jpeg' ||
        e.target.files[0]?.type === 'image/webp' ||
        e.target.files[0]?.type === 'image/png' ||
        e.target.files[0]?.type === 'image/svg+xml'
      ) {
        const files = [];
        const reader = new FileReader();
        files.push(e.target.files[0]);
        reader.onloadend = () => {
          files[0].preview = reader.result;
          files[0].toUpload = true;
          setImage(files);
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        notification.error({
          message: 'We Only Support PNG, WEBP, Or JPG Image!',
          className: 'toast-error',
        });
      }
    }
  };

  const clearImage = () => {
    setImage([]);
  };

  const uploadImage = async () => {
    setLoading(true)
    var formData = new FormData();
    formData.append('image', image?.[0]);
    formData.append('instagram_username', form.getFieldValue().username);
    dispatch(basicProfileImage(formData, validate?.payload?.message?._id)).then((res) => {
      if (res.success) {
        dispatch(getUserInfo()).then((res) => {
          setImage([]);

        })
        notification.success({
          message: 'Image Has Been Uploaded!',
          className: 'toast-success',
        });
        setLoading(false)
      }

    }).catch(err => {
      setLoading(false)
    });
  };
  const handlePassword = () => {

    setLoad(true)
    if (form.getFieldValue().newPassword) {
      if (form.getFieldValue().newPassword === form.getFieldValue().changepassword) {
        dispatch(basicPassChange(form.getFieldValue().currentPassword, form.getFieldValue().newPassword, validate?.payload?.message?._id)).then((res) => {
          if (res.success) {
            notification.success({
              message: 'Password Has Been Changed!',
              className: 'toast-success',
            });
            localStorage.removeItem("token");
            localStorage.removeItem("sc_token");
            store.dispatch({ type: AUTHENTICATE, payload: {} })
            setLoad(false)
          }
        }).catch(err => {
          setLoad(false)
          notification.error({
            message: err.message,
            className: 'toast-error',
          });
        });
      }
      else {
        setLoad(false)
        notification.error({
          message: 'New Passwords Do Not Match!',
          className: 'toast-error',
        });
      }
    } else {
      setLoad(false)
      notification.error({
        message: 'Password Field is empty!',
        className: 'toast-error',
      });
    }
  };

  useEffect(() => {
    reRender(userInfo)
  }, [userInfo])

  const saveBioData = () => {
    setLoader(true)
    dispatch(updateProfileBio(form.getFieldValue().username, form.getFieldValue().notes, form.getFieldValue().website_address, validate?.payload?.message?._id)).then(res => {
      if (res.success) {
        notification.success({
          message: 'Profile Data Updated!',
          className: 'toast-success',
        });
        // history.go(0)
        setLoader(false)
        dispatch(getUserInfo())
        // .then((res) => {
        // if (res.success) {
        //   form.setFieldsValue({
        //     username: res?.message?.data?.name,
        //     notes: res?.message?.data?.bio,
        //   });
        // }
        // });
      }
      setLoader(false)
    })
  }

  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function numberFormat(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + 'M';
    if (num >= 1000) return intlFormat(num / 1000) + 'k';
    return intlFormat(num);
  }
  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: 'Url Copied!',
      className: 'toast-success',
    });
  };
  return (
    <>
      <div class="title-area">
        <h1 class="page-title">Brand Shop</h1>
      </div>
      <div className="container p-0 ml-0">
        <div className="row">

          <div className="profile_box_main col-md-7 mb-20">
            <div className="stream-box-white mb-20">
              <h3 className="mb-20 basic-hd-top">Shop URL</h3>
              {/* <h4 className='basic-hd2'>Instagram</h4> */}
              <div class="your-copy-link">
                <div class="item-a">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={visitorURL + "/" + validate?.payload?.message?.pixel_id}
                    title={visitorURL + "/" + validate?.payload?.message?.pixel_id}
                  >
                    {visitorURL + "/" + validate?.payload?.message?.pixel_id}
                  </a>
                </div>
                <div class="item-b">
                  <Button onClick={() => copyUrl(visitorURL + "/" + validate?.payload?.message?.pixel_id)}>
                    Copy
                  </Button>
                </div>
              </div>

              <p className="fw-bold mt-3">Please copy the link and add to link in bio of all your social media accounts.</p>
            </div>
            <div class="title-area">
              <h1 class="page-title">Instructions</h1>

            </div>
            <div className="stream-box-white">
              <div className="card-information-store brandShop-popover">
              </div>
              <Collapse accordion activeKey={activeKey} onChange={handlePanelChange}>
                <Panel header={<strong>Instagram</strong>} key="1">
                  {activeKey === "1" && (
                    <>
                      <div className="mb-2">
                        1. Open the Instagram app on your device.<br />
                        2. Tap your profile picture at the bottom right to go to your profile.<br />
                        3. Tap the <strong>Edit profile</strong> button below your profile picture and details.<br />
                        4. Tap <strong>Links</strong>, then tap <strong>Add external link.</strong><br />
                        5. Type or paste your <strong>ORME Shop</strong> link in the URL field and provide a title.<br />
                        6. Tap the blue tick or Done to save your changes.
                      </div>
                      <Image src={InstaLinkinbio} alt="Instagram Instructions" width='100%' />
                    </>
                  )}
                </Panel>
                <Panel header={<strong>TikTok</strong>} key="2">
                  {activeKey === "2" && (
                    <>
                      <div className="mb-2">
                        1. Open the TikTok app on your device.<br />
                        2. Tap the profile icon at the bottom right to go to your profile.<br />
                        3. Tap the <strong>Edit profile</strong> button below your profile picture and follower count.<br />
                        4. Tap the <strong>Website</strong> section.<br />
                        5. Type or paste your ORME Shop link in the URL field and tap Save.<br />
                        Note: Adding a URL on TikTok is only available for qualified accounts.
                      </div>
                      <Image src={TiktokLinkinBio} alt="Tiktok Instructions" width='100%' />
                    </>
                  )}
                </Panel>
              </Collapse>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}