import axios from "axios";
import {
  GET_ALL_CREATOR_ERROR,
  GET_ALL_CREATOR_REQUEST,
  GET_ALL_CREATOR_SUCCESS,
  GET_INSTA_REELS,
  GET_INSTA_DATA,
  GET_INSTA_REEL_POST,
  GET_REEL_REVIEW_CONTROL_POST,
  LINK_INSTA_REELS_ERROR,
  LINK_INSTA_REELS_REQUEST,
  LINK_INSTA_REELS_SUCCESS,
  LINK_INSTA_REELS_UPDATE_ERROR,
  LINK_INSTA_REELS_UPDATE_SUCCESS,
  LOCAL_CUSTOMER_ORDER_REQUEST, LOCAL_CUSTOMER_ORDER_SUCCESS, LOCAL_CUSTOMER_ORDER_ERROR,
  GET_REVIEW_DETAILS_REQUEST,
  GET_REVIEW_DETAILS_SUCCESS,
  GET_REVIEW_DETAILS_ERROR, GET_REVIEW_ID_ERROR, GET_REVIEW_ID_REQUEST, GET_REVIEW_ID_SUCCESS,
  LINK_REEL_PRODUCT_UPDATE_SUCCESS,
  LINK_REEL_PRODUCT_SUCCESS,
  LINK_REEL_PRODUCT_UPDATE_ERROR,
  GET_REEL_DELETE_POST
} from "../types/types";
const token = JSON.parse(localStorage.getItem('token'));


// export const getInstaReels =
//   (after, previous) =>
//     async (dispatch) => {
//       const res = await axios.post(
//         `social/ig/getreels?&after=${after}&previous=${previous}`,
//         // {
//         //   from_date: startDate,
//         //   to_date: endDate,
//         //   influencer_id: influencerId,
//         //   sort_by: sort,
//         // }
//       );
//       dispatch({
//         type: GET_INSTA_REELS,
//         payload: res.data,
//       });
//     };



export const getInstaReels = (after, previous) => (dispatch) => {
  return axios({
    method: "POST",
    url: `social/ig/getreels?&after=${after}&previous=${previous}`,
    // params: {
    //     page,
    //     limit
    // },
    // data: {
    //   after: after ?parseInt(after) : "",
    //   previous,
    // }
  })
    .then((response) => {
      dispatch({
        type: GET_INSTA_DATA,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const linkInstaReel = (data) => (dispatch) => {
  dispatch({ type: LINK_INSTA_REELS_REQUEST });
  return axios({
    method: "POST",
    url: `reviews/uploadreviewfromig`,
    data: data ,
  })
    .then((response) => {
      return dispatch({
        type: LINK_INSTA_REELS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: LINK_INSTA_REELS_ERROR,
        payload: error.response,
      });
    });
};


export const getLinkReel =
  (
    //ugc,
    status,
    page = 1,
    limit = 8,
    //status,
    startDate,
    endDate,
    //influencerId,
    //gender, category, subcategory,
    sort,
    //reviewID
  ) =>
    async (dispatch) => {
      const res = await axios.post(
        `reviews/getall?status=${status}&limit=${limit}&page=${page}`,
        {
          origin: 'ig',

          //   status: status,
          from_date: startDate,
          to_date: endDate,
          //   influencer_id: influencerId,
          //   gen_category_id: gender ? gender : "",
          //   category_id: category && category !== "all" ? category : "",
          //   sub_category_id: subcategory && subcategory !== "all" ? subcategory : "",
          sort_by: sort,
          //   review_counter: reviewID ? reviewID.replaceAll("-", "") : "",
          //   ugc: ugc
        }
      );
      dispatch({
        type: GET_INSTA_REEL_POST,
        payload: res.data.message,
      });
    };

export const getReelReviewControl =
  (
    ugc,
    page = 1,
    limit = 8,
    status,
    startDate,
    endDate,
    influencerId,
    gender, category, subcategory,
    sort,
    boostStatus,
    reviewID
  ) =>
    async (dispatch) => {
      const res = await axios.post(
        `reviews/getreviews?limit=${limit}&page=${page}`,
        {
          origin: 'ig',
          status: status,
          from_date: startDate,
          to_date: endDate,
          influencer_id: influencerId,
          gen_category_id: gender ? gender : "",
          category_id: category && category !== "all" ? category : "",
          sub_category_id: subcategory && subcategory !== "all" ? subcategory : "",
          sort_by: sort,
          review_counter: reviewID ? reviewID.replaceAll("-", "") : "",
          ugc: ugc,
          boost_status : boostStatus
        }
      );
      dispatch({
        type: GET_REEL_REVIEW_CONTROL_POST,
        payload: res.data.message,
      });
    };


export const linkReelProductUpdate = (data) => (dispatch) => {
  dispatch({ type: LINK_REEL_PRODUCT_UPDATE_SUCCESS });
  return axios({
    method: "POST",
    url: `reviews/create`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: LINK_REEL_PRODUCT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: LINK_REEL_PRODUCT_UPDATE_ERROR,
        payload: error.response,
      });
    });
};

export const reelDeleted = (data) => async () => {
  const res = axios.post(`reviews/delete`, data);
  return res;
};

export const reelPublish = (data) => async () => {
  const res = axios.post(`reviews/publishandunpublish`, data);
  return res;
};

export const getReelsDelete =
  (
    ugc,
    page = 1,
    limit = 8,
    status,
    startDate,
    endDate,
    influencerId,
    gender, category, subcategory,
    sort,
    reviewID
  ) =>
    async (dispatch) => {
      const res = await axios.post(
        `reviews/getreviews?limit=${limit}&page=${page}`,
        {
          origin: "ig",
          status: status,
          from_date: startDate,
          to_date: endDate,
          influencer_id: influencerId,
          gen_category_id: gender ? gender : "",
          category_id: category && category !== "all" ? category : "",
          sub_category_id: subcategory && subcategory !== "all" ? subcategory : "",
          sort_by: sort,
          review_counter: reviewID ? reviewID.replaceAll("-", "") : "",
          ugc: ugc
        }
      );
      dispatch({
        type: GET_REEL_DELETE_POST,
        payload: res.data.message,
      });
    };

export const reviewDeletedReel = (data) => async () => {
  const res = axios.post(`reviews/delete`, data);
  return res;
};