// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styled from 'styled-components';

export const StyledControls = styled.div`
  opacity: ${props => (props.active ? '1' : '0')};
  transition: opacity 250ms ease;

  @media screen and (max-width: 768px) {
    opacity: 1;
  }

  .controls-menu {
    width: 100%;
    position: static;
    box-shadow:none;
  }
  .ch-control-bar-item-iconButton {
    margin-bottom:10px;
  }
  .ch-control-bar-item-iconButton:hover {
    background-color: #052977;  
  }
  .ch-control-bar-item-iconButton:focus {
    outline:none;
    box-shadow:none;
  }
  .ch-control-bar-item-label {
    font-size: 0.75rem;
  }
  .ch-control-bar-item-caret:hover {
    background-color: #052977;
    outline:none;
    box-shadow:none;
  }
  .ch-control-bar-item-caret:focus {
    background-color: #052977;
    outline:none;
    box-shadow:none;
  }
  .ch-icon {
    height:initial!important;
  }
 
`;