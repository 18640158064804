import { GET_PROMO_REQUEST } from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';
export const getPromoRequest = () => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .get(`ecom/getpromocodes`, {
      })
      .then((res) => {
        dispatch({
          type: GET_PROMO_REQUEST,
          payload: res.data,
        });
        resolve('success');
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};
