import { GET_REVIEW_CONTROL_WITHOUT_PRODUCT_POST } from "../../types/types";

const initialState = []

export default function getReviewControlDataWithoutProduct(state = initialState, action) {
  switch (action.type) {
    case GET_REVIEW_CONTROL_WITHOUT_PRODUCT_POST:
      return action.payload
    default:
      return state;
  }
}
