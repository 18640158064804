import { GET_REFERRAL_SUMMARY, REFERRAL_STATS, CREATOR_STATS, GET_CREATOR_SUMMARY } from "../types/types";
import axios from "axios";

export const referralSummary = (id, event) => (dispatch) => {
  return axios({
    method: "POST",
    url: `brand/reports/getreferralstatsdetail`,
    data: {
      id,
      event,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_REFERRAL_SUMMARY,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const referralStatsReport =
  (page, limit, startDate, endDate, gender, category, subCategory, source, order, purchasedBy, ref_user, group_by, id, counter_type) => (dispatch) => {
    const newData = {
      from_date: startDate,
      to_date: endDate,
      gen_category: gender ? gender : "",
      category: category ? category : "",
      sub_category: subCategory ? subCategory : "",
      source: source ? source : "",
      order: order ? order : "",
      purchasedBy: purchasedBy ? purchasedBy : "",
      ref_user: ref_user ? ref_user : "",
      group_by: group_by ? group_by : ""
    }
    if (counter_type === "review") {
      newData.review = id?.value
    } else {
      newData.event = id?.value
    }
    return axios({
      method: "POST",
      url: `brand/reports/getreferralstatssummary?page=${page}&limit=${limit}`,
      data: newData,
    })
      .then((response) => {
        dispatch({
          type: REFERRAL_STATS,
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  };

export const creatorStatsReport =
  (page, limit, startDate, endDate, gender, category, subCategory, order, creator, purchasedBy, ref_user, group_by, id, counter_type) => (dispatch) => {
    const newData = {
      from_date: startDate,
      to_date: endDate,
      gen_category: gender ? gender : "",
      category: category ? category : "",
      sub_category: subCategory ? subCategory : "",
      order: order ? order : "",
      creator: creator ? creator : "",
      purchasedBy: purchasedBy ? purchasedBy : "",
      ref_user: ref_user ? ref_user : "",
      group_by: group_by ? group_by : ""
    }
    if (counter_type === "review") {
      newData.review = id?.value
    } else {
      newData.event = id?.value
    }
    return axios({
      method: "POST",
      url: `brand/reports/getcontentsalessummary?page=${page}&limit=${limit}`,
      data: newData,
    })
      .then((response) => {
        dispatch({
          type: CREATOR_STATS,
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  };

export const creatorSummary = (id, event) => (dispatch) => {
  return axios({
    method: "POST",
    url: `brand/reports/getcontentsalesdetail`,
    data: {
      id,
      event,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_CREATOR_SUMMARY,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
