import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  notification,
  Statistic,
  Select,
  Form,
  DatePicker,
  Input,
  Skeleton,
  Space,
} from "antd";
import Swal from "sweetalert2";
import numeral from "numeral";
import { useStepsForm } from "sunflower-antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteEvent,
  deleteEventRecord,
  publishEvent,
  getBrandRecordedEvents,
  getBrandPublishedEvents,
} from "../../redux/actions/event.action";
import ViewData from "../../components/saleDetailView/view";
import { getParentCategories } from "../../redux/actions/category.action";
import { ShareUrl } from "../../redux/actions/shareUrl.action";
import searchEventImg from "../../images/search-event.png";
import moment from "moment";
import {
  faFlask,
  faCalendarDays,
  faClock,
  faUser,
  faVideo,
  faXmark,
  faAngleLeft,
  faAngleRight,
  faPlay,
  faUserTie,
  faShareNodes,
  faPenToSquare,
  faPhotoFilm,
  faCartFlatbed,
  faEye,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import defaultImage from "../../images/user.png";
import { getUserInfo } from "../../redux/actions/userInfo.action";
import { directPublish, fbPublish } from "../../redux/actions/schedulePost";
import { CleaningServices, FacebookOutlined } from "@mui/icons-material";
import LoadingSpin from "../../reUsable/loadingSpin";
import {
  getSavedCategories,
  getSavedGeneralCategories,
  getSavedSubCategories,
} from "../../redux/actions/newCategory.action";
import { searchEventID } from "../../redux/actions/eventID.action";
import { getFeeStructure } from "../../redux/actions/feestructure.action";
import { visitorURL } from "../../config";
import { deleteExternalEvent, endLiveExternalStream, getExternalEvents, goLiveExternalStream } from "../../redux/actions/externalEvent.action";
import useWebSocket from 'react-use-websocket';
const socketUrl = process.env.REACT_APP_SOCKET_URL
const { Option } = Select;

const { Countdown } = Statistic;

const { confirm } = Modal;
const isoStr = new Date().toISOString();
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const dateFormat = "MM-DD-YYYY";

export default ({ className, title, eventStatus, roleKey }) => {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSocial, setIsModalOpenSocial] = useState(false);
  const [sortBy, setSortBy] = useState("end");
  const [caption, setCaption] = useState("");
  const [mediaurl, setMediaurl] = useState("");
  const [eventNum, setEventNum] = useState("");
  const [socialName, setSocialName] = useState("");
  const [loading, setLoading] = useState(false);
  const [generalLoad, setGeneralLoad] = useState(false);
  const [catLoad, setCatLoad] = useState(false);
  const [subCatLoad, setSubCatLoad] = useState(false);
  const [kBSLYlinkFB, setKBSLYlinkFB] = useState("");
  const [kBSLYlinkIG, setKBSLYlinkIG] = useState("");
  // const fromDate = moment().startOf('month').format('YYYY-MM-DD');
  // const toDate = moment(new Date()).format('YYYY-MM-DD');
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const fromDate = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
  const [endDate, setEndDate] = useState(toDate);
  const [startDate, setStartDate] = useState(fromDate);
  const [gender, setGender] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [shareLoad, setShareLoad] = useState(false);
  const [order, setOrder] = useState("");
  const [showChild, setshowChild] = useState(false);
  const [eventID, setEventID] = useState();
  const [eventIDLoad, setEventIDLoad] = useState("");
  const [searchEventIDs, setSearchEventIDs] = useState([]);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [formStore] = Form.useForm();

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };
  //sadsada
  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({});

  const didUnmount = useRef(false);
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      return didUnmount.current === false;
    },
    reconnectAttempts: 2,
    reconnectInterval: 3000,
  });
  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);
  console.log(lastMessage?.data, 'lastMessage');

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalSocial = () => {
    setIsModalOpenSocial(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalOpenSocial(false);
  };

  const handleCancel = () => {
    setSocialName("");
    setIsModalOpen(false);
    setIsModalOpenSocial(false);
  };

  const onFinish = () => {

  };

  const dispatch = useDispatch();
  const history = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const {
    getExternalEvent,
    shareUrls,
    savedGeneralCategories,
    savedCategory,
    savedSubCategory,
    userInfo,
    brandRecordedEvents,
    getBrandPublishEvents,
    validate,
    getStructureFees
  } = useSelector((state) => {
    return state;
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const limit = 8;

  useEffect(() => {
    setStartDate(moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"))
    setEndDate(moment().format("YYYY-MM-DD"))
    setSortBy("end");
    dispatch(getFeeStructure()).then((res) => {
    })
    setEventIDLoad(true)
    dispatch(searchEventID("", title?.toLowerCase(), title == "Recorded" ? moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD") : "", title == "Recorded" ? moment().format("YYYY-MM-DD") : "")).then((res) => {
      setEventIDLoad(false)
      if (res?.success) {
        setSearchEventIDs(res?.message)
      }
    })

    setGeneralLoad(true);
    dispatch(getSavedGeneralCategories()).then((res) => {
      if (res.success) {
        setGeneralLoad(false);
      } else {
        setGeneralLoad(false);
      }
    });

    dispatch(getUserInfo());
    setCurrentPage(0);
    if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Recorded"
    ) {
      dispatch(
        getBrandRecordedEvents(
          limit,
          1,
          title,
          // sortBy,
          "end",
          // startDate,
          // endDate,
          moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
          // gender,
          // category,
          // subCategory
          "",
          "",
          ""
        )
      ).then((res) => {
        setLoading(false);
      });
    } else if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Published"
    ) {
      dispatch(
        getBrandPublishedEvents(
          limit,
          1,
          title,
          // sortBy,
          "end",
          // startDate,
          // endDate,
          moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
          // gender,
          // category,
          // subCategory
          "",
          "",
          ""
        )
      ).then((res) => {
        setLoading(false);
      });
    } else {

      dispatch(getExternalEvents(limit, 1, title, sortBy)).then((res) => {
        if (res.payload.success) {
          // setPageCount(res.payload.message?.next?.page);
        } else {

        }
      });
    }
    setGender(null);
    setCategory(null);
    setEventID(null);
    setSubCategory(null);
  }, [title, eventStatus]);

  useEffect(() => {
    dispatch(getParentCategories()).then((res) => { });

  }, []);

  useEffect(() => {
    if (lastMessage && lastMessage?.data) {
      let newData = JSON.parse(lastMessage.data)
      if (newData?.event) {
        const match = newData.event.some(item => item.brand == validate?.payload?.message?._id);
        if (match) {
          dispatch(getExternalEvents(limit, currentPage + 1, title, sortBy));
        }
      }

    }
  }, [lastMessage])

  const controlRoom = (val) => {
    history({
      pathname: `/control-room/${val?.event?._id}`,
    });
  };

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: "Url Copied!",
      className: "toast-success",
    });
    setIsModalOpen(false);
  };

  const shareKey = (id, title, tog) => {
    if (!(tog === "stop")) {
      setIsModalOpen(true);
      setShareLoad(true);
    }
    let UrlKey = "";
    let Params = "";
    if (title === "Live Events") {
      UrlKey = "live-event";
    } else if (title === "Recorded" || title === "Published") {
      UrlKey = "published-events";
      Params = "?page=1&origin=marketplace/recorded&brand=&department=&category=null&username"
    } else {
      UrlKey = "upcoming-event";
      Params = "?origin=marketplace/upcoming&department=&category=null"
    }
    let url = `${visitorURL}/${UrlKey}/${id}/${title === "Upcoming"
      ? validate?.payload?.message?.username
        ? validate?.payload?.message?.username
        : validate?.payload?.message?.pixel_id
      : ""
      }`;
    let data = { url: url + Params };
    dispatch(ShareUrl(data)).then((res) => {
      if (res.payload.success) {
        setKBSLYlinkFB(res?.payload?.message);
        // if (!(tog === "stop")) {
        //   setIsModalOpen(true);
        // }
        setShareLoad(false);
      } else {
        console.log("err");
      }
    });
    let instaURL = `${visitorURL}${validate?.payload?.message?.username
      ? validate?.payload?.message?.username
      : validate?.payload?.message?.pixel_id
      }`;
    if (tog === "stop") {
      dispatch(ShareUrl({ url: instaURL })).then((res) => {
        if (res.payload.success) {
          setKBSLYlinkIG(res?.payload?.message);
        }
      });
    }
  };

  const recordedEvents = (id, pageName) => {
    console.log(id, 'idid');

    history(`/external-recorded-event/${id}`, {
      state: {
        pageName,
      },
    });
  };

  const manageInventory = (id) => {
    history(`/inventory/manage/${id}`, {
      state: {
        title,
        inventory: 'external',
      },
    });
  };

  const handleEventID = (e) => {
    setEventIDLoad(true)
    dispatch(searchEventID(e.replaceAll("-", ''), title?.toLowerCase(), title == "Recorded" ? startDate : "", title == "Recorded" ? endDate : "")).then((res) => {
      setEventIDLoad(false)
      if (res?.success) {
        setSearchEventIDs(res?.message)
      }
    })
  }

  const handleButtonClick = (e, item) => {
    if (e.key === "cancel") {
      confirm({
        wrapClassName: "modal-generic cancel-event-modal min-modal-600",
        title: "Warning",
        centered: true,
        closable: true,
        icon: <ExclamationCircleOutlined />,
        closeIcon: <FontAwesomeIcon icon={faXmark} />,
        content: "Are you sure you want to delete this event ?",
        okText: " Yes, delete",
        okType: "danger",
        cancelText: " No, I don't want to delete ",

        onOk() {
          return new Promise((resolve, reject) => {
            if (title === "Upcoming") {
              dispatch(deleteExternalEvent(item?.event?._id)).then((res) => {
                if (res.payload.success) {
                  notification.success({
                    message: res.payload?.message,
                    className: "toast-success",
                  });

                  if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Recorded"
                  ) {
                    if (
                      brandRecordedEvents?.payload?.message?.data?.length === 1
                    ) {
                      dispatch(
                        getBrandRecordedEvents(
                          limit,
                          currentPage,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          gender,
                          category,
                          subCategory
                        )
                      );
                      setCurrentPage(currentPage - 1);
                      setTimeout(resolve, 100);
                    } else {
                      dispatch(
                        getBrandRecordedEvents(
                          limit,
                          currentPage + 1,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          gender,
                          category,
                          subCategory
                        )
                      );
                      setTimeout(resolve, 100);
                    }
                  } else if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Published"
                  ) {
                    if (
                      getBrandPublishEvents?.payload?.message?.data?.length ===
                      1
                    ) {
                      dispatch(
                        getBrandPublishedEvents(
                          limit,
                          currentPage,
                          title,
                          startDate,
                          endDate,
                          sortBy,
                          gender,
                          category,
                          subCategory
                        )
                      );
                      setCurrentPage(currentPage - 1);
                      setTimeout(resolve, 100);
                    } else {
                      dispatch(
                        getBrandPublishedEvents(
                          limit,
                          currentPage + 1,
                          title,
                          startDate,
                          endDate,
                          sortBy,
                          gender,
                          category,
                          subCategory
                        )
                      );
                      setTimeout(resolve, 100);
                    }
                  } else {
                    if (getExternalEvent?.payload?.message?.data?.length === 1) {
                      dispatch(getExternalEvents(limit, currentPage, title, sortBy));

                      setCurrentPage(currentPage - 1);
                      setTimeout(resolve, 100);
                    } else {
                      dispatch(
                        getExternalEvents(limit, currentPage + 1, title, sortBy)
                      );
                      setTimeout(resolve, 100);
                    }
                  }
                } else {
                  notification.error({
                    message: res.payload.data.message,
                    className: "toast-error",
                  });
                }
              });
            } else {
              dispatch(
                deleteEventRecord(
                  validate?.payload?.message?.account_type === "brand" &&
                    (title === "Recorded" || title === "Published")
                    ? item?._id
                    : item?.event?._id
                )
              ).then((res) => {
                if (res.payload.success) {
                  notification.success({
                    message: res.payload?.message,
                    className: "toast-success",
                  });
                  if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Recorded"
                  ) {
                    if (
                      brandRecordedEvents?.payload?.message?.data?.length === 1
                    ) {
                      dispatch(
                        getBrandRecordedEvents(
                          limit,
                          currentPage,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          gender,
                          category,
                          subCategory
                        )
                      );
                      setCurrentPage(currentPage - 1);
                      setTimeout(resolve, 100);
                    } else {
                      dispatch(
                        getBrandRecordedEvents(
                          limit,
                          currentPage + 1,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          gender,
                          category,
                          subCategory
                        )
                      );
                      setTimeout(resolve, 100);
                    }
                  }
                  if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Published"
                  ) {
                    if (
                      getBrandPublishEvents?.payload?.message?.data?.length ===
                      1
                    ) {
                      dispatch(
                        getBrandPublishedEvents(
                          limit,
                          currentPage,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          gender,
                          category,
                          subCategory
                        )
                      );
                      setCurrentPage(currentPage - 1);
                      setTimeout(resolve, 100);
                    } else {
                      dispatch(
                        getBrandPublishedEvents(
                          limit,
                          currentPage + 1,
                          title,
                          sortBy,
                          startDate,
                          endDate,
                          gender,
                          category,
                          subCategory
                        )
                      );
                      setTimeout(resolve, 100);
                    }
                  } else {
                    if (getExternalEvent?.payload?.message?.data?.length === 1) {
                      dispatch(getExternalEvents(limit, currentPage, title, sortBy));

                      setCurrentPage(currentPage - 1);
                      setTimeout(resolve, 100);
                    } else {
                      dispatch(
                        getExternalEvents(limit, currentPage + 1, title, sortBy)
                      );
                      setTimeout(resolve, 100);
                    }
                  }
                } else {
                  notification.error({
                    message: res.payload.data.message,
                    className: "toast-error",
                  });
                }
              });
            }
          });
        },

        onCancel() {
          console.log("Cancel");
        },
      });
    }
    if (e.key === "edit") {
      history({
        pathname: `/schedule-external-event-id/${validate?.payload?.message?.account_type === "brand" &&
          (title === "Recorded" || title === "Published")
          ? item?._id + "?page=edit"
          : item?.event?._id + "?page=edit"
          }`,
      });
    }
    if (e.key === "editproduct") {
      history({
        pathname: `/schedule-external-event-id/${validate?.payload?.message?.account_type === "brand" &&
          (title === "Recorded" || title === "Published")
          ? item?._id + "?page=product"
          : item?.event?._id + "?page=product"
          }`,
      });
    }
    if (e.key === "publish") {
      if (
        validate?.payload?.message?.account_type === "brand" &&
          (title === "Recorded" || title === "Published")
          ? item?.is_published
          : item?.event?.is_published
      ) {
        confirm({
          wrapClassName: "modal-generic cancel-event-modal min-modal-600",
          // title: 'Warning',
          centered: true,
          closable: true,
          icon: <ExclamationCircleOutlined />,
          closeIcon: <FontAwesomeIcon icon={faXmark} />,
          content: "Are you sure you want to unpublish this event ?",
          okText: " Yes, Unpublish",
          okType: "success",
          cancelText: " No, I don't want to unpublish",

          onOk() {
            return new Promise((resolve, reject) => {
              dispatch(
                publishEvent(
                  validate?.payload?.message?.account_type === "brand" &&
                    (title === "Recorded" || title === "Published")
                    ? item?._id
                    : item?.event?._id,
                  "unpublish"
                )
              ).then((res) => {
                if (res.payload.success) {
                  notification.success({
                    message: res.payload?.message,
                    className: "toast-success",
                  });
                  if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Recorded"
                  ) {
                    dispatch(
                      getBrandRecordedEvents(
                        limit,
                        currentPage + 1,
                        title,
                        sortBy,
                        startDate,
                        endDate,
                        gender,
                        category,
                        subCategory
                      )
                    );
                  } else if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Published"
                  ) {
                    dispatch(
                      getBrandPublishedEvents(
                        limit,
                        currentPage + 1,
                        title,
                        sortBy,
                        startDate,
                        endDate,
                        gender,
                        category,
                        subCategory
                      )
                    );
                  } else {
                    dispatch(getExternalEvents(limit, currentPage + 1, title, sortBy));
                  }
                  setTimeout(resolve, 100);
                } else {
                  notification.error({
                    message: res.payload.data.message,
                    className: "toast-error",
                  });
                }
              });
            });
          },

          onCancel() {
            console.log("cancel UnPublish");
          },
        });
      } else {
        confirm({
          wrapClassName: "modal-generic cancel-event-modal min-modal-600",
          // title: 'Warning',
          centered: true,
          closable: true,
          icon: <ExclamationCircleOutlined />,
          closeIcon: <FontAwesomeIcon icon={faXmark} />,
          content: "Are you sure you want to publish this event ?",
          okText: " Yes, publish",
          okType: "success",
          cancelText: " No, I don't want to publish",

          onOk() {
            return new Promise((resolve, reject) => {
              dispatch(
                publishEvent(
                  validate?.payload?.message?.account_type === "brand" &&
                    title === ("Recorded" || "Published")
                    ? item?._id
                    : item?.event?._id,
                  "publish"
                )
              ).then((res) => {
                if (res.payload.success) {
                  notification.success({
                    message: res.payload?.message,
                    className: "toast-success",
                  });
                  if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Recorded"
                  ) {
                    dispatch(
                      getBrandRecordedEvents(
                        limit,
                        currentPage + 1,
                        title,
                        sortBy,
                        startDate,
                        endDate,
                        gender,
                        category,
                        subCategory
                      )
                    );
                  } else if (
                    validate?.payload?.message?.account_type === "brand" &&
                    title === "Published"
                  ) {
                    dispatch(
                      getBrandPublishedEvents(
                        limit,
                        currentPage + 1,
                        title,
                        sortBy,
                        startDate,
                        endDate,
                        gender,
                        category,
                        subCategory
                      )
                    );
                  } else {
                    dispatch(getExternalEvents(limit, currentPage + 1, title, sortBy));
                  }
                  setTimeout(resolve, 100);
                } else {
                  notification.error({
                    message: res.payload.data.message,
                    className: "toast-error",
                  });
                }
              });
            });
          },

          onCancel() {
            console.log("cancel Publish");
          },
        });
      }
    }
    if (e.key === "download") {
      // const a = document.createElement('a')
      // // a.href = item?.recording_url
      // a.href = "https://d1lgl9tsums24s.cloudfront.net/assets/b9e0a529-21ad-41f7-b048-ab3948a7d052/MP4/master.mp4"
      // a.download = "https://d1lgl9tsums24s.cloudfront.net/assets/b9e0a529-21ad-41f7-b048-ab3948a7d052/MP4/master.mp4".split('/').pop()
      // document.body.appendChild(a)
      // a.click()
      // document.body.removeChild(a)
      // window.location.assign("https://d1lgl9tsums24s.cloudfront.net/assets/b9e0a529-21ad-41f7-b048-ab3948a7d052/MP4/master.mp4", '_blank');

      // try {
      //   const videoUrl = "https://d1lgl9tsums24s.cloudfront.net/assets/b9e0a529-21ad-41f7-b048-ab3948a7d052/MP4/master.mp4";
      //   const videoRequest = new Request(videoUrl);
      //   fetch(videoRequest)
      //     .then(() => {
      //       const link = document.createElement('a');
      //       link.href = videoUrl;
      //       link.setAttribute('download', 'waterfall.mp4');
      //       document.body.appendChild(link);
      //       link.click();
      //       document.body.removeChild(link);
      //     });
      // } catch (error) {
      //   console.error(error);
      // }

      setDownloadLoader(true);
      const videoUrl = item?.download_url;
      const videoRequest = new Request(videoUrl);
      fetch(videoRequest)
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            videoUrl.split('/').pop(),
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
          setDownloadLoader(false);
        });
    }
  };

  const load =
    validate?.payload?.message?.account_type === "brand" && title === "Recorded"
      ? brandRecordedEvents.loading
      : validate?.payload?.message?.account_type === "brand" &&
        title === "Published"
        ? getBrandPublishEvents.loading
        : getExternalEvent.loading;

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Recorded"
    ) {
      dispatch(
        getBrandRecordedEvents(
          limit,
          page + 1,
          title,
          sortBy,
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          gender,
          category,
          subCategory
        )
      ).then((res) => {
        if (res.payload.success) {
          setLoading(false);
        } else {
          console.log("err");
          setLoading(false);
        }
      });
    } else if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Published"
    ) {
      dispatch(
        getBrandPublishedEvents(
          limit,
          page + 1,
          title,
          sortBy,
          startDate,
          endDate,
          gender,
          category,
          subCategory
        )
      ).then((res) => {
        if (res.payload.success) {
          setLoading(false);
        } else {
          console.log("err");
          setLoading(false);
        }
      });
    } else {
      dispatch(getExternalEvents(limit, page + 1, title, sortBy)).then((res) => {
        if (res.payload.success) {
          setLoading(false);
        } else {
          console.log("err");
          setLoading(false);
        }
      });
    }
  };

  function roomDisable(item) {
    const dateIsAfter = moment(item.start_date).isAfter(
      moment(moment.utc().format())
    );
    if (dateIsAfter) {
      return false;
    } else {
      return true;
    }
  }

  const handleChange = (value) => {
    setSortBy(value);
  };

  const postSocialDetails = () => {
    setLoading(true);
    switch (socialName) {
      case "Instagram":
        return dispatch(
          directPublish({
            media_url:
              getExternalEvent?.payload?.message?.data?.[eventNum]?.event?.banner,
            caption: form
              .getFieldValue()
              .notes.replace(/\r?\n|\r/g, "")
              .trim(),
          })
        )
          .then((res) => {
            if (res?.data?.success) {
              setIsModalOpenSocial(false);
              setLoading(false);
              setSocialName("");
              notification.success({
                message: "Post Has Been Published",
                className: "toast-success",
              });
            } else {
              setLoading(false);
              notification.error({
                message: "An Error Occurred While Publishing",
                className: "toast-error",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: "An Error Occurred While Publishing",
              className: "toast-error",
            });
          });

      case "Facebook":
        return dispatch(
          fbPublish({
            media_url:
              getExternalEvent?.payload?.message?.data?.[eventNum]?.event?.banner,
            caption: form
              .getFieldValue()
              .notes.replace(/\r?\n|\r/g, "")
              .replace(kBSLYlinkFB, "")
              .trim(),
            link: kBSLYlinkFB,
          })
        )
          .then((res) => {
            if (res?.data?.success) {
              setIsModalOpenSocial(false);
              setLoading(false);
              setSocialName("");
              notification.success({
                message: "Post Has Been Published",
                className: "toast-success",
              });
            } else {
              setLoading(false);
              notification.error({
                message: "An Error Occurred While Publishing",
                className: "toast-error",
              });
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: "An Error Occurred While Publishing",
              className: "toast-error",
            });
          });

      case "Pinterest":
        setLoading(false);
        return console.log("Pinterest");
      case "LinkedIn":
        setLoading(false);
        return console.log("LinkedIn");
      default:
        return null;
    }
  };

  const handleSocial = (e) => {
    setSocialName(e);
    switch (e) {
      case "Instagram":
        form.setFieldsValue({
          // notes: getExternalEvent?.payload?.message?.data?.[eventNum]?.event?.title + `\n \n${kBSLYlinkIG}`,
          notes:
            getExternalEvent?.payload?.message?.data?.[eventNum]?.event?.title +
            "\n \n" +
            `Check My Bio Link! ` +
            `\n${kBSLYlinkIG}`,
        });
        break;
      case "Facebook":
        form.setFieldsValue({
          notes:
            getExternalEvent?.payload?.message?.data?.[eventNum]?.event?.title +
            "\n \n" +
            kBSLYlinkFB,
        });
        break;
      default:
        return null;
    }
  };

  const handleGender = (e) => {
    setCurrentPage(0);
    setGender(e);
    setCategory(null);
    setSubCategory(null);
    setCatLoad(true);
    dispatch(getSavedCategories(e)).then((res) => {
      if (res.success) {
        setCatLoad(false);
      } else {
        setCatLoad(false);
      }
    });
  };

  const handleCategory = (e) => {
    setCurrentPage(0);
    setCategory(e);
    setSubCategory(null);
    setSubCatLoad(true);
    dispatch(getSavedSubCategories(gender, e)).then((res) => {
      if (res.success) {
        setSubCatLoad(false);
      } else {
        setSubCatLoad(false);
      }
    });
  };

  const handleSubCategory = (e) => {
    setCurrentPage(0);
    setSubCategory(e);
  };

  const nFormatter = (num) => {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    if (num < 1000) {
      return numeral(num).format("0.00")
    }
    return num;
  };

  const trigger = (item) => {
    setOrder(item);
    setshowChild(true);
  };

  const handleSubmit = (e) => {
    setEventIDLoad(true)
    dispatch(searchEventID("", title?.toLowerCase(), title == "Recorded" ? moment(startDate).format("YYYY-MM-DD") : "", title == "Recorded" ? moment(endDate).format("YYYY-MM-DD") : "")).then((res) => {
      setEventIDLoad(false)
      if (res?.success) {
        setSearchEventIDs(res?.message)
      }
    })
    setLoading(true);
    e.preventDefault();
    setCurrentPage(0);
    if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Recorded"
    ) {
      dispatch(
        getBrandRecordedEvents(
          limit,
          1,
          title,
          sortBy,
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          gender,
          category,
          subCategory,
          eventID
        )
      ).then((res) => {
        setLoading(false);
      });
    } else if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Published"
    ) {
      dispatch(
        getBrandPublishedEvents(
          limit,
          1,
          title,
          sortBy,
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          gender,
          category,
          subCategory,
          eventID
        )
      ).then((res) => {
        setLoading(false);
      });
    } else {
      dispatch(getExternalEvents(limit, 1, title, sortBy)).then((res) => {
        setLoading(false);
      });
    }
  };

  const handleReset = () => {
    setLoading(true);
    setGender(null);
    setCategory(null);
    setSubCategory(null);
    setSortBy("end");
    setCurrentPage(0);
    setEventID(null)
    setStartDate(fromDate);
    setEndDate(toDate);

    setEventIDLoad(true)
    dispatch(searchEventID("", title?.toLowerCase(), title == "Recorded" ? fromDate : "", title == "Recorded" ? toDate : "")).then((res) => {
      setEventIDLoad(false)
      if (res?.success) {
        setSearchEventIDs(res?.message)
      }
    })

    if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Recorded"
    ) {
      dispatch(
        getBrandRecordedEvents(
          limit,
          1,
          title,
          "end",
          fromDate,
          toDate,
          "",
          "",
          ""
        )
      ).then((res) => {
        setLoading(false);
      });
    } else if (
      validate?.payload?.message?.account_type === "brand" &&
      title === "Published"
    ) {
      dispatch(
        getBrandPublishedEvents(
          limit,
          1,
          title,
          "end",
          fromDate,
          toDate,
          "",
          "",
          ""
        )
      ).then((res) => {
        setLoading(false);
      });
    } else {
      dispatch(getExternalEvents(limit, 1, title, sortBy)).then((res) => {
        setLoading(false);
      });
    }
  };


  function onDelete(seletedItem) {

    // setCurrentData(seletedItem);
    Swal.fire({
      title: "Are You Sure?",
      text: "You Want To Delete This Event?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteLoading(true);

        dispatch(deleteExternalEvent(seletedItem?.event?._id))
          .then(() => {
            setDeleteLoading(false);
            notification.success({
              message: "Successfully Delete Event",
              className: "toast-success",
            });
            setLoading(true);

            dispatch(getExternalEvents(limit, 1, title, sortBy)).then((res) => {
              if (res.payload.success) {
                setLoading(false);
                // setPageCount(res.payload.message?.next?.page);
              } else {
                setLoading(false);
              }
            });
          })
          .catch((err) => {
            setDeleteLoading(false);
            notification.error({
              message: err.response.message,
              className: "toast-error",
            });
          });
      }
    });
  }
  function goLive(seletedItem) {
    // console.log(seletedItem);

    // setCurrentData(seletedItem);
    Swal.fire({
      title: "Are You Sure?",
      text: "You Want To Live This Event?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteLoading(true);
        dispatch(goLiveExternalStream(seletedItem?.event?._id))
          .then(() => {
            setDeleteLoading(false);
            const liveMessage = {
              type: 'eventmessage',
              event: [{ id: seletedItem?.event?._id, title: seletedItem?.event?.title, brand: validate?.payload?.message?._id }],
              // eventName: seletedItem?.event?.title,
              message: 'Live External Event'
            };
            sendMessage(JSON.stringify(liveMessage));
            notification.success({
              message: "Your event has gone live",
              className: "toast-success",
            });
            setLoading(true);

            dispatch(getExternalEvents(limit, 1, title, sortBy)).then((res) => {
              if (res.payload.success) {
                setLoading(false);
                // setPageCount(res.payload.message?.next?.page);
              } else {
                setLoading(false);
              }
            });
          })
          .catch((err) => {
            setDeleteLoading(false);
            notification.error({
              message: err.response.message,
              className: "toast-error",
            });
          });
      }
    });
  }
  function endLive(seletedItem) {
    // console.log(seletedItem);

    // setCurrentData(seletedItem);
    Swal.fire({
      title: "Are You Sure?",
      text: "You Want To End This Live Event?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteLoading(true);

        dispatch(endLiveExternalStream(seletedItem?.event?._id))
          .then(() => {
            setDeleteLoading(false);
            const endLiveMessage = {
              type: 'eventmessage',
              event: [{ id: seletedItem?.event?._id, title: seletedItem?.event?.title, brand: validate?.payload?.message?._id }],
              message: 'End External Event'
            };
            sendMessage(JSON.stringify(endLiveMessage));
            notification.success({
              message: "Your event has gone end",
              className: "toast-success",
            });
            setLoading(true);

            dispatch(getExternalEvents(limit, 1, title, sortBy)).then((res) => {
              if (res.payload.success) {
                setLoading(false);
                // setPageCount(res.payload.message?.next?.page);
              } else {
                setLoading(false);
              }
            });
          })
          .catch((err) => {
            setDeleteLoading(false);
            notification.error({
              message: err.response.message,
              className: "toast-error",
            });
          });
      }
    });
  }


  return (
    <>
      {(title === "Recorded" || title === "Published") && (
        <>
          <div className="row w-20-desktop-main">
            {validate?.payload?.message?.account_type === "brand" &&
              (title === "Recorded" || title === "Published") && (
                <>
                  {/* {validate?.payload?.message?.account_type === "brand" &&
                    title === "Recorded" && ( */}
                  <div className="col-xl-3 col-md-6 col-12 mb-15">
                    <p className="mb-5rem">Select Start Date / End Date</p>
                    <RangePicker
                      size="large"
                      className="w-100"
                      key={4}
                      value={
                        startDate && endDate
                          ? [moment(startDate), moment(endDate)]
                          : []
                      }
                      defaultValue={
                        startDate && endDate
                          ? [moment(startDate), moment(endDate)]
                          : []
                      }
                      allowClear={false}
                      ranges={{
                        "All Time": [
                          moment().subtract(1, "year").startOf("year"),
                          moment(),
                        ],
                        "YTD": [moment().startOf("year"), moment()],
                        "Last 30 Days": [moment().subtract(30, "days"), moment()],
                        "Last 7 Days": [moment().subtract(7, "days"), moment()],
                        "Today": [moment(), moment()],
                        // Today: [moment(), moment()],
                        // Tomorrow: [
                        //   moment().add(1, 'days'),
                        //   moment().add(1, 'days'),
                        // ],
                        // Yesterday: [
                        //   moment().subtract(1, 'days'),
                        //   moment().subtract(1, 'days'),
                        // ],
                        // "Last Year": [
                        //   moment().subtract(1, "year").startOf("year"),
                        //   moment().subtract(1, "year").endOf("year"),
                        // ],
                        // "This Year": [
                        //   moment().startOf("year"),
                        //   moment().endOf("year"),
                        // ],
                        // "Last Month": [
                        //   moment().subtract(1, "month").startOf("month"),
                        //   moment().subtract(1, "month").endOf("month"),
                        // ],
                        // "This Month": [
                        //   moment().startOf("month"),
                        //   moment().endOf("month"),
                        // ],
                      }}
                      format={dateFormat}
                      onChange={dateRangePickerChanger}
                    />
                  </div>
                  {/* // )} */}
                  <div className="col-xl-3 col-md-6 col-12 mb-15">
                    <p className="mb-5rem">Department</p>
                    <Select
                      size="large"
                      className="w-100"
                      key={4}
                      placeholder="Select Department"
                      focus={false}
                      onChange={handleGender}
                      value={gender}
                      onBlur={false}
                      loading={generalLoad}
                      disabled={!savedGeneralCategories || generalLoad}
                    >
                      {savedGeneralCategories &&
                        savedGeneralCategories?.message?.map((item, i) => {
                          return (
                            <Option value={item?.category_id}>
                              {item?.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                  <div className="col-xl-3 col-md-6 col-12 mb-15">
                    <p className="mb-5rem">Category</p>
                    <Select
                      size="large"
                      className="w-100"
                      key={4}
                      placeholder="Select Category"
                      focus={false}
                      onChange={handleCategory}
                      value={category}
                      onBlur={false}
                      loading={catLoad}
                      disabled={!savedCategory.success || catLoad || !gender}
                    >
                      <Option value="all">{"All"}</Option>
                      {savedCategory &&
                        savedCategory?.message?.map((item, i) => {
                          return (
                            <Option value={item?.category_id}>
                              {item?.category_name}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                  <div className="col-xl-3 col-md-6 col-12 mb-15">
                    <p className="mb-5rem">Sub Category</p>
                    <Select
                      size="large"
                      className="w-100"
                      key={4}
                      placeholder="Select Sub Category"
                      focus={false}
                      onChange={handleSubCategory}
                      value={subCategory}
                      onBlur={false}
                      loading={subCatLoad}
                      disabled={
                        !savedSubCategory?.success ||
                        subCatLoad ||
                        !gender ||
                        !category
                      }
                    >
                      <Option value="">{"All"}</Option>
                      {savedSubCategory &&
                        savedSubCategory?.message?.map((item, i) => {
                          return (
                            <Option value={item?.sub_category_id}>
                              {item?.sub_category_name}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                </>
              )}
            <div className="col-xl-3 col-md-6 col-12 mb-15">
              <p className="mb-5rem">Event ID</p>
              <Select
                showSearch
                size="large"
                className="w-100"
                key={4}
                notFoundContent={eventIDLoad && <Spin size="small" />}
                loading={eventIDLoad}
                optionFilterProp="children"
                placeholder="Enter Event ID"
                onChange={(e) => setEventID(e?.[0] + "-" + e?.split('E')[1].split('-').join('').match(/.{1,3}/g).join('-'))}
                optionLabelProp="label"
                onSearch={handleEventID}
                filterOption={(input, options) =>
                  options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                focus={false}
                value={eventID}
                onBlur={false}
              >
                {searchEventIDs && !eventIDLoad &&
                  searchEventIDs?.map((item, i) => {
                    return (
                      <Option value={item?.event_counter}>
                        {item?.event_counter?.[0] + "-" + item?.event_counter?.split('E')[1].split('-').join('').match(/.{1,3}/g).join('-')}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div className="col-xl-3 col-md-6 col-12 mb-15">
              <p className="mb-5rem">Sort By</p>
              <Select
                className="w-100 select-style dark-field"
                placeholder="Sort By"
                size="large"
                focus={false}
                onBlur={false}
                value={sortBy}
                onChange={handleChange}
                defaultValue="end"
              >
                <Option value="end">End Date</Option>
                <Option value="start">Start Date</Option>
                <Option value="scheduled">Scheduled Date</Option>
                <Option value="referral_fee">Referral Fee</Option>
                <Option value="influencer_fee">Influencer Fee</Option>
                <Option value="discount">Discount</Option>
                <Option value="sale">Sales</Option>
                <Option value="view">Views</Option>
              </Select>
            </div>
            <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end  mb-15">
              <Button
                onClick={handleSubmit}
                // className="default-btn fltr-hpr m-width-100 ml-0 fw-normal"
                className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                htmlType="submit"
                loading={loading}
              >
                Search
              </Button>

              <Button
                onClick={handleReset}
                // className="default-btn fltr-hpr m-width-100 ml-2 fw-normal"
                className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                htmlType="submit"
                loading={loading}
              >
                Reset
              </Button>
            </div>
          </div>
          <hr className="separator-line mt-0" />
        </>
      )}

      {!load ? (
        validate?.payload?.message?.account_type === "brand" &&
          title === "Recorded" ? (
          <>
            <div
              className={`upcoming-events-wrapper mb-30 ${className ? "grid-block" : "list-block"
                }`}
            >
              {!brandRecordedEvents.loading ? (
                <>
                  {brandRecordedEvents?.payload?.message?.data.length > 0 ? (
                    <div className="upcoming-events-grid manage-style">
                      {brandRecordedEvents?.payload?.message?.data?.map(
                        (item, ind) => {
                          return (
                            <>
                              <div className="upcoming-events-box">
                                {/* <div className="event-banner" style={{ backgroundImage: `url(${item.banner})` }} > */}
                                <div className="event-banner">
                                  <div className="event-box w-100 h-100 overflow-hidden">
                                    <div className="event-banner-img">
                                      <img src={item?.banner} alt="banner" />
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                      {item?.event_status === "live" ? (
                                        <div className="schedule-badge live">
                                          Live Now
                                        </div>
                                      ) : null}

                                      {moment(item?.start_date).format(
                                        "MM-DD-YYYY hh:mm A"
                                      ) <=
                                        moment(isoStr).format(
                                          "MM-DD-YYYY hh:mm A"
                                        ) &&
                                        item?.event_status === "scheduled" ? (
                                        <div className="schedule-badge past">
                                          Past
                                        </div>
                                      ) : item?.event_status === "scheduled" ? (
                                        <div className="schedule-badge schedule ">
                                          Scheduled
                                        </div>
                                      ) : null}
                                      {item?.event_status === "recorded" ? (
                                        <div className="d-flex justify-content-between w-100">
                                          <div className="schedule-badge Recorded ">
                                            Recorded
                                          </div>
                                          {item?.gen_category?.name && (
                                            <div className="schedule-badge schedule mr-2 banner-category ">
                                              {item?.gen_category?.name}
                                            </div>
                                          )}
                                        </div>
                                      ) : null}
                                      {/* </>
                                 )} */}

                                      {item?.event_status === "scheduled" ? (
                                        <div className="schedule-badge schedule countdown-timer">
                                          <Countdown
                                            value={item?.start_date}
                                            format="DD : HH : mm : ss"
                                          />
                                        </div>
                                      ) : null}
                                    </div>

                                    <div
                                      className={`d-flex ${item?.is_facebook_live
                                        ? "justify-content-between"
                                        : "justify-content-end"
                                        }`}
                                    >
                                      {item?.is_facebook_live && (
                                        <div className="ribbon-small fb-style mr-2">
                                          <FacebookOutlined className="mr-2 mb-1" />
                                          <span>Live on Facebook</span>
                                        </div>
                                      )}
                                      <div className="ribbon-small">
                                        <FontAwesomeIcon icon={faFlask} />
                                        <span>Event </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="event-card-content w-100 col d-flex flex-column justify-content-between">
                                  <div>


                                    <div className="event-date-main top-date">
                                      <div className="fee-box">
                                        <div className="event-date">
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faEye}
                                          />

                                          <div className="event-txt">
                                            {item?.views
                                              ? item?.views
                                              : "0"}
                                          </div>
                                        </div>
                                      </div>

                                      {item?.trans[0] ? (
                                        <div className="sales-amount">
                                          <div className="sales-hd1">
                                            Sales:
                                          </div>

                                          <a
                                            className="open-modal blue-link"
                                            onClick={() => trigger(item)}
                                          >
                                            $
                                            {nFormatter(
                                              item?.trans[0]?.total_amount
                                            )}
                                            {/* {numeral(item?.trans[0]?.total_amount).format("$0,0.0'")} */}
                                          </a>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>




                                    <div className="event-date-main top-date">
                                      {item?.event_status === "recorded" ? (
                                        <div className="event-date-recorded">
                                          Scheduled:
                                        </div>
                                      ) : null}

                                      <div className="event-date">
                                        {item?.event_status != "recorded" ? (
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faCalendarDays}
                                          />
                                        ) : null}
                                        <div
                                          // className="event-txt"
                                          className={`event-txt ${item?.event_status != "recorded"
                                            ? ""
                                            : "recorded-gap"
                                            }`}
                                        >
                                          {moment(item?.start_date)
                                            .utc()
                                            .format("MM-DD-YYYY")}
                                        </div>
                                      </div>

                                      <div className="event-date">
                                        {item?.event_status != "recorded" ? (
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faClock}
                                          />
                                        ) : null}

                                        <div className="event-txt">
                                          {moment(item?.start_date).format(
                                            "hh:mm A"
                                          )}
                                        </div>
                                      </div>


                                    </div>

                                    {item?.event_status === "recorded" ? (
                                      <div className="event-date-main top-date">
                                        {item?.event_status === "recorded" ? (
                                          <div className="event-date-recorded">
                                            Start:
                                          </div>
                                        ) : null}

                                        <div className="event-date">
                                          {item?.event_status != "recorded" ? (
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faCalendarDays}
                                            />
                                          ) : null}
                                          <div
                                            // className="event-txt"
                                            className={`event-txt ${item?.event_status != "recorded"
                                              ? ""
                                              : "recorded-gap"
                                              }`}
                                          >
                                            {moment(item?.started_at)
                                              .utc()
                                              .format("MM-DD-YYYY")}
                                          </div>
                                        </div>

                                        <div className="event-date">
                                          {item?.event_status != "recorded" ? (
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faClock}
                                            />
                                          ) : null}

                                          <div className="event-txt">
                                            {moment(item?.started_at).format(
                                              "hh:mm A"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {item?.event_status === "recorded" ? (
                                      <div className="event-date-main top-date">
                                        {item?.event_status === "recorded" ? (
                                          <div className="event-date-recorded">
                                            End:
                                          </div>
                                        ) : null}

                                        <div className="event-date">
                                          {item?.event_status != "recorded" ? (
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faCalendarDays}
                                            />
                                          ) : null}
                                          <div
                                            // className="event-txt"
                                            className={`event-txt ${item?.event_status != "recorded"
                                              ? ""
                                              : "recorded-gap"
                                              }`}
                                          >
                                            {moment(item?.ended_at)
                                              .utc()
                                              .format("MM-DD-YYYY")}
                                          </div>
                                        </div>

                                        <div className="event-date">
                                          {item?.event_status != "recorded" ? (
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faClock}
                                            />
                                          ) : null}

                                          <div className="event-txt">
                                            {moment(item?.ended_at).format(
                                              "hh:mm A"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {item?.event_status === "recorded" ? (
                                      <div className="event-date-main top-date">
                                        <div className="event-date-recorded">
                                          Duration:
                                        </div>

                                        {item?.event_status != "recorded" ? (
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faClock}
                                          />
                                        ) : null}
                                        <div className="event-txt">
                                          {moment
                                            .utc(
                                              moment(
                                                item?.ended_at,
                                                "HH:mm:ss"
                                              ).diff(
                                                moment(
                                                  item?.started_at,
                                                  "HH:mm:ss"
                                                )
                                              )
                                            )
                                            .format("HH:mm:ss")}
                                        </div>
                                      </div>
                                    ) : null}


                                    <h3 className="event-hd">{item?.title}</h3>
                                  </div>

                                  <div className="event-users-listing">
                                    <div className="event-date-main">
                                      {item?.hosts?.map((it, i) => {
                                        return (
                                          <>
                                            {it?.producer && (
                                              <div className="event-date bottom-style">
                                                <FontAwesomeIcon
                                                  className="event-icon"
                                                  icon={faUserTie}
                                                />
                                                <div className="event-txt">
                                                  Producer: {it.user_name}
                                                </div>
                                              </div>
                                            )}
                                            {it?.primary && (
                                              <div className="event-date bottom-style">
                                                <FontAwesomeIcon
                                                  className="event-icon"
                                                  icon={faUser}
                                                />
                                                <div className="event-txt">
                                                  Primary: {it.user_name}{" "}
                                                  {title ===
                                                    "Recorded" ? null : it.host_status ? (
                                                      <span class="status-flag-event-user green"></span>
                                                    ) : (
                                                    <span class="status-flag-event-user red"></span>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                            {it?.host1 && (
                                              <div className="event-date bottom-style">
                                                <FontAwesomeIcon
                                                  className="event-icon"
                                                  icon={faUser}
                                                />
                                                {/* <div className="event-txt">
                                             Host1: {it.user_name}
                                           </div> */}

                                                <div className="event-txt">
                                                  Host1: {it.user_name}{" "}
                                                  {title ===
                                                    "Recorded" ? null : it.host_status ? (
                                                      <span class="status-flag-event-user green"></span>
                                                    ) : (
                                                    <span class="status-flag-event-user red"></span>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                            {it?.host2 && (
                                              <div className="event-date bottom-style">
                                                <FontAwesomeIcon
                                                  className="event-icon"
                                                  icon={faUser}
                                                />
                                                {/* <div className="event-txt">
                                             Host2: {it.user_name}
                                           </div> */}
                                                <div className="event-txt">
                                                  Host2: {it.user_name}{" "}
                                                  {title ===
                                                    "Recorded" ? null : it.host_status ? (
                                                      <span class="status-flag-event-user green"></span>
                                                    ) : (
                                                    <span class="status-flag-event-user red"></span>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                            {it?.host3 && (
                                              <div className="event-date bottom-style">
                                                <FontAwesomeIcon
                                                  className="event-icon"
                                                  icon={faUser}
                                                />
                                                {/* <div className="event-txt">
                                             Host3: {it.user_name}
                                           </div> */}
                                                <div className="event-txt">
                                                  Host3: {it.user_name}{" "}
                                                  {title ===
                                                    "Recorded" ? null : it.host_status ? (
                                                      <span class="status-flag-event-user green"></span>
                                                    ) : (
                                                    <span class="status-flag-event-user red"></span>
                                                  )}
                                                </div>
                                              </div>
                                            )}

                                          </>
                                        );
                                      })}
                                    </div>
                                    <div className="fee-box-main">
                                      <div className="fee-box">
                                        <div className="fee-state red-state">
                                          {/* {item?.referral_percent === "0" ||
                                            item?.referral_percent === "" ||
                                            item?.referral_percent ===
                                            "undefined" ||
                                            !item?.referral_percent
                                            ? ""
                                            : item?.referral_percent +
                                            "%" +
                                            " Referral Fee"} */}
                                          {/* {getStructureFees?.payload?.message?.fee_structure?.referral_fee + "%" + " Referral Fee"} */}
                                        </div>
                                      </div>

                                      <div className="fee-box">
                                        <div className="fee-state orange-state">
                                          {/* {item?.influencer_percent === "0" ||
                                            item?.influencer_percent === "" ||
                                            item?.influencer_percent ===
                                            "undefined" ||
                                            !item?.influencer_percent
                                            ? ""
                                            : item?.influencer_percent +
                                            "%" +
                                            " Influencer Fee"} */}
                                          {/* {getStructureFees?.payload?.message?.fee_structure?.content_creator_fee + "%" + " Creator Fee"} */}
                                        </div>
                                      </div>

                                      <div className="fee-box">
                                        <div className="fee-state green-state">
                                          {item?.discount === "0%" ||
                                            item?.discount === "" ||
                                            item?.discount === "undefined" ||
                                            !item?.discount
                                            ? ""
                                            : item?.discount + " Discount"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {item?.event_counter && <p className="mt-2 mb-0"><span className="fw-bold">Event ID:</span> {item?.event_counter?.[0] + "-" + item?.event_counter?.split('E')[1].split('-').join('').match(/.{1,3}/g).join('-')} </p>}
                                </div>
                                <div className="event-card-footer">
                                  {item?.recording_url &&
                                    item?.event_status != "live" ? (
                                    <>
                                      <Button
                                        className="default-btn d-flex align-items-center justify-content-center"
                                        type="primary"
                                        size="large"
                                        onClick={() =>
                                          recordedEvents(item?._id, title)
                                        }
                                        icon={
                                          <FontAwesomeIcon
                                            size="sm"
                                            className="event-icon"
                                            // icon={faVideo}
                                            icon={faPlay}
                                          />
                                        }
                                      >
                                        Play
                                      </Button>
                                      {roleKey === "inventoryManager" && (
                                        <Button
                                          className="default-btn d-flex align-items-center justify-content-center"
                                          type="primary"
                                          size="large"
                                          onClick={() =>
                                            manageInventory(item?._id)
                                          }
                                          icon={
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              icon={faCartFlatbed}
                                            />
                                          }
                                        >
                                          Manage Inventory
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {roleKey === "inventoryManager" ? (
                                        <Button
                                          className="default-btn d-flex align-items-center justify-content-center"
                                          type="primary"
                                          size="large"
                                          onClick={() =>
                                            manageInventory(item?._id)
                                          }
                                          icon={
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              // icon={faVideo}
                                              icon={faCartFlatbed}
                                            />
                                          }
                                        >
                                          Manage Inventory
                                        </Button>
                                      ) : (
                                        // control room btn 
                                        // <Button
                                        //   className="default-btn d-flex align-items-center justify-content-center"
                                        //   type="primary"
                                        //   size="large"
                                        //   onClick={() => {
                                        //     if (item?.producer) {
                                        //       if (item?.is_joined) {
                                        //         confirm({
                                        //           wrapClassName:
                                        //             "modal-generic cancel-event-modal min-modal-600",
                                        //           title: "Warning",
                                        //           centered: true,
                                        //           closable: true,
                                        //           icon: (
                                        //             <ExclamationCircleOutlined />
                                        //           ),
                                        //           closeIcon: (
                                        //             <FontAwesomeIcon
                                        //               icon={faXmark}
                                        //             />
                                        //           ),
                                        //           content:
                                        //             "Event is On, Do you want to end it?",
                                        //           okText: " Yes, end it",
                                        //           okType: "danger",
                                        //           cancelText:
                                        //             " No, I don't want to end it ",
                                        //           onOk() {
                                        //             controlRoom(item);
                                        //           },
                                        //           onCancel() {
                                        //             console.log("Cancel");
                                        //           },
                                        //         });
                                        //       } else {
                                        //         controlRoom(item);
                                        //       }
                                        //     } else {
                                        //       controlRoom(item);
                                        //     }
                                        //   }}
                                        //   icon={
                                        //     <FontAwesomeIcon
                                        //       size="sm"
                                        //       className="event-icon"
                                        //       icon={faVideo}
                                        //     />
                                        //   }
                                        //   disabled={
                                        //     // roomDisable(item) ||
                                        //     item.event_status === "recorded"
                                        //       ? true
                                        //       : false
                                        //   }
                                        // >
                                        //   Control Room
                                        // </Button>
                                        <Button
                                          loading
                                          className="default-btn d-flex align-items-center justify-content-center cursor-not-allowed"
                                          type="primary"
                                          size="large"
                                          // onClick={() =>
                                          //   recordedEvents(item?._id, title)
                                          // }
                                          icon={
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              // icon={faVideo}
                                              icon={faPlay}
                                            />
                                          }
                                        >
                                          Processing
                                        </Button>
                                      )}
                                    </>
                                  )}
                                  {roleKey !== "inventoryManager" && item?.recording_url ? (
                                    <>
                                      {/* <div>
                                        <Button
                                          className={`default-btn share-btn d-flex align-items-center justify-content-center`}
                                          type="primary"
                                          size="large"
                                          onClick={() => {
                                            showModalSocial();
                                            setEventNum(ind);
                                            shareKey(item?._id, title, "stop");
                                            form.setFieldsValue({
                                              notes: item?.title,
                                            });
                                          }}
                                          icon={
                                            // <ShareAltOutlined />
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              icon={faPhotoFilm}
                                            />
                                          }
                                          disabled={
                                            // roomDisable(item) ||
                                            item?.event_status === "recorded"
                                              ? true
                                              : false
                                          }
                                        ></Button>
                                      </div> */}
                                      <div>
                                        <Button
                                          className="default-btn share-btn d-flex align-items-center justify-content-center"
                                          type="primary"
                                          size="large"
                                          onClick={() =>
                                            shareKey(title === "Recorded" || title === "Published" ? item?._id : item?.event_id, title)
                                          }
                                          icon={
                                            // <ShareAltOutlined />
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              icon={faShareNodes}
                                            />
                                          }
                                        // disabled={
                                        //   // roomDisable(item) ||
                                        //   (item?.event_status === 'recorded' && !item?.is_published)
                                        //     ? true
                                        //     : false
                                        // }
                                        ></Button>
                                      </div>

                                      {item.event_status !== "live" && (
                                        <Dropdown.Button
                                          trigger="click"
                                          overlayClassName="control-room-drowpdown"
                                          size="large"
                                          disabled={
                                            item.event_status !== "scheduled" ||
                                              item.event_status !== "recorded"
                                              ? false
                                              : false
                                          }
                                          // onClick={() => handleButtonClick(item)}
                                          // overlay={menu}
                                          overlay={
                                            <Menu
                                              onClick={(e) =>
                                                handleButtonClick(e, item)
                                              }
                                            >
                                              {downloadLoader ?
                                                <Menu.Item
                                                  key="download"
                                                  disabled={true}
                                                  title="Downloading..."
                                                >
                                                  <span>Download <Spin className="grey-spin ml-2" size="small" /></span>
                                                </Menu.Item>
                                                :
                                                <Menu.Item
                                                  key="download"
                                                  disabled={!item?.download_url}
                                                >
                                                  <span>Download</span>
                                                </Menu.Item>
                                              }
                                              {item.event_status == "scheduled" &&
                                                <Menu.Item
                                                  key="edit"
                                                  disabled={
                                                    // roomDisable(item) ||
                                                    item.event_status ==
                                                      "scheduled"
                                                      ? false
                                                      : true
                                                  }
                                                >
                                                  <span>Edit</span>
                                                </Menu.Item>
                                              }
                                              <Menu.Item
                                                key="cancel"
                                                disabled={
                                                  // roomDisable(item) ||
                                                  item.event_status == "live" &&
                                                    item?.recording_url &&
                                                    item.event_status !=
                                                    "scheduled"
                                                    ? false
                                                    : null
                                                }
                                              >
                                                <span>Delete</span>
                                              </Menu.Item>

                                              {item.event_status ===
                                                "recorded" && (
                                                  <Menu.Item
                                                    key="publish"
                                                  // disabled={
                                                  //   item?.event?.is_published
                                                  //     ? true
                                                  //     : false
                                                  // }
                                                  >
                                                    <span>
                                                      {item?.is_published
                                                        ? "UnPublish"
                                                        : "Publish"}
                                                    </span>
                                                  </Menu.Item>
                                                )}
                                            </Menu>
                                          }
                                        ></Dropdown.Button>
                                      )}
                                    </>
                                  )
                                    : null
                                  }
                                  {/* )} */}
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div className="no-result-found mar-top-10">
                      <div className="no-result-img">
                        <img
                          src={
                            userInfo?.message?.data?.profile_image_url
                              ? userInfo?.message?.data?.profile_image_url
                              : validate?.payload?.message?.profile_image_url
                                ? validate?.payload?.message?.profile_image_url
                                : defaultImage
                          }
                          alt="searchEventImg"
                        />
                      </div>
                      <h3 className="no-event-hd"> No Event At This Time.</h3>
                      <p className="event-para">Please check back later.</p>
                      {/* <p>No Event At This Time. Please check back later.</p> */}
                    </div>
                  )}
                </>
              ) : (
                <>{null}</>
              )}

              {brandRecordedEvents?.payload?.message?.data.length > 0 && (
                <ReactPaginate
                  nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                  // marginPagesDisplayed={2}

                  pageCount={
                    brandRecordedEvents?.payload?.message?.next
                      ? brandRecordedEvents?.payload?.message?.next?.page
                      : brandRecordedEvents?.payload?.message?.previous
                        ? brandRecordedEvents?.payload?.message?.previous?.page +
                        1
                        : 1
                  }
                  forcePage={currentPage}
                  previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                  pageClassName="page-item d-none"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item d-none"
                  breakLinkClassName="page-link"
                  containerClassName={"pagination custom-paginate"}
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              )}
            </div>
          </>
        ) : validate?.payload?.message?.account_type === "brand" &&
          title === "Published" ? (
          <>
            <div
              className={`upcoming-events-wrapper mb-30 ${className ? "grid-block" : "list-block"
                }`}
            >
              {!getBrandPublishEvents.loading ? (
                <>
                  {getBrandPublishEvents?.payload?.message?.data.length > 0 ? (
                    <div className="upcoming-events-grid">
                      {getBrandPublishEvents?.payload?.message?.data?.map(
                        (item, ind) => {
                          return (
                            <>
                              <div className="upcoming-events-box">
                                {/* <div className="event-banner" style={{ backgroundImage: `url(${item.banner})` }} > */}
                                <div className="event-banner">
                                  <div className="event-box w-100 h-100 overflow-hidden">
                                    <div className="event-banner-img">
                                      <img src={item?.banner} alt="banner" />
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                      {/* {roomDisable(item) ? (
                                 <div className="schedule-badge expired">
                                   Expired
                                 </div>
                               ) : (
                                 <> */}
                                      {item?.event_status === "live" ? (
                                        <div className="schedule-badge live">
                                          Live Now
                                        </div>
                                      ) : null}

                                      {moment(item?.start_date).format(
                                        "MM-DD-YYYY hh:mm A"
                                      ) <=
                                        moment(isoStr).format(
                                          "MM-DD-YYYY hh:mm A"
                                        ) &&
                                        item?.event_status === "scheduled" ? (
                                        <div className="schedule-badge past">
                                          Past
                                        </div>
                                      ) : item?.event_status === "scheduled" ? (
                                        <div className="schedule-badge schedule ">
                                          Scheduled
                                        </div>
                                      ) : null}
                                      {item?.event_status === "recorded" ? (
                                        <div className="d-flex justify-content-between w-100">
                                          <div className="schedule-badge Recorded ">
                                            Recorded
                                          </div>
                                          {item?.category?.category_name && (
                                            <div className="schedule-badge schedule mr-2  banner-category">
                                              {item?.category?.category_name}
                                            </div>
                                          )}
                                        </div>
                                      ) : null}
                                      {/* </>
                               )} */}

                                      {item?.event_status === "scheduled" ? (
                                        <div className="schedule-badge schedule countdown-timer">
                                          <Countdown
                                            value={item?.start_date}
                                            format="DD : HH : mm : ss"
                                          />
                                        </div>
                                      ) : null}
                                    </div>

                                    <div
                                      className={`d-flex ${item?.is_facebook_live
                                        ? "justify-content-between"
                                        : "justify-content-end"
                                        }`}
                                    >
                                      {item?.is_facebook_live && (
                                        <div className="ribbon-small fb-style mr-2">
                                          <FacebookOutlined className="mr-2 mb-1" />
                                          <span>Live on Facebook</span>
                                        </div>
                                      )}
                                      <div className="ribbon-small">
                                        <FontAwesomeIcon icon={faFlask} />
                                        <span>Event </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="event-card-content w-100 col d-flex flex-column justify-content-between">
                                  <div>



                                    <div className="event-date-main top-date">
                                      <div className="fee-box">
                                        <div className="event-date">
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faEye}
                                          />

                                          <div className="event-txt">
                                            {item?.views
                                              ? item?.views
                                              : "0"}
                                          </div>
                                        </div>
                                      </div>

                                      {item?.trans[0] ? (
                                        <div className="sales-amount">
                                          <div className="sales-hd1">
                                            Sales:
                                          </div>

                                          <a
                                            className="open-modal blue-link"
                                            onClick={() => trigger(item)}
                                          >
                                            $
                                            {nFormatter(
                                              item?.trans[0]?.total_amount
                                            )}
                                            {/* {numeral(item?.trans[0]?.total_amount).format("$0,0.0'")} */}
                                          </a>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>




                                    <div className="event-date-main top-date">
                                      {item?.event_status === "recorded" ? (
                                        <div className="event-date-recorded">
                                          Scheduled:
                                        </div>
                                      ) : null}

                                      <div className="event-date">
                                        {item?.event_status != "recorded" ? (
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faCalendarDays}
                                          />
                                        ) : null}
                                        <div
                                          // className="event-txt"
                                          className={`event-txt ${item?.event_status != "recorded"
                                            ? ""
                                            : "recorded-gap"
                                            }`}
                                        >
                                          {moment(item?.start_date)
                                            .utc()
                                            .format("MM-DD-YYYY")}
                                        </div>
                                      </div>

                                      <div className="event-date">
                                        {item?.event_status != "recorded" ? (
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faClock}
                                          />
                                        ) : null}

                                        <div className="event-txt">
                                          {moment(item?.start_date).format(
                                            "hh:mm A"
                                          )}
                                        </div>
                                      </div>

                                    </div>

                                    {item?.event_status === "recorded" ? (
                                      <div className="event-date-main top-date">
                                        {item?.event_status === "recorded" ? (
                                          <div className="event-date-recorded">
                                            Start:
                                          </div>
                                        ) : null}

                                        <div className="event-date">
                                          {item?.event_status != "recorded" ? (
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faCalendarDays}
                                            />
                                          ) : null}
                                          <div
                                            // className="event-txt"
                                            className={`event-txt ${item?.event_status != "recorded"
                                              ? ""
                                              : "recorded-gap"
                                              }`}
                                          >
                                            {moment(item?.started_at)
                                              .utc()
                                              .format("MM-DD-YYYY")}
                                          </div>
                                        </div>

                                        <div className="event-date">
                                          {item?.event_status != "recorded" ? (
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faClock}
                                            />
                                          ) : null}

                                          <div className="event-txt">
                                            {moment(item?.started_at).format(
                                              "hh:mm A"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {item?.event_status === "recorded" ? (
                                      <div className="event-date-main top-date">
                                        {item?.event_status === "recorded" ? (
                                          <div className="event-date-recorded">
                                            End:
                                          </div>
                                        ) : null}

                                        <div className="event-date">
                                          {item?.event_status != "recorded" ? (
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faCalendarDays}
                                            />
                                          ) : null}
                                          <div
                                            // className="event-txt"
                                            className={`event-txt ${item?.event_status != "recorded"
                                              ? ""
                                              : "recorded-gap"
                                              }`}
                                          >
                                            {moment(item?.ended_at)
                                              .utc()
                                              .format("MM-DD-YYYY")}
                                          </div>
                                        </div>

                                        <div className="event-date">
                                          {item?.event_status != "recorded" ? (
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faClock}
                                            />
                                          ) : null}

                                          <div className="event-txt">
                                            {moment(item?.ended_at).format(
                                              "hh:mm A"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {item?.event_status === "recorded" ? (
                                      <div className="event-date-main top-date">
                                        <div className="event-date-recorded">
                                          Duration:
                                        </div>

                                        {item?.event_status != "recorded" ? (
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faClock}
                                          />
                                        ) : null}
                                        <div className="event-txt">
                                          {moment
                                            .utc(
                                              moment(
                                                item?.ended_at,
                                                "HH:mm:ss"
                                              ).diff(
                                                moment(
                                                  item?.started_at,
                                                  "HH:mm:ss"
                                                )
                                              )
                                            )
                                            .format("HH:mm:ss")}
                                        </div>
                                      </div>
                                    ) : null}

                                    {/* <div className="event-date d-inline-flex">
                         <FontAwesomeIcon className="event-icon" icon={faCalendarDays} />
                         <div className="event-txt">
                             FRIDAY, JULY 29, 2022
                         </div>
                         <FontAwesomeIcon className="event-icon" icon={faClock} />
                         <div className="event-txt">
                             3:10 PM
                         </div>
                     </div> */}


                                    <h3 className="event-hd">{item?.title}</h3>
                                  </div>
                                  {/* <div className="event-users-listing">
                             <div className="event-date-main">
                               {item?.event?.hosts.map((it, i) => {
                                 return (
                                   <>
                                     {it?.producer && (
                                       <div className="event-date bottom-style">
                                         <FontAwesomeIcon
                                           className="event-icon"
                                           icon={faUserTie}
                                         />
                                         <div className="event-txt">
                                           Producer: {it.user_name}
                                         </div>
                                       </div>
                                     )}
                                     {it?.primary && (
                                       <div className="event-date bottom-style">
                                         <FontAwesomeIcon
                                           className="event-icon"
                                           icon={faUser}
                                         />
                                         <div className="event-txt">
                                           Primary: {it.user_name}
                                         </div>
                                       </div>
                                     )}
                                     {it?.host1 && (
                                       <div className="event-date bottom-style">
                                         <FontAwesomeIcon
                                           className="event-icon"
                                           icon={faUser}
                                         />
                                         <div className="event-txt">
                                           Host1: {it.user_name}
                                         </div>
                                       </div>
                                     )}
                                     {it?.host2 && (
                                       <div className="event-date bottom-style">
                                         <FontAwesomeIcon
                                           className="event-icon"
                                           icon={faUser}
                                         />
                                         <div className="event-txt">
                                           Host2: {it.user_name}
                                         </div>
                                       </div>
                                     )}
                                     {it?.host3 && (
                                       <div className="event-date bottom-style">
                                         <FontAwesomeIcon
                                           className="event-icon"
                                           icon={faUser}
                                         />
                                         <div className="event-txt">
                                           Host3: {it.user_name}
                                         </div>
                                       </div>
                                     )}
                                   </>
                                 );
                               })}
                             </div>
                           </div> */}
                                  <div className="event-users-listing">
                                    <div className="event-date-main">
                                      {item?.hosts?.map((it, i) => {
                                        return (
                                          <>
                                            {it?.producer && (
                                              <div className="event-date bottom-style">
                                                <FontAwesomeIcon
                                                  className="event-icon"
                                                  icon={faUserTie}
                                                />
                                                <div className="event-txt">
                                                  Producer: {it.user_name}
                                                </div>
                                              </div>
                                            )}
                                            {it?.primary && (
                                              <div className="event-date bottom-style">
                                                <FontAwesomeIcon
                                                  className="event-icon"
                                                  icon={faUser}
                                                />
                                                <div className="event-txt">
                                                  Primary: {it.user_name}{" "}
                                                  {title ===
                                                    "Recorded" ? null : it.host_status ? (
                                                      <span class="status-flag-event-user green"></span>
                                                    ) : (
                                                    <span class="status-flag-event-user red"></span>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                            {it?.host1 && (
                                              <div className="event-date bottom-style">
                                                <FontAwesomeIcon
                                                  className="event-icon"
                                                  icon={faUser}
                                                />
                                                {/* <div className="event-txt">
                                           Host1: {it.user_name}
                                         </div> */}

                                                <div className="event-txt">
                                                  Host1: {it.user_name}{" "}
                                                  {title ===
                                                    "Recorded" ? null : it.host_status ? (
                                                      <span class="status-flag-event-user green"></span>
                                                    ) : (
                                                    <span class="status-flag-event-user red"></span>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                            {it?.host2 && (
                                              <div className="event-date bottom-style">
                                                <FontAwesomeIcon
                                                  className="event-icon"
                                                  icon={faUser}
                                                />
                                                {/* <div className="event-txt">
                                           Host2: {it.user_name}
                                         </div> */}
                                                <div className="event-txt">
                                                  Host2: {it.user_name}{" "}
                                                  {title ===
                                                    "Recorded" ? null : it.host_status ? (
                                                      <span class="status-flag-event-user green"></span>
                                                    ) : (
                                                    <span class="status-flag-event-user red"></span>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                            {it?.host3 && (
                                              <div className="event-date bottom-style">
                                                <FontAwesomeIcon
                                                  className="event-icon"
                                                  icon={faUser}
                                                />
                                                {/* <div className="event-txt">
                                           Host3: {it.user_name}
                                         </div> */}
                                                <div className="event-txt">
                                                  Host3: {it.user_name}{" "}
                                                  {title ===
                                                    "Recorded" ? null : it.host_status ? (
                                                      <span class="status-flag-event-user green"></span>
                                                    ) : (
                                                    <span class="status-flag-event-user red"></span>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        );
                                      })}
                                    </div>
                                    <div className="fee-box-main">
                                      <div className="fee-box">
                                        <div className="fee-state red-state">
                                          {/* {item?.referral_percent === "0" ||
                                            item?.referral_percent === "" ||
                                            item?.referral_percent ===
                                            "undefined" ||
                                            !item?.referral_percent
                                            ? ""
                                            : item?.referral_percent +
                                            "%" +
                                            " Referral Fee"} */}
                                          {/* {getStructureFees?.payload?.message?.fee_structure?.referral_fee + "%" + " Referral Fee"} */}
                                        </div>
                                      </div>

                                      <div className="fee-box">
                                        <div className="fee-state orange-state">
                                          {/* {item?.influencer_percent === "0" ||
                                            item?.influencer_percent === "" ||
                                            item?.influencer_percent ===
                                            "undefined" ||
                                            !item?.influencer_percent
                                            ? ""
                                            : item?.influencer_percent +
                                            "%" +
                                            "  Creator Fee"} */}
                                          {/* {getStructureFees?.payload?.message?.fee_structure?.content_creator_fee + "%" + " Creator Fee"} */}
                                        </div>
                                      </div>

                                      <div className="fee-box">
                                        <div className="fee-state green-state">
                                          {item?.discount === "0%" ||
                                            item?.discount === "" ||
                                            item?.discount === "undefined" ||
                                            !item?.discount
                                            ? ""
                                            : item?.discount + " Discount"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {item?.event_counter && <p className="mt-2 mb-0"><span className="fw-bold">Event ID:</span> {item?.event_counter?.[0] + "-" + item?.event_counter?.split('E')[1].split('-').join('').match(/.{1,3}/g).join('-')} </p>}
                                </div>
                                <div className="event-card-footer">
                                  {item?.recording_url &&
                                    item?.event_status != "live" ? (
                                    <>
                                      <Button
                                        className="default-btn d-flex align-items-center justify-content-center"
                                        type="primary"
                                        size=""
                                        onClick={() =>
                                          recordedEvents(item?._id, title)
                                        }
                                        icon={
                                          <FontAwesomeIcon
                                            size="sm"
                                            className="event-icon"
                                            // icon={faVideo}
                                            icon={faPlay}
                                          />
                                        }
                                      >
                                        Play
                                      </Button>
                                      {roleKey === "inventoryManager" && (
                                        <Button
                                          className="default-btn  d-flex align-items-center justify-content-center"
                                          type="primary"
                                          size=""
                                          onClick={() =>
                                            manageInventory(item?._id)
                                          }
                                          icon={
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              icon={faCartFlatbed}
                                            />
                                          }
                                        >
                                          Manage Inventory
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {roleKey === "inventoryManager" ? (
                                        <Button
                                          className="default-btn d-flex align-items-center justify-content-center"
                                          type="primary"
                                          size="large"
                                          onClick={() =>
                                            manageInventory(item?._id)
                                          }
                                          icon={
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              // icon={faVideo}
                                              icon={faCartFlatbed}
                                            />
                                          }
                                        >
                                          Manage Inventory
                                        </Button>
                                      ) : (
                                        <Button
                                          className="default-btn d-flex align-items-center justify-content-center"
                                          type="primary"
                                          size="large"
                                          onClick={() => {
                                            if (item?.producer) {
                                              if (item?.is_joined) {
                                                confirm({
                                                  wrapClassName:
                                                    "modal-generic cancel-event-modal min-modal-600",
                                                  title: "Warning",
                                                  centered: true,
                                                  closable: true,
                                                  icon: (
                                                    <ExclamationCircleOutlined />
                                                  ),
                                                  closeIcon: (
                                                    <FontAwesomeIcon
                                                      icon={faXmark}
                                                    />
                                                  ),
                                                  content:
                                                    "Event is On, Do you want to end it?",
                                                  okText: " Yes, end it",
                                                  okType: "danger",
                                                  cancelText:
                                                    " No, I don't want to end it ",
                                                  onOk() {
                                                    controlRoom(item);
                                                  },
                                                  onCancel() {
                                                    console.log("Cancel");
                                                  },
                                                });
                                              } else {
                                                controlRoom(item);
                                              }
                                            } else {
                                              controlRoom(item);
                                            }
                                          }}
                                          icon={
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              icon={faVideo}
                                            />
                                          }
                                          disabled={
                                            // roomDisable(item) ||
                                            item.event_status === "recorded"
                                              ? true
                                              : false
                                          }
                                        >
                                          Control Room
                                        </Button>
                                      )}
                                    </>
                                  )}
                                  {roleKey !== "inventoryManager" && (
                                    <>
                                      {/* <div>
                                        <Button
                                          className="default-btn share-btn d-flex align-items-center justify-content-center"
                                          type="primary"
                                          size="large"
                                          onClick={() => {
                                            showModalSocial();
                                            setEventNum(ind);
                                            shareKey(item?._id, title, "stop");
                                            form.setFieldsValue({
                                              notes: item?.title,
                                            });
                                          }}
                                          icon={
                                            // <ShareAltOutlined />
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              icon={faPhotoFilm}
                                            />
                                          }
                                          disabled={
                                            // roomDisable(item) ||
                                            item?.event_status === "recorded"
                                              ? true
                                              : false
                                          }
                                        ></Button>
                                      </div> */}
                                      <div>
                                        <Button
                                          className="default-btn share-btn d-flex align-items-center justify-content-center"
                                          type="primary"
                                          size="large"
                                          onClick={() =>
                                            shareKey(title === "Recorded" || title === "Published" ? item?._id : item?.event_id, title)
                                          }
                                          icon={
                                            // <ShareAltOutlined />
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              icon={faShareNodes}
                                            />
                                          }
                                        // disabled={
                                        //   // roomDisable(item) ||
                                        //   (item?.event_status === 'recorded' && !item?.is_published)
                                        //     ? true
                                        //     : false
                                        // }
                                        ></Button>
                                      </div>

                                      {item.event_status !== "live" && (
                                        <Dropdown.Button
                                          trigger="click"
                                          overlayClassName="control-room-drowpdown"
                                          size="large"
                                          disabled={
                                            item.event_status !== "scheduled" ||
                                              item.event_status !== "recorded"
                                              ? false
                                              : false
                                          }
                                          // onClick={() => handleButtonClick(item)}
                                          // overlay={menu}
                                          overlay={
                                            <Menu
                                              onClick={(e) =>
                                                handleButtonClick(e, item)
                                              }
                                            >
                                              <Menu.Item
                                                key="download"
                                                disabled={!item?.download_url}
                                              >
                                                <span>Download</span>
                                              </Menu.Item>
                                              <Menu.Item
                                                key="edit"
                                                disabled={
                                                  // roomDisable(item) ||
                                                  item.event_status ==
                                                    "scheduled"
                                                    ? false
                                                    : true
                                                }
                                              >
                                                <span>Edit</span>
                                              </Menu.Item>
                                              <Menu.Item
                                                key="cancel"
                                                disabled={
                                                  // roomDisable(item) ||
                                                  item.event_status == "live" &&
                                                    item?.recording_url &&
                                                    item.event_status !=
                                                    "scheduled"
                                                    ? false
                                                    : null
                                                }
                                              >
                                                <span>Delete</span>
                                              </Menu.Item>

                                              {item.event_status ===
                                                "recorded" && (
                                                  <Menu.Item
                                                    key="publish"
                                                  // disabled={
                                                  //   item?.event?.is_published
                                                  //     ? true
                                                  //     : false
                                                  // }
                                                  >
                                                    <span>
                                                      {item?.is_published
                                                        ? "UnPublish"
                                                        : "Publish"}
                                                    </span>
                                                  </Menu.Item>
                                                )}
                                            </Menu>
                                          }
                                        ></Dropdown.Button>
                                      )}
                                    </>
                                  )}
                                  {/* )} */}
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div className="no-result-found mar-top-10">
                      <div className="no-result-img">
                        <img
                          src={
                            userInfo?.message?.data?.profile_image_url
                              ? userInfo?.message?.data?.profile_image_url
                              : validate?.payload?.message?.profile_image_url
                                ? validate?.payload?.message?.profile_image_url
                                : defaultImage
                          }
                          alt="searchEventImg"
                        />
                      </div>
                      <h3 className="no-event-hd"> No Event At This Time.</h3>
                      <p className="event-para">Please check back later.</p>
                      {/* <p>No Event At This Time. Please check back later.</p> */}
                    </div>
                  )}
                </>
              ) : (
                <>{null}</>
              )}

              {getBrandPublishEvents?.payload?.message?.data.length > 0 && (
                <ReactPaginate
                  nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                  // marginPagesDisplayed={2}

                  pageCount={
                    getBrandPublishEvents?.payload?.message?.next
                      ? getBrandPublishEvents?.payload?.message?.next?.page
                      : getBrandPublishEvents?.payload?.message?.previous
                        ? getBrandPublishEvents?.payload?.message?.previous
                          ?.page + 1
                        : 1
                  }
                  forcePage={currentPage}
                  previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                  pageClassName="page-item d-none"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item d-none"
                  breakLinkClassName="page-link"
                  containerClassName={"pagination custom-paginate"}
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className={`upcoming-events-wrapper mb-30 ${className ? "grid-block" : "list-block"
                }`}
            >
              {!getExternalEvent.loading ? (
                <>
                  {getExternalEvent?.payload?.message?.data.length > 0 ? (
                    <div className="upcoming-events-grid">
                      {getExternalEvent?.payload?.message?.data?.map((item, ind) => {
                        return (
                          <>
                            <div className="upcoming-events-box">
                              {/* <div className="event-banner" style={{ backgroundImage: `url(${item.banner})` }} > */}
                              <div className="event-banner">
                                <div className="event-box w-100 h-100 overflow-hidden">
                                  <div className="event-banner-img">
                                    <img
                                      src={item?.event?.banner}
                                      alt="banner"
                                    />
                                  </div>

                                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    {/* {roomDisable(item) ? (
                                    <div className="schedule-badge expired">
                                      Expired
                                    </div>
                                  ) : (
                                    <> */}
                                    {item?.event?.event_status === "live" ? (
                                      <div className="schedule-badge live">
                                        Live Now
                                      </div>
                                    ) : null}

                                    {
                                      // moment(item?.event?.start_date).format(
                                      //   "MM-DD-YYYY hh:mm A"
                                      // ) <=
                                      //   moment(isoStr).format(
                                      //     "MM-DD-YYYY hh:mm A"
                                      //   ) &&
                                      //   item?.event?.event_status ===
                                      //   "scheduled" ? (
                                      //   <div className="schedule-badge past">
                                      //     Past
                                      //   </div>
                                      // ) : 
                                      item?.event?.event_status ===
                                        "scheduled" ? (
                                        <div className="schedule-badge schedule ">
                                          Scheduled
                                        </div>
                                      ) : null}
                                    {item?.event?.event_status ===
                                      "recorded" ? (
                                      <div className="schedule-badge Recorded ">
                                        Recorded
                                      </div>
                                    ) : null}
                                    {/* </>
                                  )} */}

                                    {item?.event?.event_status ===
                                      "scheduled" ? (
                                      <div className="schedule-badge schedule countdown-timer">
                                        <Countdown
                                          value={item?.event?.start_date}
                                          format="DD : HH : mm : ss"
                                        />
                                      </div>
                                    ) : null}
                                    {item?.event?.event_status ===
                                      "live" ? (
                                      <div className="schedule-badge schedule countdown-timer">
                                        <Countdown
                                          value={moment(item?.event?.started_at).add(item?.event?.duration, 'minutes')}
                                          format="mm : ss"
                                        />
                                      </div>
                                    ) : null}
                                  </div>

                                  <div
                                    className={`d-flex ${item?.event?.is_facebook_live
                                      ? "justify-content-between"
                                      : "justify-content-end"
                                      }`}
                                  >
                                    {item?.event?.is_facebook_live && (
                                      <div className="ribbon-small fb-style mr-2">
                                        <FacebookOutlined className="mr-2 mb-1" />
                                        <span>Live on Facebook</span>
                                      </div>
                                    )}
                                    <div className="ribbon-small">
                                      <FontAwesomeIcon icon={faFlask} />
                                      <span>Event </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="event-card-content w-100 col d-flex flex-column justify-content-between">
                                <div>
                                  {/* <div className='fee-box-main'>
                                      <div className='fee-box'>
                                        <div className='fee-state red-state'>
                                          {((item?.event?.referral_percent === "0" || item?.event?.referral_percent === "" || item?.event?.referral_percent === "undefined") || !item?.event?.referral_percent) ? "" : (item?.event?.referral_percent + "%" + " Referral Fee")}
                                        </div>
                                        <div className='fee-state orange-state'>
                                          {((item?.event?.influencer_percent === "0" || item?.event?.influencer_percent === "" || item?.event?.influencer_percent === "undefined") || item?.event?.influencer_percent) ? "" : (item?.event?.influencer_percent + "%" + " Content Creator Fee")}
                                        </div>
                                      </div>
                                      <div className='fee-box'>
                                        <div className='fee-state green-state'>
                                          {((item?.event?.discount === "0%" || item?.event?.discount === "" || item?.event?.discount === "undefined") || item?.event?.discount === "undefined") ? "" : (item?.event?.discount + " Discount")}
                                        </div>
                                      </div>
                                    </div> */}
                                  <div className="event-date-main top-date">
                                    {item?.event?.event_status ===
                                      "recorded" ? (
                                      <div className="event-date-recorded">
                                        Scheduled:
                                      </div>
                                    ) : null}

                                    <div className="event-date">
                                      {item?.event?.event_status !=
                                        "recorded" ? (
                                        <FontAwesomeIcon
                                          className="event-icon"
                                          icon={faCalendarDays}
                                        />
                                      ) : null}
                                      <div
                                        // className="event-txt"
                                        className={`event-txt ${item?.event?.event_status !=
                                          "recorded"
                                          ? ""
                                          : "recorded-gap"
                                          }`}
                                      >
                                        {moment(item?.event?.start_date)
                                          .utc()
                                          .format("MM-DD-YYYY")}
                                      </div>
                                    </div>

                                    <div className="event-date">
                                      {item?.event?.event_status !=
                                        "recorded" ? (
                                        <FontAwesomeIcon
                                          className="event-icon"
                                          icon={faClock}
                                        />
                                      ) : null}

                                      <div className="event-txt">
                                        {moment(item?.event?.start_date).format(
                                          "hh:mm A"
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {item?.event?.event_status === "recorded" ? (
                                    <div className="event-date-main top-date">
                                      {item?.event?.event_status ===
                                        "recorded" ? (
                                        <div className="event-date-recorded">
                                          Start:
                                        </div>
                                      ) : null}

                                      <div className="event-date">
                                        {item?.event?.event_status !=
                                          "recorded" ? (
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faCalendarDays}
                                          />
                                        ) : null}
                                        <div
                                          // className="event-txt"
                                          className={`event-txt ${item?.event?.event_status !=
                                            "recorded"
                                            ? ""
                                            : "recorded-gap"
                                            }`}
                                        >
                                          {moment(item?.event?.started_at)
                                            .utc()
                                            .format("MM-DD-YYYY")}
                                        </div>
                                      </div>

                                      <div className="event-date">
                                        {item?.event?.event_status !=
                                          "recorded" ? (
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faClock}
                                          />
                                        ) : null}

                                        <div className="event-txt">
                                          {moment(
                                            item?.event?.started_at
                                          ).format("hh:mm A")}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}

                                  {item?.event?.event_status === "recorded" ? (
                                    <div className="event-date-main top-date">
                                      {item?.event?.event_status ===
                                        "recorded" ? (
                                        <div className="event-date-recorded">
                                          End:
                                        </div>
                                      ) : null}

                                      <div className="event-date">
                                        {item?.event?.event_status !=
                                          "recorded" ? (
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faCalendarDays}
                                          />
                                        ) : null}
                                        <div
                                          // className="event-txt"
                                          className={`event-txt ${item?.event?.event_status !=
                                            "recorded"
                                            ? ""
                                            : "recorded-gap"
                                            }`}
                                        >
                                          {moment(item?.event?.ended_at)
                                            .utc()
                                            .format("MM-DD-YYYY")}
                                        </div>
                                      </div>

                                      <div className="event-date">
                                        {item?.event?.event_status !=
                                          "recorded" ? (
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faClock}
                                          />
                                        ) : null}

                                        <div className="event-txt">
                                          {moment(item?.event?.ended_at).format(
                                            "hh:mm A"
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}

                                  {item?.event?.duration ? (
                                    <div className="event-date-main top-date">
                                      <div className="external-event-grid-duration">
                                        Duration:
                                      </div>

                                      {/* {item?.event?.event_status !=
                                        "recorded" ? (
                                        <FontAwesomeIcon
                                          className="event-icon"
                                          icon={faClock}
                                        />
                                      ) : null} */}
                                      <div className="event-txt external-event-grid-duration">
                                        {item?.event?.duration} minutes
                                      </div>
                                    </div>
                                  ) : null}

                                  {/* <div className="event-date d-inline-flex">
                            <FontAwesomeIcon className="event-icon" icon={faCalendarDays} />
                            <div className="event-txt">
                                FRIDAY, JULY 29, 2022
                            </div>
                            <FontAwesomeIcon className="event-icon" icon={faClock} />
                            <div className="event-txt">
                                3:10 PM
                            </div>
                        </div> */}

                                  <h3 className="event-hd">
                                    {item?.event?.title}
                                  </h3>
                                </div>
                                {/* <div className="event-users-listing">
                                <div className="event-date-main">
                                  {item?.event?.hosts.map((it, i) => {
                                    return (
                                      <>
                                        {it?.producer && (
                                          <div className="event-date bottom-style">
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faUserTie}
                                            />
                                            <div className="event-txt">
                                              Producer: {it.user_name}
                                            </div>
                                          </div>
                                        )}
                                        {it?.primary && (
                                          <div className="event-date bottom-style">
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faUser}
                                            />
                                            <div className="event-txt">
                                              Primary: {it.user_name}
                                            </div>
                                          </div>
                                        )}
                                        {it?.host1 && (
                                          <div className="event-date bottom-style">
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faUser}
                                            />
                                            <div className="event-txt">
                                              Host1: {it.user_name}
                                            </div>
                                          </div>
                                        )}
                                        {it?.host2 && (
                                          <div className="event-date bottom-style">
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faUser}
                                            />
                                            <div className="event-txt">
                                              Host2: {it.user_name}
                                            </div>
                                          </div>
                                        )}
                                        {it?.host3 && (
                                          <div className="event-date bottom-style">
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faUser}
                                            />
                                            <div className="event-txt">
                                              Host3: {it.user_name}
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    );
                                  })}
                                </div>
                              </div> */}
                                <div className="event-users-listing">
                                  <div className="event-date-main">
                                    {item?.event?.hosts?.map((it, i) => {
                                      return (
                                        <>
                                          {it?.producer && (
                                            <div className="event-date bottom-style">
                                              <FontAwesomeIcon
                                                className="event-icon"
                                                icon={faUserTie}
                                              />
                                              <div className="event-txt">
                                                Producer: {it.user_name}
                                              </div>
                                            </div>
                                          )}
                                          {it?.primary && (
                                            <div className="event-date bottom-style">
                                              <FontAwesomeIcon
                                                className="event-icon"
                                                icon={faUser}
                                              />
                                              <div className="event-txt">
                                                Primary: {it.user_name}{" "}
                                                {title ===
                                                  "Recorded" ? null : it.host_status ? (
                                                    <span class="status-flag-event-user green"></span>
                                                  ) : (
                                                  <span class="status-flag-event-user red"></span>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                          {it?.host1 && (
                                            <div className="event-date bottom-style">
                                              <FontAwesomeIcon
                                                className="event-icon"
                                                icon={faUser}
                                              />
                                              {/* <div className="event-txt">
                                              Host1: {it.user_name}
                                            </div> */}

                                              <div className="event-txt">
                                                Host1: {it.user_name}{" "}
                                                {title ===
                                                  "Recorded" ? null : it.host_status ? (
                                                    <span class="status-flag-event-user green"></span>
                                                  ) : (
                                                  <span class="status-flag-event-user red"></span>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                          {it?.host2 && (
                                            <div className="event-date bottom-style">
                                              <FontAwesomeIcon
                                                className="event-icon"
                                                icon={faUser}
                                              />
                                              {/* <div className="event-txt">
                                              Host2: {it.user_name}
                                            </div> */}
                                              <div className="event-txt">
                                                Host2: {it.user_name}{" "}
                                                {title ===
                                                  "Recorded" ? null : it.host_status ? (
                                                    <span class="status-flag-event-user green"></span>
                                                  ) : (
                                                  <span class="status-flag-event-user red"></span>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                          {it?.host3 && (
                                            <div className="event-date bottom-style">
                                              <FontAwesomeIcon
                                                className="event-icon"
                                                icon={faUser}
                                              />
                                              {/* <div className="event-txt">
                                              Host3: {it.user_name}
                                            </div> */}
                                              <div className="event-txt">
                                                Host3: {it.user_name}{" "}
                                                {title ===
                                                  "Recorded" ? null : it.host_status ? (
                                                    <span class="status-flag-event-user green"></span>
                                                  ) : (
                                                  <span class="status-flag-event-user red"></span>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                  </div>
                                  <div className="fee-box-main">
                                    <div className="fee-box">
                                      <div className="fee-state red-state">
                                        {/* {item?.event?.referral_percent ===
                                          "0" ||
                                          item?.event?.referral_percent === "" ||
                                          item?.event?.referral_percent ===
                                          "undefined" ||
                                          !item?.event?.referral_percent
                                          ? ""
                                          : item?.event?.referral_percent +
                                          "%" +
                                          " Referral Fee"} */}
                                        {/* {getStructureFees?.payload?.message?.fee_structure?.referral_fee + "%" + " Referral Fee"} */}
                                      </div>
                                    </div>

                                    <div className="fee-box">
                                      <div className="fee-state orange-state">
                                        {/* {item?.event?.influencer_percent ===
                                          "0" ||
                                          item?.event?.influencer_percent ===
                                          "" ||
                                          item?.event?.influencer_percent ===
                                          "undefined" ||
                                          !item?.event?.influencer_percent
                                          ? ""
                                          : item?.event?.influencer_percent +
                                          "%" +
                                          "  Creator Fee"} */}
                                        {/* {getStructureFees?.payload?.message?.fee_structure?.content_creator_fee + "%" + " Creator Fee"} */}
                                      </div>
                                    </div>

                                    <div className="fee-box">
                                      <div className="fee-state green-state">
                                        {item?.event?.discount === "0%" ||
                                          item?.event?.discount === "" ||
                                          item?.event?.discount === "undefined" ||
                                          item?.event?.discount === "undefined"
                                          ? ""
                                          : item?.event?.discount + " Discount"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {item?.event?.event_counter && <p className="mt-2 mb-0"><span className="fw-bold">Event ID:</span> {item?.event?.event_counter?.[0] + "-" + item?.event?.event_counter?.split('E')[1].split('-').join('').match(/.{1,3}/g).join('-')} </p>}
                              </div>
                              <div className={`${item?.event_status === "ended" ? '' : 'event-card-footer'}`}>
                                {
                                  item?.event?.recording_url &&
                                    item?.event_status !== "live" ? (
                                    <>
                                      <Button
                                        className="default-btn d-flex align-items-center justify-content-center"
                                        type="primary"
                                        size=""
                                        onClick={() =>
                                          recordedEvents(item?.event?._id, title)
                                        }
                                        icon={
                                          <FontAwesomeIcon
                                            size="sm"
                                            className="event-icon"
                                            // icon={faVideo}
                                            icon={faPlay}
                                          />
                                        }
                                      >
                                        Play
                                      </Button>
                                      {roleKey === "inventoryManager" && (
                                        <Button
                                          className="default-btn d-flex share-btn align-items-center justify-content-center"
                                          type="primary"
                                          size=""
                                          onClick={() =>
                                            manageInventory(item?._id)
                                          }
                                          icon={
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              icon={faCartFlatbed}
                                            />
                                          }
                                        >
                                          Manage Inventory
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {roleKey === "inventoryManager" && item?.event_status !== "ended" ? (
                                        <Button
                                          className="default-btn d-flex align-items-center justify-content-center"
                                          type="primary"
                                          size="large"
                                          onClick={() =>
                                            manageInventory(item?.event?._id)
                                          }
                                          icon={
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              // icon={faVideo}
                                              icon={faCartFlatbed}
                                            />
                                          }
                                        >
                                          Manage Inventory
                                        </Button>
                                      ) : (
                                        item?.event_status === "live" ?
                                          <>
                                            <Button
                                              className="default-btn btn-danger d-flex align-items-center justify-content-center"
                                              type="primary"
                                              size="large"
                                              onClick={() => {
                                                recordedEvents(item?.event?._id, title)
                                              }}
                                              icon={
                                                <FontAwesomeIcon
                                                  size="sm"
                                                  className="event-icon"
                                                  icon={faVideo}
                                                />
                                              }
                                            // disabled
                                            >
                                              Play
                                            </Button>
                                            <Button
                                              className="default-btn btn-danger2 d-flex align-items-center justify-content-center"
                                              type="primary"
                                              size="large"
                                              onClick={() => {
                                                endLive(item)
                                              }}
                                              icon={
                                                <FontAwesomeIcon
                                                  size="sm"
                                                  className="event-icon"
                                                  icon={faVideo}
                                                />
                                              }
                                            // disabled
                                            >
                                              End Event
                                            </Button>
                                            {/* <Dropdown.Button
                                              trigger="click"
                                              overlayClassName="control-room-drowpdown"
                                              size="large"
                                              disabled={
                                                item.event_status !== "scheduled" ||
                                                  item.event_status !== "recorded"
                                                  ? false
                                                  : false
                                              }
                                              // onClick={() => handleButtonClick(item)}
                                              // overlay={menu}
                                              overlay={
                                                <Menu
                                                  onClick={(e) =>
                                                    handleButtonClick(e, item)
                                                  }
                                                >
                                                  <Menu.Item
                                                    key="share"
                                                    onClick={() =>
                                                      shareKey(title === "Recorded" || title === "Published" ? item?._id : item?.event_id, title)
                                                    }
                                                  >
                                                    <span>Share</span>
                                                  </Menu.Item>
                                                  <Menu.Item
                                                    key="manageInventory"
                                                    onClick={() =>
                                                      manageInventory(item?.event?._id)
                                                    }
                                                  >
                                                    <span>Manage Inventory</span>
                                                  </Menu.Item>


                                                </Menu>
                                              }
                                            ></Dropdown.Button> */}
                                          </>
                                          :
                                          item?.event_status === "ended" ?
                                            <></>
                                            :
                                            <Button
                                              className="default-btn d-flex align-items-center justify-content-center"
                                              type="primary"
                                              size="large"
                                              onClick={() => {
                                                goLive(item)
                                              }}
                                              icon={
                                                <FontAwesomeIcon
                                                  size="sm"
                                                  className="event-icon"
                                                  icon={faVideo}
                                                />
                                              }
                                              disabled={
                                                // roomDisable(item) ||
                                                item.event_status === "recorded"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              Go Live
                                            </Button>

                                      )}
                                    </>
                                  )}
                                {roleKey !== "inventoryManager" && item.event_status !== "ended" && (
                                  <>
                                    {/* <div>
                                      <Button
                                        className="default-btn share-btn d-flex align-items-center justify-content-center"
                                        type="primary"
                                        size="large"
                                        onClick={() => {
                                          showModalSocial();
                                          setEventNum(ind);
                                          shareKey(item?._id, title, "stop");
                                          form.setFieldsValue({
                                            notes: item?.title,
                                          });
                                        }}
                                        icon={
                                          // <ShareAltOutlined />
                                          <FontAwesomeIcon
                                            size="sm"
                                            className="event-icon"
                                            icon={faPhotoFilm}
                                          />
                                        }
                                        disabled={
                                          // roomDisable(item) ||
                                          item?.event_status === "recorded"
                                            ? true
                                            : false
                                        }
                                      ></Button>
                                    </div> */}
                                    <div className="d-flex">
                                      <Button
                                        className="default-btn share-btn d-flex align-items-center justify-content-center"
                                        type="primary"
                                        size="large"
                                        onClick={() =>
                                          shareKey(title === "Recorded" || title === "Published" ? item?._id : item?.event_id, title)
                                        }
                                        icon={
                                          // <ShareAltOutlined />
                                          <FontAwesomeIcon
                                            size="sm"
                                            className="event-icon"
                                            icon={faShareNodes}
                                          />
                                        }
                                      // disabled={
                                      //   // roomDisable(item) ||
                                      //   (item?.event_status === 'recorded' && !item?.is_published)
                                      //     ? true
                                      //     : false
                                      // }
                                      ></Button>
                                      {/* {
                                        item?.event?.stream_type == 'external' &&
                                        <Button
                                          className="default-btn share-btn d-flex align-items-center justify-content-center ml-2"
                                          type="primary"
                                          size="large"
                                          onClick={() => onDelete(item)}
                                          disabled={deleteLoading}
                                          title="Delete"
                                          icon={
                                            // <ShareAltOutlined />
                                            <FontAwesomeIcon
                                              size="sm"
                                              className="event-icon"
                                              icon={faTrash}
                                            />
                                          }

                                        ></Button>} */}
                                    </div>

                                    {item.event_status !== "live" && item.event_status !== "ended" && (
                                      <Dropdown.Button
                                        trigger="click"
                                        overlayClassName="control-room-drowpdown"
                                        size="large"
                                        disabled={
                                          item.event_status !== "scheduled" ||
                                            item.event_status !== "recorded"
                                            ? false
                                            : false
                                        }
                                        // onClick={() => handleButtonClick(item)}
                                        // overlay={menu}
                                        overlay={
                                          <Menu
                                            onClick={(e) =>
                                              handleButtonClick(e, item)
                                            }
                                          >
                                            <Menu.Item
                                              key="edit"
                                              disabled={
                                                // roomDisable(item) ||
                                                item.event_status == "scheduled"
                                                  ? false
                                                  : true
                                              }
                                            >
                                              <span>Edit Event</span>
                                            </Menu.Item>
                                            <Menu.Item
                                              key="editproduct"
                                              disabled={
                                                // roomDisable(item) ||
                                                item.event_status == "scheduled"
                                                  ? false
                                                  : true
                                              }
                                            >
                                              <span>Edit Products</span>
                                            </Menu.Item>
                                            <Menu.Item
                                              key="cancel"
                                              disabled={
                                                // roomDisable(item) ||
                                                item.event_status == "live" &&
                                                  item?.recording_url &&
                                                  item.event_status != "scheduled"
                                                  ? false
                                                  : null
                                              }
                                            >
                                              <span>Delete</span>
                                            </Menu.Item>

                                            {item.event_status ===
                                              "recorded" && (
                                                <Menu.Item
                                                  key="publish"
                                                // disabled={
                                                //   item?.event?.is_published
                                                //     ? true
                                                //     : false
                                                // }
                                                >
                                                  <span>
                                                    {item?.is_published
                                                      ? "UnPublish"
                                                      : "Publish"}
                                                  </span>
                                                </Menu.Item>
                                              )}
                                          </Menu>
                                        }
                                      ></Dropdown.Button>
                                    )}
                                  </>
                                )}

                                {/* )} */}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="no-result-found mar-top-10">
                      <div className="no-result-img">
                        <img
                          src={
                            userInfo?.message?.data?.profile_image_url
                              ? userInfo?.message?.data?.profile_image_url
                              : validate?.payload?.message?.profile_image_url
                                ? validate?.payload?.message?.profile_image_url
                                : defaultImage
                          }
                          alt="searchEventImg"
                        />
                      </div>
                      <h3 className="no-event-hd"> No Event At This Time.</h3>
                      <p className="event-para">Please check back later.</p>
                      {/* <p>No Event At This Time. Please check back later.</p> */}
                    </div>
                  )}
                </>
              ) : (
                <>{null}</>
              )}

              {getExternalEvent?.payload?.message?.data.length > 0 && (
                <ReactPaginate
                  nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                  // marginPagesDisplayed={2}

                  pageCount={
                    getExternalEvent?.payload?.message?.next
                      ? getExternalEvent?.payload?.message?.next?.page
                      : getExternalEvent?.payload?.message?.previous
                        ? getExternalEvent?.payload?.message?.previous?.page + 1
                        : 1
                  }
                  forcePage={currentPage}
                  previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                  pageClassName="page-item d-none"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item d-none"
                  breakLinkClassName="page-link"
                  containerClassName={"pagination custom-paginate"}
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              )}
            </div>
          </>
        )
      ) : (
        <LoadingSpin />
      )}

      <Modal
        className="modal-generic modal-500"
        centered
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
      // footer={[
      //   <Button key="back" onClick={handleCancel}>
      //     Cancel
      //   </Button>,
      //   <Button key="submit" type="primary"
      //     className=""
      //     // onClick={handleOk}
      //     onClick={{}}
      //     >
      //     Next
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Share</h2>
        {shareLoad ? (
          // <span className="spinclass loader-center position-relative">
          //   <Spin size="medium" />
          // </span>
          <div className="">
            <Skeleton.Input active small block />
          </div>
        ) : (
          <div class="your-copy-link">
            <div class="item-a">
              <a
                target="_blank"
                rel="noreferrer"
                href={shareUrls?.payload?.message}
              >
                {shareUrls?.payload?.message}
              </a>
            </div>
            <div class="item-b">
              {/* <div class="item-b copied"> */}
              <Button onClick={() => copyUrl(shareUrls?.payload?.message)}>
                Copy
              </Button>
            </div>
          </div>
        )}

        <div className="text-center mt-4">
          <Button onClick={handleCancel} className="default-btn outline">
            Exit
          </Button>
        </div>
      </Modal>
      {showChild && <ViewData review_id={order} flag={trigger} />}
      <Modal
        className="modal-generic modal-700"
        centered
        visible={isModalOpenSocial}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
      // footer={[
      //   <Button key="back" onClick={handleCancel}>
      //     Cancel
      //   </Button>,
      //   <Button key="submit" type="primary"
      //     className=""
      //     // onClick={handleOk}
      //     onClick={{}}
      //     >
      //     Next
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        <div className="form-styling ">
          <div className="row ">
            <div className="col-12 mb-20">
              <p className="mbpx-5">Select Social Platform</p>
              <Select
                className="select-style dark-field w-100 "
                placeholder="Social Platform"
                size="large"
                value={socialName}
                onChange={(e) => handleSocial(e)}
              >
                <Option value="Facebook">Facebook</Option>
                <Option value="Instagram">Instagram</Option>
                <Option value="Pinterest">Pinterest</Option>
                <Option value="LinkedIn">LinkedIn</Option>
              </Select>
            </div>
          </div>
          <div className="row">
            <div className="col-6 mb-20">
              <div className="social-modal-banner-main">
                <img
                  src={
                    getExternalEvent?.payload?.message?.data?.[eventNum]?.event?.banner
                  }
                  className="social-modal-banner"
                  alt="banner"
                />
              </div>
            </div>
            <div className="col-6 mb-20">
              {/* <Form.Item
            labelCol={{ span: 24 }}
            label="Caption"
            // initialValue={
            //   formState === 'add' ? null : form.getFieldValue().title
            // }
            name="title"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please Enter Event Title',
            //   },
            // ]}
          >
            <Input
              placeholder="e.g. MKG1402 -LIVE- Winter 2020 Clearance"
              value="JOE'S JEANS - The Stellie is the perfect high rise and barrel leg blend."
              size="large"
              showCount
              maxLength={80}
              // onChange={(e) => setEventTitle(e.target.value)}
            />
          </Form.Item> */}
              <Form
                fields={[
                  {
                    name: ["notes"],
                    value: form.getFieldValue().notes,
                  },
                ]}
              >
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Caption"
                  // initialValue={
                  //   formState === 'add' ? null : form.getFieldValue().notes
                  // }
                  name="notes"
                >
                  <TextArea
                    showCount
                    maxLength={200}
                    // onChange={onChange}
                    className="dark-field"
                    // placeholder="Get rid of 2021 inventory. Use Promo Code : WINTER2022 - Promo Code description : 20% OFF of the entire collection"
                    placeholder="Enter your details"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    initialValues={form.getFieldValue().notes}
                    onChange={(e) =>
                      form.setFieldsValue({ notes: e.target.value })
                    }
                  />
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button
              disabled={socialName == "" ? true : false}
              loading={loading}
              onClick={postSocialDetails}
              // className="default-btn padb-3 d-inline-flex align-items-center justify-content-center color-secondary"
              className={`default-btn padb-3 d-inline-flex align-items-center justify-content-center 
              ${socialName == "" ? "color-secondary" : ""}`}
            >
              <span className="nav-text">Post</span>
            </Button>
            <Button
              className="default-btn padb-3 outline d-inline-flex align-items-center justify-content-center"
              key="back"
              onClick={handleCancel}
            >
              Exit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
