import axios from 'axios';
import {
  POST_EXTERNAL_EVENT_REQUEST,
  POST_EXTERNAL_EVENT_SUCCESS,
  POST_EXTERNAL_EVENT_ERROR,
  GET_EXTERNAL_EVENT_REQUEST,
  GET_EXTERNAL_EVENT_SUCCESS,
  GET_EXTERNAL_EVENT_ERROR,
  DELETE_EXTERNAL_EVENT_REQUEST,
  DELETE_EXTERNAL_EVENT_SUCCESS,
  DELETE_EXTERNAL_EVENT_ERROR,
  GET_EXTERNAL_EVENT_ID_REQUEST,
  GET_EXTERNAL_EVENT_ID_SUCCESS,
  GET_EXTERNAL_EVENT_ID_ERROR,
  UPDATE_EXTERNAL_EVENT_REQUEST,
  UPDATE_EXTERNAL_EVENT_SUCCESS,
  UPDATE_EXTERNAL_EVENT_ERROR,
  GO_LIVE_EXTERNAL_REQUEST,
  GO_LIVE_EXTERNAL_SUCCESS,
  GO_LIVE_EXTERNAL_ERROR,
  END_LIVE_EXTERNAL_REQUEST,
  END_LIVE_EXTERNAL_SUCCESS,
  END_LIVE_EXTERNAL_ERROR,
} from '../types/types';
import moment from "moment";
const token = JSON.parse(localStorage.getItem('token'));


export const postExternalEvent = (data) => (dispatch) => {
  dispatch({ type: POST_EXTERNAL_EVENT_REQUEST });
  return axios({
    method: 'POST',
    url: `external/events/createnewscheduleevent`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_EXTERNAL_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_EXTERNAL_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const getExternalEvents = (limit, page, title, sort) => (dispatch) => {
  let status = '';
  dispatch({ type: GET_EXTERNAL_EVENT_REQUEST });

  if (title === 'Live Events') {
    status = 'live';
  }
  if (title === 'Upcoming') {
    status = 'scheduled';
  }
  if (title === 'Recorded') {
    status = 'recorded';
  }
  if (title === 'scheduled,live') {
    status = 'scheduled,live';
  }
  if (title === 'Ended') {
    status = 'ended';
  }

  return axios({
    method: 'GET',
    url: `external/events/getallevents?limit=${limit}&page=${page}&status=${status}&sortby=${status === 'recorded' ? sort : ''
      }`,

  })
    .then((response) => {
      return dispatch({
        type: GET_EXTERNAL_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_EXTERNAL_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const deleteExternalEvent = (id) => (dispatch) => {
  dispatch({ type: DELETE_EXTERNAL_EVENT_REQUEST });
  return axios({
    method: 'DELETE',
    url: `external/events/deletescheduleevent/${id}`,

  })
    .then((response) => {
      return dispatch({
        type: DELETE_EXTERNAL_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_EXTERNAL_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const getExternalEventsBy_Id = (id, room) => (dispatch) => {
  dispatch({ type: GET_EXTERNAL_EVENT_ID_REQUEST });

  return axios({
    method: 'GET',
    url: `external/events/getoneevent/${id}?room=${room ? 1 : 0}`,

    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_EXTERNAL_EVENT_ID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_EXTERNAL_EVENT_ID_ERROR,
        payload: error.response,
      });
    });
};

export const UpdateExternalEvents = (data, id) => (dispatch) => {
  dispatch({ type: UPDATE_EXTERNAL_EVENT_REQUEST });
  return axios({
    method: 'PUT',
    url: `external/events/updatescheduleevent/${id}`,

    // headers: {
    //     Accept: "application/json",
    //     Authorization: "Bearer " + token,
    // },
    data: data,
  })
    .then((response) => {
      return dispatch({ type: UPDATE_EXTERNAL_EVENT_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_EXTERNAL_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const goLiveExternalStream = (id) => (dispatch) => {
  dispatch({ type: GO_LIVE_EXTERNAL_REQUEST });
  return axios({
    method: "POST",
    url: `external/events/golive`,

    data: {
      id: id
      // name: name,
      // playbackurl: playbackurl,
    },
  })
    .then((response) => {
      return dispatch({
        type: GO_LIVE_EXTERNAL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GO_LIVE_EXTERNAL_ERROR,
        payload: error.response,
      });
    });
};


export const endLiveExternalStream = (id) => (dispatch) => {
  dispatch({ type: END_LIVE_EXTERNAL_REQUEST });
  return axios({
    method: "POST",
    url: `external/events/endlive`,

    data: {
      id: id
      // name: name,
      // playbackurl: playbackurl,
    },
  })
    .then((response) => {
      return dispatch({
        type: END_LIVE_EXTERNAL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: END_LIVE_EXTERNAL_ERROR,
        payload: error.response,
      });
    });
};