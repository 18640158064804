import {
  STRIPE_CARD_DETAIL_REQUEST,
  STRIPE_CARD_DETAIL_SUCCESS,
  STRIPE_CARD_DETAIL_ERROR,
} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const stripeCardDetail = () => (dispatch) => {
  dispatch({ type: STRIPE_CARD_DETAIL_REQUEST });
  return axios({
    method: "POST",
    url: `stripe/promoted/paymentmethods`,
  })
    .then((response) => {
      return dispatch({
        type: STRIPE_CARD_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: STRIPE_CARD_DETAIL_ERROR,
        payload: error.response,
      });
    });
};
