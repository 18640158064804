import {
    CONNECT_SELLERCENTER_REQUEST,
    CONNECT_SELLERCENTER_SUCCESS,
    CONNECT_SELLERCENTER_ERROR,
} from "../../types/types";
export default function connectSellerCenterDetail(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case CONNECT_SELLERCENTER_REQUEST:
            return {
                loading: true,
            };
        case CONNECT_SELLERCENTER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case CONNECT_SELLERCENTER_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}