import { CUSTOMURL1 } from '../../config';
import {
  SC_GET_ALL_PRODUCT
} from '../types/types';
import axios from 'axios';
const sc_token = JSON.parse(localStorage.getItem('sc_token'));


export const scGetAllProduct = (page, limit, token, status, sku) => (dispatch) => {
  console.log(token, 'token');
  console.log(sc_token, 'sc_token');

  return axios({
    method: 'GET',
    url: `${CUSTOMURL1}products`,
    headers: { Authorization: `Bearer ${token ? token : sc_token}` },
    params: {
      page: page ? page : 1,
      limit,
      type: status,
      sku
    }
  })
    .then((response) => {
      dispatch({
        type: SC_GET_ALL_PRODUCT,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
