import {
  AGREEMENT, AGREEMENT_LINK
} from '../types/types';
import axios from 'axios';


export const acceptOrRejectAgreement = (agreement) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `/users/revise/acceptOrrejectAgreement`,
    data: {
      agreement
    }
  })
    .then((response) => {
      dispatch({
        type: AGREEMENT,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};


export const getAgreementPDFLink = () => (dispatch) => {
  return axios({
    method: 'POST',
    url: `/users/revise/getBrandPdf`,
  })
    .then((response) => {
      dispatch({
        type: AGREEMENT_LINK,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
