import React, { useEffect, useState } from 'react';
import { useStepsForm } from 'sunflower-antd';
import { Button, Form, Input, message,InputNumber, Spin } from 'antd';
import '../../css/feeStructure.scss';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import Swal from "sweetalert2";
import { getFeeStructure,saveFeeStructure } from '../../redux/actions/feestructure.action';

function FeeStructure() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [feeData, setFeeData] = useState();

  useEffect(() => {
    dispatch(getFeeStructure()).then((res) => {
      if (res.payload.success) {
        setLoading(false);
        setFeeData(res.payload?.message?.fee_structure)
      }
      else {
        setLoading(false);
        console.log(res,"error");
      }
    });
  }, []);

  const onFinish = (values) => {
    Swal.fire({
      title: "Are You Sure?",
      text: "You Want To Change Fee Structure?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        setSpinner(true);
        dispatch(saveFeeStructure(values)).then((res) => {
          if (res?.payload?.success) {
            setSpinner(false);
            notification.success({
              message: res?.payload?.message,
              className: "toast-success",
            });
          } else {
            setSpinner(false);
            notification.error({
              message: "Something went wrong",
              className: "toast-error",
            });
            console.log(res?.response,"error");
          }
        })
      }
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div class="title-area">
        <h1 class="page-title">Fee Structure</h1>
      </div>
      <div className="container p-0 ml-0">
     
        <div className="row fee-structure">
          <div className="profile_box_main col-md-8 col-lg-6 mb-20">
            <div className="stream-box-white h-10 mb-0">
            {!loading ? (
            <Form
                name="basic"
                // layout="vertical"
                initialValues={{
                  shopper_cashback : feeData?.shopper_cashback,
                  referral_fee : feeData?.referral_fee,
                  content_creator_fee : feeData?.content_creator_fee
                  
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                // onValuesChange={onValuesChange}
                autoComplete="off"
              >
                <div className='row mb-3'>
                <div className='col-6 fee-label'>Shopper Cashback</div>
                <div className='col-6'>
                  <Form.Item
                  // label="Shopper Cashback"
                  name="shopper_cashback"
                  // onChange={(e) => form.setFieldsValue({ shoppercashback: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                 <InputNumber 
                 disabled
                    addonBefore={"%"} 
                    // defaultValue={0}
                    className='fee-form'
                    min={0}
                    max={100}
                 />
                </Form.Item></div>
                </div>
                <div className='row mb-3'>
                <div className='col-6 fee-label'>Referral Fee</div>
                <div className='col-6'>
                  <Form.Item
                  // label="Shopper Cashback"
                  name="referral_fee"
                  // onChange={(e) => form.setFieldsValue({ shoppercashback: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                 <InputNumber 
                 disabled
                    addonBefore={"%"} 
                    // defaultValue={0}
                    className='fee-form'
                    min={0}
                    max={100}
                 />
                </Form.Item></div>
                </div>
                

           
                <div className='row mb-3'>

                <div className='col-6 fee-label'>Creator Fee</div>
                <div className='col-6'>
                <Form.Item
                  // label="Content Creator Fee"
                  name="content_creator_fee"
                  // onChange={(e) => form.setFieldsValue({ contentcreatorfee: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                 <InputNumber 
                 disabled
                  addonBefore={"%"} 
                  className='fee-form'
                  min={0}
                  max={100}
                //  defaultValue={0} 
                 />
                </Form.Item>
                </div>
                </div>
                
                
                
{/*                 
                <div className="mt-9">
                <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="default-btn login-btn ml-0"
                      loading={spinner}
                      // disabled={spinner}
                      disabled={spinner}
                    
                    >
                      Save
                    </Button>
                </Form.Item>
                </div> */}
              </Form>
              ):
              (
               <span className="spinclass loader-center position-relative">
                 <Spin size="large" />
               </span>
             )}
            </div>
          </div>
        </div>
      
      </div>
    </>
  );
}

export default FeeStructure;