import { Link, NavLink, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/public/public.scss";
import "../../css/public/brand.scss";
import "../../css/public/about_us.scss";
import PublicHeader from "../../components/public/header/header";
import PublicFooter from "../../components/public/footer/footer";
import { useDispatch, useSelector } from "react-redux";

// import '../../PDFViewer.css'
import { Viewer, Worker, ScrollMode, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Button, Spin, notification } from "antd";
import { acceptOrRejectAgreement, getAgreementPDFLink } from "../../redux/actions/agreement.action";
import { validateUser } from "../../redux/actions/login.action";
import { AUTHENTICATE, RESET_INSTA_STATE } from "../../redux/types/types";
import { ToolbarSlot, TransformToolbarSlot, toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { store } from "../../redux/store";
export default function Agreement() {
  const { validate } = useSelector((state) => {
    return state;
  });
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [load, setLoad] = useState(false);
  const [pdfURL, setPdfUrl] = useState();


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAgreementPDFLink()).then((res) => {
      console.log("resLINKKK", res);
      setPdfUrl(res?.data?.ducument_url)
      setLoader(false)
    })
  }, [])
  // const defaultLayoutPluginInstance = defaultLayoutPlugin({
  //   toolbarPlugin: {
  //     fullScreenPlugin: {
  //       onEnterFullScreen: (zoom) => {
  //         zoom(SpecialZoomLevel.PageFit);
  //         defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
  //           ScrollMode.Wrapped
  //         );
  //       },
  //       onExitFullScreen: (zoom) => {
  //         zoom(SpecialZoomLevel.PageWidth);
  //         defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
  //           ScrollMode.Vertical
  //         );
  //       },
  //     },
  //   },
  // });

  const defaultLayoutPluginInstance = toolbarPlugin({
    toolbarPlugin: {
      fullScreenPlugin: {
        onEnterFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
          defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
            ScrollMode.Wrapped
          );
        },
        onExitFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageWidth);
          defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
            ScrollMode.Vertical
          );
        },
      },
    },
  });
  const { renderDefaultToolbar, Toolbar } = defaultLayoutPluginInstance;

  const transform = (slot) => ({
    ...slot,
    // Download: () => <></>,
    DownloadMenuItem: () => <></>,
    // EnterFullScreen: () => <></>,
    // SwitchScrollModeMenuItem: () => <></>,
    ShowProperties: () => <></>,
    Open: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
  });

  const renderPage = (props) => (
    <>
      {props.canvasLayer.children}
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.2)',
            fontSize: `${8 * props.scale}rem`,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            transform: 'rotate(-45deg)',
            userSelect: 'none',
          }}
        >
          ORME
        </div>
      </div>
      {props.annotationLayer.children}
      {props.textLayer.children}
    </>
  );

  const agreeRes = (status) => {
    dispatch(acceptOrRejectAgreement(status)).then((res) => {

      console.log("ressss", res);
      if (res?.success) {
        dispatch(validateUser(validate?.payload?.message?.token))
        notification.success({
          message: res?.message,
          className: "toast-success",
        });
      } else {
        history("/login")
        localStorage.removeItem("token");
        localStorage.removeItem("sc_token");
        localStorage.removeItem("Role");
        store.dispatch({ type: AUTHENTICATE, payload: {} })
        store.dispatch({ type: RESET_INSTA_STATE });
        notification.error({
          message: res?.response?.data?.message,
          className: "toast-error",
        });
      }
      setLoad(false)
      setLoading(false)
    })
  }

  // useEffect(() => {
  //   document.body.classList.add("agreement-page");
  //   return () => {
  //     document.body.classList.remove("agreement-page");
  //   };
  // }, []);

  return (
    <>
      {validate?.payload?.success && (validate?.payload?.message?.agreement === "waiting" || validate?.payload?.message?.agreement === "rejected") && <PublicHeader />}
      {loader ? <span className="spinclass loader-center position-relative">
        <Spin size="large" />
      </span> :
        (
          pdfURL ?
            <div style={{minHeight: "100vh"}} className={` general-section ${validate?.payload?.success && "p-0"}`}>
              <div className="mid-width max-1140">
                <h2 className="text-left mt-5 mb-4 terms-hd">
                  TERMS & AGREEMENT
                </h2>
              </div>

              <div
              className="pdf-wrapper-main"
              >

                <div
                  className="rpv-core__viewer mid-width max-1140 pdf-wrapper"
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: '0'
                  }}
                >
                  <div
                    className=""
                    style={{
                      alignItems: 'center',
                      backgroundColor: '#eeeeee',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      padding: '0.25rem',
                    }}
                  >
                    <Toolbar>
                      {(props) => {
                        const {
                          // CurrentPageInput,
                          Download,
                          EnterFullScreen,
                          // GoToNextPage,
                          // GoToPreviousPage,
                          NumberOfPages,
                          Print,
                          ShowSearchPopover,
                          Zoom,
                          ZoomIn,
                          ZoomOut,
                        } = props;
                        return (
                          <>
                            <div style={{ padding: '0px 2px' }}>
                              <ShowSearchPopover />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                              <ZoomOut />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                              <Zoom />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                              <ZoomIn />
                            </div>
                            {/* <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                              <GoToPreviousPage />
                            </div>
                            <div style={{ padding: '0px 2px', width: '4rem' }}>
                              <CurrentPageInput />
                            </div> */}
                            <div style={{ padding: '0px 2px', textAlign: "center", flexGrow: "1" }}>
                               <NumberOfPages /> Pages
                            </div>
                            {/* <div style={{ padding: '0px 2px' }}>
                              <GoToNextPage />
                            </div> */}
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                              <EnterFullScreen />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                              <Download />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                              <Print />
                            </div>
                          </>
                        );
                      }}
                    </Toolbar>
                  </div>
                  <div
                  className="scroll-box"
                    style={{
                      flex: 1,
                      overflow: 'hidden',
                    }}
                  >
                    <div className="mid-widt max-114">
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer fileUrl={pdfURL} renderPage={renderPage} plugins={[defaultLayoutPluginInstance]} />
                      </Worker>
                    </div>
                  </div>
                </div>
              </div>




              {/* <div className="mid-width max-1140">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl='https://d1hq75pouwojqi.cloudfront.net/ORME+Brand+Agreement.pdf' renderPage={renderPage}
              plugins={[
                toolbarPluginInstance,
              ]}
            />
          </Worker>
        </div> */}
              <div className=" d-flex align-items-end justify-content-center  mb-5 mt-5">
                <Button
                  className="default-btn red-btn d-flex align-items-center justify-content-center"
                  type="primary"
                  size="large"
                  loading={loading}
                  onClick={() => { agreeRes("rejected"); setLoading(true) }}
                >
                  Reject
                </Button>
                <Button
                  loading={load}
                  className="default-btn  d-flex align-items-center justify-content-center"
                  type="primary"
                  size="large"
                  onClick={() => { agreeRes("accepted"); setLoad(true) }}
                >
                  Accept
                </Button>
              </div>
            </div> :
            <h3 className="text-center">Error in fetching Agreement File</h3>)}
      {validate?.payload?.success && (validate?.payload?.message?.agreement === "waiting" || validate?.payload?.message?.agreement === "rejected") && <PublicFooter />}
    </>
  );
}
