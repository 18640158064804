import { CUSTOMURL1 } from '../../config';
import {
  SC_GET_ALL_ORDER
} from '../types/types';
import axios from 'axios';
const sc_token = JSON.parse(localStorage.getItem('sc_token'));


export const scGetAllOrder = (page, limit, token, from_date, to_date, customer_id) => (dispatch) => {
  return axios({
    method: 'GET',
    url: `${CUSTOMURL1}orders`,
    headers: { Authorization: `Bearer ${token ? token : sc_token}` },
    params: {
      page: page ? page : 1,
      limit,
      from_date,
      to_date,
      customer_id
    },
  })
    .then((response) => {
      dispatch({
        type: SC_GET_ALL_ORDER,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
