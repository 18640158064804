import { CONNECT_STRIPE, STRIPE_BALANCE, STRIPE_REPORT, STRIPE_PAYOUT } from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';

export const connectStripe = () => (dispatch) => {
  return axios({
    method: 'POST',
    url: `/stripe/account/config`,
  })
    .then((response) => {
      dispatch({
        type: CONNECT_STRIPE,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const stripeBalance = () => (dispatch) => {
  return axios({
    method: 'POST',
    url: `/stripe/account/balance`,
  })
    .then((response) => {
      dispatch({
        type: STRIPE_BALANCE,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const stripeReport = ({ limit, last_id, first_id }) => (dispatch) => {

  return axios({
    method: 'POST',
    url: `/stripe/account/transactions`,
    data: {
      limit,
      last_id,
      first_id
    }
  })
    .then((response) => {
      dispatch({
        type: STRIPE_REPORT,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const stripePayout = ({ limit, last_id, first_id }) => (dispatch) => {

  return axios({
    method: 'POST',
    url: `/stripe/account/payouts`,
    data: {
      limit,
      last_id,
      first_id
    }
  })
    .then((response) => {
      dispatch({
        type: STRIPE_PAYOUT,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
