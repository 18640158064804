import { Card, CardContent, Typography } from '@mui/material';
import { Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Select from 'react-select';
import NoDataFound from '../../../reUsable/NoDataFound';
import * as profileActions from '../../../redux/actions/searchProfile';
import LoadingSpin from '../../../reUsable/loadingSpin';
import Box from './Box/Box';

function SearchProfile({
  searchProfileAc,
  profile,
  filterProfileMedia,
  getProfiles,
  profiles,
  clearProfile,
  validate
}) {
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [error, setError] = useState(false);

  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const [sortBy, setSortBy] = useState({ value: 'timestamp', label: 'DATE' });
  const [orderBy, setOrderBy] = useState({ value: 'desc', label: 'DESC' });
  const [posts, setPosts] = useState({ value: '25', label: '25' });
  const [spinner, setLoad] = useState(true);
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const fromDate = moment().startOf("year").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  useEffect(() => {
    getProfiles().then(() => {
      setLoading(false);
    });
    return () => {
      clearProfile();
    };
  }, []);

  const searchProfile = () => {
    setLoading(true);
    setSortBy({ value: 'timestamp', label: 'DATE' });
    setOrderBy({ value: 'desc', label: 'DESC' });
    setPosts({ value: '25', label: '25' });
    searchProfileAc(userName.label, false, validate?.payload?.message?.fb_token, validate?.payload?.message?.instagram_id)
      .then(() => {
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  const style = {
    control: (base) => ({
      ...base,
      height: '40px',
      boxShadow: 'none',
      borderRadius: '0px',
      border: '1px solid black',
      '&:hover': {
        border: '1px solid black',
      },
    }),
  };

  const sortByOptions = [
    { value: 'timestamp', label: 'DATE' },
    { value: 'like_count', label: 'LIKES' },
    { value: 'comments_count', label: 'COMMENTS' },
  ];
  const sortOrderOptions = [
    { value: 'asc', label: 'ASC' },
    { value: 'desc', label: 'DESC' },
  ];
  const noOfPost = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '300', label: '300' },
  ];

  const filterProfileFunc = (e) => {
    setSortBy({ value: 'timestamp', label: 'DATE' });
    filterProfileMedia(false);
  };

  function onSubmitData(e) {
    e.preventDefault();
    filterProfileMedia({
      from_date: startDate.toString(),
      to_date: endDate.toString(),
      sort: sortBy.value,
      order_by: orderBy.value,
      noOfPost: posts.value,
    });
  }
  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function numberFormat(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + 'M';
    if (num >= 1000) return intlFormat(num / 1000) + 'k';
    return intlFormat(num);
  }

  function renderData() {
    if (!error) {
      if (profiles.username) {
        return (
          <>
            <Card sx={{ marginTop: 2 }}>
              <CardContent>
                <Row>
                  <Col
                    xl={2}
                    lg={3}
                    sm={12}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <Card
                      elevation={3}
                      sx={{ maxWidth: 200, borderRadius: '50%' }}
                    >
                      <img
                        style={{ width: '100%' }}
                        src={profiles.profile_picture_url}
                      />
                    </Card>
                  </Col>
                  <Col
                    xl={10}
                    lg={9}
                    sm={12}
                    className="d-flex flex-column justify-content-center"
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      sx={{ color: 'gray', marginTop: 1 }}
                    >
                      <a
                        target="_blank"
                        href={`https://www.instagram.com/${profiles.username}/`}
                        style={{ color: 'gray' }}
                      >
                        {profiles.username}
                      </a>
                    </Typography>
                    {/* <Paper variant='outlined'> */}
                    <div style={{ display: 'flex' }}>
                      <Typography
                        variant="body"
                        sx={{
                          backgroundColor: '#e8e8e8',
                          padding: 1,
                          borderRadius: 1,
                          marginRight: 2,
                        }}
                      >
                        {numberFormat(profiles.followers_count)} Followers
                      </Typography>
                      <Typography
                        variant="body"
                        sx={{
                          backgroundColor: '#e8e8e8',
                          padding: 1,
                          borderRadius: 1,
                          marginRight: 2,
                        }}
                      >
                        {numberFormat(profiles.follows_count)} Following
                      </Typography>
                      <Typography
                        variant="body"
                        sx={{
                          backgroundColor: '#e8e8e8',
                          padding: 1,
                          borderRadius: 1,
                          marginRight: 2,
                        }}
                      >
                        {numberFormat(profiles.media_count)} Posts
                      </Typography>
                    </div>
                    <div className="mt-2">
                      <Typography variant="body" component="div" color="gray">
                        Biography:{' '}
                      </Typography>
                      <Typography variant="body" color="gray">
                        {profiles.biography}
                      </Typography>
                    </div>
                    <div className="mt-2">
                      <Typography variant="body" component="div" color="gray">
                        Website:{' '}
                      </Typography>
                      <Typography
                        variant="body"
                        sx={{
                          color: 'gray',
                          marginTop: 1,
                          textAlign: 'center',
                        }}
                      >
                        <a target="_blank" href={profile.website}>
                          {/* {profiles.website?.slice(0, 40)}... */}
                          {profiles.website}
                        </a>
                      </Typography>
                    </div>
                    {/* </Paper> */}
                  </Col>
                </Row>
              </CardContent>
            </Card>

            <Row className="post-analytics-tab mb-4 mt-3">
              <Col xs={12} xl={12} md={12}>
                <form onSubmit={onSubmitData}>
                  <Row>
                    <Col xs={12} xl={3} md={6}>
                      {/* <Form.Item
                        labelCol={{ span: 24 }}
                        label="Sort By"
                        name="sort"
                      > */}
                      <p>Sort By</p>
                      <Select
                        size="large"
                        value={sortBy}
                        name="sort"
                        className="selectCustomization select-style dark-field select-position"
                        options={sortByOptions}
                        onChange={(e) => {
                          setSortBy(e);
                        }}
                        placeholder="Sort By"
                        styles={style}
                        isSearchable={false}
                      />
                      {/* </Form.Item> */}
                    </Col>
                    {/* <Col xs={12} xl={2} md={6}>
                      <p>Order By</p>
                      <Select
                        value={orderBy}
                        name="order"
                        className="selectCustomization"
                        options={sortOrderOptions}
                        onChange={(e) => {
                          setOrderBy(e);
                        }}
                        placeholder="Order By"
                        styles={style}
                        isSearchable={false}
                      />
                    </Col> */}
                    {/* <Col xs={12} xl={2} md={6}>
                      <p>No of Posts</p>
                      <Select
                        value={posts}
                        name="order"
                        className="selectCustomization"
                        options={noOfPost}
                        onChange={(e) => {
                          setPosts(e);
                        }}
                        placeholder="Order By"
                        styles={style}
                        isSearchable={false}
                      />
                    </Col> */}
                    {/* <Col
                      className="d-flex align-items-end"
                      xs={12}
                      xl={2}
                      md={6}
                    >
                      {searchLoading ? (
                        <Form.Item className="d-flex align-items-end">
                          <Spin spinning={spinner}>
                            <Button
                              type="submit"
                              variant="primary"
                              className="default-btn fltr-hpr  medium-btn"
                            >
                              Search
                            </Button>
                          </Spin>
                        </Form.Item>
                      ) : (
                        <Form.Item className="d-flex align-items-end">
                          <Button
                            type="submit"
                            variant="primary"
                            className="default-btn fltr-hpr  medium-btn"
                          >
                            Search
                          </Button>
                        </Form.Item>

                        // <Button
                        //   style={{ height: 44 }}
                        //   type="submit"
                        //   variant="primary"
                        //   className="fltr-hpr"
                        // >
                        //   Search
                        // </Button>
                      )}
                      {clearLoading ? (
                        <Form.Item className="d-flex align-items-end">
                          <Spin spinning={spinner}>
                            <Button
                              type="submit"
                              variant="primary"
                              className="default-btn fltr-hpr  medium-btn"
                            >
                              Reset
                            </Button>
                          </Spin>
                        </Form.Item>
                      ) : (
                        <Form.Item className="d-flex align-items-end">
                          <Button
                            onClick={filterProfileFunc}
                            className="default-btn outline fltr-hpr  medium-btn  res-btn2"
                            type="primary"
                            size="large"
                          >
                            Reset
                          </Button>
                        </Form.Item>
                      )}
                    </Col> */}
                    <Col
                      className="d-flex align-items-end"
                      xs={12}
                      xl={3}
                      md={6}
                    >
                      {searchLoading ? (
                        <Spin spinning={spinner}>
                          <Button
                            style={{ height: 44 }}
                            type="button"
                            variant="primary"
                            className="default-btn fltr-hpr medium-btn ml-0 w-initial"
                          >
                            Search
                          </Button>
                        </Spin>
                      ) : (
                        <Button
                          style={{ height: 44 }}
                          type="submit"
                          variant="primary"
                          className="default-btn fltr-hpr  medium-btn ml-0 w-initial"
                        >
                          Search
                        </Button>
                      )}
                      {clearLoading ? (
                        <Spin spinning={spinner}>
                          <Button
                            style={{ height: 44 }}
                            variant="gray"
                            className="default-btn outline fltr-hpr  medium-btn  res-btn2 w-initial"
                          >
                            Reset
                          </Button>
                        </Spin>
                      ) : (
                        <Button
                          style={{ height: 44 }}
                          onClick={filterProfileFunc}
                          variant="gray"
                          className="default-btn outline fltr-hpr  medium-btn  res-btn2 w-initial"
                        >
                          Reset
                        </Button>
                      )}
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
            <hr className="separator-line" />
            <div className="mt-3">
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 700: 2, 1100: 3, 1500: 4 }}
              >
                <Masonry gutter="15px">
                  {profiles.media?.data.map((item, index) => {
                    return <Box data={item} index={index} />;
                  })}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </>
        );
      }
      return null;
    } else {
      return <NoDataFound />;
    }
  }
  return (
    <div className="container-flui">
      <div className="title-area">
        <h1 className="page-title">Monitor</h1>
      </div>
      {profile.success &&(
      <Row className="post-analytics-tab mb-">
        <Col xs={12} xl={12} md={12}>
          <form onSubmit={onSubmitData}>
            <Row>
              <Col xs={12} xl={3} md={6}>
                <Select
                  value={userName}
                  name="sort"
                  className="selectCustomization"
                  options={[...profile?.Data].map((item) => {
                    return {
                      value: item._id,
                      label: item.profile_name,
                    };
                  })}
                  onChange={(e) => {
                    setUserName(e);
                  }}
                  placeholder="Select Profile"
                  styles={style}
                  isSearchable={false}
                />
              </Col>
              <Col className="d-flex col-xl-3" xs={12} md={6}>
                {loading ? (
                  <Button
                    type="submit"
                    variant="primary"
                    className="default-btn fltr-hpr medium-btn ml-0 d-flex justify-content-center min-w-100"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-2"
                    />{' '}
                    Search
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="primary"
                    className="default-btn fltr-hpr medium-btn ml-0 min-w-100"
                    onClick={searchProfile}
                    disabled={userName ? false : true}
                  >
                    Search
                  </Button>
                )}
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
      )}
      <hr className="separator-line" />
      {!loading ? (
        renderData()
      ) : (
        <div className='ugc-loading'>
        <LoadingSpin />
        </div>
      )}
    </div>
  );
}
function mapStateToProps({ profile, profiles, validate }) {
  return { profile, profiles, validate };
}
export default connect(mapStateToProps, profileActions)(SearchProfile);
