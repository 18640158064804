import React, { useState, useEffect, useRef } from "react";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { Button, Checkbox, Form, Input, InputNumber, Modal as Modald, Tag, notification } from "antd";
import { DatePicker, Spin, Select, Empty } from "antd";
import {
  customerorderHistory,
  details,
  getPurchaser,
  getReferrer,
} from "../../redux/actions/purchaseHistory.action";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AsyncSelect from "react-select/async";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { getSavedCategories, getSavedGeneralCategories, getSavedSubCategories } from "../../redux/actions/newCategory.action";
import { searchEventID, searchReviewID, searchShowID } from "../../redux/actions/eventID.action";
import { usersComments, searchTitleFilter, searchUserFilter } from "../../redux/actions/banUserList";
import { deleteChat, deleteComment, unBan } from "../../redux/actions/chat";
import { banUser } from "../../redux/actions/chat";
import Swal from "sweetalert2";
import debounce from 'lodash.debounce'; // Import the debounce function
import useWebSocket from 'react-use-websocket';
const socketUrl = process.env.REACT_APP_SOCKET_URL
const { Option } = Select;
let arrHost = [];
const { RangePicker } = DatePicker;

export default () => {
  const [form] = Form.useForm();
  const dateFormat = "MM-DD-YYYY";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const min_date = moment().startOf("year").format("YYYY-MM-DD");
  const max_date = moment(new Date()).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(min_date);
  const [endDate, setEndDate] = useState(max_date);
  const [totalEvent, setTotalEvent] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [source, setSource] = useState("all");
  const [allCustomer, setAll] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [modal, setModal] = useState(false);
  const [flag, setFlag] = useState(false);
  const [status, setStatus] = useState(false);
  const [submit, setSubmit] = useState("none");
  const [customerId, setCustomerId] = useState();
  const [gender, setGender] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [load, setLoad] = useState(false);
  const [generalLoad, setGeneralLoad] = useState(false);
  const [catLoad, setCatLoad] = useState(false);
  const [loadd, setLoadd] = useState(true);
  const [subCatLoad, setSubCatLoad] = useState(false);
  const [order, setOrder] = useState();
  const [purchaseBy, setPurchaseBy] = useState();
  const [referredBy, setReferredBy] = useState();
  const [searchPurchaseBy, setSearchPurchaseBy] = useState([]);
  const [searchReferredBy, setsearchReferredBy] = useState([]);
  const [banType, setBanType] = useState("chatban");
  // const [banUser, setBanUser] = useState();
  const [searchBanUser, setSearchBanUser] = useState();
  const [searchBanUserData, setSearchBanUserData] = useState([]);
  const [searchAllBanUserData, setSearchAllBanUserData] = useState([]);
  const [groupByTable, setGroupByTable] = useState("chatban");
  const [purchaseLoad, setPurchaseLoad] = useState(false);
  const [referrerLoad, setReferrerLoad] = useState(false);
  const [searchID, setSearchID] = useState();
  const [searchIDLoad, setSearchIDLoad] = useState("");
  const [searchDataIDs, setSearchDataIDs] = useState([]);
  const [idType, setIdType] = useState("event");
  const [banInfo, setBanInfo] = useState({});
  const [banModal, setBanModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [commentData, setCommentData] = useState();
  const [banLoading, setBanLoading] = useState(false);
  const [videoTitleLoad, setVideoTitleLoad] = useState("");
  const [videoTitle, setVideoTitle] = useState();
  const [videoTitleList, setVideoTitleList] = useState([]);

  const [userLoad, setUserLoad] = useState("");
  const [userData, setUserData] = useState();
  const [userList, setUserList] = useState([]);

  const { shopifyCustomerorder, detailsOrder, savedGeneralCategories, savedCategory, savedSubCategory, bannedUsersLists, validate } = useSelector(
    (state) => {
      return state;
    }
  );
  const limit = 15;
  const upperLimit = (currentPage + 1) * limit;
  const lowerLimit = upperLimit - limit + 1;
  const itemsPerPage = 15;
  const pageCount = Math.ceil(totalEvent / itemsPerPage);
  const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);

  const didUnmount = useRef(false);
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      return didUnmount.current === false;
    },
    reconnectAttempts: 2,
    reconnectInterval: 3000,
  });
  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    // setSearchIDLoad(true)
    // dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
    //   setSearchIDLoad(false)
    //   if (res?.success) {
    //     setSearchDataIDs(res?.message)
    //   }
    // })
    // setLoading(true);
    // setGeneralLoad(true)
    // dispatch(getSavedGeneralCategories()).then((res) => {
    //   if (res.success) {
    //     setGeneralLoad(false)
    //   } else {
    //     setGeneralLoad(false)
    //   }
    // })

    dispatch(usersComments(
      1,
      limit,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    )).then((res) => {
      setLoading(false);
      setSearchBanUserData(res)
      setSearchAllBanUserData(res)
      // 
    })
    handleFilterVideo();
    handleFilterUser();

    // dispatch(
    //   customerorderHistory(1, limit, startDate, endDate, gender, category, subCategory, source, "", "", "", "", "Order")
    // ).then((res) => {
    //   setTotalEvent(res?.message?.total_records);
    //   setLoading(false);
    // });
  }, []);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
    handleFilters(dataString[0], dataString[1])
  };

  const handleFilters = (startDate, endDate) => {
    setVideoTitle();
    setUserData();
    setVideoTitleLoad(true);
    dispatch(searchTitleFilter(undefined, 1, limit, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"))).then((res) => {
      setVideoTitleLoad(false);
      if (res?.success) {
        setVideoTitleList(res?.message);
      }
    });
    setUserLoad(true);
    dispatch(searchUserFilter(undefined, 1, limit, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"))).then((res) => {
      setUserLoad(false);
      if (res?.success) {
        setUserList(res?.message);
      }
    });
  }

  const handleFilterVideo = debounce((e) => {
    setVideoTitleLoad(true);
    dispatch(searchTitleFilter(e, 1, limit, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"))).then((res) => {
      setVideoTitleLoad(false);
      if (res?.success) {
        setVideoTitleList(res?.message);
      }
    });
  }, 300);

  const handleFilterUser = debounce((e) => {
    setUserLoad(true);
    dispatch(searchUserFilter(e, 1, limit, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"))).then((res) => {
      setUserLoad(false);
      if (res?.success) {
        setUserList(res?.message);
      }
    });
  }, 300);

  const handleChangeTitle = (e) => {
    setVideoTitle(e)
    setUserData();
    setUserLoad(true);
    dispatch(searchUserFilter(undefined, 1, limit, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), e?.value)).then((res) => {
      setUserLoad(false);
      if (res?.success) {
        setUserList(res?.message);
      }
    });
  };

  // console.log(searchBanUserData?.message?.total_records, 'page');
  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);

    setLoading(true);
    dispatch(
      usersComments(
        page + 1,
        limit,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        videoTitle?.value,
        userData?.value
      )
    ).then((res) => {
      setLoading(false);
      setSearchBanUserData(res)
      setSearchAllBanUserData(res)
      setTotalEvent(res?.payload?.message?.total_records);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setGroupByTable(banType)
    setStatus(true);
    dispatch(usersComments(
      1,
      limit,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      videoTitle?.value,
      userData?.value
    )).then((res) => {
      setLoading(false);
      setSearchBanUserData(res)
      setSearchAllBanUserData(res)
      setTotalEvent(res?.message?.total_records);
      setCurrentPage(0);
    });
    // setSubmit(groupBy);
  };

  const detailView = (orderDetail) => {
    setModal(true);
    setLoad(true);
    dispatch(details(orderDetail?.sales_tracker_id, orderDetail?._id)).then(
      (res) => {
        setLoad(false);
      }
    );
  };

  const handleReset = (res) => {
    setLoading(true);
    setCurrentPage(0);
    setBanType("chatban")
    setGroupByTable("chatban")
    setSearchBanUser()
    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment(new Date()).format("YYYY-MM-DD");
    setStartDate(min_date);
    setEndDate(max_date);
    setVideoTitle();
    setUserData();
    dispatch(usersComments(
      1,
      limit,
      moment(min_date).format("YYYY-MM-DD"),
      moment(max_date).format("YYYY-MM-DD")
    )).then((res) => {
      setLoading(false);
      setSearchBanUserData(res)
      setSearchAllBanUserData(res)
      setTotalEvent(res?.message?.total_records);
    });
    // handleFilterVideo();
    // handleFilterUser();
    handleFilters(min_date, max_date)
  };

  const loadOptions = async (input, callback) => {
    await smartSearchFilter(input);

    const result = arrHost.filter((item) => {
      return {
        value: `${item.value}`,
        label: `${item.label}`,
      };
    });

    callback(result);
  };

  const smartSearchFilter = async (value) => {
    if (value.length > 0 && value.trim()) {
      await axios
        .post(`brand/reports/getCustomers`, {
          name: value.trim(),
        })
        .then((response) => {
          const loadHost = [];
          const host = response.data.data;
          if (host.length === 0) {
            // notification.error({
            //   message: "No Host Found",
            //   className: "toast-error",
            // });
          }
          setAll(host);
          host.map((item) => {
            return loadHost.push({
              value: item?._id,
              label: item?.first_name + " " + item?.last_name,
            });
          });
          arrHost = loadHost;
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  const handleGender = (e) => {
    setCurrentPage(0);
    setGender(e);
    setCategory(null)
    setSubCategory(null)
    setCatLoad(true)
    dispatch(getSavedCategories(e)).then((res) => {
      if (res.success) {
        setCatLoad(false)
      } else {
        setCatLoad(false)
      }
    })

  };

  const handleCategory = (e) => {
    setCurrentPage(0);
    setCategory(e);
    setSubCategory(null)
    setSubCatLoad(true)
    dispatch(getSavedSubCategories(gender, e)).then((res) => {
      if (res.success) {
        setSubCatLoad(false)
      } else {
        setSubCatLoad(false)
      }
    })
  };

  const handleSubCategory = (e) => {
    setCurrentPage(0);
    setSubCategory(e);
  };

  const handleSource = (e) => {
    setSource(e);
  };

  const handleFilterPurchaseBy = (e) => {
    setPurchaseLoad(true)
    dispatch(getPurchaser(e)).then((res) => {
      setPurchaseLoad(false)
      if (res?.success) {
        setSearchPurchaseBy(res?.message)
      }
    })

  }

  const unban = () => {
    dispatch(unBan(banInfo)).then((res) => {
      if (res.success) {
        const banMessage = {
          type: 'eventmessage',
          message: "User ban message",
          isban: false,
          messageId: banInfo?.event_id ? banInfo?.event_id : banInfo?.review_id,
          user_id: banInfo?.user_id,
          id: Math.floor(Math.random() * 10) + 1,
          custom_message: banInfo?.event_id ? 'from_event' : null
        };
        sendMessage(JSON.stringify(banMessage));
        notification.success({
          message: res?.message,
          className: "toast-success",
        });
        setLoadd(true)
        setBanModal(false);
        dispatch(usersComments(1, limit)).then((res) => {
          setLoadd(false)
          setSearchBanUserData(res)
          setSearchAllBanUserData(res)
        })
      }
      else {
        notification.error({
          message: "User not found in ban list",
          className: "toast-error",
        });
      }
    })
  }

  const removeComment = (id) => {
    Swal.fire({
      title: `Are You Sure You Want To delete this comment?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteComment(id)).then((res) => {
          console.log(res, 'res');

          setLoading(true)
          if (res.payload.success) {
            notification.success({
              message: res?.payload?.message,
              className: "toast-success",
            });
            dispatch(usersComments(currentPage + 1, limit)).then((res) => {
              setLoading(false);
              setSearchBanUserData(res)
              setSearchAllBanUserData(res)
              // 
            })
          } else {
            notification.error({
              message: res?.payload?.data?.message,
              className: "toast-error",
            });
            dispatch(usersComments(currentPage + 1, limit)).then((res) => {
              setLoading(false);
              setSearchBanUserData(res)
              setSearchAllBanUserData(res)
              // 
            })
          }
        });
      }
    });
  };

  const editComment = (item) => {
    setCommentModal(true);
    setCommentData(item);
  };

  const handleIDType = (e) => {
    setIdType(e)
    setSearchDataIDs([])
    setSearchID(null)

    switch (e) {
      case "event":
        return dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID("", "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID("", "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
    }
  }

  const handleReviewID = (e) => {
    setSearchIDLoad(true)
    switch (idType) {
      case "event":
        return dispatch(searchEventID(e.replaceAll("-", ''), "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID(e.replaceAll("-", ''), "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID(e.replaceAll("-", ''), "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
    }
  }

  const onFinish = (val) => {
    const { reason, delete_chat } = val;
    console.log(reason);
    const formData = {
      reason: reason,
      event_id: commentData?.event?._id,
      user_id: commentData?.user?._id,
      ban_type: banType
    }


    setBanLoading(true);
    dispatch(banUser(formData)).then((res) => {
      setLoading(true);
      if (res.payload.success) {
        const banMessage = {
          type: 'eventmessage',
          // message: "User ban message",
          message: reason,
          isban: true,
          messageId: commentData?.event?._id,
          // reviewId: review_id,
          user_id: commentData?.user?._id,
          // id: Math.floor(Math.random() * 10) + 1,
        };
        sendMessage(JSON.stringify(banMessage));
        notification.success({
          message: res?.payload?.message,
          className: "toast-success",
          duration: 5,
        });
        if (delete_chat) {
          dispatch(deleteComment(commentData?._id)).then((res) => {
            if (res.payload.success) {
              const deleteMessage = {
                type: 'eventmessage',
                messageId: commentData?._id,
                reviewId: commentData?._id,
                user_id: validate?.payload?.message?._id,
                id: Math.floor(Math.random() * 10) + 1,
              };
              sendMessage(JSON.stringify(deleteMessage));
              notification.success({
                message: 'Chat deleted Successfully',
                className: "toast-success",
              });
            } else {
              notification.error({
                message: res?.payload?.data?.message,
                className: "toast-error",
              });
            }
          });
        }
        dispatch(usersComments(currentPage + 1, limit)).then((res) => {
          setLoading(false);
          setSearchBanUserData(res)
          setSearchAllBanUserData(res)
          // 
        })
        setLoading(false);
        setCommentModal(false);
        setBanLoading(false);
        form.resetFields();
      } else {
        setBanLoading(false);
        setLoading(false);
        notification.error({
          message: res?.payload?.data?.message,
          className: "toast-error",
        });
      }
    });

  };

  const handleFilterReferredBy = (e) => {
    setReferrerLoad(true)
    dispatch(getReferrer(e)).then((res) => {
      setReferrerLoad(false)
      if (res?.success) {
        setsearchReferredBy(res?.message)
      }
    })

  }
  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);
  function DefaultTable() {
    // let data = shopifyCustomerorder?.payload?.message?.data;
    if (searchBanUserData?.message?.data) {
      switch (groupByTable) {
        default:
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-120">Date</th>
                    {/* <th className="td-width-200">Brand</th> */}
                    {/* <th className="td-width-100">PID</th> */}
                    <th className="td-width-100">Video Title</th>
                    <th className="td-width-100">User</th>
                    <th className="td-width-100">Comments</th>
                    <th className="td-width-100">Reason</th>
                    <th className="td-width-100">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {searchBanUserData?.message?.data?.length === 0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    searchBanUserData?.message?.data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {item?.message?.datetime ? moment(item?.message?.datetime).format('MMM DD, YYYY HH:MM A') : "-"}
                          </td>

                          <td>
                            <div className="desc-limit" style={{ maxHeight: '40px' }}>
                              {item?.event?.title}
                            </div>
                          </td>
                          <td>
                            {/* {item?.user?.name} */}
                            {item?.user?.name} {item?.ban && <span title={item?.ban?.reason} style={{ fontSize: '10px', color: 'red' }}>(Ban)</span>}
                          </td>
                          <td style={{ width: '200px' }}>
                            <div title={item?.message?.content} className="desc-limit w-100" style={{ maxHeight: '40px' }}>
                              {item?.message?.content ? item?.message?.content : "-"}
                            </div>
                          </td>
                          <td>
                            <div className="desc-limit" style={{ maxHeight: '40px', color: 'red' }}>
                              {item?.ban?.reason}
                            </div>
                          </td>
                          <td className="" style={{ width: '100px' }}>
                            <div style={{ float: 'right' }}>
                              {/* <button type="button" 
                            onClick={() => { setBanModal(true); item?.event?._id ? setBanInfo({ event_id: item?.event_id, user_id: item?.user_id, ban_type: banType }) : setBanInfo({ review_id: item?.review_id, user_id: item?.user_id, ban_type: banType }) }} 
                            class="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"><span>Un Ban</span></button> */}
                              {
                                item?.user?._id !== item?.brand?._id ?
                                  !item?.ban ?
                                    <button
                                      type="button"
                                      onClick={() => {
                                        editComment(item);
                                      }}
                                      class="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"
                                    >
                                      <span title="edit">
                                        {/* <i class="fa fa-pencil" /> */}
                                        Ban
                                      </span>
                                    </button>
                                    :
                                    <button
                                      type="button"
                                      onClick={() => { setBanModal(true); setBanInfo({ event_id: item?.event?._id, user_id: item?.user?._id, ban_type: banType }) }}
                                      class="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"
                                    >
                                      <span title="edit">
                                        {/* <i class="fa fa-pencil" /> */}
                                        UnBan
                                      </span>
                                    </button>
                                  :
                                  <button class="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"
                                    style={{ visibility: 'hidden' }}>
                                    <span title="edit">
                                      {/* <i class="fa fa-pencil" /> */}
                                      Ban
                                    </span>
                                  </button>
                              }
                              <button
                                type="button"
                                onClick={() => {
                                  removeComment(item?._id);
                                }}
                                class="ant-btn ant-btn-re red-btn-new default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"
                              >
                                <span title="remove">
                                  <i class="fa fa-trash" />
                                </span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </>
          );
      }
    }
  }

  return (
    <>
      <div className="sales-page-main">
        {/* <div class="title-area mb-10">
          <h1>Orders</h1>
        </div> */}
        <div className="brand_container_main aff-payment">
          <Form form={form}>
            <div className="row w-20-desktop-main">
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select Start Date / End Date</p>
                <RangePicker
                  size="large"
                  className="w-100"
                  key={4}
                  value={
                    startDate && endDate
                      ? [moment(startDate), moment(endDate)]
                      : []
                  }
                  allowClear={false}
                  ranges={{
                    "Last Year": [
                      moment().startOf("year"),
                      moment().subtract(1, "year").endOf("year"),
                    ],
                    "This Year": [moment().startOf("year"), moment()],
                    "Last Month": [
                      moment().subtract(1, "month").startOf("month"),
                      moment().subtract(1, "month").endOf("month"),
                    ],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Week": [
                      moment().subtract(1, "week").startOf("week"),
                      moment().subtract(1, "week").endOf("week"),
                    ],
                    "Yesterday": [
                      moment().subtract(1, "day").startOf("day"),
                      moment().subtract(1, "day").endOf("day"),
                    ],
                    Today: [moment(), moment()],
                  }}
                  format={dateFormat}
                  onChange={dateRangePickerChanger}
                />
              </div>

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Search Video Title</p>
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  key={4}
                  type="Number"
                  notFoundContent={videoTitleLoad ? <Spin size="small" /> : <Empty />}
                  loading={videoTitleLoad}
                  optionFilterProp="children"
                  placeholder="Enter Title"
                  labelInValue
                  onChange={(e) => handleChangeTitle(e)}
                  optionLabelProp="label"
                  onSearch={handleFilterVideo}
                  // filterOption={(input, option) => {
                  //   const rawValue = option.value;
                  //   return rawValue.toLowerCase().includes(input.toLowerCase());
                  // }}
                  focus={false}
                  value={videoTitle?.label}
                  onBlur={false}
                >
                  {videoTitleList?.data && !videoTitleLoad &&
                    videoTitleList?.data?.map((item, i) => {
                      return (
                        <Option value={item?._id} label={item?.title}>
                          {item?.title}
                        </Option>
                      );
                    })}
                </Select>
              </div>

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Search User</p>
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  key={4}
                  type="Number"
                  notFoundContent={userLoad && <Spin size="small" />}
                  loading={userLoad}
                  optionFilterProp="children"
                  placeholder="Enter User"
                  labelInValue
                  onChange={(e) => setUserData(e)}
                  optionLabelProp="label"
                  onSearch={handleFilterUser}
                  // filterOption={(input, option) => {
                  //   const rawValue = option.value;
                  //   return rawValue.toLowerCase().includes(input.toLowerCase());
                  // }}
                  focus={false}
                  value={userData?.label}
                  onBlur={false}
                >
                  {userList?.data && !videoTitleLoad &&
                    userList?.data?.map((item, i) => {
                      return (
                        <Option value={item?._id} label={item?.name}>
                          {item?.name}
                        </Option>
                      );
                    })}
                </Select>
              </div>



              {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select User</p>
                <Select
                  showSearch
                  className="w-100 select-style dark-field"
                  placeholder="Enter User Name"
                  key={4}
                  size="large"
                  focus={false}
                  // loading={purchaseLoad}
                  // disabled={purchaseLoad}
                  onBlur={false}
                  // notFoundContent={purchaseLoad && <Spin size="small" />}
                  value={searchBanUser}
                  onChange={(e) => setSearchBanUser(e)}
                  // onSearch={handleFilterBanUser}
                  filterOption={(input, options) =>
                    options?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                // defaultValue="date"
                >
                  {/* <Option value="chatban">Events/Reviews Ban</Option>
                  <Option value="permaban">Global Ban</Option> */}
              {/* {searchBanUserData?.message?.data && !loading &&
                Array.from(new Map(searchBanUserData?.message?.data.map(item => [item?.user?._id, item])).values())?.map((item, i) => {
                  return (
                    <Option value={item?.user?._id}>
                      {item?.user?.name}
                    </Option>
                  );
                })}
            </Select>
        </div> */}




              <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                <Button
                  onClick={handleSubmit}
                  className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
                <Button
                  onClick={handleReset}
                  className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Reset
                </Button>
              </div>
            </div>
          </Form >
          {/* <hr className="separator-line mt-0" /> */}

          < div className="purchase-data referral-data" >
            {
              loading ? (
                // <div className="antd-loading-ift">
                // 	<Spin size="large" />
                // </div>
                <div className="loading-wrap" >
                  <span className="spinclass loader-center position-relative">
                    <Spin size="large" />
                  </span>
                </div>
              ) : (
                <>
                  {<DefaultTable />}
                  {/* {submit === "date" && <DateGroupTable />} */}
                  {/* {submit === "category" && <CategoryGroupTable />}
                {submit === "brand" && <BrandGroupTable />} */}
                  <div className="purchase-data referral-data" style={{ position: 'relative' }}>
                    {searchBanUserData?.message?.data?.length ? (
                      <>
                        <span style={{
                          position: 'absolute',
                          right: 90,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          paddingRight: '10px' // adjust as needed
                        }}>
                          Page {currentPage + 1} of {Math.ceil(searchBanUserData?.message?.total_records / 15)}
                        </span>
                        <ReactPaginate
                          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                          pageCount={Math.ceil(searchBanUserData?.message?.total_records / 15)}
                          forcePage={currentPage}
                          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                          pageClassName="page-item d-none"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item d-none"
                          breakLinkClassName="page-link"
                          containerClassName={"pagination custom-paginate"}
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                </>
              )
            }
          </div >
        </div >
      </div >

      <Modal
        dialogClassName="code-activated-popup"
        size="lg"
        scrollable
        show={modal}
        onHide={() => setModal(false)}
        centered
        backdrop="static"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Header className="modal-header-style2" closeButton>
          <Modal.Title className="mb-0">Order Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {load ? (
            <span className="mt-2 mr-2 loader-center position-relative">
              <Spin size="large" />
            </span>
            // <Spin
            //   style={{
            //     display: "flex",
            //     justifyContent: "center",
            //   }}
            // />
          ) : (
            <>
              <div className="order-detail-title-area">
                <div className="d-flex flex-column">
                  <div className="order-num">
                    Order #
                    <div className="order-name">
                      {
                        detailsOrder?.payload?.message?.order_detail
                          ?.order_number
                      }
                    </div>
                  </div>
                  <duv className="order-num order-name-main">
                    Customer Name:
                    <div className="order-name">
                      {detailsOrder?.payload?.message?.order_detail?.customer
                        ?.first_name +
                        " " +
                        detailsOrder?.payload?.message?.order_detail?.customer
                          ?.last_name}
                    </div>
                  </duv>
                </div>

                <span className="order-date">
                  {moment
                    .utc(
                      detailsOrder?.payload?.message?.order_detail?.created_at
                    )
                    .format("MMMM Do YYYY [from Online Shop]")}
                </span>
              </div>
              <Table responsive size="sm" className="transactions-box">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-120">Order Date</th>
                    <th className="td-width-100">SKU #</th>
                    <th className="td-width-200">Description</th>
                    <th className="td-width-80">Qty</th>
                    <th className="td-width-100 text-right">Price</th>
                    <th className="td-width-100 text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {!detailsOrder?.loading &&
                    detailsOrder?.payload?.message?.order_detail?.line_items?.map(
                      (item, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              {moment
                                .utc(order?.created_at)
                                .format("DD-MM-YYYY")}
                            </td>
                            <td>{item?.sku}</td>
                            <td>{item?.title}</td>
                            <td>{item?.quantity}</td>
                            <td className="text-right">{numeral(item?.price).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(
                                getSum(item?.quantity, item?.price)
                              ).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </Table>
              <div className="order-sum">
                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Subtotal</div>
                    <div className="left-info">
                      {
                        detailsOrder?.payload?.message?.order_detail?.line_items
                          ?.length
                      }{" "}
                      item
                    </div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail
                        ?.total_line_items_price
                    ).format("$0,0.0'")}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Discount</div>
                    {detailsOrder?.payload?.message?.order_detail
                      ?.total_discounts
                      ? numeral(
                        detailsOrder?.payload?.message?.order_detail
                          ?.total_discounts /
                        detailsOrder?.payload?.message?.order_detail
                          ?.total_line_items_price
                      ).format("0%")
                      : ""}
                  </div>
                  {detailsOrder?.payload?.message?.order_detail
                    ?.total_discounts ? (
                    <>
                      <div className="right-txt">
                        -
                        {numeral(
                          detailsOrder?.payload?.message?.order_detail
                            ?.total_discounts
                        ).format("$0,0.0'")}
                      </div>
                    </>
                  ) : (
                    <div className="right-txt">
                      {numeral(0).format("$0,0.0'")}
                    </div>
                  )}
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Shipping</div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail
                        ?.total_shipping_price_set?.presentment_money?.amount
                    ).format("$0,0.0'")}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Tax</div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail?.total_tax
                    ).format("$0,0.0'")}
                  </div>
                </div>
                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd fw-bold">Total Amount</div>
                  </div>
                  <div className="right-txt fw-bold">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail?.total_price
                    ).format("$0,0.0'")}
                  </div>
                </div>
              </div>
              {!detailsOrder?.loading &&
                detailsOrder?.payload?.message?.order_return_detail && (
                  <div style={{ marginTop: "20px" }}>
                    <div className="order-detail-title-area">
                      <div className="d-flex flex-column">
                        <div className="order-num">Refund Detail</div>
                      </div>
                    </div>
                    <Table responsive size="sm" className="transactions-box">
                      <thead className="table_heading">
                        <tr>
                          <th>S#</th>
                          <th>Order Date</th>
                          <th>SKU #</th>
                          <th>Description</th>
                          <th>Qty</th>
                          <th>Price</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!detailsOrder?.loading &&
                          detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.map(
                            (item, key) => {
                              return (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>
                                    {moment
                                      .utc(order?.created_at)
                                      .format("DD-MM-YYYY")}
                                  </td>
                                  <td>{item?.line_item?.sku}</td>
                                  <td>{item?.line_item?.title}</td>
                                  {/* <td>{item?.line_item?.quantity}</td>
                              <td>
                                {numeral(
                                  getSum(item?.subtotal, item?.quantity)
                                ).format("$0,0.0'")}
                              </td> */}
                                  <td>{item?.quantity}</td>
                                  <td>
                                    {numeral(
                                      item?.line_item?.price
                                    ).format("$0,0.0'")}
                                  </td>
                                  <td>
                                    {numeral(item?.subtotal).format("$0,0.0'")}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                    {/* {console.log(detailsOrder?.payload?.message?.order_return_detail?.refund_line_items, 'refunds')} */}
                    <div className="order-sum">
                      <>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd">Subtotal</div>
                            <div className="left-info">
                              {detailsOrder?.payload?.message?.order_return_detail
                                ?.refund_line_items?.length
                                ? detailsOrder?.payload?.message
                                  ?.order_return_detail?.refund_line_items?.length
                                : 0}{" "}
                              item
                            </div>
                          </div>
                          <div className="right-txt">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.subtotal);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd">Tax</div>
                          </div>
                          <div className="right-txt">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.total_tax);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd fw-bold">Total Amount</div>
                          </div>
                          <div className="right-txt fw-bold">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.subtotal);
                                },
                                0
                              ) +
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.total_tax);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                )}
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => setModal(false)}
            className="default-btn outline padb-3"
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      <Modald
        maskClosable={false}
        className="modal-generic modal-500"
        centered
        visible={banModal}
        onCancel={() => setBanModal(false)}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={[
          <Button type="primary" onClick={() => setBanModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            // onClick={handleOk}
            onClick={() => {
              unban();
            }}
          >
            Unban
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Unban Confirmation</h2>
        <div className="my-4">
          <h5>Are you sure you want to unban user?</h5>
        </div>
      </Modald>



      <Modald
        maskClosable={false}
        className="modal-generic modal-500"
        centered
        visible={commentModal}
        onCancel={() => { setCommentModal(false); form.resetFields() }}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
      // footer={[
      //   <Button type="primary" onClick={() => setCommentModal(false)}>
      //     Cancel
      //   </Button>,
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // onClick={handleOk}
      //     onClick={() => {
      //       unban();
      //     }}
      //   >
      //     Unban
      //   </Button>,
      // ]}
      >
        <Form
          // layout="vertical"
          form={form}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div class="row">
            <div className="col-md-12">
              <h2 className="modal-hd1">
                Ban User
              </h2>
            </div>
          </div>
          <div className="comment-label">
            Are you Sure You want to ban the user from {commentData?.event?.title} event?
          </div>
          <div class="row">
            <div className="col-md-12 modal-review-right">
              <div className="form-styling">
                <Form.Item
                  name="reason"
                  labelCol={{ span: 24 }}
                  label="Reason to Ban"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the reason",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter the reason"
                    size="large"
                  />
                </Form.Item>
              </div>
              <Form.Item
                className="checkbox-flip"
                name="delete_chat"
                labelCol={{ span: 24 }}
                // label="Discount Duration"
                valuePropName="checked"
                initialValue={
                  true
                }
              >
                <Checkbox>Delete this comment also</Checkbox>
              </Form.Item>
              {/* <Form.Item
                className="checkbox-flip"
                name="delete_chat"
                labelCol={{ span: 24 }}
                // label="Discount Duration"
                valuePropName="checked"
                initialValue={
                  true
                }
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Enter Discount Duration",
              //   },
              // ]}
              >
                <Checkbox>Delete this message also</Checkbox>
              </Form.Item> */}

            </div>
          </div>

          <div class="row">
            <div className="col-12">
              <div className="d-flex justify-content-md-end">
                <Button
                  className="default-btn fw-normal d-flex align-items-center justify-content-center"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={banLoading}
                >
                  Ban
                </Button>
                <Button
                  key="back"
                  className="default-btn  fw-normal outline d-flex align-items-center justify-content-center"
                  onClick={() => { setCommentModal(false); form.resetFields() }}
                >
                  Exit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Modald>
    </>
  );
};
