import axios from 'axios';
import {
    SC_GET_PRODUCT_SKU
} from '../types/types';
import { CUSTOMURL1 } from '../../config';
const sc_token = JSON.parse(localStorage.getItem('sc_token'));


export const scGetProductSku = (sku, token) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `${CUSTOMURL1}product/getproductsku`,
        headers: { Authorization: `Bearer ${sc_token ? sc_token : token}` },
        data: {
            sku: sku ? sku : ""
        }
    })
        .then((response) => {
            dispatch({
                type: SC_GET_PRODUCT_SKU,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};