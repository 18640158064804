import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import * as influencerReviewAction from "../../redux/actions/influencerReview";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faXmark,
  faCalendarDays,
  faClock,
  faCartFlatbed,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import NoDataFound from "../../reUsable/NoDataFound";
import { Col, Row } from "react-bootstrap";
import {
  Spin,
  Modal,
  Button,
  Form,
  Input,
  Upload,
  message,
  Select,
  Dropdown,
  notification,
  Avatar,
  DatePicker,
  Table,
  Typography,
  InputNumber,
  Menu,
  Image as ImageA,
  Checkbox
} from "antd";
import { Player, LoadingSpinner } from "video-react";
import "video-react/dist/video-react.css";
import { UserOutlined } from "@ant-design/icons";
import AsyncInfluencer from "./asyncInfluencer";
import AsyncProduct from "../create-event/scheduleEvent/asyncProduct";
import GroupIcon from "@mui/icons-material/Group";
import ReactPlayer from "react-player";
import LoadingSpin from "../../reUsable/loadingSpin";
import * as savedCategoriesAction from "../../redux/actions/newCategory.action";
import * as feeStructuresAction from "../../redux/actions/feestructure.action";
import { useNavigate } from "react-router";
import { searchReviewID } from "../../redux/actions/eventID.action";
import { getInfluencers } from "../../redux/actions/influencerNew.action";
import { getPromoRequest } from "../../redux/actions/promoRequest";
import { getPricePoint } from "../../redux/actions/receivePrice.action";
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";

function ReviewControl({
  getReviewControlWithoutProduct,
  getReviewControlDataWithoutProduct,
  reviewPublish,
  reviewDeleted,
  getAllCreator,
  linkInfluencerWithoutProductUpdate,
  getInfluencerReview,
  getAllCreatorData,
  // promoRequest,
  getSavedGeneralCategories,
  getSavedCategories,
  getSavedSubCategories,
  savedGeneralCategories,
  savedCategory,
  savedSubCategory,
  getStructureFees,
  getFeeStructure
}) {

  const {
    getAllInfluencers, promoRequest
  } = useSelector((state) => {
    return state;
  });

  const dispatch = useDispatch();

  const childRef = useRef();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [publishLoading, setPublishLoading] = useState(false);

  // const fromDate = moment().startOf('year').format('YYYY-MM-DD');
  // const toDate = moment(new Date()).format("YYYY-MM-DD");
  const fromDate = moment().startOf("year").format("YYYY-MM-DD");
  const toDate = moment().endOf("year").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [influencerId, setInfluencerId] = useState();
  const [influencer, setInfluencer] = useState("");
  const [flag, setFlag] = useState(false);
  const [productPromoCodeDscs, setProductPromoCodeDscs] = useState("0%");
  const [productPromoCodePromo, setproductPromoCodePromo] = useState("KB0");
  const [referralPercent, setReferralPercent] = useState("0");
  const [influencer_percent, setInfluencer_percent] = useState("0");

  const [saveLoading, setSaveLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [banner, setBanner] = useState([]);
  const [sku, setSku] = useState([]);
  const [skuError, setSkuError] = useState(false);
  const [dataDetails, setDataDetail] = useState([]);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [sortBy, setSortBy] = useState("date");
  const [currentPage, setCurrentPage] = useState(0);
  const [orientation, setOrientation] = useState("");
  const [dateTime, setDate] = useState("");
  // const [gender, setGender] = useState("");
  // const [category, setCategory] = useState("");
  // const [subCategory, setSubCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [genLoading, setGenLoading] = useState(true);
  const [gender, setGender] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [generalLoad, setGeneralLoad] = useState(false);
  const [catLoad, setCatLoad] = useState(false);
  const [subCatLoad, setSubCatLoad] = useState(false);
  const [reviewID, setReviewID] = useState();
  const [reviewIDLoad, setReviewIDLoad] = useState("");
  const [searchReviewIDs, setSearchReviewIDs] = useState([]);
  const [editProducts, setEditProducts] = useState(false);
  const [user, setUser] = useState('')
  const [msg, setMsg] = useState(false);
  const [email, setEmail] = useState('')
  const [prices, setPrices] = useState([]);
  const [matchingPrice, setMatchingPrice] = useState([]);
  const [defaultFile, setDefaultFile] = useState("");
  const [defaulted, setDefaulted] = useState(true);
  const roleKey = localStorage.getItem("Role");
  const history = useNavigate();
  const limit = 8;

  useEffect(() => {
    getFeeStructure().then((res) => {
    })
    setReviewIDLoad(true)
    dispatch(searchReviewID("", "control", startDate, endDate)).then((res) => {
      setReviewIDLoad(false)
      if (res?.success) {
        setSearchReviewIDs(res?.message)
      }
    })
    getReviewControlWithoutProduct(
      false,
      1,
      limit,
      "control",
      startDate,
      endDate,
      influencerId,
      gender,
      category,
      subCategory,
      sortBy,
    ).then(() => setLoading(false)).catch((err) => setLoading(false));
    setGeneralLoad(true);
    getSavedGeneralCategories().then((res) => {
      if (res.success) {
        setGeneralLoad(false);
      } else {
        setGeneralLoad(false);
      }
    });
    dispatch(getInfluencers()).then((res) => {
    })
    getAllCreator().then((res) => {
      if (res.payload.success) {
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
    // dispatch(getPromoRequest()).then((res) => { });

    dispatch(getPricePoint()).then((res) => {
      // console.log(res.data, '12323');
      setPrices(res.data)
      // console.log(prices, 'prices');
    })
  }, []);

  function onPublish(seletedItem) {

    setCurrentData(seletedItem);
    Swal.fire({
      title: "Are You Sure?",
      text: "You Want To Publish This Video?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        setPublishLoading(true);
        reviewPublish({
          review_id: seletedItem._id,
          status: true,
        })
          .then(() => {
            setPublishLoading(false);
            notification.success({
              message: "Successfully Published",
              className: "toast-success",
            });
            setLoading(true);

            setCurrentPage((getReviewControlDataWithoutProduct?.data?.length === 1 && currentPage !== 1) ? currentPage - 1 : currentPage)

            getReviewControlWithoutProduct(
              false,
              (getReviewControlDataWithoutProduct?.data?.length === 1 && currentPage !== 0) ? currentPage : currentPage + 1,
              limit,
              "control",
              startDate,
              endDate,
              influencerId,
              gender,
              category,
              subCategory,
              sortBy
            ).then(() => setLoading(false));
          })
          .catch((err) => {
            notification.error({
              message: err.response.message,
              className: "toast-error",
            });
          });
      }
    });


  }
  const manageInventory = (id) => {
    history(`/inventory/manage/${id}`, {
      state: {
        inventory: "review",
        title: "controls"
      },
    });
  };

  function onDelete(seletedItem) {
    setCurrentData(seletedItem);
    Swal.fire({
      title: "Are You Sure?",
      text: "You Want To Archive This Video?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteLoading(true);
        reviewDeleted({
          review_id: seletedItem._id,
          status: "remove",
        })
          .then(() => {
            setDeleteLoading(false);
            notification.success({
              message: "Successfully Archived Video",
              className: "toast-success",
            });
            setLoading(true);
            getReviewControlWithoutProduct(
              false,
              currentPage + 1,
              limit,
              "control",
              startDate,
              endDate,
              influencerId,
              gender,
              category,
              subCategory,
              sortBy
            ).then(() => setLoading(false));
          })
          .catch((err) => {
            setDeleteLoading(false);
            notification.error({
              message: err.response.message,
              className: "toast-error",
            });
          });
      }
    });
  }

  const handleReviewID = (e) => {

    setReviewIDLoad(true)
    dispatch(searchReviewID(e.replaceAll("-", ''), "control", startDate, endDate)).then((res) => {
      setReviewIDLoad(false)
      if (res?.success) {
        setSearchReviewIDs(res?.message)
      }
    })
  }

  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function numberFormat(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + "M";
    if (num >= 1000) return intlFormat(num / 1000) + "k";
    return intlFormat(num);
  }
  const changePromoCode = (e, options, name, index) => {
    if (e === undefined) {
    } else {
      var values = e.value.split(" ");
      var discount = values[0];

      setProductPromoCodeDscs(discount);
      setproductPromoCodePromo(e.children);
    }
  };

  function renderContent() {
    if (!loading) {
      return (
        <Row className="post-analytics-tab-boxes-ift influencer-reviews-main">
          {getReviewControlDataWithoutProduct.data?.length > 0 ? (
            getReviewControlDataWithoutProduct.data?.map((item, i) => (
              <Col xs={12} xl={3} md={6}>
                <div
                  key={i}
                  className={`card any_bx analytic-box campaign-box pb-0`}
                >
                  <div className="camp-row row">
                    <div className="campaign-header review-control-box col-12">
                      <div className="inf-header">
                        {/* <span className="review-box-header mr-2">
                          {item?.influencer?.profile_image_url ? (
                            <Avatar
                              icon={<UserOutlined />}
                              src={item?.influencer?.profile_image_url}
                            />
                          ) : (
                            <Avatar icon={<UserOutlined />} />
                          )}
                        </span> */}
                        <div className="inf-username-box">
                          {item?.influencer?.name ? (
                            <a
                              title={item?.influencer?.name}
                              className="inf-username"
                              target="_blank"
                            // href={`https://www.instagram.com/${item?.influencer?.name}`}
                            >
                              {item?.influencer?.name.length > 20
                                ? item?.influencer?.name.slice(0, 20) + "..."
                                : item?.influencer?.name}
                            </a>
                          ) : item?.influencer?.insta_handle ? (
                            <a
                              title={item?.influencer?.insta_handle}
                              className="inf-username"
                              target="_blank"
                              href={`https://www.instagram.com/${item?.influencer?.insta_handle}`}
                            >
                              @
                              {item?.influencer?.insta_handle?.length > 20
                                ? item?.influencer?.insta_handle.slice(0, 20) +
                                "..."
                                : item?.influencer?.insta_handle}
                            </a>
                          ) :
                            item?.influencer?.ig_handle ?
                              (
                                <a
                                  title={item?.influencer?.ig_handle}
                                  className="inf-username"
                                  target="_blank"
                                  href={`https://www.instagram.com/${item?.influencer?.ig_handle}`}
                                >
                                  @
                                  {item?.influencer?.ig_handle?.length > 20
                                    ? item?.influencer?.ig_handle.slice(0, 20) +
                                    "..."
                                    : item?.influencer?.ig_handle}
                                </a>
                                // <span
                                //   className="inf-username"
                                //   title={item?.influencer?.name}
                                // >
                                //   {item?.influencer?.name}
                                // </span>
                              )
                              : (
                                <p className="inf-username py-1"></p>
                              )
                          }
                          {/* <span className="inf-email">{item?.influencer?.email}</span> */}
                          {/* <span className="inf-email">Followers: 10</span> */}
                        </div>
                        {/* <div className="inf-head-followers">
                          <GroupIcon className="fol-icon" />
                          <h5>
                            {item?.influencer?.followers
                              ? numberFormat(item?.influencer?.followers)
                              : 0}
                          </h5>
                        </div> */}
                      </div>
                    </div>
                    <div
                      className="any-post-img-col col-12"
                    // style={{ marginBottom: 50 }}
                    >
                      <div className="any-post-image media-post-new">
                        <div className="any-image-box">
                          <div className="any-image-box-iner review-video">
                            <div className="review-video">
                              <ReactPlayer
                                playing
                                // className={`review_react_player ${item?.media_url?.split(".")[
                                //   item?.media_url?.split(".")?.length - 1
                                // ] === "m3u8" && "objectFit"
                                //   }`}
                                // className="review_react_player objectFit"
                                // className={`review_react_player ${item?.orientation === "portrait" ? "" : "objectFit bg-cover"}`}
                                className={`review_react_player`}
                                width={"100%"}
                                height={"250px"}
                                url={item?.media_url}
                                controls
                                light={item?.banner}
                                playsinline
                                config={{
                                  file: {
                                    attributes: {
                                      controlsList: "donwload",
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`inf-content-area ${roleKey === "inventoryManager" && 'mb-10'} `}>
                      <div className="d-flex justify-content-between">
                        <div className="event-date-main top-date">
                          <div className="event-date">
                            <FontAwesomeIcon
                              className="event-icon"
                              icon={faCalendarDays}
                            />
                            <div className="event-txt">
                              {moment(item?.created_date)
                                .utc()
                                .format("MM-DD-YYYY")}
                            </div>
                          </div>

                          <div className="event-date">
                            <FontAwesomeIcon
                              className="event-icon"
                              icon={faClock}
                            />

                            <div className="event-txt">
                              {moment(item?.created_date).format("hh:mm A")}
                            </div>
                          </div>
                        </div>
                        <div className="category-text-main">
                          {item?.gen_category?.name && (
                            <div className="category-text">
                              {item?.gen_category?.name}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="campaign-header review-control-box col-12 p-0">
                        <h5 title={item?.title} className="btm-title">
                          {item.title?.length > 50
                            ? item.title?.slice(0, 50) + "..."
                            : item?.title}
                        </h5>
                      </div>
                      {/* <div className="fee-box-main">
                        <div className="fee-box fee-height">
                          <div className="fee-state red-state">
                            {item?.referral_percent === "0" ||
                              item?.referral_percent === "" ||
                              item?.referral_percent === "null" ||
                              item?.referral_percent === "undefined" ||
                              !item?.referral_percent
                              ? ""
                              : item?.referral_percent + "%" + " Referral Fee"}
                            {getStructureFees?.payload?.message?.fee_structure?.referral_fee + "%" + " Referral Fee"}
                          </div>
                        </div>
                      </div>
                      <div className="fee-box-main">
                        <div className="fee-box fee-height">
                          <div className="fee-state orange-state">
                            {item?.influencer_percent === "0" ||
                              item?.influencer_percent === "" ||
                              item?.influencer_percent === "null" ||
                              item?.influencer_percent === "undefined" ||
                              !item?.influencer_percent
                              ? ""
                              : item?.influencer_percent + "%" + " Influencer Fee"}
                            {getStructureFees?.payload?.message?.fee_structure?.content_creator_fee + "%" + " Creator Fee"}
                          </div>
                        </div>
                      </div> */}
                      {item?.review_counter && <p className="mt-2 mb-0"><span className="fw-bold">Video ID: </span> {item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-')} </p>}
                    </div>
                  </div>

                  <div className="cam-buttons col-12">
                    {
                      roleKey === "inventoryManager" ? (
                        <></>
                        // <Button
                        //   className="default-btn d-flex align-items-center justify-content-center w-100 m-0"
                        //   type="primary"
                        //   size="large"
                        //   onClick={() => manageInventory(item?._id)}
                        //   icon={
                        //     <FontAwesomeIcon
                        //       size="large"
                        //       className="event-icon"
                        //       // icon={faVideo}
                        //       icon={faCartFlatbed}
                        //     />
                        //   }
                        // >
                        //   Manage Inventory
                        // </Button>
                      ) : (
                        <>
                          <button
                            className="default-btn padb-3 ml-0 w-inherit"
                            onClick={() => onPublish(item)}
                            disabled={publishLoading}
                          >
                            <i className="fa fa-check" /> Publish
                          </button>
                          {/* <button
                          className="default-btn medium-btn  padb-3 mr-0 w-inherit"
                          onClick={() => {onEdit(item); setEditProducts(false)}}
                        >
                          <i class="fa fa-pencil" /> Edit
                        </button>
                        <button
                          className="default-btn medium-btn  padb-3 mr-0 w-inherit"
                          onClick={() => {onEdit(item); setEditProducts(true)}}
                        >
                          <i class="fa fa-pencil" /> Edit Products
                        </button> */}
                          {/* <button
                          className="default-btn padb-3 mr-2 w-inherit"
                          onClick={() => onDelete(item)}
                          disabled={deleteLoading}
                        >
                          <i class="fa fa-trash" /> Delete
                        </button> */}
                          <Dropdown.Button
                            trigger="click"
                            overlayClassName="control-room-drowpdown"
                            size="large"
                            // disabled={
                            //   item.event_status !== "scheduled" ||
                            //   item.event_status !== "recorded"
                            //     ? false
                            //     : false
                            // }
                            // onClick={() => handleButtonClick(item)}
                            // overlay={menu}
                            overlay={
                              <Menu
                              >
                                <Menu.Item
                                  key="edit"
                                  onClick={() => {
                                    onEdit(item); setEditProducts(false);
                                    if (item?.influencer?.email) {
                                      setUser(item?.influencer)
                                    } else {
                                      setUser(null)
                                    }
                                  }}
                                >
                                  <span>Edit Content</span>
                                </Menu.Item>
                                {/* <Menu.Item
                                key="editProducts"
                                onClick={() => {
                                  onEdit(item); setEditProducts(true);
                                  if (item?.influencer?.email) {
                                    setUser(item?.influencer)
                                  } else {
                                    setUser(null)
                                  }
                                }}
                              >
                                <span>Edit Products</span>
                              </Menu.Item> */}
                                <Menu.Item
                                  disabled={deleteLoading}
                                  key="delete"
                                  onClick={() => onDelete(item)}
                                >
                                  <span>Archive</span>
                                </Menu.Item>



                              </Menu>
                            }
                          ></Dropdown.Button>
                        </>
                      )
                    }
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="col-md-12">
              <NoDataFound />
            </div>
          )}
        </Row>
      );
    } else {
      return <LoadingSpin />;
    }
  }

  const handlePageClick = (e) => {
    setLoading(true);
    const page = e.selected;
    setCurrentPage(page);
    getReviewControlWithoutProduct(
      false,
      page + 1,
      limit,
      "control",
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      influencerId,
      gender,
      category,
      subCategory,
      sortBy
    ).then(() => setLoading(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setReviewIDLoad(true)
    dispatch(searchReviewID("", "control", moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"))).then((res) => {
      setReviewIDLoad(false)
      if (res?.success) {
        setSearchReviewIDs(res?.message)
      }
    })
    const page = e.selected;
    setCurrentPage(0);
    setLoading(true);
    getReviewControlWithoutProduct(
      false,
      1,
      limit,
      "control",
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      influencerId,
      gender,
      category,
      subCategory,
      sortBy,
      "",
      reviewID
    ).then(() => {
      setLoading(false);
      setInfluencer("");
    });
  };

  const handleReset = () => {
    setInfluencerId("");
    setGender(null);
    setCategory(null);
    setSubCategory(null);
    setStartDate(fromDate);
    setCurrentPage(0)
    setInfluencerId()
    setEndDate(toDate);
    setLoading(true);
    setSortBy("date");
    setReviewID(null)
    childRef.current?.clear();
    setReviewIDLoad(true)
    dispatch(searchReviewID("", "control", fromDate, toDate)).then((res) => {
      setReviewIDLoad(false)
      if (res?.success) {
        setSearchReviewIDs(res?.message)
      }
    })
    getReviewControlWithoutProduct(
      false,
      1,
      limit,
      "control",
      fromDate,
      toDate,
      "",
      "",
      "",
      "",
      "date"
    ).then(() => {
      setLoading(false);
      setInfluencer("");
      setGender(null);
      setCategory(null);
      setSubCategory(null);
    });
  };

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const selectInfluencer = (value) => {
    setInfluencerId(value);
  }
  const getInfluencer = (influencerData, influencerUsername, insta_handle) => {
    setInfluencerId(influencerData);
    setUser(influencerData);
  };
  const handleGender = (e) => {
    setGender(e);
    setCategory(null);
    setSubCategory(null);
    setCatLoad(true);
    getSavedCategories(e).then((res) => {
      if (res.success) {
        setCatLoad(false);
      } else {
        setCatLoad(false);
      }
    });
  };
  const handleCategory = (e) => {
    setCategory(e);
    setSubCategory(null);
    setSubCatLoad(true);
    getSavedSubCategories(gender, e).then((res) => {
      if (res.success) {
        setSubCatLoad(false);
      } else {
        setSubCatLoad(false);
      }
    });
  };

  const handleSubCategory = (e) => {
    setSubCategory(e);
  };
  function onEdit(seletedItem) {
    // console.log("seletedItem", seletedItem);
    setCurrentData(seletedItem);
    setSku(seletedItem?.products);
    setBanner(seletedItem?.banner);
    setDefaulted(true);
    setDefaultFile(seletedItem?.banner);
    setproductPromoCodePromo(seletedItem?.promo);
    setProductPromoCodeDscs(seletedItem?.discount);
    // setReferralPercent(seletedItem?.referral_percent);
    setGenLoading(false);

    if (seletedItem?.gen_category?._id) {
      getSavedCategories(seletedItem?.gen_category?._id).then((res) => {
        if (res.success) {
          setCategoryOptions(res?.message);
          // setCatLoad(false)
          setGenLoading(false);
        } else {
          // setCatLoad(true)
        }
      });

      getSavedSubCategories(
        seletedItem?.gen_category?._id,
        seletedItem?.category?._id ? seletedItem?.category?._id : ""
      ).then((res) => {
        if (res.success) {
          setSubCategoryOptions(res?.message);
          // setCatLoad(false)
        } else {
          // setCatLoad(true)
        }
      });
    }
    form.setFieldsValue({
      title: seletedItem?.title,
      gen_category_id: seletedItem?.gen_category?._id,
      category_id: seletedItem?.category?._id
        ? seletedItem?.category?._id
        : "all",
      sub_category_id: seletedItem?.sub_category?._id
        ? seletedItem?.sub_category?._id
        : "all",
      datetime: moment(seletedItem?.created_date)
    });
    setEditModal(true);
  }

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: 60,
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: "SKU",
      dataIndex: "ProductSku",
      key: "ProductSku",
      width: 60,
    },
    {
      title: "IMAGE",
      dataIndex: "mediaUrl",
      key: "mediaUrl",
      width: 70,
      render: (text, record) => {
        return (
          <div className="listing-image inventory-image">
            <ImageA src={`${record.mediaUrl}`} />
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "productDesc",
      key: "productDesc",
      width: 250,
      render: (text, record) => {
        return (
          <div className="desc-limit">
            {record.productDesc.length > 70
              ? record.productDesc.slice(0, 70) + "..."
              : record.productDesc}
          </div>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 80,
      render: (text, record) => {
        return <div className="desc-limit">${record.price}</div>;
      },
    },
    {
      title: "Balance",
      dataIndex: "event",
      key: "event",
      width: 140,
      render: (text, record) => (
        <>
          <div className="balance-area">
            <div className="balance-count">
              {record?.variants?.reduce((acc, item) => {
                return (acc = acc + item.inventory_quantity);
              }, 0)}
            </div>
            <div className="balance-area">
              <a
                className="balance-btn default-btn outline small-btn"
                onClick={() => {
                  showModalDetail(record);
                }}
              >
                View
                {/* <FontAwesomeIcon icon={faEye} /> */}
              </a>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "event",
      key: "event",
      width: 100,
      render: (text, record) => {
        let status = record?.variants?.reduce((acc, item) => {
          return (acc = acc + item.inventory_quantity);
        }, 0);
        if (status >= 70) {
          return (
            <div className="status-info">
              <div className="status-flag green"></div>
              {/* <p>green</p> */}
            </div>
          );
        } else if (status >= 50 && status <= 70) {
          return (
            <div className="status-info">
              <div className="status-flag yellow"></div>
              {/* <p>yellow</p> */}
            </div>
          );
        } else if (status <= 50) {
          return (
            <div className="status-info">
              <div className="status-flag red"></div>
              {/* <p>red</p> */}
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];

  const handleCancel = () => {
    setEditModal(false);
    setBanner([]);
    setSku([]);
    setSubCategoryOptions([]);
    setCategoryOptions([]);
    setCategory();
    setSubCategory();
    setGender();
    setSkuError(false);
    form.resetFields();
  };

  // console.log(sku, 'sku');
  let matchingRange;
  useEffect(() => {
    if (sku.length > 0) {
      // console.log('change');

      let totalPrice = sku.reduce((sum, item) => sum + Number(item.price), 0);
      // console.log(totalPrice, 'total');

      // console.log(prices, 'prices');
      let maxValue = sku.reduce((max, item) => Math.max(max, item.price), -Infinity);

      matchingRange = prices.find((price) => maxValue >= price.min && maxValue <= price.max);


      setMatchingPrice([matchingRange])
      // console.log(matchingPrice, 'matchingPrice');

      form.setFieldsValue({
        pricepointId: matchingRange?._id,
        pricepoint: matchingRange?.name
      })


    }
    else {
      form.setFieldsValue({
        pricepointId: '',
        pricepoint: ''
      })
    }
  }, [sku])

  const reUse = () => {

    setDefaulted(!defaulted)
    setBanner([])
    form.setFieldsValue({
      banner: defaultFile
    })

  };

  const onFinish = (val) => {
    const { title, gen_category_id, category_id, sub_category_id } = val;

    // if (sku.length === 0) {
    //   setSkuError(true);
    //   notification.error({
    //     message: "Please Add Product",
    //     className: "toast-error",
    //   });
    // } else {
    // let newSku = sku.map((skuItem) => {
    //   return {
    //     ...skuItem,
    //     // referral_percent: referralPercent ? referralPercent : "0",
    //     referral_percent: getStructureFees?.payload?.message?.fee_structure?.referral_fee > 0 ? getStructureFees?.payload?.message?.fee_structure?.referral_fee : 0,
    //     influencer_percent: getStructureFees?.payload?.message?.fee_structure?.influencer_fee > 0 ? getStructureFees?.payload?.message?.fee_structure?.influencer_fee : "0",
    //     content_creator_fee: getStructureFees?.payload?.message?.fee_structure?.content_creator_fee > 0 ? getStructureFees?.payload?.message?.fee_structure?.content_creator_fee : "0",
    //     cashback_percent: getStructureFees?.payload?.message?.fee_structure?.shopper_cashback > 0 ? getStructureFees?.payload?.message?.fee_structure?.shopper_cashback : "0",
    //   };
    // });
    let formData = new FormData();
    formData.append("user_id", user?._id ? user?._id : user ? user : getAllInfluencers?.message[0]?.user_id);
    // formData.append("image", banner);
    formData.append("image", banner?.length === 0 ? defaultFile : banner);
    formData.append("review_id", currentData._id);
    formData.append("created_date", dateTime.toISOString());
    // formData.append("media_url", currentData?.media_url);
    // formData.append("media_library_id", currentData?._id);
    formData.append("title", title ? title : form.getFieldValue().title);
    formData.append("gen_category_id", gen_category_id ? gen_category_id : (form.getFieldValue().gen_category_id ? form.getFieldValue().gen_category_id : "all"));

    // if (category_id !== "all") {
    formData.append("category_id", category_id ? category_id : (form.getFieldValue().category_id ? form.getFieldValue().category_id : "all"));
    // }
    // if (sub_category_id !== "all") {
    formData.append("sub_category_id", sub_category_id ? sub_category_id : (form.getFieldValue().sub_category_id ? form.getFieldValue().sub_category_id : "all"));
    // }
    // formData.append("products", JSON.stringify(newSku));
    formData.append("discount", productPromoCodeDscs);
    formData.append("promo", productPromoCodePromo);
    // formData.append(
    //   "referral_percent",
    //   referralPercent > 0 ? referralPercent : 0
    // );
    formData.append(
      "referral_percent",
      getStructureFees?.payload?.message?.fee_structure?.referral_fee > 0 ? getStructureFees?.payload?.message?.fee_structure?.referral_fee : 0
    );
    formData.append(
      "influencer_percent",
      getStructureFees?.payload?.message?.fee_structure?.influencer_fee > 0 ? getStructureFees?.payload?.message?.fee_structure?.influencer_fee : 0
    );
    formData.append(
      "content_creator_fee",
      getStructureFees?.payload?.message?.fee_structure?.content_creator_fee > 0 ? getStructureFees?.payload?.message?.fee_structure?.content_creator_fee : 0
    );
    formData.append(
      "cashback_percent",
      getStructureFees?.payload?.message?.fee_structure?.shopper_cashback > 0 ? getStructureFees?.payload?.message?.fee_structure?.shopper_cashback : 0
    );
    formData.append("pricepoint_id", form.getFieldValue().pricepointId);
    formData.append("pricepoint_name", form.getFieldValue().pricepoint);

    setSaveLoading(true);
    // setLoading(true);
    linkInfluencerWithoutProductUpdate(formData).then((res) => {
      // console.log(res);
      if (res.payload.success) {
        // console.log('success');
        notification.success({
          message: res.payload?.message,
          className: "toast-success",
        });
        setBanner([]);
        setSku([]);
        setGender();
        setCategory();
        setSubCategory();
        setSaveLoading(false);
        // setLoading(false);
        setEditModal(false);
        getReviewControlWithoutProduct(
          false,
          currentPage + 1,
          limit,
          "control",
          startDate,
          endDate,
          "",
          "",
          "",
          "",
          "date"
        ).then(() => setLoading(false));
      } else {
        notification.error({
          message: res?.payload?.data?.message?.message,
          className: "toast-error",
        });
        setSaveLoading(false);
        setLoading(false);
      }
    });
    // }
  };

  const getSku = (skuData) => {
    setSkuError(false);
    setSku(skuData);
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    listType: "picture",
    // fileList={fileList}
    maxCount: 1,
    action: "https://httpbin.org/post",

    beforeUpload: (file) => {
      const isPNG =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg+xml" ||
        file.type === "image/svg";

      if (!isPNG) {
        message.error(`${file?.name} is not a valid format`);
      }

      return isPNG || Upload.LIST_IGNORE;
    },

    onChange(info) {
      // setImageUpload(true);
      const { status } = info?.file;
      setBanner(info?.file?.originFileObj);
      setDefaulted(false)
      if (status === "done") {
        message.success(`${info?.file.name} Image uploaded successfully.`);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
    },

    onRemove(e) {
      // console.log(e, "onRemove");
      // return Upload.LIST_IGNORE;
      // setPreviewImage('');
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const showModalDetail = (s) => {
    setDataDetail(s);
    setIsModalOpenDetail(true);
  };
  const onDateTimeChange = (e) => {
    if (e !== null) {
      setDate(e._d);
    } else {
      setDate("");
    }
  };

  const handleCancelDetail = () => {
    setIsModalOpenDetail(false);
  };

  const handleOkDetail = () => {
    setIsModalOpenDetail(false);
  };

  const handleSortBy = (value) => {
    setSortBy(value);
  };
  useEffect(() => {
    setDate(moment(currentData?.created_date))
    //setProductPromoCodeDscs(currentData?.discount)
    //setproductPromoCodePromo(currentData?.promo)



  }, [currentData])
  const columnDetails = [
    {
      title: "Size",
      key: "option1",
      dataIndex: ["option1"],
      width: 40,
      render: (text, record, index) => {
        return (
          <>
            <div className=""> {record?.option1 ? record?.option1 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Wash",
      key: "option1",
      dataIndex: ["option1"],
      width: 100,
      render: (text, record, index) => {
        return (
          <>
            <div className=""> {record?.option2 ? record?.option2 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Inseam",
      key: "option1",
      dataIndex: ["option1"],
      width: 70,
      render: (text, record, index) => {
        return (
          <>
            <div className="">{record?.option3 ? record?.option3 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Stock",
      dataIndex: "inventory_quantity",
      key: "inventory_quantity",
      width: 60,
    },
  ];

  const handleCreator = (value, option) => {
    setInfluencerId(value);
  };

  const onGenderChange = (value) => {
    setGender(value);
    setSubCategoryOptions([]);
    setCategoryOptions([]);
    setCategory("");
    setSubCategory("");
    form.setFieldsValue({ category_id: undefined, sub_category_id: undefined });
    const data = value === "all" ? "" : value;
    setGenLoading(true);
    getSavedCategories(data).then((res) => {
      if (res.success) {
        setCategoryOptions(res?.message);
        setGenLoading(false);
      } else {
        setGenLoading(false);
      }
    });
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const onCategoryChange = (value) => {
    setSubCategory([]);
    setSubCategoryOptions([]);
    setSubCategory("");
    form.setFieldsValue({ sub_category_id: undefined });
    const data = value === "all" ? "" : value;
    getSavedSubCategories(gender === "all" ? "" : gender, data).then((res) => {
      if (res.success) {
        setSubCategoryOptions(res?.message);
        // setCatLoad(false)
      } else {
        // setCatLoad(true)
      }
    });
  };

  const onSubCategoryChange = (value) => {
    setSubCategory(value);
  };

  return (
    <div className="container-fluid p-0">
      <div className="row w-20-desktop-main w-25-desktop-main">
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Select Start Date / End Date</p>
          <RangePicker
            size="large"
            className="w-100"
            key={4}
            value={
              startDate && endDate ? [moment(startDate), moment(endDate)] : []
            }
            allowClear={false}
            ranges={{
              "All Time": [
                moment().startOf("year"),
                moment(),
              ],
              "YTD": [moment().startOf("year"), moment()],
              "Last 30 Days": [moment().subtract(30, "days"), moment()],
              "Last 7 Days": [moment().subtract(7, "days"), moment()],
              "Today": [moment(), moment()],
              // Today: [moment(), moment()],
              // Tomorrow: [
              //   moment().add(1, 'days'),
              //   moment().add(1, 'days'),
              // ],
              // Yesterday: [
              //   moment().subtract(1, 'days'),
              //   moment().subtract(1, 'days'),
              // ],
              // "Last Year": [
              //   moment().startOf("year"),
              //   moment().subtract(1, "year").endOf("year"),
              // ],
              // "This Year": [moment().startOf("year"), moment().endOf("year")],
              // "Last Month": [
              //   moment().subtract(1, "month").startOf("month"),
              //   moment().subtract(1, "month").endOf("month"),
              // ],
              // "This Month": [
              //   moment().startOf("month"),
              //   moment().endOf("month"),
              // ],
            }}
            format={dateFormat}
            onChange={dateRangePickerChanger}
          />
        </div>

        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Department</p>
          <Select
            size="large"
            className="w-100"
            key={4}
            placeholder="Select Department"
            focus={false}
            onChange={handleGender}
            value={gender}
            onBlur={false}
            loading={generalLoad}
            disabled={!savedGeneralCategories || generalLoad}
          >
            {savedGeneralCategories &&
              savedGeneralCategories?.message?.map((item, i) => {
                return <Option value={item?.category_id}>{item?.name}</Option>;
              })}
          </Select>
        </div>
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Category</p>
          <Select
            size="large"
            className="w-100"
            key={4}
            placeholder="Select Category"
            focus={false}
            onChange={handleCategory}
            value={category}
            onBlur={false}
            loading={catLoad}
            disabled={!savedCategory.success || catLoad || !gender}
          >
            <Option value="all">{"All"}</Option>
            {savedCategory &&
              savedCategory?.message?.map((item, i) => {
                return (
                  <Option value={item?.category_id}>
                    {item?.category_name}
                  </Option>
                );
              })}
          </Select>
        </div>
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Sub Category</p>
          <Select
            size="large"
            className="w-100"
            key={4}
            placeholder="Select Sub Category"
            focus={false}
            onChange={handleSubCategory}
            value={subCategory}
            onBlur={false}
            loading={subCatLoad}
            disabled={
              !savedSubCategory?.success || subCatLoad || !gender || !category
            }
          >
            <Option value="">{"All"}</Option>
            {savedSubCategory &&
              savedSubCategory?.message?.map((item, i) => {
                return (
                  <Option value={item?.sub_category_id}>
                    {item?.sub_category_name}
                  </Option>
                );
              })}
          </Select>
        </div>

        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Select Creator</p>
          {/* <div className="select-inf-field d-flex flow-row">
            <AsyncInfluencer
              ref={childRef}
              getInfluencer={getInfluencer}
              influencer={influencer}
            />
          </div> */}
          <Select
            showSearch
            name="sort"
            size="large"
            placeholder="Select Creator"
            className="w-100 select-style dark-field"
            optionFilterProp="children"
            value={influencerId}
            onChange={(value) => selectInfluencer(value)}
            // onSearch={onSearch}
            filterOption={filterOption}
            options={getAllInfluencers?.message && [
              ...getAllInfluencers?.message].map((influencer) => {
                return ({
                  label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                  value: influencer?.user_id
                }
                )
              })
            }
          />
        </div>
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Video ID</p>
          <Select
            showSearch
            size="large"
            className="w-100"
            key={4}
            type="Number"
            notFoundContent={reviewIDLoad && <Spin size="small" />}
            loading={reviewIDLoad}
            optionFilterProp="children"
            placeholder="Enter Video ID"
            labelInValue
            onChange={(e) => setReviewID(e?.value?.[0] + "-" + e?.value?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-'))}
            optionLabelProp="label"
            onSearch={handleReviewID}
            filterOption={(input, option) => {
              const rawValue = option.value;
              return rawValue.toLowerCase().includes(input.toLowerCase());
            }}
            focus={false}
            value={reviewID}
            onBlur={false}
          >
            {searchReviewIDs && !reviewIDLoad &&
              searchReviewIDs?.map((item, i) => {
                return (
                  <Option value={item?.review_counter}>
                    {item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-')}
                  </Option>
                );
              })}
          </Select>
        </div>
        {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Sort By</p>
          <Select
            className="w-100 select-style dark-field"
            placeholder="Select Sort By"
            size="large"
            // focus={false}
            // onBlur={false}
            onChange={handleSortBy}
            value={sortBy}
          >
            <Option value="date">Date</Option>
            <Option value="follower">Followers</Option>
            <Option value="views">Views</Option>
            <Option value="likes">Likes</Option>
          </Select>
        </div> */}
        <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
          <Button
            onClick={handleSubmit}
            className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Search
          </Button>

          <Button
            onClick={handleReset}
            className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Reset
          </Button>
        </div>
      </div>
      <hr className="separator-line mt-0" />
      {renderContent()}
      {getReviewControlDataWithoutProduct?.data?.length > 0 && !loading && (
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          pageClassName="page-item "
          pageLinkClassName="page-link custom-paginate-link btn btn-primary"
          previousClassName="page-item"
          previousLinkClassName="page-link custom-paginate-prev btn btn-primary"
          nextClassName="page-item"
          nextLinkClassName="page-link custom-paginate-next btn btn-primary"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={currentPage}
          pageCount={Math.ceil(getReviewControlDataWithoutProduct.total_count / limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
          onPageChange={handlePageClick}
          containerClassName={
            "pagination justify-content-center mt-2 custom-paginate review-paginate"
          }
          activeClassName={"active"}
        />
      )}
      {editModal && (
        <Modal
          // className="modal-generic modal-800"
          className={`modal-generic ${editProducts ? "modal-1000" : "modal-800"}`}
          centered
          visible={editModal}
          // onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={false}
          maskClosable={false}
          keyboard={false}
        >
          <h2 className="modal-hd1">{editProducts ? "Edit Products" : "Edit Content"}</h2>
          <div className="modal-scroll-data scrollbar-style">
            <Form
              // layout="vertical"
              form={form}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={{
                // title: currentData?.title,

                // referral_percent: currentData?.referral_percent,
                // banner: [{ url: currentData?.banner }]
                banner: currentData?.banner,
                gen_category_id: currentData?.gen_category?._id,
              }}
            >
              {
                !editProducts &&

                <div class="row">
                  <div className="col-md-7 modal-review-right">
                    {/* <div className="form-styling">
                    <Form.Item
                      name="User"
                      labelCol={{ span: 24 }}
                      label="Creator"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Creator",
                    //   },
                    // ]}
                    >
                      <div className="select-inf-field d-flex flow-row">

                       
                        <Select
                          showSearch
                          name="sort"
                          size="large"
                          placeholder="Select Creator"
                          className="w-100 select-style dark-field"
                          optionFilterProp="children"
                          value={user?._id ? user?._id : user}
                          onChange={(value) => setUser(value)}
                          // onSearch={onSearch}
                          filterOption={filterOption}
                          options={getAllInfluencers?.message && [
                            ...getAllInfluencers?.message].map((influencer) => {
                              return ({
                                label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                                value: influencer?.user_id
                              }
                              )
                            })
                          }
                        />
                      </div>
                      {msg &&
                        <div role="alert" className="ant-form-item-explain-error">
                          Please Select Creator
                        </div>
                      }
                    </Form.Item>
                  </div> */}
                    <div className="form-styling new-style">
                      <Form.Item
                        name="title"
                        labelCol={{ span: 24 }}
                        label="Title"
                        rules={[
                          {
                            required: true,
                            message: "Please enter title",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Title"
                          // value={currentData?.title}
                          // value="dl1961denim"
                          size="large"
                        // onChange={onChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-styling new-style">
                      <Form.Item
                        labelCol={{ span: 24 }}
                        initialValue={form.getFieldValue().gen_category_id}
                        label="Department"
                        name="gen_category_id"
                        rules={[
                          {
                            required: true,
                            message: "Please select a department",
                          },
                        ]}
                      >
                        <Select
                          className="select-style dark-field"
                          placeholder="Select Department"
                          size="large"
                          focus={false}
                          onBlur={false}
                          onChange={onGenderChange}
                          value={gender}
                          loading={genLoading}
                          disabled={genLoading}
                        >
                          {savedGeneralCategories?.message?.map((option) => {
                            return (
                              <Option value={option?.category_id}>
                                {option?.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>

                      {categoryOptions?.length ? (
                        <Form.Item
                          labelCol={{ span: 24 }}
                          initialValue={form.getFieldValue().category_id}
                          label="Category"
                          name="category_id"
                          rules={[
                            {
                              required: true,
                              message: "Please select a category",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Category"
                            size="large"
                            className="select-style dark-field"
                            onChange={onCategoryChange}
                            value={category ? category : undefined}
                          >
                            <Option value="all">All</Option>
                            {categoryOptions?.map((option) => {
                              return (
                                <Option value={option?.category_id}>
                                  {option?.gen_category?.name} {" > "}
                                  {option?.category_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      ) : null}

                      {subCategoryOptions?.length ? (
                        <Form.Item
                          labelCol={{ span: 24 }}
                          initialValue={form.getFieldValue().sub_category_id}
                          label="Sub Category"
                          name="sub_category_id"
                          rules={[
                            {
                              required: true,
                              message: "Please select a sub category",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Sub Category"
                            size="large"
                            className="select-style dark-field"
                            onChange={onSubCategoryChange}
                            value={subCategory ? subCategory : undefined}
                          >
                            <Option value="all">All</Option>
                            {subCategoryOptions?.map((option) => {
                              return (
                                <Option value={option?.sub_category_id}>
                                  {option?.gen_category?.name} {" > "}
                                  {option?.category?.category_name} {" > "}
                                  {option?.sub_category_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      ) : null}
                    </div>
                    {/* <div className="form-styling  new-style">
                      <Form.Item
                        name="datetime"
                        initialValue={dateTime}
                        // initialValue={
                        //   formState === "add" ? null : form.getFieldValue().datetime
                        // }
                        rules={[
                          {
                            required: true,
                            message: "Please select date & time",
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        label="Date and Time"
                      >
                        <DatePicker
                          style={{
                            width: "100%",
                          }}
                          size="large"
                          // showTime={{ use12Hours: true, format: "HH:mm" }}
                          onChange={(e) => {
                            onDateTimeChange(e);
                            setFlag(true);
                          }}
                          //disabledDate={startDateLimit}
                          // format="MM/DD/YYYY h:mm A"
                          format="MM/DD/YYYY"
                        />
                      </Form.Item>
                    </div> */}
                    <div className="form-styling">
                      {!(defaultFile && defaulted) &&
                        <Form.Item
                          name="banner"
                          labelCol={{ span: 24 }}
                          label="Upload Banner"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Form.Item
                            name="banner1"
                            labelCol={{ span: 24 }}
                            label="Upload Banner"
                            // valuePropName={formState !== 'edit' && 'fileList'}
                            //initialValue={formState === "add" ? null : form.getFieldValue().banner}
                            //isImageUrl={formState === "add" ? null : "https://cdn.pixabay.com/photo/2017/02/09/21/08/wings-2053515__340.png"}
                            getValueFromEvent={normFile}
                            noStyle
                            multiple={false}
                            rules={[
                              {
                                required: true,
                                message: "Please upload banner image",
                              },
                            ]}
                          >
                            <Upload.Dragger
                              customRequest={dummyRequest}
                              className="upload-image-review"
                              {...uploadProps}
                              name="banner"
                            // defaultFileList={[
                            //   {
                            //     url: currentData?.banner,
                            //   },
                            // ]}
                            // defaultFileList={
                            //   formState === "edit" &&
                            //   form.getFieldValue()?.banner === null
                            //     ? null
                            //     : [
                            //         {
                            //           url: form.getFieldValue()?.banner,
                            //         },
                            //       ]
                            // }
                            // defaultFileList={
                            //   formState === 'edit'
                            //     ? sampleBanner || form.getFieldValue()?.banner === null
                            //       ? form.getFieldValue()?.banner
                            //       : form.getFieldValue()?.banner?.length === 1
                            //         ? form.getFieldValue()?.banner
                            //         : [
                            //           {
                            //             url: form.getFieldValue()?.banner,
                            //           },
                            //         ]
                            //     : null
                            // }
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            >
                              {/* <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p> */}
                              <p className="ant-upload-text">
                                drag files here to upload
                              </p>
                              {/* <p className="ant-upload-hint">
                            Support for a single or bulk upload.
                          </p> */}
                            </Upload.Dragger>
                          </Form.Item>
                        </Form.Item>
                      }
                      {
                        (defaultFile && defaulted) &&
                        <Form.Item className="preview-image-main-link-product" name="banner" labelCol={{ span: 24 }}
                          label="Upload Banner">
                          <ImageA
                            // width={100}
                            // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                            // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                            src={defaultFile}
                          />
                        </Form.Item>
                      }
                      <Checkbox
                        checked={defaulted}
                        onChange={reUse}>
                        Use default banner
                      </Checkbox>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className=" review-video mb-20">
                      <ReactPlayer
                        playing
                        // className={`review_react_player ${currentData?.media_url?.split(".")[
                        //   currentData?.media_url?.split(".")?.length - 1
                        // ] === "m3u8" && "objectFit"
                        //   }`}
                        // className="review_react_player objectFit"
                        className={`review_react_player ${currentData?.orientation === "portrait" ? "" : "objectFit bg-cover"}`}
                        width={"100%"}
                        height={"250px"}
                        url={currentData?.media_url}
                        controls
                        light={currentData?.banner}
                        playsinline
                      />
                    </div>
                  </div>
                </div>
              }
              {editProducts &&
                <>

                  {/* <div className="row mr-0">
                  <div className="col-md-4 col-lg">
                    <label className="pb-0">Promo Code</label>
                    <Select
                      size="small"
                      filterOption={(input, options) =>
                        options.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={
                        productPromoCodePromo
                          ? productPromoCodePromo
                          : "Select PromoCode"
                      }
                      //disabled={!(formState === "add" || formState === "edit")}
                      placeholder="Select PromoCode"
                      //loading={this.state.promoCond}
                      optionFilterProp="children"
                      className="w-100 select-modal mb-20"
                      // onSearch={onSearch}
                      onChange={(options, e) => {
                        changePromoCode(e, options);
                        setFlag(true);
                      }}
                      showSearch
                      allowClear={false}
                      loading={promoRequest?.loading ? true : false}
                      disabled={promoRequest?.loading ? true : false}
                    >
                      {promoRequest?.message?.map((customer, key) => {
                        return (
                          <Option key={customer?.promo_percent + " " + key}>
                            {customer?.promo}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="col-md-4 col-lg">
                    <label>Discount</label>
                    <div className="promo_discount form-control mb-20">
                      {productPromoCodeDscs}
                    </div>
                  </div> */}

                  {/* <div className="col-md-4 col-lg">
                    <label>Referral Fee</label>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      // label="Referral Fee"
                      className="mb-20 label-padb-0 w-100"
                      name="referral_percent"
                      initialValue={
                        getStructureFees?.payload?.message?.fee_structure?.referral_fee ? getStructureFees?.payload?.message?.fee_structure?.referral_fee : 0
                      }
                    >
                      <InputNumber
                        disabled
                        className="w-100 select-modal"
                        defaultValue={0}
                        size="large"
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace("%", "")}
                        onChange={(e) => {
                          {
                            setReferralPercent(e);
                            setFlag(true);
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-4 col-lg">
                    <label>Cashback</label>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      // label="Influencer Fee"
                      className="mb-20 label-padb-0"
                      // initialValue={
                      //   formState === "add"
                      //     ? null
                      //     : form.getFieldValue().influencer_percent
                      // }
                      disabled={true}
                      initialValue={
                        getStructureFees?.payload?.message?.fee_structure?.shopper_cashback ? getStructureFees?.payload?.message?.fee_structure?.shopper_cashback : 0
                      }
                      name="influencer_percent"
                    >
                      <InputNumber
                        disabled={true}
                        className="w-100 select-modal"
                        defaultValue={0}
                        size="large"
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace("%", "")}
                        onChange={(e) => {
                          {
                            setInfluencer_percent(e);
                            setFlag(true);
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-4 col-lg">
                    <label>Creator Fee</label>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      // label="Influencer Fee"
                      className="mb-20 label-padb-0"
                      // initialValue={
                      //   formState === "add"
                      //     ? null
                      //     : form.getFieldValue().influencer_percent
                      // }
                      disabled={true}
                      initialValue={
                        getStructureFees?.payload?.message?.fee_structure?.content_creator_fee ? getStructureFees?.payload?.message?.fee_structure?.content_creator_fee : 0
                      }
                      name="Creator_fee"
                    >
                      <InputNumber
                        disabled={true}
                        className="w-100 select-modal"
                        defaultValue={0}
                        size="large"
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace("%", "")}
                        onChange={(e) => {
                          {
                            // setCreator_fee(e);
                            setFlag(true);
                          }
                        }}
                      />
                    </Form.Item>
                  </div> */}
                  {/* </div> */}
                  {
                    sku?.length > 0 &&
                    <div class="row">
                      <div className="col-md-12 modal-review-right">
                        <div className="form-styling disable-style new-style">
                          <Form.Item
                            name="pricepoint"
                            labelCol={{ span: 24 }}
                            label="Price Point"
                          >
                            <Input
                              value={matchingPrice[0]?.name || ''}
                              disabled={true}
                              size="large"
                              onChange={(e) => {
                                // Handle input changes if needed
                                // e.target.value is the new input value
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  }
                  <div class="row">
                    <div className="col-12">
                      <div className="form-styling new-style">
                        <Form.Item name="productsku">
                          <Form.Item
                            name="productsku"
                            label="Product SKU"
                            labelCol={{ span: 24 }}
                            className="d-flex flex-column sku-field pos-index mb-0"
                          >
                            <AsyncProduct getSku={getSku} sku={sku} />
                            {skuError && (
                              <div
                                role="alert"
                                className="ant-form-item-explain-error"
                              >
                                Please Select Product
                              </div>
                            )}
                          </Form.Item>
                        </Form.Item>
                        <div className="row d-flex mb-20 product-listing-table-main">
                          <div className="col">
                            {sku?.length > 0 && (
                              <Table
                                pagination={false}
                                columns={columns}
                                size="small"
                                className="product-listing-table"
                                //  rowSelection={{ ...rowSelection }}
                                dataSource={[...sku]}
                                scroll={
                                  sku.length > 4 ? { y: 290, x: 800 } : { x: 800 }
                                }
                              // scroll={
                              //   sku.length > 4
                              //     ? { y: 270, x: window.innerWidth <= 1440 ? 900 : 0 }
                              //     : false
                              // }
                              />
                            )}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </>}
              <div className="d-flex justify-content-md-end">
                <Button
                  className="default-btn d-flex align-items-center justify-content-center ml-0"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={saveLoading}
                >
                  Update
                </Button>
                <Button
                  key="back"
                  className="default-btn outline d-flex align-items-center justify-content-center mr-0"
                  onClick={handleCancel}
                >
                  Exit
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      )}

      <Modal
        className="modal-generic modal-600"
        centered
        maskClosable={false}
        visible={isModalOpenDetail}
        onOk={handleOkDetail}
        onCancel={handleCancelDetail}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        // footer={false}
        footer={[
          <Button
            key="back"
            onClick={handleCancelDetail}
            className="d-table m-auto"
          >
            Exit
          </Button>,
          // <Button key="submit" type="primary"
          //   className="d-table m-auto"
          //   // onClick={handleOk}
          //   // onClick={handleCancel}
          // >
          //   Submit
          // </Button>,
        ]}
      >
        <h2 className="modal-hd1">Details</h2>
        <div className="co">
          {/* <Table
            pagination={false}
            columns={columnDetails}
            size="small"
            className="product-listing-tabl inventory-box-main details-table"
            //  rowSelection={{ ...rowSelection }}
            dataSource={dataDetails}
            // scroll={{
            //     x: 500,
            //     y: 500,
            // }}
            scroll={dataDetails?.length > 10 ? { y: 400, x: 500 } : { x: 500 }}
          /> */}
          <div className="table-container inventory-table">
            <table
              class={`table m-0 ${dataDetails?.productOpt?.length &&
                dataDetails?.productOpt?.length + 2 == 5
                ? "width-item-5"
                : ""
                } ${dataDetails?.productOpt?.length &&
                  dataDetails?.productOpt?.length + 2 == 6
                  ? "width-item-6"
                  : ""
                }`}
            >
              <thead>
                <tr>
                  {dataDetails?.productOpt?.map((item, i) => {
                    return (
                      <th className="width-25 border-bottom-0">{item.name}</th>
                    );
                  })}
                  <th className="width-25 border-bottom-0">Stock</th>
                  <th className="width-25 border-bottom-0">Price</th>
                </tr>
              </thead>
            </table>
            <div className="table-body scrollbar-style">
              <table
                class={`table ${dataDetails?.productOpt?.length &&
                  dataDetails?.productOpt?.length + 2 == 5
                  ? "width-item-5"
                  : ""
                  } ${dataDetails?.productOpt?.length &&
                    dataDetails?.productOpt?.length + 2 == 6
                    ? "width-item-6"
                    : ""
                  }`}
              >
                <tbody>
                  {dataDetails?.variants?.map((item, i) => {
                    return (
                      <tr>
                        <td className="width-25">{item.option1}</td>
                        {item.option2 && (
                          <td className="width-25">{item.option2}</td>
                        )}
                        {item.option3 && (
                          <td className="width-25">{item.option3}</td>
                        )}
                        <td className="width-25">{item.inventory_quantity}</td>
                        <td className="width-25">${item.price}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
function mapStateProps({
  getReviewControlDataWithoutProduct,
  savedGeneralCategories,
  savedCategory,
  savedSubCategory,
  getAllCreatorData,
  getStructureFees
}) {
  return {
    getReviewControlDataWithoutProduct,
    savedGeneralCategories,
    savedCategory,
    savedSubCategory,
    getAllCreatorData,
    getStructureFees
  };
}

export default connect(mapStateProps, {
  ...influencerReviewAction,
  ...savedCategoriesAction,
  ...feeStructuresAction
})(ReviewControl);
