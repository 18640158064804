import {
  GET_INSTAGRAM_URL_REQUEST,
  GET_INSTAGRAM_URL_SUCCESS,
  GET_INSTAGRAM_URL_ERROR,
  GET_INSTAGRAM_DATA_REQUEST,
  GET_INSTAGRAM_DATA_SUCCESS,
  GET_INSTAGRAM_DATA_ERROR,
  UPDATE_ACCESS_TOKEN_REQUEST,
  UPDATE_ACCESS_TOKEN_SUCCESS,
  UPDATE_ACCESS_TOKEN_ERROR,
  DISCONNECT_INSTAGRAM_REQUEST,
  DISCONNECT_INSTAGRAM_SUCCESS,
  DISCONNECT_INSTAGRAM_ERROR, CHECK_INSTAGRAM_USER_REQUEST, CHECK_INSTAGRAM_USER_SUCCESS, CHECK_INSTAGRAM_USER_ERROR, CHECK_INSTA_CONNECTION
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';


export const getInstagramUrl = () => (dispatch) => {
  dispatch({ type: GET_INSTAGRAM_URL_REQUEST });
  return axios({
    method: 'POST',
    url: `social/ig/url/instagram`,

  })
    .then((response) => {
      return dispatch({
        type: GET_INSTAGRAM_URL_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_INSTAGRAM_URL_ERROR,
        payload: error.response,
      });
    });
};

export const getInstagramData = (code, email) => (dispatch) => {
  dispatch({ type: GET_INSTAGRAM_DATA_REQUEST });
  return axios({
    method: 'POST',
    url: `social/ig/data/${code}`,

    data: {
      email: email,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_INSTAGRAM_DATA_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_INSTAGRAM_DATA_ERROR,
        payload: error.response,
      });
    });
};

export const updateAccessToken =
  (userId, username, accessToken) => (dispatch) => {
    dispatch({ type: UPDATE_ACCESS_TOKEN_REQUEST });
    return axios({
      method: 'PUT',
      url: `users/revise/ig/instagram`,

      data: {
        user_id: userId,
        username: username,
        access_token: accessToken,
      },
    })
      .then((response) => {
        return dispatch({
          type: UPDATE_ACCESS_TOKEN_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_ACCESS_TOKEN_ERROR,
          payload: error.response,
        });
      });
  };

export const disconnectInstagram = (userId) => (dispatch) => {
  dispatch({ type: DISCONNECT_INSTAGRAM_REQUEST });
  return axios({
    method: 'PUT',
    url: `users/revise/disconnectinstagram/${userId}`,

  })
    .then((response) => {
      return dispatch({
        type: DISCONNECT_INSTAGRAM_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DISCONNECT_INSTAGRAM_ERROR,
        payload: error.response,
      });
    });
};

export const checkInstagramUser = (username) => (dispatch) => {
  dispatch({ type: CHECK_INSTAGRAM_USER_REQUEST });
  return axios({
    method: 'POST',
    url: `graph/monitorProfile/getUserProfile`,

    data: { username },
  })
    .then((response) => {
      return dispatch({
        type: CHECK_INSTAGRAM_USER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: CHECK_INSTAGRAM_USER_ERROR,
        payload: error.response,
      });
    });
};

export const checkInstaConn = () => (dispatch) => {
  return axios({
    method: 'GET',
    url: `social/ig/checkconnection`,
  })
    .then((response) => {
      dispatch({
        type: CHECK_INSTA_CONNECTION,
        payload: response.data,
      });
      return response;
    })
    .catch((error) => {
      return error;
    });
};
