import React from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
// import { toast } from "react-toastify";
import "../../css/asyncProduct.scss";
import { BASEURL } from "../../config";
import { notification, Select } from "antd";
let arrInfluencer = [];
const { Option } = Select;
class AsyncInfluencerFollow extends React.Component {
  state = {
    influencer: this.props.influencer,
    allInfluencer: "",
    searchType: "email",
    // default_value: this.props.defaultValue,
  };

  loadOptions = async (input, callback) => {
    await this.smartSearchFilter(input);
    const result = arrInfluencer.map((item) => {
      return {
        value: `${item.value}`,
        label: `${item.label}`,
        name: `${item.name}`,
        id: `${item?._id}`,
        email: `${item?.email}`,
        pixel_id: `${item?.pixel_id}`,
      };
    });
    callback(result);
  };

  smartSearchFilter = async (value) => {
    if (value.length > 0 && value.trim()) {
      await axios
        .post(`brand/reports/getFilterFollowers`, {
          user: value.trim(),
          source: this.state.searchType
        })
        .then((response) => {
          const loadInfluencer = [];
          const Influencer = response.data.followerData[0].userData;
          // if (Influencer.length === 0) {
          //   // notification.error({  
          //   //   message: "No Host Found",
          //   //   className: "toast-error",
          //   // });
          // }

          this.setState({ allInfluencer: Influencer });
          this.setState({ influencer: {label: this.state.email, value:this.state.influencer} });
          Influencer.map((item) => {
            return loadInfluencer.push({
              value: item?._id,
              label: item?.email,
              name: item?.name,
              _id: item?._id,
              email: item?.email,
              pixel_id: item?.pixel_id,
            });
          });

          arrInfluencer = loadInfluencer;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  handleMultiSelect = (e) => {
    if (e) {
      this.props.getInfluencer(
        e.value,
        e.instagram_username ? e.instagram_username : e.insta_handle
      );
      this.setState({
        influencer: e,
      });
    } else {
      this.props.getInfluencer("");
      this.setState({
        influencer: "",
      });
    }
  };

  handleOnPaste = async (e) => {
    const getData = e.clipboardData.getData("text");
    await this.smartSearchFilter(getData);
  };
  formatOptionLabel = ({ label, name, instagram_username, insta_handle, email, pixel_id }) => (
    <div style={{ display: "flex" }}>
      <div>
        {name && email && pixel_id && name + " - " + email + " - " + pixel_id}
      </div>
      {/* <div style={{ marginLeft: "10px" }}>( {instagram_username ? instagram_username : name} )</div> */}
    </div>
  );

  clear = () => {
    this.setState({ influencer: "" });
  };

  render() {
    return (
      // <React.Fragment>
      <>
        {/* {this.props.uploadReview && (
          <> */}
          {/* <span class="input-group-text at-styleupload">&#9993;</span> */}
          <div className='d-flex align-items-center'>
          <Select
              placeholder="Type"
              size="large"
              className='idTypeSelect'
              // style={{
              //     width: '35%',
              // }}
              focus={false}
              onBlur={false}
              value={this.state.searchType}
              onChange={(e) => {this.setState({ searchType: e }); this.setState({influencer: null}); this.props.getInfluencer("");}}
          >
              <Option value="email">Email</Option>
              <Option value="pixel_id">Pixel ID</Option>
          </Select>
          </div>
          {/* </>
        )} */}
        <div className="flex-grow-1" onPaste={(e) => this.handleOnPaste(e)}>
          <AsyncSelect
            className="select-influencer"
            // isMulti={this.props.isMulti === false ? false : true}
            // isMulti={false}
            // cacheOptions
            isClearable
            defaultOptions
            // delimiter=","
            loadOptions={this.loadOptions}
            placeholder={"Select Creator"}
            name={"influencer"}
            value={this.state.influencer}
            onChange={(e) => {
              this.handleMultiSelect(e);
            }}
            noOptionsMessage={({ inputValue }) =>
              !inputValue ? "Search Creator" : "No Creator Found"
            }
            formatOptionLabel={this.formatOptionLabel}
            // value={this.props.defaultValue}
          />
        </div>
      </>
      // </React.Fragment>
    );
  }
}

export default AsyncInfluencerFollow;
