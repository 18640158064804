import {
  GET_FEE_STRUCTURE_REQUEST,
  GET_FEE_STRUCTURE_SUCCESS,
  GET_FEE_STRUCTURE_ERROR,
  SAVE_FEE_STRUCTURE_REQUEST,
  SAVE_FEE_STRUCTURE_SUCCESS,
  SAVE_FEE_STRUCTURE_ERROR,
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';


export const getFeeStructure = (event_id) => (dispatch) => {
  dispatch({ type: GET_FEE_STRUCTURE_REQUEST });
  return axios({
    method: 'GET',
    url: `users/receive/getfeestructure`,
    data: { event_id },
  })
    .then((response) => {
      return dispatch({
        type: GET_FEE_STRUCTURE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_FEE_STRUCTURE_ERROR,
        payload: error.response,
      });
    });
};

export const saveFeeStructure = (data) => (dispatch) => {
  dispatch({ type: SAVE_FEE_STRUCTURE_REQUEST });
  return axios({
    method: 'POST',
    url: `users/receive/savefee`,
    data: { data },
  })
    .then((response) => {
      return dispatch({
        type: SAVE_FEE_STRUCTURE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SAVE_FEE_STRUCTURE_ERROR,
        payload: error.response,
      });
    });
};
