import { React, useEffect, useState } from 'react';
import { Button, Spin, notification } from 'antd';
import { Navigate, useNavigate } from "react-router-dom";
import PublicHeader from '../../../components/public/header/header';
// import '../../../css/payment.scss';
import { useDispatch, useSelector } from 'react-redux';
import { validateUser } from "../../../redux/actions/login.action";
import { connectStripe } from '../../../redux/actions/connectStripe.action';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars, faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  SyncOutlined
} from '@ant-design/icons';

export default function ConnectStripe() {
  const { validate, stripeConnect } = useSelector((state) => { return state })
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");
  const [message, setMessage] = useState("");
  const [connected, setConnected] = useState(false);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [continueBtn, setContinueBtn] = useState(false);

  useEffect(() => {
    // let query = new URL(window.location.href);
    // let searchParams = new URLSearchParams(query.search);
    dispatch(connectStripe()).then((res) => {
      setUrl(res?.url)
      setLoader(false)
      if (res?.details_submitted && !res?.metadata?.account_disabled) {
        setConnected(true)
        if (!res?.charges_enabled) {
          setMessage("Wait")
          let intervalId = setInterval(() => {
            dispatch(connectStripe()).then((response) => {
              if (response?.charges_enabled) {
                setContinueBtn(true)
                setMessage(response?.message)
                clearInterval(intervalId)
              }
            })
          }, 20000);
        } else {
          setContinueBtn(true)
          // notification.success({
          //   message: res?.message,
          //   className: "toast-success",
          // });
        }
      }
    });
  }, [])




  // useEffect(() => {
  //   document.body.classList.add("agreement-page");
  //   return () => {
  //     document.body.classList.remove("agreement-page");
  //   };
  // }, []);

  const ProceedToStripe = () => {
    setLoading(true);
    window.location.replace(url);
  }
  const ProceedToApp = () => {
    setLoading(true)
    dispatch(validateUser(validate?.payload?.message?.token))
  }

  return (
    <>
      <PublicHeader />
      {
        loader ? <span className="spinclass loader-center position-relative">
          <Spin size="large" />
        </span> :
          (!connected ?
            <div className="container  mt-5">
              <div className='row d-flex justify-content-center'>
                <div className='col-12'>
                  <div className="title-area">
                    <h1 className="page-title">Setup Stripe</h1>
                  </div>
                  <div className="payment-page payment-box-white ">
                    {/* <div className="white-box"> */}
                    {/* <StripeText status={searchParams.get("status")} /> */}

                    {/* </div> */}
                    <div className="row d-flex justify-content-between align-items-center no-gutters">
                      <div className='col-md-8'>
                        <div className='stripe-hd'>
                          Add a Payout Account
                        </div>
                        <p className='payment-para'>
                          Please setup stripe account to allow us to send payout. All transaction payments are paid out directly into your bank account.
                        </p>
                      </div>
                      <div className='col-md-4 d-flex justify-content-start justify-content-md-end '>
                        <Button
                          // disabled={this.state.responseSuccess ? false : true}
                          onClick={ProceedToStripe}
                          loading={loading}
                          variant="primary"
                          type="submit"
                          className="payment-next ml-0 default-btn medium-btn padb-3"
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          Add Payout Account
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            :
            <div className="container  mt-5">
              <div className='row d-flex justify-content-center'>
                <div className='col-12'>
                  <div class="title-area">
                    <h1 class="page-title">Setup Stripe</h1>
                  </div>
                  <div className="payment-page payment-box-white ">
                    {/* <div className="white-box"> */}
                    {/* <StripeText status={searchParams.get("status")} /> */}

                    {/* </div> */}


                    <div className="row d-flex justify-content-between align-items-center no-gutters">
                      <div className='col-md-8'>
                        <p className='payment-para'>
                          {message === "Wait" ? (
                            <>
                              <span className='mx-2'>
                                Waiting for stripe to enable charges
                              </span>
                              <SyncOutlined spin />
                            </>

                          ) : message}
                        </p>
                      </div>
                      {continueBtn && <div className='col-md-4 d-flex justify-content-start justify-content-md-end '>
                        <Button
                          // disabled={this.state.responseSuccess ? false : true}
                          onClick={ProceedToApp}
                          loading={loading}
                          variant="primary"
                          type="submit"
                          className="payment-next ml-0 default-btn medium-btn padb-3"
                        >
                          Continue
                        </Button>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>

            </div>)
      }


    </>
  );
}