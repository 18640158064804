import { GET_INFLUENCER_VIDEO_WITHOUT_PRODUCT_POST } from "../../types/types";

const initialState = []

export default function getInfluencerVideoWithoutProduct(state = initialState, action) {
  switch (action.type) {
    case GET_INFLUENCER_VIDEO_WITHOUT_PRODUCT_POST:
      return action.payload
    default:
      return state;
  }
}
