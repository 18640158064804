import React, { useState } from 'react';
import { Tabs } from 'antd';
import "../../css/review.scss";
import '../../css/analytics.scss';
import "../..";
import CampaignPayment from '../boost/campaignPayment';
import CampaignSummary from './campaignSummary';
import { useSelector } from "react-redux";


const { TabPane } = Tabs;

export default function CampaignPaymentManager() {
    const { stripeCardDetails } = useSelector(
        (state) => {
            return state;
        }
    );
    let query = new URL(window.location.href);
    let searchParams = new URLSearchParams(query.search);
    const [title, setTitle] = useState(
        searchParams.get('type') === null ? 'all' : searchParams.get('type')
    );
    const callbackTabClicked = (key, event) => {
        const url = new URL(window.location.href);
        url.searchParams.set('type', key);
        window.history.replaceState(null, null, url);
        setTitle(key);
    };


    return (
        <>
            <div className="grid-listing-area flex-column tabs-overflow-scroll scrollbar-style flex-sm-row align-items-start align-items-sm-center mb-30">
                <div className="grid-listing-left flex-grow-1">
                    <Tabs
                        // defaultActiveKey="allvideos"
                        defaultActiveKey={title}
                        // onChange={onChange}
                        className="events-tabs-main"
                        onTabClick={callbackTabClicked}
                    >
                        <TabPane tab="Payment" key="all"></TabPane>
                        <TabPane tab="Reports" key="reports"></TabPane>
                    </Tabs>
                </div>
            </div>

            {/* <div className="title-area">
        <h1>{eventTitle}</h1>
      </div> */}

            <div className={`upcoming-events-wrapper mb-30`}>
                {title === 'all' && <CampaignPayment />}
                {title !== 'all' && <CampaignSummary />}
            </div>
        </>
    );
}
