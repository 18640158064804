import React from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
// import { toast } from "react-toastify";
import "../../../css/asyncProduct.scss";
import { BASEURL, TOKEN } from "../../../config";
import { notification, Select } from "antd";
const { Option } = Select;
let arrHost = [];
class AsyncHost2 extends React.Component {
  state = {
    host: this.props.host,
    host2: this.props.host2,
    host3: this.props.host3,
    host4: this.props.host4,
    allHost: "",
    form: this.props.form,
    status: false,
    searchType: "email"
  };

  loadOptions = async (input, callback) => {
    await this.smartSearchFilter(input);

    const result = arrHost.filter((item) => {
      if (item?.label == this.props.host?.[0]?.label || item?.label == this.props.host2?.[0]?.label || item?.label == this.props.host3?.[0]?.label || item?.label == this.props.host4?.[0]?.label) {
      } else {
        return {
          value: `${item.value}`,
          label: `${item.label}`,
          email: `${item?.email}`,
          pixel_id: `${item?.pixel_id}`,
        };
      }
    });
    callback(result);
  };


  // componentDidMount(){
  //   if(this.state.form == "edit"){
  //      this.setState({status:true})
  //   }
  // }

  smartSearchFilter = async (value) => {
    if (value.length > 0 && value.trim()) {
      await axios
        .post(
          `roles/getusers`,
          {
            email: value.trim(),
            source: this.state.searchType
          },

        )
        .then((response) => {
          const loadHost = [];
          const host = response.data.message;
          if (host.length === 0) {
            // notification.error({
            //   message: "No Host Found",
            //   className: "toast-error",
            // });
          }

          this.setState({ allHost: host });
          host.map((item) => {
            return loadHost.push({
              value: item?.id,
              label: item?.email,
              email: item?.email,
              pixel_id: item?.pixel_id,
            });
          });
          arrHost = loadHost;
        })
        .catch(function (error) {
        });
    }
  };

  handleMultiSelect = (e) => {
    let data = [e];

    if (data.length <= 1) {
      this.props.getHost2(data);
      this.setState({
        host2: data,
      });
      if (this.props.form === "edit") {
        this.props.flag(true);
      }
    } else {
      let lastRemoved = e.slice(0, -1);
      this.props.getHost2(lastRemoved);
      this.setState({
        host2: lastRemoved,
      });
      notification.error({
        message: "1 Host are allowed",
        className: "toast-error",
      });
    }
  };

  handleOnPaste = async (e) => {
    const getData = e.clipboardData.getData("text");
    await this.smartSearchFilter(getData);
  };
  // formatOptionLabel = ({ label, title, price }) => (
  //   <div style={{ display: "flex" }}>
  //     <div>{label}</div>&nbsp;-&nbsp;
  //     <div style={{ marginLeft: "10px" }}>{title}</div>&nbsp;-&nbsp;
  //     <div style={{ marginLeft: "10px" }}>${price}</div>
  //   </div>
  // );

  render() {
    return (
      <React.Fragment>
          <div className="select-inf-field d-flex flow-row">
           <div className='d-flex align-items-center f-height'>
          <Select
              placeholder="Type"
              size="large"
              className='idTypeSelect'
              // style={{
              //     width: '35%',
              // }}
              focus={false}
              onBlur={false}
              value={this.state.searchType}
              onChange={(e) => this.setState({ searchType: e })}
          >
              <Option value="email">Email</Option>
              <Option value="pixel_id">Pixel ID</Option>
          </Select>
          </div>
        <div className="flex-grow-1" onPaste={(e) => this.handleOnPaste(e)}>
          <AsyncSelect
            isMulti={false}
            cacheOptions
            isClearable={true}
            defaultOptions
            isDisabled={this.state.status}
            loadOptions={this.loadOptions}
            placeholder="Enter Host Email"
            name={"host"}
            value={this.state.host2}
            onChange={(e, options) => {
              this.handleMultiSelect(e, options);
            }}
            noOptionsMessage={({ inputValue }) => !inputValue ? "Search Host" : "No Host Found"}
          // formatOptionLabel={this.formatOptionLabel}
          />
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AsyncHost2;
