import axios from 'axios';
import { SEARCH_EVENT_ID, SEARCH_REVIEW_ID, SEARCH_SHOW_ID } from '../types/types';

export const searchEventID = (event_counter, source, startDate, endDate, for_report ) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `events/geteventcounters`,
        data: {
            event_counter,
            source,
            from_date: startDate,
            to_date: endDate,
            for_report
        },
    })
        .then((response) => {
            dispatch({
                type: SEARCH_EVENT_ID,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const searchShowID = (show_counter, source, startDate, endDate) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `shows/getshowcounters`,
        data: {
            show_counter,
            source,
            from_date: startDate,
            to_date: endDate,
        },
    })
        .then((response) => {
            dispatch({
                type: SEARCH_SHOW_ID,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const searchReviewID = (review_counter, status, startDate, endDate) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `reviews/getreviewcounters`,
        data: {
            review_counter,
            status,
            from_date: startDate,
            to_date: endDate,
        },
    })
        .then((response) => {
            dispatch({
                type: SEARCH_REVIEW_ID,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

