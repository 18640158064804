import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStepsForm } from "sunflower-antd";
import { Form, InputNumber, Modal, notification, Spin, Checkbox, Radio, Input } from "antd";
import { Button } from "react-bootstrap";
import { Button as Buttond } from "antd";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
    getAffiliateContractDetail,
    getAffiliateCards,
    makePayment,
    setPaymentDefault,
    deletePaymentMethod,
    editPaymentMethod,
} from "../../redux/actions/kbSetup";
import { getUserInfo } from "../../redux/actions/userInfo.action";
import { getShippingSetup, addShippingMethod, getAllShippingSetup, removeShippingMethod, updateShippingMethod, updateShippingName, addNewShippingMethod } from "../../redux/actions/shippingSetup.action"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default () => {
    const dispatch = useDispatch();
    const { confirm } = Modal;
    const [formState, setFormState] = useState("add");
    const [newShipping, setShipping] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [load, setLoad] = useState(false);
    const [methodLoad, setMethodLoad] = useState(false);
    const [data, setData] = useState(true);
    const [cardLoading, setCardLoading] = useState(true);
    const [defaultPaymentID, setDefaultPaymentID] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [expMonth, setExpMonth] = useState("");
    const [expYear, setExpYear] = useState("");
    const [methodID, setMethodID] = useState("");
    const [selectedStandard, setSelectedStandard] = useState(false);
    const [selectedOvernight, setSelectedOvernight] = useState(false);
    const [selectedExpress, setSelectedExpress] = useState(false);

    const {
        affiliateCards,
        affiliatePayment,
        userInfo,
        shippingSetup,
        getAllShipping
    } = useSelector((state) => {
        return state;
    });

    const [formStore] = Form.useForm();

    useEffect(() => {
        dispatch(getAffiliateCards()).then(() => {
            setCardLoading(false);
        }).catch((err) => {
            setCardLoading(false);
        });
        dispatch(makePayment()).then((res) => {
        }).catch((err) => {
        });;
        dispatch(getUserInfo());
        dispatch(getShippingSetup());
        dispatch(getAllShippingSetup());
    }, []);

    useEffect(() => {
        // setLoading(true);
        dispatch(getAffiliateContractDetail()).then((res) => {
            setLoading(false);
            setData(res?.payload?.message);
        });
    }, []);


    const {
        form,
        current,
        gotoStep,
        stepsProps,
        formProps,
        submit,
        formLoading,
    } = useStepsForm({
        async submit(values) {
            if (newShipping) {
                const newData = {
                    name: values?.name,
                    amount: Number(values?.amount),
                };
                console.log(values, "add");
                dispatch(addNewShippingMethod(newData)).then((res) => {
                    if (res?.success) {
                        notification.success({
                            message: "Shipping Status Has Been Created",
                            className: "toast-success",
                        });
                        setShipping(false)
                        setIsModalOpenAdd(false);
                        dispatch(getShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });

                    } else {
                        setShipping(false)
                    }
                })
            }
            else {
                console.log('edit');
                const updatedData = {
                    id: values?.id,
                    name: values?.name,
                    amount: Number(values?.amount),
                    is_default: values?.is_default
                };

                dispatch(updateShippingName(updatedData)).then((res) => {
                    if (res?.success) {
                        notification.success({
                            message: "Shipping Status Has Been Updated",
                            className: "toast-success",
                        });
                        setIsModalOpen(false);
                        dispatch(getShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });

                    } else {

                    }
                })

            }
        },
    });


    const handleOkAdd = () => {
        formStore.resetFields();
        form.setFieldsValue({
            name: '',
            amount: 0,
        });
        setIsModalOpenAdd(false);
        setShipping(false);
    };

    const handleCancelAdd = () => {
        formStore.resetFields();
        form.setFieldsValue({
            name: '',
            amount: 0,
        });

        setIsModalOpenAdd(false);
        setShipping(false);
    };


    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        formStore.resetFields();
        form.setFieldsValue({
            name: '',
            amount: 0,
            is_default: false,
            id: ''
        });

        setIsModalOpen(false);
    };


    const formList = [
        <div className="form-styling style-new">
            <div className="row  d-flex align-items-center">

            </div>
            <Form.Item
                name="name"
                labelCol={{ span: 24 }}
                className="mb-10"
                label="Name"
                rules={[
                    {
                        required: true,
                        message: "Please Enter Shipping Method",
                    },
                ]}
            >
                <Input
                    placeholder="Enter Shipping Method"
                    //value={"test.myshopify.com"}
                    size="medium"
                // onChange={onChange}
                />
            </Form.Item>
            <Form.Item
                name="amount"
                labelCol={{ span: 24 }}
                label="Amount"
                rules={[
                    {
                        required: true,
                        message: "Please Enter Amount",
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: "Only Numbers",
                    },
                ]}
            >
                <Input
                    placeholder="Enter Amount"
                    type="number"
                    min={0}
                    //value={"test.myshopify.com"}
                    size="medium"
                // onChange={onChange}
                />
            </Form.Item>

            <div className="d-flex justify-content-end">
                <Button
                    // to="#"
                    className="default-btn d-inline-flex align-items-center justify-content-center"
                    onClick={submit}
                >
                    <span className="nav-text">Save</span>
                </Button>
                <Button
                    className="default-btn outline d-inline-flex align-items-center justify-content-center"
                    key="back"
                    onClick={handleCancel}
                >
                    Exit
                </Button>
            </div>
        </div>,
    ];

    const formListAdd = [
        <div className="form-styling style-new">
            <div className="row  d-flex align-items-center">

            </div>
            <Form.Item
                name="name"
                labelCol={{ span: 24 }}
                className="mb-10"
                label="Name"
                rules={[
                    {
                        required: true,
                        message: "Please Enter Shipping Method",
                    },
                ]}
            >
                <Input
                    placeholder="Enter Shipping Method"
                    //value={"test.myshopify.com"}
                    size="medium"
                // onChange={onChange}
                />
            </Form.Item>
            <Form.Item
                name="amount"
                labelCol={{ span: 24 }}
                label="Amount"
                rules={[
                    {
                        required: true,
                        message: "Please Enter Amount",
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: "Numbers Required without 0 Amount",
                    },
                ]}
            >
                <Input
                    placeholder="Enter Amount"
                    type="number"
                    min={0}
                    //value={"test.myshopify.com"}
                    size="medium"
                // onChange={onChange}
                />
            </Form.Item>

            <div className="d-flex justify-content-end">
                <Button
                    // to="#"
                    
                    className="default-btn d-inline-flex align-items-center justify-content-center"
                    onClick={submit}
                >
                    <span className="nav-text">Save</span>
                </Button>
                <Button
                    className="default-btn outline d-inline-flex align-items-center justify-content-center"
                    key="back"
                    onClick={handleCancelAdd}
                >
                    Exit
                </Button>
            </div>
        </div>,
    ];


    const paymentMethod = () => {
        if (affiliatePayment?.success == true) {
            let data = affiliatePayment?.message;
            window.open(data, "_self");
        } else {
        }
    };

    const setMethodDefault = () => {
        setMethodLoad(true)
        dispatch(setPaymentDefault(defaultPaymentID)).then((res) => {
            if (res?.success) {
                setMethodLoad(false)
                notification.success({
                    message: "Selected Payment Method Has Been Set To Default",
                    className: "toast-success",
                });

                setDefaultPaymentID("")
            } else {

                setMethodLoad(false)
            }
        })
    };

    const removeMethod = (id) => {

        confirm({
            wrapClassName: "modal-generic cancel-event-modal min-modal-600",
            // title: 'Warning',
            centered: true,
            closable: true,
            icon: <ExclamationCircleOutlined />,
            closeIcon: <FontAwesomeIcon icon={faXmark} />,
            content: "Are you sure you want to remove shipping status ?",
            okText: " Yes, Remove",
            okType: "danger",
            cancelText: " No, I don't want to Remove",
            onOk() {
                dispatch(removeShippingMethod(id)).then((res) => {
                    if (res?.success) {
                        notification.success({
                            message: "Shipping Status Has Been Removed",
                            className: "toast-success",
                        });
                        dispatch(getShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });
                        dispatch(getAllShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });


                    } else {
                        notification.error({
                            message: res?.response?.data?.message,
                            className: "toast-error",
                        });

                    }
                })
            },
            onCancel() {

            },
        });

    };


    const updateMethod = (id, item) => {
        const updatedData = {
            id: item._id,
            name: item.name,
            amount: item.amount,
            is_default: true
        };

        confirm({
            wrapClassName: "modal-generic cancel-event-modal min-modal-600",
            // title: 'Warning',
            centered: true,
            closable: true,
            icon: <ExclamationCircleOutlined />,
            closeIcon: <FontAwesomeIcon icon={faXmark} />,
            content: "Are you sure you want to change shipping status ?",
            okText: " Yes, Change",
            okType: "success",
            cancelText: " No, I don't want to Change",
            onOk() {
                dispatch(updateShippingMethod(updatedData)).then((res) => {
                    if (res?.success) {
                        notification.success({
                            message: "Shipping Status Has Been Updated",
                            className: "toast-success",
                        });
                        dispatch(getShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });

                    } else {

                    }
                })
            },
            onCancel() {

            },
        });

    };


    const addMethod = (id) => {

        confirm({
            wrapClassName: "modal-generic cancel-event-modal min-modal-600",
            // title: 'Warning',
            centered: true,
            closable: true,
            icon: <ExclamationCircleOutlined />,
            closeIcon: <FontAwesomeIcon icon={faXmark} />,
            content: "Are you sure you want to add shipping status ?",
            okText: " Yes, Add",
            okType: "success",
            cancelText: " No, I don't want to Add",
            onOk() {
                dispatch(addShippingMethod(id)).then((res) => {
                    if (res?.success) {
                        notification.success({
                            message: "Shipping Status Has Been Added",
                            className: "toast-success",
                        });
                        dispatch(getShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });
                        dispatch(getAllShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });


                    } else {

                    }
                })
            },
            onCancel() {

            },
        });

    };

    const editMethod = (val) => {
        form.setFieldsValue({
            name: val?.name,
            amount: val?.amount,
            is_default: val?.is_default,
            id: val?._id

        });
        setIsModalOpen(true);
    }

    const addNewMethod = () => {
        form.setFieldsValue({
            name: '',
            amount: null,
        });
        setShipping(true)
        setIsModalOpenAdd(true);
        
    }




    return (
        <>
            <div class="title-area">
                <h1 class="page-title">Global Shipping</h1>
            </div>


            <div className="container p-0 ml-0">
                <div className="row">
                    <div className="col-lg-6 col-xl-7">
                        <div className="stream-box-white">
                            <div class="data-listing-box check-style border-btm">
                                <h4 class="ship-title">Shipping Methods</h4>
                                <div className="check-right">
                                    <div className="ship-hds">
                                        <div className="select-ship text-center">
                                            Select
                                        </div>
                                    </div>

                                </div>


                            </div>
                            {!getAllShipping?.loading ? (
                                <>
                                    {getAllShipping?.payload?.data.map((item, i) => {
                                        return (
                                            <>
                                                <div class="data-listing-box check-style">
                                                    <h4 class="count-title">{item.name}</h4>
                                                    <h3 class="count ">${item.amount}
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                if (!item.selected) {
                                                                    addMethod(item._id)
                                                                }
                                                            }}
                                                            checked={item.selected}
                                                            size="large"
                                                        >
                                                        </Checkbox>
                                                    </h3>

                                                </div>

                                            </>
                                        )
                                    })}

                                    {/* <div className="amount-box">
                          <>
                            <div className="amount-box pt-0">
                              <Button
                                className="default-btn ml-0 fw-normal"
                                onClick={() => paymentMethod()}
                                disabled={true}
              
                              >
                                Save
                              </Button>
                            </div>
                          </>

                  </div> */}
                                </>
                            ) : (
                                <span className="spinclass loader-center position-relative">
                                    <Spin size="large" />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>


        




            <div className="container p-0 ml-0">
                <div className="row">
                    <div className="col-lg-6 col-xl-7">
                    <div class="title-area">
                <h1 class="page-title">User Shipping</h1> <span><Button className="default-btn medium-btn padb-3" onClick={addNewMethod}>Add Shipping</Button></span>
            </div>
                        <div className="stream-box-white">
                            <div class="data-listing-box check-style border-btm">
                                <h4 class="ship-title">Shipping Methods</h4>
                                <div className="check-right">
                                    <div className="ship-hds">
                                        <div className="select-ship">
                                            Default
                                        </div>
                                        <div className="remove-ship">Remove</div>
                                        <div className="remove-ship">Edit</div>
                                    </div>

                                </div>


                            </div>
                            {!shippingSetup?.loading ? (
                                <>
                                    {shippingSetup?.payload?.data.map((item, i) => {
                                        return (
                                            <>
                                                <div class="data-listing-box check-style">
                                                    <h4 class="count-title">{item.name}</h4>
                                                    <div className="check-right">
                                                        <div className="count">
                                                            <h3 className="value-box mb-0">
                                                                <div className="text-right">
                                                                    ${item.amount}
                                                                </div>
                                                                <Radio
                                                                    onChange={(e) => updateMethod(e, item)}
                                                                    checked={item.is_default}
                                                                >
                                                                </Radio>
                                                            </h3>
                                                            <div className="cross-btn-main center-btn">
                                                                <button className="cross-btn" onClick={(e) => removeMethod(item._id)}> <i class="fa fa-times"></i></button>
                                                            </div>
                                                            <div className="cross-btn-main">
                                                                <button className="edit-btn" onClick={(e) => editMethod(item)}> <i class="fa fa-edit"></i></button>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                            </>
                                        )
                                    })}
                                    {/* <h5 className="mb-15 mt-10"></h5> */}
                                    {/* <div className="amount-box">
                          <>
                            <div className="amount-box pt-0">
                              <Button
                                className="default-btn ml-0 fw-normal"
                                onClick={() => paymentMethod()}
                                disabled={true}
              
                              >
                                Save
                              </Button>
                            </div>
                          </>

                  </div> */}
                                </>
                            ) : (
                                <span className="spinclass loader-center position-relative">
                                    <Spin size="large" />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className="modal-generic modal-500"
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                closeIcon={<FontAwesomeIcon icon={faXmark} />}
                footer={false}
                maskClosable={false}
                keyboard={false}
            // footer={[
            //   <Button
            //     key="submit"
            //     type="primary"
            //     // className="d-table m-auto"
            //     onClick={handleOk}
            //   >
            //     Save
            //   </Button>,
            //   <Button key="back" onClick={handleCancel}>
            //     Exit
            //   </Button>,
            // ]}
            >
                <h2 className="modal-hd1">Shipping Method</h2>
                <Form {...formProps}>{formList[current]}</Form>

            </Modal>


            <Modal
                className="modal-generic modal-500"
                centered
                open={isModalOpenAdd}
                onOk={handleOkAdd}
                onCancel={handleCancelAdd}
                closeIcon={<FontAwesomeIcon icon={faXmark} />}
                footer={false}
                maskClosable={false}
                keyboard={false}
            // footer={[
            //   <Button
            //     key="submit"
            //     type="primary"
            //     // className="d-table m-auto"
            //     onClick={handleOk}
            //   >
            //     Save
            //   </Button>,
            //   <Button key="back" onClick={handleCancel}>
            //     Exit
            //   </Button>,
            // ]}
            >
                <h2 className="modal-hd1">New Shipping</h2>
                <Form {...formProps}>{formListAdd[current]}</Form>

            </Modal>


        </>
    );
};
