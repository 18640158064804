import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, InputNumber, Modal, notification, Spin } from "antd";
import { connectStripe, stripeBalance } from "../../redux/actions/connectStripe.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numeral from "numeral";
import {
  faUsd,
} from "@fortawesome/free-solid-svg-icons";


export default () => {
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(true);
  const [balance, setBalance] = useState();


  useEffect(() => {
    dispatch(connectStripe()).then((res) => {
      setMessage(res?.message)
      if (res?.success) {
        dispatch(stripeBalance()).then((res) => {
          setBalance(res?.message)
          setLoading(false)
        });
      }
    });

  }, []);

  return (
    <>
      <div className="containerd p-0 ml-0">
        <div className="row">
          <div className="col-lg-6 col-xl-6">
            <div class="title-area">
              <h1 class="page-title">Stripe Setup</h1>
            </div>
            <div className="stream-box-white">
              {!loading ? (
                <>
                  <div class="data-listing-box pb-3 social-header-hd-area">
                    <h4 class="count-title align-items-center d-flex">{message}</h4>
                    {/* <h3 class="count">{userInfo?.message?.data?.name}</h3> */}
                    <div className="connection-status-badge-green">Connected</div>
                  </div>
                </>
              ) : (
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              )}
              <div className="container m-0 p-0">
                <h5 className="pt-3">Account Balance</h5>
                <div className="row">
                  <div className="col-12 col-xl col-md-6  mt-2">
                    <div className="dashboard-info-box btm-space-mob">
                      <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
                      <div class="value-info-hd">
                        {loading ? (
                          <Spin size="small" />
                        )
                          :
                          <div>
                            {numeral((balance?.available[0]?.amount + balance?.pending[0]?.amount) / 100).format("$0,0.0'")}
                          </div>}
                      </div>
                      <div class="total-info-hd">Total Balance</div>
                    </div>
                  </div>
                  <div className="col-12 col-xl col-md-6 mt-2">
                    <div className="dashboard-info-box btm-space-mob">
                      <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
                      <div class="value-info-hd">
                        {loading ? (
                          <Spin size="small" />
                        )
                          :
                          <div>
                            {numeral(balance?.available[0]?.amount / 100).format("$0,0.0'")}
                          </div>}
                      </div>
                      <div class="total-info-hd">Available Balance</div>
                    </div>
                  </div>
                  <div className="col-12 col-xl col-md-6 mt-2">
                    <div className="dashboard-info-box btm-space-mob">
                      <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
                      <div class="value-info-hd">
                        {loading ? (
                          <Spin size="small" />
                        )
                          : <div>
                            {numeral(balance?.pending[0]?.amount / 100).format("$0,0.0'")}
                          </div>}
                      </div>
                      <div class="total-info-hd">Pending Balance</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
