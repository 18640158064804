import React, { useContext, useMemo, useState } from "react";
import { Modal, notification, Select, Tabs, Upload } from "antd";
import { Link } from "react-router-dom";

import EventGrid from "../../components/eventsGrid/eventsGrid";
// import GridListing from "../../components/gridListingBar/gridListingBar"
import {
  faArrowUpFromBracket,
  faBars, faCloudArrowUp, faGrip, faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import ShowsGrid from "./showsGrid";
import { showErrMessage, showsBulkUpload } from "../../redux/actions/event.action";
import { Button } from "react-bootstrap";
import { UPLOADS3, UPLOAD_DONE } from "../../redux/types/types";
import { SetPercent } from "../../context/PercentProvider";
import AWS from 'aws-sdk';
const { Option } = Select;

AWS.config.update({
  region: process.env.REACT_APP_SHOWS_REGION,
  accessKeyId: process.env.REACT_APP_SHOWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SHOWS_SECRET_ACCESS_KEY,
});
const { TabPane } = Tabs;
export default function Events() {
  const setPercent = useContext(SetPercent)
  const s3 = new AWS.S3();

  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [gridActive, setGridActive] = useState(true);
  const [modal, setModal] = useState(false);

  const [eventTitle, setEventTitle] = useState(
    searchParams.get("type") === null ? "Recorded" : searchParams.get("type")
  );
  const [eventStatus, setEventStatus] = useState(123);
  const dispatch = useDispatch();
  const { validate } = useSelector((state) => {
    return state;
  });

  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set("type", key);
    window.history.replaceState(null, null, url);
    setEventTitle(key);
    setEventStatus(event.timeStamp);
  };

  const roleId = localStorage.getItem("Role");

  // useEffect(() => {
  //   dispatch(getSocialFollowers(validate?.payload?.message?._id)).then((res) => {
  //     if (res?.payload?.success) {
  //       localStorage.setItem("social", JSON.stringify(res?.payload?.message));
  //     } else {
  //       console.log(res?.payload?.statusText);
  //     }
  //   });
  // }, []);

  const [role, setRole] = useState(roleId);
  const { Dragger } = Upload;
  const [videofiles, setVideoFiles] = useState([])
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false);

  const props = {
    accept: "video/mp4",
    multiple: true,
    id: "uploadVideoFile",
    beforeUpload: (file, fileList) => {
      let ort;
      const url = URL.createObjectURL(file);
      const $video = document.createElement("video");
      $video.src = url;
      $video.addEventListener("loadedmetadata", function () {
        if (this.videoWidth > this.videoHeight) {
          ort = 'landscape'
        } else {
          ort = 'portrait'
        }
        setFileList((prev) => [...prev, file])
        setVideoFiles((prev) => [...prev, { orientation: ort, file }])
      });
      return false
    },
    onRemove: (file) => {
      let arr = [...videofiles]
      let newarr = [...fileList]
      setVideoFiles(arr.filter(f => f.file.uid !== file.uid))
      setFileList(newarr.filter(f => f.uid !== file.uid))
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
    fileList
  };
  function onSubmit() {
    let obj = { data: videofiles?.map(m => ({ orientation: m.orientation, file_name: m.file.name })) }
    setLoading(true)
    dispatch(showsBulkUpload(obj)).then((res) => {
      if (res.success) {

        for (let i = 0; i < videofiles.length; i++) {
          let arr = []
          let old = JSON.parse(localStorage.getItem("uploads"))
          let key = res?.data?.[i]?.show_s3_id
          if (old) {
            arr = [...old, { load: true, key }]
          } else {
            arr.push({ load: true, key })
          }
          localStorage.setItem('uploads', JSON.stringify(arr))
          dispatch({
            type: UPLOADS3,
            payload: { id: key, load: true },
          });
          const newFile = new File([videofiles[i].file], key + "." + videofiles[i].file.name.split('.').slice(-1).join(''), {
            type: videofiles[i].file.type,
          });
          const dT = new DataTransfer();
          dT.items.add(newFile);
          const params = {
            Bucket: process.env.REACT_APP_SHOWS_BUCKET_NAME,
            Key: dT.files[0].name,
            Body: dT.files[0]
          };
          s3.upload(params, (err, data) => {
            if (err) {
              console.log("123", err)
              showErrMessage([{ show_s3_id: key, error_reason: "Uploading file error on S3. Please try again." }])
              // localStorage.removeItem('uploads')
            }
          })
            .on('httpUploadProgress', progress => {
              let done = Math.round(progress.loaded / progress.total * 100)
              setPercent({ [key]: done })
            }).send((err, data) => {
              if (err) {
                dispatch({
                  type: UPLOAD_DONE,
                  payload: { id: key },
                });
                setTimeout(() => {
                  setPercent({ [key]: 100 })
                }, 1000)

                showErrMessage([{ show_s3_id: key, error_reason: "Uploading file error on S3. Please try again." }])
                // localStorage.removeItem('uploads')
                return
              }
              dispatch({
                type: UPLOAD_DONE,
                payload: { id: key },
              });
              // localStorage.removeItem('uploads')
              let old = JSON.parse(localStorage.getItem("uploads"))
              let temp = old.filter(f => f.key !== key)
              localStorage.setItem('uploads', JSON.stringify(temp))
              setTimeout(() => {
                setPercent({ [key]: 100 })
              }, 1000)
            })
        }
        setTimeout(() => {
          clear()
        }, 1000)
      } else {
        console.log("er", res)
        clear()
      }
    })

  }
  function clear() {
    setFileList([])
    setVideoFiles([])
    setModal(false)
    setLoading(false)
  }

  return (
    <>
      <div className="top-btn-area mb-10 d-inline-flex">
        {validate?.payload?.message?.account_type === "brand" &&
          <>
            <button
              onClick={() => setModal(true)}
              className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
            >
              <span className="nav-text ">Bulk Upload</span>
            </button>
            <Link
              to="/schedule-show"
              className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className="nav-text ">Upload Shows</span>
            </Link>
          </>
        }
      </div>


      <div className="grid-listing-area flex-column  flex-sm-column align-items-sm-start flex-md-column align-items-md-start flex-lg-column align-items-lg-start flex-xl-row align-items-start  mb-30 position-relative">
        <div className="grid-listing-left flex-grow-1">


          <Tabs
            // defaultActiveKey="Upcoming"
            defaultActiveKey={eventTitle}
            // onChange={onChange}
            className="events-tabs-main"
            onTabClick={callbackTabClicked}
          >
            {/* <TabPane tab="Upcoming" key="Upcoming"></TabPane>
            <TabPane tab="Live" key="Live Events"></TabPane> */}
            <TabPane tab="Management" key="Recorded"></TabPane>
            {validate?.payload?.message?.account_type === "brand" && (
              <TabPane tab="Published" key="Published"></TabPane>
            )}
            {/* <TabPane tab="Expired" key="Expired">
            </TabPane> */}
          </Tabs>
        </div>

        <div className="grid-listing-right">
          <div className={`grid-icon ${gridActive ? "active" : ""}`}>
            <FontAwesomeIcon icon={faGrip} />
            <a
              className="block-link"
              href="#"
              onClick={() => {
                setGridActive(true);
              }}
            ></a>
          </div>
          <div className={`grid-icon ${!gridActive ? "active" : ""}`}>
            <FontAwesomeIcon icon={faBars} />
            <a
              className="block-link"
              href="#"
              onClick={() => {
                setGridActive(false);
              }}
            ></a>
          </div>


          {/* {validate?.payload?.message?.account_type === "brand" &&
            <>
              <button
                onClick={() => setModal(true)}
                className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
              >
                <span className="nav-text ">Bulk Upload</span>
              </button>
              <Link
                to="/schedule-show"
                className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="nav-text ">Upload Shows</span>
              </Link>
            </>
          } */}



          {/* <button
            onClick={() => {
              showModal()
            }}
            // to="/schedule-event"
            className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
          >
            <span className="nav-text ">test</span>
          </button> */}

          {/* <Button
            className="default-btn d-flex align-items-center justify-content-center m-0"
            type="primary"
            block
            size="large"
            icon={<PlusOutlined />}
          >
            Create Event
          </Button> */}
        </div>
      </div>
      <Modal
        className="modal-generic  modal-reviews-main"
        footer={false}
        maskClosable={false}
        keyboard={false}
        open={modal}
        onCancel={clear}
      >
        <h2 className="modal-hd1">Bulk Upload</h2>
        <Dragger {...props} className="upload_area_2 m-auto mb-20 position-relative uploadWidth">
          <FontAwesomeIcon icon={faCloudArrowUp} className="mt-0" />
          <h4>Drag & Drop Your Media Here</h4>
          <h4>Or</h4>
          <Button
            // className="default-btn medium-btn txt-btn-pad padb-3"
            className="ant-btn ant-btn-default default-btn medium-btn txt-btn-pad padb-3"
          >
            <FontAwesomeIcon icon={faArrowUpFromBracket} className="mr-2" />
            Select File</Button>
        </Dragger>
        {videofiles.length !== 0 && <div className="d-flex justify-content-center">
          <Button disabled={loading} className="default-btn medium-btn padb-3 mt-3" onClick={onSubmit} loading={loading}>{loading ? "Uploading.." : "Upload"}</Button>
        </div>
        }
      </Modal>
      <div className="title-area">
        <h1>{eventTitle === "Recorded" ? "Management" : eventTitle}</h1>
      </div>
      <ShowsGrid
        className={gridActive}
        title={eventTitle}
        roleKey={role}
        eventStatus={eventStatus}
      />
    </>
  );
}