import { CUSTOMURL1 } from '../../config';
import {
  SC_IMPORT_PRODUCT
} from '../types/types';
import axios from 'axios';
const sc_token = JSON.parse(localStorage.getItem('sc_token'));


export const scImportProduct = (file, token) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `${CUSTOMURL1}products/csv`,
    headers: { Authorization: `Bearer ${sc_token ? sc_token : token}` },
    data: file
  })
    .then((response) => {
      dispatch({
        type: SC_IMPORT_PRODUCT,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
