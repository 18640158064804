import { ThemeProvider } from "styled-components";
import {
  MeetingProvider,
  lightTheme,
  UserActivityProvider,
  useRemoteVideoTileState,
  RemoteVideo,
  useLocalVideo,
  useMeetingStatus,
  useRosterState,
  LocalVideo,
  useContentShareControls,
  useContentShareState,
  useMediaStreamMetrics,
  useAudioVideo,
  AudioVideoProvider,
  useAudioInputs,
  useToggleLocalMute,
  Label,
  Select,
} from "amazon-chime-sdk-component-library-react";
import {
  useMeetingManager,
  NotificationProvider,
} from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { BackgroundBlurProvider } from "amazon-chime-sdk-component-library-react";
import Control from "./Control";
import DeviceSetup from "./deviceSetup/index";
import { NavigationProvider } from "./NotificationProvider";
import Notifications from "./Notification";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Slider, Col, Row, Modal, Switch } from "antd";
import ReactRouterPrompt from "react-router-prompt";
import { ErrorBoundary } from "react-error-boundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VideoStreamMerger } from "video-stream-merger";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import grid1 from "./videos/1.jpg";
import grid2 from "./videos/2.jpg";
import grid22 from "./videos/2-2.jpg";
import grid3 from "./videos/3.jpg";
import grid1p from "./videos/1p.jpg";
import grid2p from "./videos/2p.jpg";

import grid32 from "./videos/3-2.jpg";
import grid4 from "./videos/4.jpg";
import grid22p from "./videos/2-2p.jpg";

import IVSBroadcastClient from "amazon-ivs-web-broadcast";
import { useSelector } from "react-redux";

const lgrids = [
  {
    img: grid1,
    name: "grid1",
  },
  {
    img: grid2,
    name: "grid2",
  },
  {
    img: grid22,
    name: "grid22",
  },
  {
    img: grid3,
    name: "grid3",
  },
  // {
  //   img: grid32,
  //   name: "grid32"
  // },
  {
    img: grid4,
    name: "grid4",
  },
];
const pgrids = [
  {
    img: grid1p,
    name: "grid1p",
  },
  {
    img: grid2p,
    name: "grid2p",
  },
  {
    img: grid22p,
    name: "grid22p",
  },
];

const MyApp = ({
  meetingData,
  host,
  endMeeting,
  onGolive,
  live,
  checkEvent,
  endMeetingFlag,
  channel,
  stopLiveEvent,
}) => {
  const history = useNavigate();
  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const [leavemet, setLeaveMet] = useState(false);
  const { tiles, attendeeIdToTileId, tileIdToAttendeeId } =
    useRemoteVideoTileState();
  const [ratio, setRatio] = useState({ w: 16, h: 9 });
  const { validate } = useSelector((state) => {
    return state;
  });
  const [currentTileId, setCurrentTileId] = useState("");
  const { isVideoEnabled } = useLocalVideo();
  const { toggleContentShare } = useContentShareControls();
  const { sharingAttendeeId } = useContentShareState();
  const metrics = useMediaStreamMetrics();
  const audioVideo = useAudioVideo();
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [brightness, setBrightness] = useState(100);
  const [grayscale, setGrayscale] = useState(0);
  const [hue, setHue] = useState(0);
  const [blur, setBlur] = useState(0);
  const { muted, toggleMute } = useToggleLocalMute();
  const [contrast, setContrast] = useState(100);
  const [saturation, setSaturation] = useState(100);
  const [aspectRatio, setAspectRatio] = useState("16:9");
  const [slotDrop, setSlotDrop] = useState("null");
  const [pipeline, setPipeline] = useState([]);
  const { devices, selectedDevice } = useAudioInputs();
  const [currentGrid, setCurrentGrid] = useState({
    name: "grid1p",
    img: grid1p,
  });
  const [submit, setSubmit] = useState(false);
  const [pipeUpdateCounter, setPipeUpdateCounter] = useState(0);
  const videoRef = useRef();
  const mainMerger = useRef();
  const ivsSDK = useRef();
  const [current, setCurrent] = useState({
    brightness: 100,
    ratio: [16, 9],
    tileId: "",
    grayscale: 0,
    hue: 0,
    saturate: 100,
    blur: 0,
    contrast: 100,
  });
  const [height, setHeight] = useState(495);

  const [orientation, setOrientation] = useState("Portrait");

  const canvasRef = useRef();
  const capCanvas = useRef();
  const gridRef = useRef();

  const joinMeeting = async () => {
    const meetingSessionConfiguration = new MeetingSessionConfiguration(
      meetingData[0]?.meeting_info?.Meeting,
      {
        Attendee: meetingData[0]?.meeting_info?.Attendees.filter(
          (it) => it.ExternalUserId ===  validate?.payload?.message?._id
        )[0],
      }
    );

    // Create a `MeetingSession` using `join()` function with the `MeetingSessionConfiguration`
    return meetingManager.join(meetingSessionConfiguration).then(() => {});

    // At this point you could let users setup their devices, or by default
    // the SDK will select the first device in the list for the kind indicated
    // by `deviceLabels` (the default value is DeviceLabels.AudioAndVideo)
    // ...

    // Start the `MeetingSession` to join the meeting
  };

  useEffect(() => {
    if (current.tileId !== "") {
      videoRef.current.stop();
      videoRef.current.destroy();
      const video = document
        .getElementById(`v${current.tileId}`)
        .firstChild.captureStream(25);
      const outputVideo = document.getElementById("video1");
      videoRef.current = new VideoStreamMerger({
        width: current.ratio[0] * 100,
        height: current.ratio[1] * 100,
      });
      //
      const videoElement = {
        videoWidth: video.getTracks()[0].getSettings().width,
        videoHeight: video.getTracks()[0].getSettings().height,
      };
      const width = videoRef.current.width;
      const height = videoRef.current.height;
      const aspectRatio = width / height;

      let newWidth;
      let newHeight;

      const imageRatio = videoElement.videoWidth / videoElement.videoHeight;

      if (aspectRatio >= imageRatio) {
        newWidth = videoElement.videoWidth;
        newHeight = videoElement.videoWidth / aspectRatio;
      } else {
        newWidth = videoElement.videoHeight * aspectRatio;
        newHeight = videoElement.videoHeight;
      }
      var x = (videoElement.videoWidth - newWidth) / 2;
      var y = (videoElement.videoHeight - newHeight) / 2;
      //
      videoRef.current.addStream(video, {
        x: 0,
        y: 0,
        width: newWidth,
        height: newHeight,
        draw: (ctx, frame, done) => {
          if (current.tileId == "local") {
            ctx.scale(-1, 1);
            ctx.translate(-current.ratio[0] * 100, 0);
          }
          ctx.filter = `brightness(${current.brightness}%) contrast(${current.contrast}%) grayscale(${current.grayscale}%) saturate(${current.saturate}%) hue-rotate(${current.hue}deg) blur(${current.blur}px)`;
          ctx.drawImage(
            frame,
            x,
            y,
            newWidth,
            newHeight,
            0,
            0,
            current.ratio[0] * 100,
            current.ratio[1] * 100
          );
          if (current.tileId == "local") {
            ctx.setTransform(1, 0, 0, 1, 0, 0);
          }
          done();
        },
      });
      videoRef.current.start();
      outputVideo.srcObject = videoRef.current.result;
      outputVideo.play();
    }
  }, [
    current.brightness,
    current.ratio,
    current.blur,
    current.grayscale,
    current.hue,
    current.saturate,
    current.contrast,
  ]);

  useEffect(() => {
    if (meetingStatus != 0) {
      if (mainMerger.current?.addStream) {
        mainMerger.current.stop();
        mainMerger.current.destroy();
      }
      changeGrid();
    }
  }, [meetingStatus, currentGrid]);

  function getGridRatio(s) {
    switch (currentGrid.name) {
      case "grid1p":
        return [6, 12];
        break;
      case "grid2p":
        return [6, 6];
        break;
      case "grid22p":
        return [6, 12];
        break;
      case "grid1":
        return [16, 9];
        break;
      case "grid2":
        return [8, 9];
        break;
      case "grid22":
        if (s == "1") {
          return [10, 9];
        } else {
          return [6, 9];
        }
        break;
      case "grid3":
        if (s == "1") {
          return [8, 9];
        } else {
          return [16, 9];
        }
        break;
      case "grid4":
        return [16, 9];
        break;
    }
  }

  function getPipeForgrid() {
    switch (currentGrid.name) {
      case "grid1p":
        return [...pipeline]
          .map((it, i) => ({ ...it, slot: (i + 1).toString() }))
          .slice(0, 1);
      case "grid1":
        return [...pipeline]
          .map((it, i) => ({ ...it, slot: (i + 1).toString() }))
          .slice(0, 1);
      case "grid2":
        return [...pipeline]
          .map((it, i) => ({ ...it, slot: (i + 1).toString() }))
          .slice(0, 2);
      case "grid2p":
        return [...pipeline]
          .map((it, i) => ({ ...it, slot: (i + 1).toString() }))
          .slice(0, 2);
      case "grid22p":
        return [...pipeline]
          .map((it, i) => ({ ...it, slot: (i + 1).toString() }))
          .slice(0, 2);
      case "grid22":
        return [...pipeline]
          .map((it, i) => ({ ...it, slot: (i + 1).toString() }))
          .slice(0, 2);
      case "grid3":
        return [...pipeline]
          .map((it, i) => ({ ...it, slot: (i + 1).toString() }))
          .slice(0, 3);
      case "grid4":
        return [...pipeline]
          .map((it, i) => ({ ...it, slot: (i + 1).toString() }))
          .slice(0, 4);
    }
  }
  function changeGrid() {
    var canvas = canvasRef?.current;
    canvas.width =
      currentGrid.name == "grid1p" ||
      currentGrid.name == "grid2p" ||
      currentGrid.name == "grid22p"
        ? 600
        : 1600;
    canvas.height =
      currentGrid.name == "grid1p" ||
      currentGrid.name == "grid2p" ||
      currentGrid.name == "grid22p"
        ? 1200
        : 900;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    var videoElement = document.getElementById("mainFrame");
    videoElement.pause();
    videoElement.removeAttribute("src"); // empty source
    videoElement.load();
    const tileCombine = isVideoEnabled ? ["local", ...tiles] : [...tiles];
    if (
      tileCombine.length > 0 &&
      pipeline[0] != "remove" &&
      pipeline.length > 0
    ) {
      setTimeout(() => {
        setPipeline(
          getPipeForgrid().map((it) => {
            return {
              ...it,
              ratio: getGridRatio(it.slot),
            };
          })
        );
      }, 500);
    } else {
      setPipeline([]);
    }

    setCurrent({
      brightness: 100,
      ratio: [16, 9],
      tileId: "",
      grayscale: 0,
      hue: 0,
      saturate: 100,
      blur: 0,
      contrast: 100,
    });
    setSlotDrop("null");
    // outputVideo.clear(true)
    // canvas?.srcObject = ""
    // canvas?.pause()
    // canvas = null
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    img.src = currentGrid.img;
    img.onload = function () {
      var scale = Math.max(
        canvas.width / img.width,
        canvas.height / img.height
      );
      // get the top left position of the image
      var x = canvas.width / 2 - (img.width / 2) * scale;
      var y = canvas.height / 2 - (img.height / 2) * scale;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      capCanvas.current = canvas.captureStream(25);

      if (ivsSDK?.current?.addVideoInputDevice && pipeline.length > 0) {
        if (ivsSDK?.current?.getVideoInputDevice("camera1")?.name) {
          ivsSDK.current.removeVideoInputDevice("camera1");
          ivsSDK.current.addVideoInputDevice(
            videoElement.captureStream(25),
            "camera1",
            { index: 0 }
          );
        }
      }
    };
  }

  useEffect(() => {
    const tileCombine = isVideoEnabled ? ["local", ...tiles] : [...tiles];
    if (tileCombine.length > 0 && pipeline.length > 0) {
      const updatedPipe = pipeline.filter((it) =>
        tileCombine.find((itSub) => itSub == it.tileId)
      );
      setPipeline(updatedPipe);
      videoRef.current?.stop();
      videoRef.current?.destroy();
    }
  }, [tiles]);

  useEffect(() => {
    if (meetingStatus != 0 && meetingData[0]?.event_status == "live") {
      goLive(true);
    }
  }, [meetingStatus]);

  useEffect(() => {
    joinMeeting();
  }, [meetingData]);

  function ab() {
    audioVideo.getCurrentMeetingAudioStream().then((res) => {
    });
  }

  useEffect(() => {
    if (meetingStatus != 0) {
      if (!isVideoEnabled) {
        toggleAttendee(isVideoEnabled, "local");
      }
    }
  }, [meetingStatus, isVideoEnabled]);

  useEffect(() => {
    return () => {
      meetingManager.leave();
    };
  }, []);

  const leaveMeeting = () => {
    return meetingManager.leave();
  };

  useEffect(() => {
    if (pipeline[0] == "remove") {
      changeGrid();
    }
    if (pipeline.length > 0 && pipeline[0] != "remove") {
      if (mainMerger.current?.addStream) {
        mainMerger.current.stop();
        mainMerger.current.destroy();
      }
      mainMerger.current = new VideoStreamMerger({
        width:
          currentGrid.name == "grid1p" ||
          currentGrid.name == "grid2p" ||
          currentGrid.name == "grid22p"
            ? 600
            : 1600,
        height:
          currentGrid.name == "grid1p" ||
          currentGrid.name == "grid2p" ||
          currentGrid.name == "grid22p"
            ? 1200
            : 900,
      });
      var canvas = capCanvas.current;
      mainMerger.current.addStream(canvas, {
        x: 0,
        y: 0,
        width:
          currentGrid.name == "grid1p" ||
          currentGrid.name == "grid2p" ||
          currentGrid.name == "grid22p"
            ? 600
            : 1600,
        height:
          currentGrid.name == "grid1p" ||
          currentGrid.name == "grid2p" ||
          currentGrid.name == "grid22p"
            ? 1200
            : 900,
      });
      pipeline
        .sort((a, b) => a.slot - b.slot)
        .forEach((it, i) => {
          var mainFrame = document.getElementById("mainFrame");
          var videoTileElement = document.getElementById(
            `v${it.tileId}`
          )?.firstChild;
          const video = videoTileElement?.captureStream(25);

          if (!video?.getTracks()[0]?.getSettings) {
            mainFrame.pause();
            mainFrame.removeAttribute("src"); // empty source
            mainFrame.load();
          }
          if (video) {
            const videoElement = video?.getTracks()[0]?.getSettings
              ? {
                  videoWidth: video.getTracks()[0].getSettings().width,
                  videoHeight: video.getTracks()[0].getSettings().height,
                }
              : video;
            const width = it.ratio[0] * 100;
            const height = it.ratio[1] * 100;
            const aspectRatio = width / height;

            let newWidth;
            let newHeight;

            const imageRatio =
              videoElement.videoWidth / videoElement.videoHeight;

            if (aspectRatio >= imageRatio) {
              newWidth = videoElement.videoWidth;
              newHeight = videoElement.videoWidth / aspectRatio;
            } else {
              newWidth = videoElement.videoHeight * aspectRatio;
              newHeight = videoElement.videoHeight;
            }
            var x = (videoElement.videoWidth - newWidth) / 2;
            var y = (videoElement.videoHeight - newHeight) / 2;
            switch (currentGrid.name) {
              case "grid1p":
                mainMerger.current.addStream(video, {
                  x: 0,
                  y: 0,
                  width: newWidth,
                  height: newHeight,
                  draw: (ctx, frame, done) => {
                    ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`;
                    switch (it.slot) {
                      case "1":
                        if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            150,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        if (it.ratio[0] == 6 && it.ratio[1] == 12) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            262.5,
                            600,
                            675
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            431,
                            600,
                            338
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            330,
                            600,
                            540
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        break;
                    }
                  },
                });
                break;
              case "grid1":
                mainMerger.current.addStream(video, {
                  x: 0,
                  y: 0,
                  width: newWidth,
                  height: newHeight,
                  draw: (ctx, frame, done) => {
                    ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`;
                    switch (it.slot) {
                      case "1":
                        if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100 + 2 * 500), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            500,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100 + 2 * 400), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            400,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100 + 2 * 0), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100 + 2 * 300), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            300,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        break;
                    }
                  },
                });
                break;
              case "grid2":
                var margin = it.slot == 1 ? 0 : 800;
                mainMerger.current.addStream(video, {
                  x: margin,
                  y: 0,
                  width: newWidth,
                  height: newHeight,
                  draw: (ctx, frame, done) => {
                    ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`;
                    if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                      if (it.tileId == "local") {
                        ctx.scale(-1, 1);
                        ctx.translate(
                          -(
                            it.ratio[0] * 100 +
                            2 * (it.slot == 1 ? margin + 200 : margin)
                          ),
                          0
                        );
                      }
                      ctx.drawImage(
                        frame,
                        x,
                        y,
                        newWidth,
                        newHeight,
                        it.slot == 1 ? margin + 200 : margin,
                        0,
                        it.ratio[0] * 100,
                        it.ratio[1] * 100
                      );
                      if (it.tileId == "local") {
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                      }
                      done();
                    } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                      if (it.tileId == "local") {
                        ctx.scale(-1, 1);
                        ctx.translate(-(it.ratio[0] * 100 + 2 * margin), 0);
                      }
                      ctx.drawImage(
                        frame,
                        x,
                        y,
                        newWidth,
                        newHeight,
                        margin + 0,
                        0,
                        it.ratio[0] * 100,
                        it.ratio[1] * 100
                      );
                      if (it.tileId == "local") {
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                      }
                      done();
                    } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                      if (it.tileId == "local") {
                        ctx.scale(-1, 1);
                        ctx.translate(-(800 + 2 * margin), 0);
                      }
                      ctx.drawImage(
                        frame,
                        x,
                        y,
                        newWidth,
                        newHeight,
                        margin,
                        225,
                        800,
                        450
                      );
                      if (it.tileId == "local") {
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                      }
                      done();
                    } else if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                      if (it.tileId == "local") {
                        ctx.scale(-1, 1);
                        ctx.translate(-(800 + 2 * margin), 0);
                      }
                      ctx.drawImage(
                        frame,
                        x,
                        y,
                        newWidth,
                        newHeight,
                        margin,
                        90,
                        800,
                        720
                      );
                      if (it.tileId == "local") {
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                      }
                      done();
                    }
                  },
                });
                break;
              case "grid22p":
                mainMerger.current.addStream(video, {
                  x: 0,
                  y: 0,
                  width: newWidth,
                  height: newHeight,
                  draw: (ctx, frame, done) => {
                    ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`;
                    switch (it.slot) {
                      case "1":
                        if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            150,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );

                          ctx.fillStyle = "black";
                          ctx.fillRect(
                            it.tileId == "local" ? 0 : 600 - 225.5,
                            1200 - 451,
                            225.5,
                            451
                          );
                          ctx.font = "140px Arial";
                          if (it.tileId == "local") {
                            ctx.scale(1, -1);
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          ctx.fillStyle = "white";
                          ctx.fillText(
                            "2",
                            600 - 50 - 225.5 / 2,
                            1200 + 40 - 451 / 2
                          );

                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        if (it.ratio[0] == 6 && it.ratio[1] == 12) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );

                          ctx.fillStyle = "black";
                          ctx.fillRect(
                            it.tileId == "local" ? 0 : 600 - 225.5,
                            1200 - 451,
                            225.5,
                            451
                          );
                          ctx.font = "140px Arial";
                          if (it.tileId == "local") {
                            ctx.scale(1, -1);
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          ctx.fillStyle = "white";
                          ctx.fillText(
                            "2",
                            600 - 50 - 225.5 / 2,
                            1200 + 40 - 451 / 2
                          );

                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            262.5,
                            600,
                            675
                          );

                          ctx.fillStyle = "black";
                          ctx.fillRect(
                            it.tileId == "local" ? 0 : 600 - 225.5,
                            1200 - 451,
                            225.5,
                            451
                          );
                          ctx.font = "140px Arial";
                          if (it.tileId == "local") {
                            ctx.scale(1, -1);
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          ctx.fillStyle = "white";
                          ctx.fillText(
                            "2",
                            600 - 50 - 225.5 / 2,
                            1200 + 40 - 451 / 2
                          );

                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            431,
                            600,
                            338
                          );

                          ctx.fillStyle = "black";
                          ctx.fillRect(
                            it.tileId == "local" ? 0 : 600 - 225.5,
                            1200 - 451,
                            225.5,
                            451
                          );
                          ctx.font = "140px Arial";
                          if (it.tileId == "local") {
                            ctx.scale(1, -1);
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          ctx.fillStyle = "white";
                          ctx.fillText(
                            "2",
                            600 - 50 - 225.5 / 2,
                            1200 + 40 - 451 / 2
                          );

                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            330,
                            600,
                            540
                          );

                          ctx.fillStyle = "black";
                          ctx.fillRect(
                            it.tileId == "local" ? 0 : 600 - 225.5,
                            1200 - 451,
                            225.5,
                            451
                          );
                          ctx.font = "140px Arial";
                          if (it.tileId == "local") {
                            ctx.scale(1, -1);
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          ctx.fillStyle = "white";
                          ctx.fillText(
                            "2",
                            600 - 50 - 225.5 / 2,
                            1200 + 40 - 451 / 2
                          );

                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        break;
                      case "2":
                        if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            it.tileId == "local" ? 0 : 600 - 225.5,
                            56.75 + (1200 - 451),
                            225.5,
                            337.5
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        if (it.ratio[0] == 6 && it.ratio[1] == 12) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            it.tileId == "local" ? 0 : 600 - 225.5,
                            0 + (1200 - 451),
                            225.5,
                            451
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            it.tileId == "local" ? 0 : 600 - 225.5,
                            98.9 + (1200 - 451),
                            222.5,
                            253.13
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            it.tileId == "local" ? 0 : 600 - 225.5,
                            162.2 + (1200 - 451),
                            225.5,
                            126.56
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            it.tileId == "local" ? 0 : 600 - 225.5,
                            124.25 + (1200 - 451),
                            225.5,
                            202.5
                          );

                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        break;
                    }
                  },
                });
                break;
              case "grid2p":
                mainMerger.current.addStream(video, {
                  x: 0,
                  y: 0,
                  width: newWidth,
                  height: newHeight,
                  draw: (ctx, frame, done) => {
                    ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`;
                    switch (it.slot) {
                      case "1":
                        if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(400 + 2 * 100), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            100,
                            0,
                            400,
                            600
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 6 && it.ratio[1] == 6) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            0,
                            newWidth,
                            newHeight,
                            0,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(533.33 + 2 * 33.335), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            33.335,
                            0,
                            533.33,
                            600
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            131.25,
                            600,
                            337.5
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            30,
                            600,
                            540
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        break;
                      case "2":
                        if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(400 + 2 * 100), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            100,
                            600,
                            400,
                            600
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 6 && it.ratio[1] == 6) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(it.ratio[0] * 100), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            0,
                            newWidth,
                            newHeight,
                            0,
                            600,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(533.33 + 2 * 33.335), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            33.335,
                            600,
                            533.33,
                            600
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            131.25 + 600,
                            600,
                            337.5
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-600, 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            0,
                            30 + 600,
                            600,
                            540
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        break;
                    }
                  },
                });
                break;
              case "grid22":
                var margin = it.slot == 1 ? 0 : 1000;
                mainMerger.current.addStream(video, {
                  x: margin,
                  y: 0,
                  width: newWidth,
                  height: newHeight,
                  draw: (ctx, frame, done) => {
                    ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`;
                    switch (it.slot) {
                      case "1":
                        if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(
                              -(it.ratio[0] * 100 + 2 * (margin + 200)),
                              0
                            );
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin + 200,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(
                              -(it.ratio[0] * 100 + margin + 2 * 100),
                              0
                            );
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin + 100,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(1000 + margin), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin,
                            169,
                            1000,
                            562
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(
                              -(it.ratio[0] * 100 + margin + 2 * 0),
                              0
                            );
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        break;
                      case "2":
                        if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(600 + 2 * (margin + 0)), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin,
                            180,
                            600,
                            540
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(600 + 2 * (margin + 0)), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin,
                            112.5,
                            600,
                            675
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(600 + 2 * (margin + 0)), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin,
                            281,
                            600,
                            338
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(
                              -(it.ratio[0] * 100 + 2 * (margin + 0)),
                              0
                            );
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        break;
                    }
                  },
                });
                break;
              case "grid3":
                var margin = it.slot == "1" ? 0 : 800;
                var marginY = it.slot == "3" ? 450 : 0;
                mainMerger.current.addStream(video, {
                  x: margin,
                  y: 0,
                  width: newWidth,
                  height: newHeight,
                  draw: (ctx, frame, done) => {
                    ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`;
                    switch (it.slot) {
                      case "1":
                        if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(
                              -(it.ratio[0] * 100 + 2 * (margin + 100)),
                              0
                            );
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin + 100,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(
                              -(it.ratio[0] * 100 + 2 * (margin + 0)),
                              0
                            );
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin + 0,
                            0,
                            it.ratio[0] * 100,
                            it.ratio[1] * 100
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(800 + 2 * margin), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin,
                            225,
                            800,
                            450
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(800 + 2 * margin), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin,
                            90,
                            800,
                            720
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        break;
                      case "2":
                        if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(500 + 2 * (margin + 150)), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin + 150,
                            0,
                            500,
                            450
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(400 + 2 * (margin + 200)), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin + 200,
                            0,
                            400,
                            450
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(800 + 2 * margin), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin,
                            0,
                            800,
                            450
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(300 + 2 * (margin + 250)), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin + 250,
                            0,
                            300,
                            450
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        break;
                      case "3":
                        if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(500 + 2 * (margin + 150)), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin + 150,
                            marginY,
                            500,
                            450
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(400 + 2 * (margin + 200)), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin + 200,
                            marginY,
                            400,
                            450
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(800 + 2 * (margin + 0)), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin,
                            marginY,
                            800,
                            450
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        } else if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                          if (it.tileId == "local") {
                            ctx.scale(-1, 1);
                            ctx.translate(-(300 + 2 * (margin + 250)), 0);
                          }
                          ctx.drawImage(
                            frame,
                            x,
                            y,
                            newWidth,
                            newHeight,
                            margin + 250,
                            marginY,
                            300,
                            450
                          );
                          if (it.tileId == "local") {
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                          }
                          done();
                        }
                        break;
                    }
                  },
                });
                break;
              case "grid4":
                var margin = it.slot == "1" || it.slot == "3" ? 0 : 800;
                var marginY = it.slot == "3" || it.slot == "4" ? 450 : 0;
                mainMerger.current.addStream(video, {
                  x: margin,
                  y: 0,
                  width: newWidth,
                  height: newHeight,
                  draw: (ctx, frame, done) => {
                    ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`;
                    if (it.ratio[0] == 10 && it.ratio[1] == 9) {
                      if (it.tileId == "local") {
                        ctx.scale(-1, 1);
                        ctx.translate(-(500 + 2 * (margin + 150)), 0);
                      }
                      ctx.drawImage(
                        frame,
                        x,
                        y,
                        newWidth,
                        newHeight,
                        margin + 150,
                        marginY,
                        500,
                        450
                      );
                      if (it.tileId == "local") {
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                      }
                      done();
                    } else if (it.ratio[0] == 8 && it.ratio[1] == 9) {
                      if (it.tileId == "local") {
                        ctx.scale(-1, 1);
                        ctx.translate(-(400 + 2 * (margin + 200)), 0);
                      }
                      ctx.drawImage(
                        frame,
                        x,
                        y,
                        newWidth,
                        newHeight,
                        margin + 200,
                        marginY,
                        400,
                        450
                      );
                      if (it.tileId == "local") {
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                      }
                      done();
                    } else if (it.ratio[0] == 16 && it.ratio[1] == 9) {
                      if (it.tileId == "local") {
                        ctx.scale(-1, 1);
                        ctx.translate(-(800 + 2 * (margin + 0)), 0);
                      }
                      ctx.drawImage(
                        frame,
                        x,
                        y,
                        newWidth,
                        newHeight,
                        margin,
                        marginY,
                        800,
                        450
                      );
                      if (it.tileId == "local") {
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                      }
                      done();
                    } else if (it.ratio[0] == 6 && it.ratio[1] == 9) {
                      if (it.tileId == "local") {
                        ctx.scale(-1, 1);
                        ctx.translate(-(300 + 2 * (margin + 250)), 0);
                      }
                      ctx.drawImage(
                        frame,
                        x,
                        y,
                        newWidth,
                        newHeight,
                        margin + 250,
                        marginY,
                        300,
                        450
                      );
                      if (it.tileId == "local") {
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                      }
                      done();
                    }
                  },
                });
                break;
            }
          }
        });
      // mainMerger.current.setOutputSize(IVSBroadcastClient.BASIC_LANDSCAPE.maxResolution.width, IVSBroadcastClient.BASIC_LANDSCAPE.maxResolution.height)
      mainMerger.current.start();
      const outputVideo = document.getElementById("mainFrame");
      outputVideo.srcObject = mainMerger.current.result;
      outputVideo.play();
      setCurrent({
        brightness: 100,
        ratio: [16, 9],
        tileId: "",
        grayscale: 0,
        hue: 0,
        saturate: 100,
        blur: 0,
        contrast: 100,
      });
      setSlotDrop("null");
      if (ivsSDK?.current?.addVideoInputDevice) {
        const outputVideo = document
          .getElementById("mainFrame")
          .captureStream(25);

        if (ivsSDK?.current?.getVideoInputDevice("camera1")?.name) {
          ivsSDK.current.removeVideoInputDevice("camera1");
          ivsSDK.current.removeAudioInputDevice("mic1");
        }

        if (muted) {
          audioVideo.getCurrentMeetingAudioStream().then((audioStream) => {
            ivsSDK.current.addAudioInputDevice(audioStream, "mic1");
            ivsSDK.current.addVideoInputDevice(outputVideo, "camera1", {
              index: 0,
            });
          });
        } else {
          navigator.mediaDevices
            .getUserMedia({
              video: false,
              audio: {
                deviceId: selectedDevice,
              },
            })
            .then((producerAudioStream) => {
              audioVideo.getCurrentMeetingAudioStream().then((audioStream) => {
                var OutgoingAudioMediaStream = new MediaStream();
                OutgoingAudioMediaStream.addTrack(
                  producerAudioStream.getAudioTracks()[0]
                );

                var IncomingAudioMediaStream = new MediaStream();
                IncomingAudioMediaStream.addTrack(
                  audioStream.getAudioTracks()[0]
                );

                const audioContext = new AudioContext();

                var audioIn_01 = audioContext.createMediaStreamSource(
                  OutgoingAudioMediaStream
                );
                var audioIn_02 = audioContext.createMediaStreamSource(
                  IncomingAudioMediaStream
                );

                var dest = audioContext.createMediaStreamDestination();

                audioIn_01.connect(dest);
                audioIn_02.connect(dest);

                var FinalStream = dest.stream;

                ivsSDK.current.addAudioInputDevice(FinalStream, "mic1");
                ivsSDK.current.addVideoInputDevice(outputVideo, "camera1", {
                  index: 0,
                });
              });
            });
        }
      }
    }
  }, [pipeline, pipeUpdateCounter]);

  useEffect(() => {
    if (meetingStatus == 3) {
      meetingManager.leave().then(() => {
        history({
          pathname: `/events`,
        });
      });
    }
  }, [meetingStatus]);

  useEffect(() => {
    if (ivsSDK?.current?.addVideoInputDevice) {
      if (ivsSDK?.current?.getVideoInputDevice("camera1")?.name) {
        ivsSDK.current.removeAudioInputDevice("mic1");
      }
      if (muted) {
        audioVideo.getCurrentMeetingAudioStream().then((audioStream) => {
          ivsSDK.current.addAudioInputDevice(audioStream, "mic1");
        });
      } else {
        navigator.mediaDevices
          .getUserMedia({
            video: false,
            audio: {
              deviceId: selectedDevice,
            },
          })
          .then((producerAudioStream) => {
            audioVideo.getCurrentMeetingAudioStream().then((audioStream) => {
              var OutgoingAudioMediaStream = new MediaStream();
              OutgoingAudioMediaStream.addTrack(
                producerAudioStream.getAudioTracks()[0]
              );

              var IncomingAudioMediaStream = new MediaStream();
              IncomingAudioMediaStream.addTrack(
                audioStream.getAudioTracks()[0]
              );

              const audioContext = new AudioContext();

              var audioIn_01 = audioContext.createMediaStreamSource(
                OutgoingAudioMediaStream
              );
              var audioIn_02 = audioContext.createMediaStreamSource(
                IncomingAudioMediaStream
              );

              var dest = audioContext.createMediaStreamDestination();

              audioIn_01.connect(dest);
              audioIn_02.connect(dest);

              var FinalStream = dest.stream;

              ivsSDK.current.addAudioInputDevice(FinalStream, "mic1");
            });
          });
      }
    }
  }, [muted, selectedDevice]);

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }

  if (meetingStatus == 0) {
    return (
      <UserActivityProvider>
        <DeviceSetup
          joinMeeting={async (cb) => {
            await meetingManager.start();
            // cb()
          }}
          checkEvent={checkEvent}
          meetingData={meetingData}
          ratio={ratio}
          setRatio={setRatio}
          meetingChecker={meetingData ? null : "Event not started by producer"}
          leaveMeeting={leaveMeeting}
          host={host}
          endMeeting={endMeeting}
        />
      </UserActivityProvider>
    );
  }

  const changeBrightness = (value) => {
    setBrightness(value);
  };
  const changeSaturation = (value) => {
    setSaturation(value);
  };

  const closeSettingModal = () => {
    videoRef.current.stop();
    videoRef.current.destroy();
    setCurrent({
      brightness: 100,
      ratio: [16, 9],
      tileId: "",
      grayscale: 1,
      hue: 0,
      saturate: 100,
      blur: 0,
      contrast: 100,
    });
    setAspectRatio("16:9");
    setBrightness(100);
    setContrast(100);
    setSaturation(100);
    setBlur(0);
    setHue(0);
    setSlotDrop("null");
    setGrayscale(0);
    setShowSettingModal(!showSettingModal);
    setSubmit(false);
  };

  const onSaveSetting = () => {
    if (true) {
      var pipeClone = [...pipeline];
      pipeClone = pipeClone.filter((it) => {
        return it.tileId != current.tileId && it.slot != slotDrop;
      });
      setPipeline([...pipeClone, { ...current, slot: slotDrop }]);

      videoRef.current.stop();
      videoRef.current.destroy();
      setCurrent({
        brightness: 100,
        ratio: [16, 9],
        tileId: "",
        grayscale: 1,
        hue: 0,
        saturate: 100,
        blur: 0,
        contrast: 100,
      });
      setAspectRatio("16:9");
      setBrightness(100);
      setContrast(100);
      setSaturation(100);
      setBlur(0);
      setHue(0);
      setGrayscale(0);
      setShowSettingModal(!showSettingModal);
    }
  };

  const onSaveSettingToggle = (tileId) => {
    if (currentGrid.name == "grid1") {
      setPipeline([
        {
          ...current,
          tileId: tileId,
          slot: "1",
          ratio: [16, 9],
        },
      ]);
      videoRef.current?.stop();
      videoRef.current?.destroy();
    } else if (currentGrid.name == "grid1p") {
      setPipeline([
        {
          ...current,
          tileId: tileId,
          slot: "1",
          ratio: [6, 12],
        },
      ]);
      videoRef.current?.stop();
      videoRef.current?.destroy();
    } else if (currentGrid.name == "grid2") {
      if (pipeline.length > 0) {
        if (pipeline.length < 2) {
          if (pipeline[0].slot == "1") {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "2",
                ratio: [8, 9],
              },
            ]);
          } else {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "1",
                ratio: [8, 9],
              },
            ]);
          }
        } else {
          alert("You are exceeding the limit");
        }
      } else {
        setPipeline([
          {
            ...current,
            tileId: tileId,
            slot: "1",
            ratio: [8, 9],
          },
        ]);
      }
      videoRef.current?.stop();
      videoRef.current?.destroy();
    } else if (currentGrid.name == "grid2p") {
      if (pipeline.length > 0) {
        if (pipeline.length < 2) {
          if (pipeline[0].slot == "1") {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "2",
                ratio: [6, 6],
              },
            ]);
          } else {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "1",
                ratio: [6, 6],
              },
            ]);
          }
        } else {
          alert("You are exceeding the limit");
        }
      } else {
        setPipeline([
          {
            ...current,
            tileId: tileId,
            slot: "1",
            ratio: [6, 6],
          },
        ]);
      }
      videoRef.current?.stop();
      videoRef.current?.destroy();
    } else if (currentGrid.name == "grid22") {
      if (pipeline.length > 0) {
        if (pipeline.length < 2) {
          if (pipeline[0].slot == "1") {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "2",
                ratio: [6, 9],
              },
            ]);
          } else {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "1",
                ratio: [10, 9],
              },
            ]);
          }
        } else {
          alert("You are exceeding the limit");
        }
      } else {
        setPipeline([
          {
            ...current,
            tileId: tileId,
            slot: "1",
            ratio: [10, 9],
          },
        ]);
      }
      videoRef.current?.stop();
      videoRef.current?.destroy();
    } else if (currentGrid.name == "grid22p") {
      if (pipeline.length > 0) {
        if (pipeline.length < 2) {
          if (pipeline[0].slot == "1") {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "2",
                ratio: [6, 12],
              },
            ]);
          } else {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "1",
                ratio: [6, 12],
              },
            ]);
          }
        } else {
          alert("You are exceeding the limit");
        }
      } else {
        setPipeline([
          {
            ...current,
            tileId: tileId,
            slot: "1",
            ratio: [6, 12],
          },
        ]);
      }
      videoRef.current?.stop();
      videoRef.current?.destroy();
    } else if (currentGrid.name == "grid3") {
      if (pipeline.length > 0) {
        if (pipeline.length < 3) {
          if (
            pipeline.find((it) => it.slot == "1") &&
            !pipeline.find((it) => it.slot == "2")
          ) {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "2",
                ratio: [16, 9],
              },
            ]);
          } else if (
            pipeline.find((it) => it.slot == "2") &&
            !pipeline.find((it) => it.slot == "3")
          ) {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "3",
                ratio: [16, 9],
              },
            ]);
          } else if (
            pipeline.find((it) => it.slot == "3") &&
            !pipeline.find((it) => it.slot == "1")
          ) {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "1",
                ratio: [16, 9],
              },
            ]);
          } else if (
            pipeline.find((it) => it.slot == "3") &&
            !pipeline.find((it) => it.slot == "2")
          ) {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "2",
                ratio: [16, 9],
              },
            ]);
          }
        } else {
          alert("You are exceeding the limit");
        }
      } else {
        setPipeline([
          {
            ...current,
            tileId: tileId,
            slot: "1",
            ratio: [8, 9],
          },
        ]);
      }
      videoRef.current?.stop();
      videoRef.current?.destroy();
    } else if (currentGrid.name == "grid4") {
      if (pipeline.length > 0) {
        if (pipeline.length < 4) {
          if (
            pipeline.find((it) => it.slot == "1") &&
            !pipeline.find((it) => it.slot == "2")
          ) {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "2",
                ratio: [16, 9],
              },
            ]);
          } else if (
            pipeline.find((it) => it.slot == "2") &&
            !pipeline.find((it) => it.slot == "3")
          ) {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "3",
                ratio: [16, 9],
              },
            ]);
          } else if (
            pipeline.find((it) => it.slot == "3") &&
            !pipeline.find((it) => it.slot == "4")
          ) {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "4",
                ratio: [16, 9],
              },
            ]);
          } else if (
            pipeline.find((it) => it.slot == "4") &&
            !pipeline.find((it) => it.slot == "1")
          ) {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "1",
                ratio: [16, 9],
              },
            ]);
          } else if (
            pipeline.find((it) => it.slot == "4") &&
            !pipeline.find((it) => it.slot == "2")
          ) {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "2",
                ratio: [16, 9],
              },
            ]);
          } else if (
            pipeline.find((it) => it.slot == "4") &&
            !pipeline.find((it) => it.slot == "3")
          ) {
            setPipeline([
              ...pipeline,
              {
                ...current,
                tileId: tileId,
                slot: "3",
                ratio: [16, 9],
              },
            ]);
          }
        } else {
          alert("You are exceeding the limit");
        }
      } else {
        setPipeline([
          {
            ...current,
            tileId: tileId,
            slot: "1",
            ratio: [16, 9],
          },
        ]);
      }
      videoRef.current?.stop();
      videoRef.current?.destroy();
    }
  };

  const goLive = async (initialStart) => {
    if (pipeline.length > 0 || initialStart) {
      const outputVideo = document
        .getElementById("mainFrame")
        ?.captureStream(25);
      const imgCanvas = document.getElementById("imgGrid")?.captureStream(25);

      ivsSDK.current = IVSBroadcastClient.create({
        // Enter the desired stream configuration
        streamConfig:
          currentGrid.name == "grid1p" ||
          currentGrid.name == "grid2p" ||
          currentGrid.name == "grid22p"
            ? {
                maxResolution: { height: 1200, width: 600 },
                maxFramerate: 25,
                maxBitrate: 2000,
              }
            : IVSBroadcastClient.BASIC_LANDSCAPE,
        // Enter the ingest endpoint from the AWS console or CreateChannel API
        ingestEndpoint: channel?.rtmp,
      });

      if (meetingData[0]?.event_status == "live" && initialStart) {
        ivsSDK.current.addVideoInputDevice(imgCanvas, "camera1", { index: 0 });
      } else {
        ivsSDK.current.addVideoInputDevice(outputVideo, "camera1", {
          index: 0,
        });
      }

      if (muted) {
        const audioStream = await audioVideo.getCurrentMeetingAudioStream();
        ivsSDK.current.addAudioInputDevice(audioStream, "mic1");
      } else {
        const producerAudioStream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: {
            deviceId: selectedDevice,
          },
        });

        const audioStream = await audioVideo.getCurrentMeetingAudioStream();

        var OutgoingAudioMediaStream = new MediaStream();
        OutgoingAudioMediaStream.addTrack(
          producerAudioStream.getAudioTracks()[0]
        );

        var IncomingAudioMediaStream = new MediaStream();
        IncomingAudioMediaStream.addTrack(audioStream.getAudioTracks()[0]);

        const audioContext = new AudioContext();

        var audioIn_01 = audioContext.createMediaStreamSource(
          OutgoingAudioMediaStream
        );
        var audioIn_02 = audioContext.createMediaStreamSource(
          IncomingAudioMediaStream
        );

        var dest = audioContext.createMediaStreamDestination();

        audioIn_01.connect(dest);
        audioIn_02.connect(dest);

        var FinalStream = dest.stream;
        ivsSDK.current.addAudioInputDevice(FinalStream, "mic1");
      }
      ivsSDK.current
        .startBroadcast(channel.key)
        .then((result) => {
          const orientation =
            currentGrid.name == "grid1p" || currentGrid.name == "grid1p"
              ? "portrait"
              : "landscape";
          onGolive(orientation);
        })
        .catch((error) => {
          console.error(
            "Something drastically failed while broadcasting!",
            error
          );
        });
      // client.stopBroadcast()
    } else {
      alert("Add atleast one attendee");
    }
  };

  const toggleAttendee = (checked, tileId) => {
    if (checked) {
      onSaveSettingToggle(tileId);
    } else {
      const data = [...pipeline].filter((it) => it.tileId != tileId);
      if (data.length > 0) {
        setPipeline(data);
      } else {
        setPipeline(["remove"]);
      }
      setCurrent({
        brightness: 100,
        ratio: [16, 9],
        tileId: "",
        grayscale: 0,
        hue: 0,
        saturate: 100,
        blur: 0,
        contrast: 100,
      });
      setAspectRatio("16:9");
      setBrightness(100);
      setContrast(100);
      setSaturation(100);
      setBlur(0);
      setHue(0);
      setSlotDrop("null");
      setGrayscale(0);
      setSubmit(false);
      setShowSettingModal(false);
    }
  };

  function renderTopVideos() {
    const tileCombine = ["local", ...tiles];

    return tileCombine.map((it, i) => {
      if (it == "local") {
        return (
          <div key={i} className="producer-input-info">
            <div className="producer-video-thumb-main dimension-fix">
              <LocalVideo id={`v${it}`} />
            </div>
            <div className="producer-video-data">
              <div className="producer-title">Me</div>
              <div className="producer-input-control">
                <Switch
                  checkedChildren="On"
                  checked={
                    pipeline.find((itSub) => itSub.tileId == it) ? true : false
                  }
                  unCheckedChildren="Off"
                  onChange={(checked) => toggleAttendee(checked, it)}
                  className="switch-toggle-color mb-1"
                />
                <button
                  className="producer-btn default-btn small-btn"
                  onClick={() => {
                    setCurrent((pre) => {
                      return {
                        ...pre,
                        tileId: it,
                      };
                    });
                    setShowSettingModal(true);
                    setTimeout(() => {
                      if (videoRef.current?.stop) {
                        videoRef.current.stop();
                        videoRef.current.destroy();
                      }
                      const currentData = pipeline.filter(
                        (itSub) => itSub.tileId == it
                      )[0];

                      if (currentData) {
                        // alert(JSON.stringify(currentData))
                        setCurrent({ ...currentData, tileId: it });
                        setSlotDrop(currentData.slot);
                        setAspectRatio(
                          `${currentData.ratio[0]}:${currentData.ratio[1]}`
                        );
                        setBrightness(currentData.brightness);
                        setContrast(currentData.contrast);
                        setSaturation(currentData.saturate);
                        setGrayscale(currentData.grayscale);
                        setBlur(currentData.blur);
                        setHue(currentData.hue);
                        const video = document
                          .getElementById(`v${it}`)
                          .firstChild.captureStream(25);
                        const outputVideo = document.getElementById("video1");
                        videoRef.current = new VideoStreamMerger({
                          width: currentData.ratio[0] * 100,
                          height: currentData.ratio[1] * 100,
                        });
                        const videoElement = {
                          videoWidth: video.getTracks()[0].getSettings().width,
                          videoHeight: video.getTracks()[0].getSettings()
                            .height,
                        };
                        const width = videoRef.current.width;
                        const height = videoRef.current.height;
                        const aspectRatio = width / height;

                        let newWidth;
                        let newHeight;

                        const imageRatio =
                          videoElement.videoWidth / videoElement.videoHeight;

                        if (aspectRatio >= imageRatio) {
                          newWidth = videoElement.videoWidth;
                          newHeight = videoElement.videoWidth / aspectRatio;
                        } else {
                          newWidth = videoElement.videoHeight * aspectRatio;
                          newHeight = videoElement.videoHeight;
                        }
                        var x = (videoElement.videoWidth - newWidth) / 2;
                        var y = (videoElement.videoHeight - newHeight) / 2;
                        videoRef.current.addStream(video, {
                          x: 0,
                          y: 0,
                          width: videoRef.current.width,
                          height: videoRef.current.height,
                          draw: (ctx, frame, done) => {
                            ctx.scale(-1, 1);
                            ctx.translate(-videoRef.current.width, 0);
                            ctx.filter = `brightness(${
                              currentData
                                ? currentData.brightness
                                : current.brightness
                            }%) contrast(${
                              currentData
                                ? currentData.contrast
                                : current.contrast
                            }%) grayscale(${
                              currentData
                                ? currentData.grayscale
                                : current.grayscale
                            }%) saturate(${
                              currentData
                                ? currentData.saturate
                                : current.saturate
                            }%) hue-rotate(${
                              currentData ? currentData.hue : current.hue
                            }deg) blur(${
                              currentData ? currentData.blur : current.blur
                            }px)`;
                            ctx.drawImage(
                              frame,
                              x,
                              y,
                              newWidth,
                              newHeight,
                              0,
                              0,
                              videoRef.current.width,
                              videoRef.current.height
                            );
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                            done();
                          },
                        });
                        videoRef.current.start();
                        outputVideo.srcObject = videoRef.current.result;
                        outputVideo.play();
                      } else {
                        const video = document
                          .getElementById(`v${it}`)
                          ?.firstChild.captureStream(25);
                        const outputVideo = document.getElementById("video1");
                        videoRef.current = new VideoStreamMerger({
                          width: current.ratio[0] * 100,
                          height: current.ratio[1] * 100,
                        });

                        const videoElement = {
                          videoWidth: video.getTracks()[0].getSettings().width,
                          videoHeight: video.getTracks()[0].getSettings()
                            .height,
                        };
                        const width = videoRef.current.width;
                        const height = videoRef.current.height;
                        const aspectRatio = width / height;

                        let newWidth;
                        let newHeight;

                        const imageRatio =
                          videoElement.videoWidth / videoElement.videoHeight;

                        if (aspectRatio >= imageRatio) {
                          newWidth = videoElement.videoWidth;
                          newHeight = videoElement.videoWidth / aspectRatio;
                        } else {
                          newWidth = videoElement.videoHeight * aspectRatio;
                          newHeight = videoElement.videoHeight;
                        }
                        var x = (videoElement.videoWidth - newWidth) / 2;
                        var y = (videoElement.videoHeight - newHeight) / 2;

                        videoRef.current.addStream(video, {
                          x: 0,
                          y: 0,
                          width: videoRef.current.width,
                          height: videoRef.current.height,
                          draw: (ctx, frame, done) => {
                            ctx.scale(-1, 1);
                            ctx.translate(-videoRef.current.width, 0);
                            ctx.filter = `brightness(${
                              currentData
                                ? currentData.brightness
                                : current.brightness
                            }%) contrast(${
                              currentData
                                ? currentData.contrast
                                : current.contrast
                            }%) grayscale(${
                              currentData
                                ? currentData.grayscale
                                : current.grayscale
                            }%) saturate(${
                              currentData
                                ? currentData.saturate
                                : current.saturate
                            }%) hue-rotate(${
                              currentData ? currentData.hue : current.hue
                            }deg) blur(${
                              currentData ? currentData.blur : current.blur
                            }px)`;
                            ctx.drawImage(
                              frame,
                              x,
                              y,
                              newWidth,
                              newHeight,
                              0,
                              0,
                              videoRef.current.width,
                              videoRef.current.height
                            );
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                            done();
                          },
                        });
                        videoRef.current.start();
                        outputVideo.srcObject = videoRef.current.result;
                        outputVideo.play();
                      }
                    }, 200);
                  }}
                >
                  {/* <FontAwesomeIcon icon={faPlus} /> */}
                  Setting
                </button>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div key={i} className="producer-input-info">
            <div className="producer-video-thumb-main dimension-fix">
              <RemoteVideo tileId={it} id={`v${it}`} />
            </div>
            <div className="producer-video-data">
              <div className="producer-title">{`Host ${i + 1}`}</div>
              <div className="producer-input-control">
                <Switch
                  checkedChildren="On"
                  checked={
                    pipeline.find((itSub) => itSub.tileId == it) ? true : false
                  }
                  unCheckedChildren="Off"
                  onChange={(checked) => toggleAttendee(checked, it)}
                  className="switch-toggle-color mb-1"
                />
                <button
                  className="producer-btn default-btn small-btn"
                  onClick={() => {
                    setCurrent((pre) => {
                      return {
                        ...pre,
                        tileId: it,
                      };
                    });
                    setShowSettingModal(true);
                    setTimeout(() => {
                      if (videoRef.current?.stop) {
                        videoRef.current.stop();
                        videoRef.current.destroy();
                      }
                      const currentData = pipeline.filter(
                        (itSub) => itSub.tileId == it
                      )[0];

                      if (currentData) {
                        // alert(JSON.stringify(currentData))
                        setCurrent({ ...currentData, tileId: it });
                        setSlotDrop(currentData.slot);
                        setAspectRatio(
                          `${currentData.ratio[0]}:${currentData.ratio[1]}`
                        );
                        setBrightness(currentData.brightness);
                        setContrast(currentData.contrast);
                        setSaturation(currentData.saturate);
                        setGrayscale(currentData.grayscale);
                        setBlur(currentData.blur);
                        setHue(currentData.hue);
                        const video = document
                          .getElementById(`v${it}`)
                          ?.firstChild.captureStream(25);
                        const outputVideo = document.getElementById("video1");
                        videoRef.current = new VideoStreamMerger({
                          width: currentData.ratio[0] * 100,
                          height: currentData.ratio[1] * 100,
                        });
                        const videoElement = {
                          videoWidth: video.getTracks()[0].getSettings().width,
                          videoHeight: video.getTracks()[0].getSettings()
                            .height,
                        };
                        const width = videoRef.current.width;
                        const height = videoRef.current.height;
                        const aspectRatio = width / height;

                        let newWidth;
                        let newHeight;

                        const imageRatio =
                          videoElement.videoWidth / videoElement.videoHeight;

                        if (aspectRatio >= imageRatio) {
                          newWidth = videoElement.videoWidth;
                          newHeight = videoElement.videoWidth / aspectRatio;
                        } else {
                          newWidth = videoElement.videoHeight * aspectRatio;
                          newHeight = videoElement.videoHeight;
                        }
                        var x = (videoElement.videoWidth - newWidth) / 2;
                        var y = (videoElement.videoHeight - newHeight) / 2;
                        videoRef.current.addStream(video, {
                          x: 0,
                          y: 0,
                          width: videoRef.current.width,
                          height: videoRef.current.height,
                          draw: (ctx, frame, done) => {
                            ctx.filter = `brightness(${
                              currentData
                                ? currentData.brightness
                                : current.brightness
                            }%) contrast(${
                              currentData
                                ? currentData.contrast
                                : current.contrast
                            }%) grayscale(${
                              currentData
                                ? currentData.grayscale
                                : current.grayscale
                            }%) saturate(${
                              currentData
                                ? currentData.saturate
                                : current.saturate
                            }%) hue-rotate(${
                              currentData ? currentData.hue : current.hue
                            }deg) blur(${
                              currentData ? currentData.blur : current.blur
                            }px)`;
                            ctx.drawImage(
                              frame,
                              x,
                              y,
                              newWidth,
                              newHeight,
                              0,
                              0,
                              videoRef.current.width,
                              videoRef.current.height
                            );
                            done();
                          },
                        });
                        videoRef.current.start();
                        outputVideo.srcObject = videoRef.current.result;
                        outputVideo.play();
                      } else {
                        const video = document
                          .getElementById(`v${it}`)
                          ?.firstChild.captureStream(25);
                        const outputVideo = document.getElementById("video1");
                        videoRef.current = new VideoStreamMerger({
                          width: current.ratio[0] * 100,
                          height: current.ratio[1] * 100,
                        });
                       
                        const videoElement = {
                          videoWidth: video.getTracks()[0].getSettings().width,
                          videoHeight: video.getTracks()[0].getSettings()
                            .height,
                        };
                        const width = videoRef.current.width;
                        const height = videoRef.current.height;
                        const aspectRatio = width / height;

                        let newWidth;
                        let newHeight;

                        const imageRatio =
                          videoElement.videoWidth / videoElement.videoHeight;

                        if (aspectRatio >= imageRatio) {
                          newWidth = videoElement.videoWidth;
                          newHeight = videoElement.videoWidth / aspectRatio;
                        } else {
                          newWidth = videoElement.videoHeight * aspectRatio;
                          newHeight = videoElement.videoHeight;
                        }
                        var x = (videoElement.videoWidth - newWidth) / 2;
                        var y = (videoElement.videoHeight - newHeight) / 2;

                        videoRef.current.addStream(video, {
                          x: 0,
                          y: 0,
                          width: videoRef.current.width,
                          height: videoRef.current.height,
                          draw: (ctx, frame, done) => {
                            ctx.filter = `brightness(${
                              currentData
                                ? currentData.brightness
                                : current.brightness
                            }%) contrast(${
                              currentData
                                ? currentData.contrast
                                : current.contrast
                            }%) grayscale(${
                              currentData
                                ? currentData.grayscale
                                : current.grayscale
                            }%) saturate(${
                              currentData
                                ? currentData.saturate
                                : current.saturate
                            }%) hue-rotate(${
                              currentData ? currentData.hue : current.hue
                            }deg) blur(${
                              currentData ? currentData.blur : current.blur
                            }px)`;
                            ctx.drawImage(
                              frame,
                              x,
                              y,
                              newWidth,
                              newHeight,
                              0,
                              0,
                              videoRef.current.width,
                              videoRef.current.height
                            );
                            done();
                          },
                        });
                        videoRef.current.start();
                        outputVideo.srcObject = videoRef.current.result;
                        outputVideo.play();
                      }
                    }, 200);
                  }}
                >
                  Setting
                </button>
              </div>
            </div>
          </div>
        );
      }
    });
  }

  function getSlot() {
    if (currentGrid.name == "grid1") {
      return ["null", "1"];
    }
    switch (currentGrid.name) {
      case "grid1":
        return ["null", "1"];
        break;
      case "grid1p":
        return ["null", "1"];
        break;
      case "grid2":
        return ["null", "1", "2"];
        break;
      case "grid2p":
        return ["null", "1", "2"];
        break;
      case "grid22p":
        return ["null", "1", "2"];
        break;
      case "grid22":
        return ["null", "1", "2"];
        break;
      case "grid3":
        return ["null", "1", "2", "3"];
        break;
      case "grid32":
        return ["null", "1", "2", "3"];
        break;
      case "grid4":
        return ["null", "1", "2", "3", "4"];
        break;
    }
  }

  function getAspectRatioLabel() {
    if (currentGrid.name == "grid1") {
      return [
        {
          value: "6:9",
          label: "6:9",
        },
        {
          value: "8:9",
          label: "8:9",
        },
        {
          value: "10:9",
          label: "10:9",
        },
        {
          value: "16:9",
          label: "16:9 (Auto fill)",
        },
      ];
    } else if (currentGrid.name == "grid1p") {
      return [
        {
          value: "6:9",
          label: "6:9",
        },
        {
          value: "8:9",
          label: "8:9",
        },
        {
          value: "6:12",
          label: "9:16 (Auto fill)",
        },
        {
          value: "10:9",
          label: "10:9",
        },
        {
          value: "16:9",
          label: "16:9",
        },
      ];
    } else if (currentGrid.name == "grid2") {
      return [
        {
          value: "6:9",
          label: "6:9",
        },
        {
          value: "8:9",
          label: "8:9 (Auto fill)",
        },
        {
          value: "10:9",
          label: "10:9",
        },
        {
          value: "16:9",
          label: "16:9",
        },
      ];
    } else if (currentGrid.name == "grid2p") {
      return [
        {
          value: "6:9",
          label: "6:9",
        },
        {
          value: "8:9",
          label: "8:9",
        },
        {
          value: "6:6",
          label: "9:8 (Auto fill)",
        },
        {
          value: "10:9",
          label: "10:9",
        },
        {
          value: "16:9",
          label: "16:9",
        },
      ];
    } else if (currentGrid.name == "grid22p") {
      return [
        {
          value: "6:9",
          label: "6:9",
        },
        {
          value: "8:9",
          label: "8:9",
        },
        {
          value: "6:12",
          label: "9:16 (Auto fill)",
        },
        {
          value: "10:9",
          label: "10:9",
        },
        {
          value: "16:9",
          label: "16:9",
        },
      ];
    } else if (currentGrid.name == "grid22") {
      if (slotDrop == 1) {
        return [
          {
            value: "6:9",
            label: "6:9",
          },
          {
            value: "8:9",
            label: "8:9",
          },
          {
            value: "10:9",
            label: "10:9 (Auto fill)",
          },
          {
            value: "16:9",
            label: "16:9",
          },
        ];
      } else {
        return [
          {
            value: "6:9",
            label: "6:9 (Auto fill)",
          },
          {
            value: "8:9",
            label: "8:9",
          },
          {
            value: "10:9",
            label: "10:9",
          },
          {
            value: "16:9",
            label: "16:9",
          },
        ];
      }
    } else if (currentGrid.name == "grid3") {
      if (slotDrop == 1) {
        return [
          {
            value: "6:9",
            label: "6:9",
          },
          {
            value: "8:9",
            label: "8:9 (Auto fill)",
          },
          {
            value: "10:9",
            label: "10:9",
          },
          {
            value: "16:9",
            label: "16:9",
          },
        ];
      } else {
        return [
          {
            value: "6:9",
            label: "6:9",
          },
          {
            value: "8:9",
            label: "8:9",
          },
          {
            value: "10:9",
            label: "10:9",
          },
          {
            value: "16:9",
            label: "16:9 (Auto fill)",
          },
        ];
      }
    } else if (currentGrid.name == "grid4") {
      return [
        {
          value: "6:9",
          label: "6:9",
        },
        {
          value: "8:9",
          label: "8:9",
        },
        {
          value: "10:9",
          label: "10:9",
        },
        {
          value: "16:9",
          label: "16:9 (Auto fill)",
        },
      ];
    }
  }
  function getGridOrientation() {
    return orientation == "Portrait" ? pgrids : lgrids;
  }
  return (
    <>
      <UserActivityProvider>
        <div className="producer-input-box">{renderTopVideos()}</div>
        <div className={`host-box-wrapper`}>
          <div className="control-grid-views-left">
            <div
              className={`video-box localvideosingle video-frame-border position-relative ${
                currentGrid.name == "grid1p" ||
                currentGrid.name == "grid2p" ||
                currentGrid.name == "grid22p"
                  ? "ratio-6"
                  : "ratio-16"
              } ${
                tiles?.length > 0 && isVideoEnabled ? "ratio-4-multiview" : ""
              } `}
              ref={gridRef}
            >
              <div style={{ position: "absolute", width: "100%" }}>
                <canvas
                  style={{ width: "100%" }}
                  id="imgGrid"
                  ref={canvasRef}
                />
              </div>

              {(meetingData[0]?.event_status == "live"
                ? meetingData[0]?.event_status
                : live) == "live" && (
                <div style={{ position: "absolute", zIndex: 10 }}>
                  <div
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      padding: 5,
                    }}
                  >
                    Live
                  </div>
                </div>
              )}
              <div
                style={!host ? { height: "100%", boxSizing: "border-box" } : {}}
              >
                <div className="single-grid video-full-main row no-gutters">
                  <div className="col-12">
                    <video className="video-full " id="mainFrame"></video>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`control-grid-views-right scrollbar-style-grid`}
            style={{ height: height }}
          >
            <div className="select-orientation mb-2">
              <Select
                options={[
                  { value: "Portrait", label: "Portrait" },
                  { value: "Landscape", label: "Landscape" },
                ]}
                disabled={
                  (meetingData[0]?.event_status == "live"
                    ? meetingData[0]?.event_status
                    : live) == "live"
                }
                value={orientation}
                onChange={(e) => {
                  setOrientation(e.target.value);
                  if (e.target.value == "Portrait") {
                    setCurrentGrid({
                      img: grid1p,
                      name: "grid1p",
                    });
                  } else {
                    setCurrentGrid({
                      img: grid1,
                      name: "grid1",
                    });
                  }
                }}
              />
            </div>
            {getGridOrientation().map((it, i) => {
              return (
                <button
                  onClick={() => {
                    setCurrentGrid(it);
                  }}
                  key={i}
                  className={`grid-views view1 ${
                    orientation === "Portrait" && "gridview6"
                  }`}
                >
                  <img className="grid-img" src={it.img} alt={it.name} />
                </button>
              );
            })}
          </div>
        </div>
        {showSettingModal && (
          <Modal
            maskClosable={false}
            className="modal-generic modal-474"
            centered
            visible={showSettingModal}
            // onOk={() => {
            //   endMeeting();
            //   onConfirm();
            // }}
            onCancel={closeSettingModal}
            closeIcon={<FontAwesomeIcon icon={faXmark} />}
            footer={false}
            keyboard={false}
          >
            <div className="producer-setting">
              <h2 className="modal-hd1">Setting</h2>
              <div className="modal-video-box-main">
                <video
                  className="modal-video"
                  id="video1"
                  autoPlay
                  controls={false}
                  width={"100%"}
                ></video>
              </div>
              <div className="mt-3">
                <Label>Slot</Label>
                <Select
                  // value={JSON.stringify(ratio)}
                  options={getSlot().map((it) => {
                    return {
                      value: it,
                      label: it == "null" ? "Select slot" : it,
                    };
                  })}
                  placeholder="Select slot"
                  aria-label="Slot"
                  value={slotDrop}
                  onChange={(e) => {
                    // alert(e.target.value)
                    setSlotDrop(e.target.value);
                  }}
                />
              </div>
              {submit && slotDrop == "null" && (
                <div>
                  <small style={{ color: "red" }}>Please select</small>
                </div>
              )}
              <div>
                <Label>Aspect Ratio</Label>
                <Select
                  // value={JSON.stringify(ratio)}
                  // disabled={slotDrop == "null" ? true : false}
                  options={getAspectRatioLabel()}
                  aria-label="Aspect Ratio"
                  value={aspectRatio}
                  onChange={(e) => {
                    const d = e.target.value.split(":");
                    setAspectRatio(e.target.value);
                    setCurrent((pre) => {
                      return {
                        ...pre,
                        ratio: [Number(d[0]), Number(d[1])],
                      };
                    });
                  }}
                />
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="mt-3">
                    <Label>Brightness</Label>
                    <Row className="d-flex align-items-center">
                      <Col span={20}>
                        <Slider
                          min={0}
                          max={200}
                          value={brightness}
                          defaultValue={brightness}
                          onChange={changeBrightness}
                          onAfterChange={(v) => {
                            setCurrent((pre) => {
                              return {
                                ...pre,
                                brightness: v,
                              };
                            });
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {brightness}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mt-3">
                    <Label>Contrast</Label>
                    <Row className="d-flex align-items-center">
                      <Col span={20}>
                        <Slider
                          min={0}
                          max={200}
                          defaultValue={contrast}
                          value={contrast}
                          onChange={setContrast}
                          onAfterChange={(v) => {
                            setCurrent((pre) => {
                              return {
                                ...pre,
                                contrast: v,
                              };
                            });
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {contrast}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="mt-3">
                    <Label>Saturation</Label>
                    <Row className="d-flex align-items-center">
                      <Col span={20}>
                        <Slider
                          min={0}
                          max={200}
                          value={saturation}
                          onChange={changeSaturation}
                          onAfterChange={(v) => {
                            setCurrent((pre) => {
                              return {
                                ...pre,
                                saturate: v,
                              };
                            });
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {saturation}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mt-3">
                    <Label>Grayscale</Label>
                    <Row className="d-flex align-items-center">
                      <Col span={20}>
                        <Slider
                          defaultValue={grayscale}
                          onChange={setGrayscale}
                          value={grayscale}
                          min={0}
                          max={100}
                          onAfterChange={(v) => {
                            setCurrent((pre) => {
                              return {
                                ...pre,
                                grayscale: v,
                              };
                            });
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {grayscale}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="mt-3">
                    <Label>Blur</Label>
                    <Row className="d-flex align-items-center">
                      <Col span={20}>
                        <Slider
                          defaultValue={blur}
                          onChange={setBlur}
                          value={blur}
                          min={0}
                          max={100}
                          onAfterChange={(v) => {
                            setCurrent((pre) => {
                              return {
                                ...pre,
                                blur: v,
                              };
                            });
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {blur}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mt-3">
                    <Label>Hue</Label>
                    <Row className="d-flex align-items-center">
                      <Col span={20}>
                        <Slider
                          defaultValue={hue}
                          onChange={setHue}
                          value={hue}
                          min={0}
                          max={360}
                          onAfterChange={(v) => {
                            setCurrent((pre) => {
                              return {
                                ...pre,
                                hue: v,
                              };
                            });
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {hue}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <button
                  type="button"
                  className="default-btn medium-btn padb-3"
                  onClick={() => {
                    if (slotDrop != "null") {
                      if (currentGrid.name == "grid1") {
                        if (pipeline.length <= 1) {
                          onSaveSetting();
                        } else {
                          alert("You are exceeding the limit");
                        }
                      } else if (currentGrid.name == "grid1p") {
                        if (pipeline.length <= 1) {
                          onSaveSetting();
                        } else {
                          alert("You are exceeding the limit");
                        }
                      } else if (currentGrid.name == "grid2p") {
                        if (pipeline.length <= 2) {
                          onSaveSetting();
                        } else {
                          alert("You are exceeding the limit");
                        }
                      } else if (currentGrid.name == "grid22p") {
                        if (pipeline.length <= 2) {
                          onSaveSetting();
                        } else {
                          alert("You are exceeding the limit");
                        }
                      } else if (currentGrid.name == "grid2") {
                        if (pipeline.length <= 2) {
                          onSaveSetting();
                        } else {
                          alert("You are exceeding the limit");
                        }
                      } else if (currentGrid.name == "grid22") {
                        if (pipeline.length <= 2) {
                          onSaveSetting();
                        } else {
                          alert("You are exceeding the limit");
                        }
                      } else if (currentGrid.name == "grid3") {
                        if (pipeline.length <= 3) {
                          onSaveSetting();
                        } else {
                          alert("You are exceeding the limit");
                        }
                      } else if (currentGrid.name == "grid4") {
                        if (pipeline.length <= 4) {
                          onSaveSetting();
                        } else {
                          alert("You are exceeding the limit");
                        }
                      }
                    } else {
                      setSubmit(true);
                    }
                  }}
                >
                  <span>
                    {pipeline.find((it) => it.slot == slotDrop)
                      ? "Update"
                      : "Add"}
                  </span>
                </button>
                {pipeline.find((it) => it.tileId == current.tileId) && (
                  <button
                    onClick={() => {
                      const data = [...pipeline].filter(
                        (it) => it.tileId != current.tileId
                      );
                      if (data.length > 0) {
                        setPipeline(data);
                      } else {
                        setPipeline(["remove"]);
                      }
                      setCurrent({
                        brightness: 100,
                        ratio: [16, 9],
                        tileId: "",
                        grayscale: 0,
                        hue: 0,
                        saturate: 100,
                        blur: 0,
                        contrast: 100,
                      });
                      setAspectRatio("16:9");
                      setBrightness(100);
                      setContrast(100);
                      setSaturation(100);
                      setBlur(0);
                      setHue(0);
                      setSlotDrop("null");
                      setGrayscale(0);
                      setSubmit(false);
                      setShowSettingModal(false);
                    }}
                    type="button"
                    className="default-btn medium-btn padb-3"
                  >
                    <span>Remove</span>
                  </button>
                )}
                <button
                  onClick={() => {
                    setBrightness(100);
                    setGrayscale(0);
                    setHue(0);
                    setBlur(0);
                    setContrast(100);
                    setSaturation(100);
                    setAspectRatio("16:9");
                    setSlotDrop("null");
                    setCurrent((pre) => {
                      return {
                        ...pre,
                        brightness: 100,
                        ratio: [16, 9],
                        grayscale: 0,
                        hue: 0,
                        saturate: 100,
                        blur: 0,
                        contrast: 100,
                      };
                    });
                  }}
                  type="button"
                  className="default-btn medium-btn padb-3"
                >
                  <span>Reset</span>
                </button>
                <button
                  onClick={() => setShowSettingModal(false)}
                  type="button"
                  className="default-btn medium-btn padb-3"
                >
                  <span>Exit</span>
                </button>
              </div>
            </div>
          </Modal>
        )}
        <Control
          ratio={ratio}
          onLocalVideo={() => toggleAttendee(true, "local")}
          setRatio={setRatio}
          setLeaveMet={setLeaveMet}
          live={
            meetingData[0]?.event_status == "live"
              ? meetingData[0]?.event_status
              : live
          }
          onGolive={onGolive}
          host={host}
          leaveMeeting={leaveMeeting}
          stopLiveEvent={stopLiveEvent}
          endMeeting={() => {
            if (ivsSDK.current?.stopBroadcast) ivsSDK.current.stopBroadcast();
            endMeeting();
          }}
          meetingData={meetingData}
          goLive={goLive}
        />

        {!endMeetingFlag &&
          meetingStatus != 3 &&
          !leavemet &&
          (meetingData[0]?.event_status == "live"
            ? meetingData[0]?.event_status
            : live) == "live" && (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ReactRouterPrompt when={meetingData}>
                {({ isActive, onConfirm, onCancel }) =>
                  isActive && (
                    <Modal
                      maskClosable={false}
                      className="modal-generic modal-500"
                      centered
                      visible={isActive}
                      onOk={() => {
                        endMeeting();
                        onConfirm();
                      }}
                      onCancel={onCancel}
                      closeIcon={<FontAwesomeIcon icon={faXmark} />}
                      footer={[
                        <Button type="primary" onClick={onCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          // onClick={handleOk}
                          onClick={() => {
                            endMeeting ? endMeeting() : meetingManager.leave();
                            onConfirm();
                          }}
                        >
                          Ok
                        </Button>,
                      ]}
                    >
                      <h2 className="modal-hd1">Warning</h2>
                      <h5>
                        {host
                          ? "Are you sure you want to leave event?"
                          : "Are you sure you want to end event?"}
                      </h5>
                    </Modal>
                    // <div className="lightbox">
                    //   <div className="container">
                    //     <p>Do you really want to leave?</p>
                    //     <button onClick={onCancel}>Cancel</button>
                    //     <button onClick={onConfirm}>Ok</button>
                    //   </div>
                    // </div>
                  )
                }
              </ReactRouterPrompt>
            </ErrorBoundary>
          )}
        {(meetingData[0]?.event_status == "live"
          ? meetingData[0]?.event_status
          : live) != "live" &&
          meetingStatus != 3 && (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ReactRouterPrompt when={meetingData}>
                {({ isActive, onConfirm, onCancel }) =>
                  isActive && (
                    <Modal
                      maskClosable={false}
                      className="modal-generic modal-500"
                      centered
                      visible={isActive}
                      onOk={() => {
                        endMeeting();
                        onConfirm();
                      }}
                      onCancel={onCancel}
                      closeIcon={<FontAwesomeIcon icon={faXmark} />}
                      footer={[
                        <Button type="primary" onClick={onCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          // onClick={handleOk}
                          onClick={() => {
                            endMeeting ? endMeeting() : meetingManager.leave();
                            // endMeeting()
                            onConfirm();
                          }}
                        >
                          Ok
                        </Button>,
                      ]}
                    >
                      <h2 className="modal-hd1">Warning</h2>
                      <h5>
                        {!host
                          ? "Do you want to exit the event"
                          : "Do you want to leave this event"}
                      </h5>
                    </Modal>
                    // <div className="lightbox">
                    //   <div className="container">
                    //     <p>Do you really want to leave?</p>
                    //     <button onClick={onCancel}>Cancel</button>
                    //     <button onClick={onConfirm}>Ok</button>
                    //   </div>
                    // </div>
                  )
                }
              </ReactRouterPrompt>
            </ErrorBoundary>
          )}
      </UserActivityProvider>
    </>
  );
};

const Root = ({
  meetingData,
  host,
  endMeeting,
  onGolive,
  live,
  checkEvent,
  endMeetingFlag,
  channel,
  stopLiveEvent,
}) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <NotificationProvider>
        <Notifications />
        <BackgroundBlurProvider>
          <MeetingProvider>
            <NavigationProvider>
              <AudioVideoProvider>
                <MyApp
                  channel={channel}
                  checkEvent={checkEvent}
                  meetingData={meetingData}
                  endMeetingFlag={endMeetingFlag}
                  onGolive={onGolive}
                  host={host}
                  endMeeting={endMeeting}
                  live={live}
                  stopLiveEvent={stopLiveEvent}
                />
              </AudioVideoProvider>
            </NavigationProvider>
          </MeetingProvider>
        </BackgroundBlurProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default Root;
