import { SC_GET_ONE_ORDER, SC_GET_ONE_ORDER_SUCCESS, SC_GET_ONE_ORDER_ERROR } from "../../types/types";


export default function scGetOneOrderDetail(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case SC_GET_ONE_ORDER:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case SC_GET_ONE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case SC_GET_ONE_ORDER_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}
