import { SOCIAL_MEDIA_FOLLOWERS } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
export const getSocialFollowers = (user) => (dispatch) => {
	return axios({
		method: "POST",
		url: `${BASEURL}public/graph/ig/user`,
		headers: {
			Accept: "application/json",
		},
		data: {
			user
		},
	})
		.then((response) => {
			return dispatch({
				type: SOCIAL_MEDIA_FOLLOWERS,
				payload: response.data,
			});
			// return response.data
		})
		.catch((error) => {
			return error
		});
};