import { useState, useRef, useEffect } from "react";
import IVSBroadcastClient from "amazon-ivs-web-broadcast";
import { Button, Spin, Select, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideoSlash,
  faVideo,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { ErrorBoundary } from "react-error-boundary";
import ReactRouterPrompt from "react-router-prompt";
import "../../css/singleScreen.scss";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

// const ingestEndpoint = "rtmps://a88b955548c7.global-contribute.live-video.net";
// const streamKey = "sk_us-east-1_Iir4W1pNp2HC_9Y2Dru21D6DyRenrzy5x64cf3y1VVA";

function SingleScreen({ channel, onGolive, endSingleMeeting, setCheckCamera }) {
  const history = useNavigate();

  const [muted, setMuted] = useState(false);
  const [enableVideo, setEnableVideo] = useState(false);
  const [isBroadcasting, setIsBroadcasting] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const clientRef = useRef(null);
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [audioDeviceIndex, setAudioDeviceIndex] = useState(0);
  const [videoDeviceIndex, setVideoDeviceIndex] = useState(0);
  const [orientation, setOrientation] = useState("Landscape");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return async () => {
      if (clientRef.current) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        window.videoDevices = devices.filter((d) => d.kind === "videoinput");
        window.audioDevices = devices.filter((d) => d.kind === "audioinput");
        if (isBroadcasting) {
          clientRef.current.stopBroadcast();
          clientRef.current = null;
        }
        clientRef.current.removeVideoInputDevice(window?.selectedVideoDeviceId);
        clientRef.current.removeAudioInputDevice(window?.selectedAudioDeviceId);
      }
    };
  }, []);

  const init = async () => {
    const client = IVSBroadcastClient.create({
      streamConfig: IVSBroadcastClient.STANDARD_LANDSCAPE,
      // streamConfig: IVSBroadcastClient.BASIC_PORTRAIT,
      ingestEndpoint: channel?.rtmp,
    });
    clientRef.current = client;

    await handlePermissions();
    await getDevices();
    await createVideoStream(orientation);
    await createAudioStream();

    previewVideo();
  };

  const getDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    window.videoDevices = devices.filter((d) => d.kind === "videoinput");
    window.audioDevices = devices.filter((d) => d.kind === "audioinput");
    setVideoDevices(window.videoDevices);
    setAudioDevices(window.audioDevices);
  };

  const previewVideo = () => {
    const previewEl = document.getElementById("broadcast-preview");
    clientRef.current.attachPreview(previewEl);
  };

  const createVideoStream = async (screen) => {
    if (!videoDevices.length) return;

    if (
      clientRef.current &&
      clientRef.current.getVideoInputDevice(window.selectedVideoDeviceId)
    )
      clientRef.current.removeVideoInputDevice(window.selectedVideoDeviceId);

    const streamConfig =
      screen === "Landscape" || screen === undefined
        ? IVSBroadcastClient?.STANDARD_LANDSCAPE
        : IVSBroadcastClient?.BASIC_PORTRAIT;
    // const streamConfig = IVSBroadcastClient.BASIC_PORTRAIT;

    window.videoStream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: { exact: window.selectedVideoDeviceId },
        // deviceId: videoDevices[videoDeviceIndex].deviceId,
        width: {
          ideal: streamConfig.maxResolution.width,
          max: streamConfig.maxResolution.width,
        },
        height: {
          ideal: streamConfig.maxResolution.height,
          max: streamConfig.maxResolution.height,
        },
      },
    });
    if (clientRef.current)
      clientRef.current.addVideoInputDevice(
        window.videoStream,
        window.selectedVideoDeviceId,
        { index: 0 }
      );
  };

  const createAudioStream = async () => {
    if (!audioDevices.length) return;

    if (
      clientRef.current &&
      clientRef.current.getAudioInputDevice(window.selectedAudioDeviceId)
    )
      clientRef.current.removeAudioInputDevice(window.selectedAudioDeviceId);
    window.audioStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: window.selectedAudioDeviceId,
      },
    });
    if (clientRef.current)
      clientRef.current.addAudioInputDevice(
        window.audioStream,
        window.selectedAudioDeviceId
      );
  };

  const selectCamera = async (e, options) => {
    setVideoDeviceIndex(parseInt(options.key));
    clientRef?.current?.removeVideoInputDevice(window?.selectedVideoDeviceId);
    window.selectedVideoDeviceId = options.value;
    await createVideoStream(orientation);
  };

  const selectMic = async (e, options) => {
    setAudioDeviceIndex(parseInt(options.key));
    window.selectedAudioDeviceId = options.value;
    await createAudioStream();
  };

  useEffect(() => {
    const reInit = async () => {
      if (videoDevices.length) {
        window.selectedVideoDeviceId = videoDevices[videoDeviceIndex].deviceId;
        window.selectedAudioDeviceId = audioDevices[audioDeviceIndex].deviceId;

        await createVideoStream(orientation);
        await createAudioStream();

        previewVideo();
      }
    };

    reInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioDevices, videoDevices]);

  const handlePermissions = async () => {
    let permissions = {
      audio: false,
      video: false,
    };
    setLoading(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      for (const track of stream.getTracks()) {
        track.stop();
      }
      permissions = { video: true, audio: true };
      setLoading(false);
      setCameraEnabled(true);
      setCheckCamera(true);
    } catch (err) {
      setLoading(false);
      permissions = { video: false, audio: false };
      console.error(err.message);
    }
    // If we still don"t have permissions after requesting them display the error message
    if (!permissions.video) {
      console.error("Failed to get video permissions.");
    } else if (!permissions.audio) {
      console.error("Failed to get audio permissions.");
    }
  };

  const startBroadcast = async () => {
    if (!cameraEnabled || !clientRef.current) await init();
    setIsConnecting(true);
    clientRef.current
      // .startBroadcast(streamKey)
      .startBroadcast(channel?.key)
      .then((result) => {
        const data = orientation === "Portrait" ? "portrait" : "landscape";
        onGolive(data);
        setIsBroadcasting(true);
        setIsConnecting(false);
      })
      .catch((error) => {
        stopBroadcast();
        setIsConnecting(false);
        console.error(
          "Something drastically failed while broadcasting!",
          error
        );
      });
  };

  const stopBroadcast = () => {
    if (clientRef.current) {
      setIsConnecting(true);
      clientRef.current.stopBroadcast();
      // clientRef.current = null;

      setIsBroadcasting(false);
      endSingleMeeting();

      // notification.success({
      //   message: "Event End Successfully!!",
      //   className: "toast-success",
      // });

      setTimeout(() => {
        clientRef.current.removeVideoInputDevice(window?.selectedVideoDeviceId);
        clientRef.current.removeAudioInputDevice(window?.selectedAudioDeviceId);
      }, 1000);
    }
  };

  const onMute = () => {
    if (!clientRef.current) return;
    let audioStream = clientRef.current.getAudioInputDevice(
      audioDevices[audioDeviceIndex].deviceId
    );
    audioStream.getAudioTracks()[0].enabled = muted;
    setMuted((prev) => !prev);
  };

  const onOrientationChange = async (e) => {
    setOrientation(e);
    await createVideoStream(e);
    // if (e == "Portrait") {
    //   await createVideoStream("Portrait");
    // } else {
    //   await createVideoStream("Landscape");
    // }
  };

  const onHideVideo = async () => {
    if (!clientRef.current) return;

    if (!enableVideo) {
      ///video On
      clientRef?.current?.removeVideoInputDevice(window.selectedVideoDeviceId);
    } else {
      ///video Off
      await createVideoStream(orientation);
    }
    setEnableVideo((prev) => !prev);
  };

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }

  return (
    <>
      {!loading ? (
        !cameraEnabled ? (
          <div
            className="single-camera-enabled"
            style={
              {
                // display: cameraEnabled ? "none" : "flex",
                // display: "flex",
              }
            }
          >
            <div className="single-camera-enabled-inner">
              <FontAwesomeIcon
                className="camera-icon"
                icon={faVideoSlash}
                size="2xl"
              />
              <h3>
                To start streaming, allow access to your camera and microphone
              </h3>
            </div>
          </div>
        ) : (
          <div className="single-stream-wrapper">
            <div className="single-stream-main">
              <div className="single-stream-canvas-left">
                <div className="single-stream-canvas">
                  {isBroadcasting && (
                    <div style={{ position: "absolute", zIndex: 10 }}>
                      <div
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          padding: 5,
                        }}
                      >
                        Live
                      </div>
                    </div>
                  )}
                  <canvas
                    id="broadcast-preview"
                    className=""
                    style={
                      {
                        // objectFit: "contain",
                        // height: "100%",
                        // maxWidth: "1000px",
                        // display: cameraEnabled ? "block" : "none",
                        // display: "block",
                      }
                    }
                  ></canvas>
                </div>
              </div>
              <div className="single-stream-canvas-right">
                <div className="select-orientation mb-2 mt-2">
                  <Select
                    className="w-100 select-style dark-field"
                    placeholder="Select Orientation"
                    size="small"
                    focus={false}
                    onBlur={false}
                    value={orientation}
                    onChange={(e) => {
                      onOrientationChange(e);
                    }}
                    disabled={isBroadcasting}
                  >
                    <Option value="Portrait">Portrait</Option>
                    <Option value="Landscape">Landscape</Option>
                  </Select>
                </div>
              </div>
            </div>
            {cameraEnabled && (
              <div className="single-control-main">
                <div className="row single-control">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 mob-control">
                    <Select
                      className="w-100 select-style dark-field"
                      placeholder="Select video device"
                      size="large"
                      focus={false}
                      onBlur={false}
                      value={
                        videoDeviceIndex < 0
                          ? "Select video device"
                          : videoDevices[videoDeviceIndex]?.label
                      }
                      onChange={selectCamera}
                      disabled={isBroadcasting || !cameraEnabled}
                      // disabled={!cameraEnabled}
                    >
                      {videoDevices.map((device, index) => (
                        <Option
                          key={index}
                          value={device?.deviceId}
                          disabled={
                            device?.deviceId ===
                            videoDevices[videoDeviceIndex]?.deviceId
                          }
                        >
                          {device?.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 mob-control">
                    <Select
                      className="w-100 select-style dark-field"
                      placeholder="Select audio device"
                      size="large"
                      focus={false}
                      onBlur={false}
                      value={
                        audioDevices < 0
                          ? "Select audio device"
                          : audioDevices[audioDeviceIndex]?.label
                      }
                      onChange={selectMic}
                      disabled={isBroadcasting || !cameraEnabled}
                      // disabled={!cameraEnabled}
                    >
                      {audioDevices.map((device, index) => (
                        <Option
                          key={index}
                          value={device?.deviceId}
                          disabled={
                            device?.deviceId ===
                            audioDevices[audioDeviceIndex]?.deviceId
                          }
                        >
                          {device?.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {window.innerWidth < 767 && (
                    <div className="col-12 col-sm-6 mob-control">
                      <Select
                        className="w-100 select-style dark-field"
                        placeholder="Select Orientation"
                        size="large"
                        focus={false}
                        onBlur={false}
                        value={orientation}
                        onChange={(e) => {
                          onOrientationChange(e);
                        }}
                        disabled={isBroadcasting}
                      >
                        <Option value="Portrait">Portrait</Option>
                        <Option value="Landscape">Landscape</Option>
                      </Select>
                    </div>
                  )}
                  <div
                    className={`${
                      window.innerWidth < 767
                        ? window.innerWidth < 576
                          ? "col-12"
                          : "col-6"
                        : "col-12"
                    } col-sm-6 col-md-4 col-lg-6 d-flex align-items-center justify-content-center justify-content-md-start`}
                  >
                    <Button
                      onClick={onHideVideo}
                      // disabled={!isBroadcasting}
                      className="mr-2"
                    >
                      {enableVideo ? (
                        <FontAwesomeIcon
                          icon={faVideoSlash}
                          // size="2xl"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faVideo}
                          // size="2xl"
                        />
                      )}
                    </Button>
                    <Button
                      onClick={onMute}
                      // disabled={!isBroadcasting}
                      className="mr-2"
                    >
                      {muted ? (
                        <i className="fa fa-microphone-slash" />
                      ) : (
                        <i className="fa fa-microphone" />
                      )}
                    </Button>

                    <Button
                      onClick={isBroadcasting ? stopBroadcast : startBroadcast}
                      className="ml-0 fw-normal btn-danger"
                      disabled={!cameraEnabled}
                      loading={isConnecting}
                    >
                      {isBroadcasting
                        ? "End Live"
                        : !isConnecting
                        ? "Go Live"
                        : "Loading"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      ) : (
        <div className="single-camera-enabled">
          <div className="single-camera-enabled-inner">
            <Spin size="large" />
          </div>
        </div>
      )}
      {isBroadcasting && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ReactRouterPrompt when={isBroadcasting}>
            {({ isActive, onConfirm, onCancel }) =>
              isActive && (
                <Modal
                  maskClosable={false}
                  className="modal-generic modal-500"
                  centered
                  visible={isActive}
                  onOk={() => {
                    // endMeeting();
                    endSingleMeeting();
                    onConfirm();
                  }}
                  onCancel={onCancel}
                  closeIcon={<FontAwesomeIcon icon={faXmark} />}
                  footer={[
                    <Button type="primary" onClick={onCancel}>
                      Cancel
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      // onClick={handleOk}
                      onClick={() => {
                        // endMeeting ? endMeeting() : meetingManager.leave();
                        endSingleMeeting();
                        onConfirm();
                      }}
                    >
                      Ok
                    </Button>,
                  ]}
                >
                  <h2 className="modal-hd1">Warning</h2>
                  <h5>Are you sure you want to end event?</h5>
                </Modal>
              )
            }
          </ReactRouterPrompt>
        </ErrorBoundary>
      )}
    </>
  );
}

export default SingleScreen;
