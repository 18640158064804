import {
  GET_AFFILIATE_CONTRACT_DETAIL_REQUEST,
  GET_AFFILIATE_CONTRACT_DETAIL_SUCCESS,
  GET_AFFILIATE_CONTRACT_DETAIL_ERROR,
  GET_AFFILIATE_CARDS,
  GET_PAYMENT_METHOD,
  GET_BALANCE_REQUEST,
  SET_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';


export const getAffiliateContractDetail = () => (dispatch) => {
  dispatch({ type: GET_AFFILIATE_CONTRACT_DETAIL_REQUEST });

  return axios({
    method: 'GET',
    url: `affiliate/getcontract`,
  })
    .then((response) => {
      return dispatch({
        type: GET_AFFILIATE_CONTRACT_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_AFFILIATE_CONTRACT_DETAIL_ERROR,
        payload: error.response,
      });
    });
};


export const getAffiliateCards = () => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .post(`stripe/paymentmethods`, {})
      .then((res) => {
        resolve("success");
        dispatch({
          type: GET_AFFILIATE_CARDS,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: GET_AFFILIATE_CARDS,
          payload: [],
        });
        reject("error");
      });
  });
  return promise;
};


export const makePayment = () => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .post(`stripe/makepaymentmethod`, { redirection_url: "https://ormelive.com/brandsetup" })
      .then((res) => {
        resolve("success");
        dispatch({
          type: GET_PAYMENT_METHOD,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: GET_PAYMENT_METHOD,
          payload: [],
        });
        reject("error");
      });
  });
  return promise;
};

export const setPaymentDefault = (id) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .post(`stripe/setdefaultpaymentmethod`, {id})
      .then((res) => {
        resolve(res.data);
        dispatch({
          type: SET_PAYMENT_METHOD,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: SET_PAYMENT_METHOD,
          payload: [],
        });
        reject("error");
      });
  });
  return promise;
};

export const editPaymentMethod = (id, month, year) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .post(`stripe/updatepaymentmethod`, {id, month, year})
      .then((res) => {
        resolve(res.data);
        dispatch({
          type: UPDATE_PAYMENT_METHOD,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: UPDATE_PAYMENT_METHOD,
          payload: [],
        });
        reject("error");
      });
  });
  return promise;
};
export const deletePaymentMethod = (id) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .post(`stripe/detachpaymentmethod`, {id})
      .then((res) => {
        resolve(res.data);
        dispatch({
          type: DELETE_PAYMENT_METHOD,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: DELETE_PAYMENT_METHOD,
          payload: [],
        });
        reject("error");
      });
  });
  return promise;
};


export const showBalance = () => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .post(`deposit/getbalance`, {})
      .then((res) => {
        resolve("success");
        dispatch({
          type: GET_BALANCE_REQUEST,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: GET_BALANCE_REQUEST,
          payload: [],
        });
        reject("error");
      });
  });
  return promise;
};
