import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import "../../css/controlRoom.scss";
import {
  Select,
  Button,
  Tabs,
  Dropdown,
  Menu,
  Skeleton,
  Modal,
  Spin,
  notification,
  Tooltip,
} from "antd";
import { ChatRoom, SendMessageRequest, DeleteMessageRequest, DisconnectUserRequest } from "amazon-ivs-chat-messaging";
import ScrollToBottom, { useScrollToBottom } from "react-scroll-to-bottom";
import { useSelector, useDispatch, connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getEventsBy_Id,
  deleteEvent,
  hostCheck,
} from "../../redux/actions/event.action";
import {
  goLive,
  stopLive,
  getBroadcastClientRequest,
  startMeetingRequest,
  endMeetingRequest,
  goFbLive,
} from "../../redux/actions/broadcast.action";
import { LoadingOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  faAngleLeft,
  faXmark,
  faUserTie,
  faUser,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { ShareUrl } from "../../redux/actions/shareUrl.action";
import StartMeetingScreen from "./StartMeetingScreen";
import { CardImg } from "react-bootstrap";
import {
  useNavigate,
  UNSAFE_NavigationContext as NavigationContext,
} from "react-router-dom";
import SingleScreen from "./SingleScreen";
import MixerScreen from "./MixerScreen";
import ProducerMixerScreen from "./ProducerMixerScreen";
import Chat from "./chat";
import * as chatActions from "../../redux/actions/chat";
import { getChat, getChatLog } from "../../redux/actions/chat";
import { visitorURL } from "../../config";

const { TabPane } = Tabs;

const { confirm } = Modal;

function ControlRoom({ createChatToken }) {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [spinner, setSpin] = useState(true);
  const [cameraPreview, setCameraPreview] = useState(false);
  const [startbroadcast, setStartBroadcast] = useState(false);
  const [dateTime, setDate] = useState("");
  const [sku, setSku] = useState([]);
  const [banner, setBanner] = useState("");
  const [title, setTitle] = useState("");
  const [liveData, setLiveData] = useState("");
  const [modal2Visible, settingsModal] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const [eventEnded, setEventEnded] = useState(false);
  const [mirror, setMirror] = useState(false);
  const [mute, setMute] = useState(false);
  const [error, setError] = useState("");
  const [endSession, setEndSession] = useState(false);
  const [endLiveLoading, setEndLiveLoading] = useState(false);
  const [settingPreview, setSettingPreview] = useState(false);
  const [videoDevices, setVideoDevices] = useState();
  const [audioDevices, setAudioDevices] = useState();
  const [audioOutputDevices, setAudioOutputDevices] = useState();
  const [screenView, setScreenView] = useState("landscape");
  const [mirrorVideo, setMirrorVideo] = useState(true);
  const [videoDeviceID, setVideoDeviceID] = useState("");
  const [audioMicrophoneID, setAudioMicrophoneID] = useState("");
  const [controlData, setControlData] = useState("");
  const [meeting, setMeeting] = useState(false);
  const [meetingData, setMeetingData] = useState("");
  const [producer, setProducer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [urlModal, setUrlModal] = useState(false);
  const [endMeetingFlag, setEndMeetingFlag] = useState(false);
  const [live, setLive] = useState("");
  const [chat, setChat] = useState([]);
  const [height, setHeight] = useState(495);
  const [startLoading, setStartLoading] = useState(false);
  const [checkCamera, setCheckCamera] = useState(false);
  const [multipleHost, setMultipleHost] = useState(true);
  const [chatRoom, setChatRoom] = useState([]);
  const [comments, setComments] = useState([]);
  const [connected, setConnected] = useState(false);


  const scrollToBottomMethod = useScrollToBottom();

  const scrollToBottom = () => {
    scrollToBottomMethod();
  };
  const { getEventById, getBroadcastClient, shareUrls, validate, myChatToken } = useSelector(
    (state) => {
      return state;
    }
  );

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: "Url Copied!",
      className: "toast-success",
    });
    setIsModalOpen(false);
    setUrlModal(false);
  };

  const shareKey = (id, status) => {
    let UrlKey = "";
    if (status === "live") {
      UrlKey = "live-event";
    } else {
      UrlKey = "upcoming-event";
    }
    let url = `${visitorURL}/${UrlKey}/${id}/${validate?.payload?.message?.username
      ? validate?.payload?.message?.username
      : validate?.payload?.message?.pixel_id
      }`;
    let data = { url: url };
    dispatch(ShareUrl(data)).then((res) => {
      if (res.payload.success) {
        setIsModalOpen(true);
      } else {
        console.log("err");
      }
    });
  };

  const params = useParams();
  const id = params.id;

  const chatRef = useRef();

  // useEffect(() => {
  //   const resizeObserver = new ResizeObserver((event) => {
  //     setHeight(event[0].contentBoxSize[0].blockSize);
  //   });

  //   if (chatRef && chatRef.current) {
  //     resizeObserver.observe(chatRef.current);
  //   }
  // }, [chatRef, meeting]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const productUrl = (url) => {
    let data = { url: url };
    dispatch(ShareUrl(data)).then((res) => {
      if (res.payload.success) {
        setUrlModal(true);
      } else {
        console.log("err");
      }
    });
  };

  useEffect(() => {
    return () => {
      dispatch(hostCheck(id, false));
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getEventsBy_Id(id, true)).then((res) => {
        if (res.payload.success) {
          setProducer(res.payload?.message[0]?.producer);
          if (res.payload?.message[0]?.meeting_info) {
            setMeetingData(res.payload?.message);
            setTimeout(() => {
              setMeeting(true);
            }, 1000);
          } else {
            setMeeting(false);
          }
          setEventEnded(res.payload?.message);
          setBanner(res.payload?.message[0]?.event?.banner);
          setDate(res.payload?.message[0]?.event?.start_date);
          setTitle(res.payload?.message[0]?.event?.title);
          setMultipleHost(res.payload?.message[0]?.event?.multiple_hosts);
          setSpin(false);
          if (!res.payload?.message[0]?.producer) {
            dispatch(hostCheck(id, true));
          }
          setTimeout(() => {
            const resizeObserver = new ResizeObserver((event) => {
              setHeight(event[0].contentBoxSize[0].blockSize);
            });

            if (chatRef && chatRef?.current) {
              resizeObserver.observe(chatRef.current);
            }
          }, 500);
        }
      });

      dispatch(getBroadcastClientRequest(id));
    }
  }, [chatRef, id]);

  useEffect(() => {
    if (meeting == "start") {
      setStartLoading(true);
      dispatch(startMeetingRequest(id)).then((res) => {
        dispatch(getEventsBy_Id(id)).then((res) => {
          if (res.payload.success) {
            setSpin(false);
            setStartLoading(false);
            setMeetingData(res.payload?.message);
            setEventEnded(res.payload?.message);
            setBanner(res.payload?.message[0]?.event?.banner);
            setDate(res.payload?.message[0]?.event?.start_date);
            setTitle(res.payload?.message[0]?.event?.title);
          }
        });
      });
    }
  }, [meeting]);

  function endMeeting(cb) {
    setEndMeetingFlag(true);
    return dispatch(endMeetingRequest(id, cb, () => dispatch(stopLive(id))));
    // client.stopBroadcast()
  }
  function onGolive(orientation) {
    dispatch(goLive(id, orientation)).then((res) => {
      createChatToken(id).then((res) => {
        console.log(res?.payload, 'res?.payload');

        if (res?.payload?.token) {
          const room = new ChatRoom({
            regionOrUrl: "us-east-1",
            tokenProvider: () => ({
              token: res?.payload.token,
              sessionExpirationTime: new Date(res?.payload.sessionExpirationTime),
              tokenExpirationTime: new Date(res?.payload.tokenExpirationTime),
            }),
          });
          setChatRoom(room);
          // Connect to the chat room
          console.log(room, 'room');
          dispatch(getChatLog(id)).then((res) => {
            // console.log(res?.payload, "ress logs");
            // work for previous messages 

            const previousMessages = [...res?.payload?.message];
            const bannedUserList = [...res?.payload?.bannedUserList];

            if (previousMessages?.length > 0) {
              setComments(previousMessages?.map((item) => {
                let banReason = null;
                if (bannedUserList?.length > 0) {
                  const messageUserId = item?.payload?.Attributes?.user_id;
                  // Check if this user_id is in the banuserlist
                  const bannedUser = bannedUserList.find(ban => ban?.user_id == messageUserId);
                  // console.log(bannedUser, 'bannedUser');
                  if (bannedUser) {
                    banReason = bannedUser.reason;
                  }
                }
                return {
                  id: item?.payload?.Id,
                  sender: {
                    userId: item?.payload?.Sender?.UserId,
                    attributes: item?.payload?.Sender?.Attributes
                  },
                  content: item?.payload?.Content,
                  sendTime: item?.payload?.sendTime,
                  attributes: item?.payload?.Attributes,
                  attributes: item?.payload?.Attributes,
                  isban: banReason ? true : undefined,
                  ban_reason: banReason ? banReason : undefined,
                }
              }
              ))

            }


            if (res.payload.success) {
              setChat(res.payload.message);
            } else {
              notification.error({
                message: res.payload.data.message,
                className: "toast-error",
              });
            }
          });
        }


      });

      dispatch(getEventsBy_Id(id)).then((res) => {
        setLive(res.payload?.message[0]?.event_status);
        if (res.payload?.message[0]?.event?.is_facebook_live) {
          goFbLive(id);
        }
      });

      notification.success({
        message: "You are Live!",
        className: "toast-success",
      });
    });
  }

  useEffect(() => {
    // If chat room listeners are not available, do not continue
    if (!chatRoom.addListener) {
      return;
    }
    /**
     * Called when room is establishing the initial connection or reestablishing
     * connection after socket failure/token expiration/etc
     */
    const unsubscribeOnConnecting = chatRoom.addListener(
      "connecting",
      () => { }
    );

    /** Called when connection has been established. */
    const unsubscribeOnConnected = chatRoom.addListener("connect", (a, b) => {
      setConnected(true);
    });

    /** Called when a room has been disconnected. */
    const unsubscribeOnDisconnected = chatRoom.addListener(
      "disconnect",
      () => { }
    );

    /** Called when a chat message has been received. */
    const unsubscribeOnMessageReceived = chatRoom.addListener(
      "message",
      (message) => {
        console.log(message, "unsubscribeOnMessageReceived");

        // const data = {
        //   author: message?.attributes?.name,
        //   avatar: message?.attributes?.image,
        //   user_id: message?.attributes?.user_id,
        //   content: message.content,
        //   accountType: message?.attributes?.accountType,
        //   datetime: moment(message?.sendTime).format(),
        // };
        // setComments((preState) => [...preState, data]);
        setComments((preState) => [...preState, message])
        // {
        //   message?.attributes?.user_id === validate?.payload?.message?._id &&
        //     eventId &&
        //     setComments((preState) => [...preState, message])
        // }

        scrollToBottom();
        /* Example message:
         * {
         *   id: "5OPsDdX18qcJ",
         *   sender: { userId: "user1" },
         *   content: "hello world",
         *   sendTime: new Date("2022-10-11T12:46:41.723Z"),
         *   requestId: "d1b511d8-d5ed-4346-b43f-49197c6e61de"
         * }
         */
      }
    );

    /** Called when a chat event has been received. */
    const unsubscribeOnEventReceived = chatRoom.addListener(
      "event",
      (event) => {
        console.log(event, "Called when a chat event has been received.");

        /* Example event:
         * {
         *   id: "5OPsDdX18qcJ",
         *   eventName: "customEvent,
         *   sendTime: new Date("2022-10-11T12:46:41.723Z"),
         *   requestId: "d1b511d8-d5ed-4346-b43f-49197c6e61de",
         *   attributes: { "Custom Attribute": "Custom Attribute Value" }
         * }
         */
      }
    );

    /** Called when `aws:DELETE_MESSAGE` system event has been received. */
    const unsubscribeOnMessageDelete = chatRoom.addListener(
      "messageDelete",
      (deleteMessageEvent) => {
        console.log(deleteMessageEvent, "deleteMessageEvent");

        setComments(prevMessages => prevMessages.filter(m => m?.id !== deleteMessageEvent?.messageId && m?.payload?.Id !== deleteMessageEvent?.messageId));
        /* Example delete message event:
         * {
         *   id: "AYk6xKitV4On",
         *   messageId: "R1BLTDN84zEO",
         *   reason: "Spam",
         *   sendTime: new Date("2022-10-11T12:56:41.113Z"),
         *   requestId: "b379050a-2324-497b-9604-575cb5a9c5cd",
         *   attributes: { MessageID: "R1BLTDN84zEO", Reason: "Spam" }
         * }
         */
      }
    );

    /** Called when `aws:DISCONNECT_USER` system event has been received. */
    const unsubscribeOnUserDisconnect = chatRoom.addListener(
      "userDisconnect",
      (disconnectUserEvent) => {
        console.log(disconnectUserEvent, 'disconnectUserEvent');

        // setComments(prevMessages => prevMessages.map(item => ({
        //   ...item,
        //   isban: disconnectUserEvent.userId == item?.attributes?.user_id ? item?.attributes?.user_id : item?.payload?.Attributes?.user_id,
        //   ban_reason: disconnectUserEvent.userId == item?.attributes?.user_id && disconnectUserEvent?.reason
        // })));


        setComments(prevMessages => prevMessages.map(item => {
          // Check if the item should be updated based on the disconnectUserEvent
          const isAffected = disconnectUserEvent.userId === item?.attributes?.user_id || disconnectUserEvent.userId === item?.payload?.Attributes?.user_id;
          return {
            ...item,
            isban: isAffected ? (disconnectUserEvent.userId === item?.attributes?.user_id ? item?.attributes?.user_id : item?.payload?.Attributes?.user_id) : item.isban,
            ban_reason: isAffected ? (disconnectUserEvent.userId === item?.attributes?.user_id ? disconnectUserEvent?.reason : item.ban_reason) : item.ban_reason
          };
        }));



        // let banUser = comments.map(item => ({ ...item,
        //   isban: disconnectUserEvent.userId == item.attributes.user_id
        // }));

        // console.log(banUser,"banUser");

        // setComments(banUser)

        // const isban = {
        //   ...disconnectUserEvent,
        //    isban : true 
        // }

        // console.log(isban,"isban");

        // const allcomments = comments.findIndex(c => c.attributes.UserId === disconnectUserEvent.attributes.UserId)
        // comments[allcomments] = isban

        // setComments(comments);

        /* Example event payload:
         * {
         *   id: "AYk6xKitV4On",
         *   userId": "R1BLTDN84zEO",
         *   reason": "Spam",
         *   sendTime": new Date("2022-10-11T12:56:41.113Z"),
         *   requestId": "b379050a-2324-497b-9604-575cb5a9c5cd",
         *   attributes": { UserId: "R1BLTDN84zEO", Reason: "Spam" }
         * }
         */
      }
    );

    chatRoom.connect();
    return () => {
      unsubscribeOnConnected();
      unsubscribeOnDisconnected();
      unsubscribeOnUserDisconnect();
      unsubscribeOnConnecting();
      unsubscribeOnMessageReceived();
      unsubscribeOnEventReceived();
      unsubscribeOnMessageDelete();
    };
  }, [chatRoom]);

  const handleButtonClick = (e) => {
    if (e.key === "cancel") {
      confirm({
        wrapClassName: "modal-generic cancel-event-modal min-modal-600",
        title: "Warning",
        centered: true,
        closable: true,
        icon: <ExclamationCircleOutlined />,
        closeIcon: <FontAwesomeIcon icon={faXmark} />,
        content: "Are you sure you want to delete this event ?",
        okText: " Yes, delete",
        okType: "danger",
        cancelText: " No, I don't want to delete ",

        onOk() {
          return new Promise((resolve, reject) => {
            dispatch(deleteEvent(id)).then((res) => {
              if (res.payload.success) {
                notification.success({
                  message: res.payload?.message,
                  className: "toast-success",
                });

                setTimeout(resolve, 100);
                history({
                  pathname: `/events`,
                });
              } else {
                notification.error({
                  message: res.payload.data.message,
                  className: "toast-error",
                });
              }
            });
          });
        },

        onCancel() {
          console.log("Cancel");
        },
      });
    }
    if (e.key === "edit") {
      history({
        pathname: `/schedule-event-id/${id}?page=edit`,
      });
    }
    if (e.key === "editproduct") {
      history({
        pathname: `/schedule-event-id/${id}?page=product`,
      });
    }
  };

  const onKeyChange = (key) => {
    if (key == "1" && id) {
      // createChatToken(id);
      // dispatch(getChatLog(id)).then((res) => {
      //   console.log(res, "ress");

      //   if (res.payload.success) {
      //     setChat(res.payload.message);
      //   } else {
      //     notification.error({
      //       message: res.payload.data.message,
      //       className: "toast-error",
      //     });
      //   }
      // });
    }
  };

  function endSingleMeeting() {
    return dispatch(stopLive(id)).then(() => {
      notification.success({
        message: "Event End Successfully!!",
        className: "toast-success",
      });
      setTimeout(() => {
        history({
          pathname: `/events`,
        });
      }, 1000);
    });
  }

  if (!spinner) {
    return (
      <>
        <div className="control-room-top-area mb-2">
          <div className="control-top-box">
            <Link
              to="/events?type=Upcoming"
              className="prev-next-link ff-nunito"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              <span>Go back</span>
            </Link>
          </div>
          <div className="control-top-box">
            <a
              onClick={() =>
                shareKey(
                  getEventById?.payload?.message[0]?.event_id,
                  getEventById?.payload?.message[0]?.event?.event_status
                )
              }
              className="default-btn outline mob-style d-md-flex align-items-center justify-content-center m-0"
              target="_blank"
            >
              <FontAwesomeIcon icon={faShareNodes} />

              <span className="nav-text">Share Link</span>
            </a>

            {producer && (
              <Dropdown.Button
                trigger="click"
                overlayClassName="control-room-drowpdown"
                size="large"
                disabled={
                  getEventById?.payload?.message[0]?.event?.event_status ==
                    "live"
                    ? true
                    : liveData
                      ? true
                      : false
                }
                // onClick={() => handleButtonClick(item)}
                // overlay={menu}
                overlay={
                  <Menu onClick={(e) => handleButtonClick(e)}>
                    <Menu.Item key="edit">
                      <span>Edit Title</span>
                    </Menu.Item>
                    <Menu.Item key="editproduct">
                      <span>Edit Products</span>
                    </Menu.Item>
                    <Menu.Item key="cancel">
                      <span>Delete</span>
                    </Menu.Item>
                  </Menu>
                }
              ></Dropdown.Button>
            )}
          </div>
        </div>

        <div className="event-preview-box-main mb-20">
          {!getEventById.loading ? (
            <div className="row">
              <div className="col-md-2 col-lg-2 d-lg-flex align-items-center">
                {/* <div className="event-banner" style={{ backgroundImage: `url(${getEventById?.payload?.message[0]?.banner})` }} > */}
                <div className="event-banner">
                  <div className="event-box w-100 h-100 overflow-hidden">
                    <div className="event-banner-img">
                      <img
                        src={getEventById?.payload?.message[0]?.event?.banner}
                        alt="banner"
                      />
                    </div>
                    <div>
                      <div className="schedule-badge">
                        {live === "live" ||
                          meetingData[0]?.event_status === "live"
                          ? "Live"
                          : "Preview"}
                      </div>
                      {getEventById?.payload?.message[0]?.event
                        ?.is_facebook_live && (
                          <div className="schedule-badge control-fb">
                            Live on Facebook
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border border-left-0 align-items-center">
                <div className="preview-date-main">
                  <h4 className="date-txt">Date</h4>
                  <h5 className="preview-date">
                    {" "}
                    {moment(
                      getEventById?.payload?.message[0]?.event?.start_date
                    )
                      .utc()
                      .format("MM-DD-YYYY")}
                  </h5>
                  <h6 className="preview-time">
                    At{" "}
                    {moment(
                      getEventById?.payload?.message[0]?.event?.start_date
                    ).format("hh:mm a")}
                  </h6>
                </div>
              </div>
              <div className="col-md-2 col-lg-2 preview-data-border  d-lg-flex align-items-center">
                <div className="preview-date-main">
                  <h4 className="date-txt">Event</h4>

                  <h5
                    className="preview-date"
                    title={getEventById?.payload?.message[0]?.event?.title}
                  >
                    {getEventById?.payload?.message[0]?.event?.title.length > 30
                      ? getEventById?.payload?.message[0]?.event?.title.slice(
                        0,
                        30
                      ) + "..."
                      : getEventById?.payload?.message[0]?.event?.title}
                  </h5>
                </div>
                <div></div>
              </div>
              <div className="col-md-3 col-lg-3 preview-data-border d-lg-flex align-items-center">
                <div className="preview-date-main producer-style">
                  <h4 className="date-txt"></h4>

                  {getEventById?.payload?.message[0]?.hosts.map((item, i) => {
                    return (
                      <>
                        {item.producer && (
                          <h5 className="preview-date">
                            <FontAwesomeIcon className="" icon={faUserTie} />{" "}
                            Producer:{" "}
                            <span className="fw-normal">{item.user_name}</span>
                          </h5>
                        )}
                        {item.primary && (
                          <h5 className="preview-date">
                            <FontAwesomeIcon className="" icon={faUser} />{" "}
                            Primary:{" "}
                            <span className="fw-normal">{item.user_name}</span>
                          </h5>
                        )}
                        {item.host1 && (
                          <h5 className="preview-date">
                            <FontAwesomeIcon className="" icon={faUser} />{" "}
                            Host1:{" "}
                            <span className="fw-normal">{item.user_name}</span>
                          </h5>
                        )}
                        {item.host2 && (
                          <h5 className="preview-date">
                            <FontAwesomeIcon className="" icon={faUser} />{" "}
                            Host2:{" "}
                            <span className="fw-normal">{item.user_name}</span>
                          </h5>
                        )}
                        {item.host3 && (
                          <h5 className="preview-date">
                            <FontAwesomeIcon className="" icon={faUser} />{" "}
                            Host3:{" "}
                            <span className="fw-normal">{item.user_name}</span>
                          </h5>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-3 col-lg-3 preview-data-border d-lg-flex align-items-center">
                <div className="preview-date-main no-border-bottom">
                  <h4 className="date-txt notes">Details</h4>
                  <h6 className="preview-time notes-txt">
                    {getEventById?.payload?.message[0]?.event?.notes !== ""
                      ? getEventById?.payload?.message[0]?.event?.notes
                      : "-"}
                  </h6>
                </div>
              </div>
            </div>
          ) : (
            <div className="event-detail-skeleton">
              <Skeleton
                avatar={{
                  size: "large",
                  shape: "square",
                }}
                active
                paragraph={{
                  rows: 2,
                }}
              />
            </div>
          )}
        </div>

        <Modal
          maskClosable={false}
          className="modal-generic modal-500"
          centered
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={false}
        >
          <h2 className="modal-hd1">Share </h2>
          <div class="your-copy-link">
            <div class="item-a">
              <a
                target="_blank"
                rel="noreferrer"
                href={shareUrls?.payload?.message}
              >
                {shareUrls?.payload?.message}
              </a>
            </div>
            <div class="item-b">
              {/* <div class="item-b copied"> */}
              <Button onClick={() => copyUrl(shareUrls?.payload?.message)}>
                Copy
              </Button>
            </div>
          </div>
          <div className="text-center mt-4">
            <Button onClick={handleCancel} className="default-btn outline">
              Exit
            </Button>
          </div>
        </Modal>

        <Modal
          maskClosable={false}
          className="modal-generic modal-500"
          centered
          visible={urlModal}
          onOk={() => setUrlModal(false)}
          onCancel={() => setUrlModal(false)}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={false}
        >
          <h2 className="modal-hd1">Share</h2>
          <div class="your-copy-link">
            <div class="item-a">
              <a
                target="_blank"
                rel="noreferrer"
                href={shareUrls?.payload?.message}
              >
                {shareUrls?.payload?.message}
              </a>
            </div>
            <div class="item-b">
              {/* <div class="item-b copied"> */}
              <Button onClick={() => copyUrl(shareUrls?.payload?.message)}>
                Copy
              </Button>
            </div>
          </div>
          <div className="text-center mt-4">
            <Button
              onClick={() => setUrlModal(false)}
              className="default-btn outline"
            >
              Exit
            </Button>
          </div>
        </Modal>

        <div className="control-boxes-main">
          <div className="control-boxes-box  mob-height" ref={chatRef}>
            {producer ? (
              <>
                {multipleHost || multipleHost === undefined ? (
                  meeting && meetingData.length > 0 ? (
                    <ProducerMixerScreen
                      channel={{
                        rtmp: getBroadcastClient?.payload?.message
                          ?.ingestEndpoint,
                        key: getBroadcastClient?.payload?.message?.streamKey,
                      }}
                      endMeetingFlag={endMeetingFlag}
                      live={live}
                      onGolive={onGolive}
                      stopLiveEvent={() => dispatch(stopLive(id))}
                      endMeeting={endMeeting}
                      meetingData={meetingData}
                    />
                  ) : (
                    <StartMeetingScreen
                      setMeeting={setMeeting}
                      id={id}
                      startLoading={startLoading}
                    />
                  )
                ) : (
                  <SingleScreen
                    channel={{
                      rtmp: getBroadcastClient?.payload?.message
                        ?.ingestEndpoint,
                      key: getBroadcastClient?.payload?.message?.streamKey,
                    }}
                    onGolive={onGolive}
                    endSingleMeeting={endSingleMeeting}
                    setCheckCamera={setCheckCamera}
                    stopLiveEvent={() => dispatch(stopLive(id))}
                  />
                )}
              </>
            ) : (
              <>
                <MixerScreen
                  endMeetingFlag={endMeetingFlag}
                  host={true}
                  meetingData={meetingData}
                  checkEvent={() => {
                    dispatch(getEventsBy_Id(id)).then((res) => {
                      if (res.payload.success) {
                        setProducer(res.payload?.message[0]?.producer);
                        if (res.payload?.message[0]?.meeting_info) {
                          setMeetingData(res.payload?.message);
                          setMeeting(true);
                        } else {
                          setMeeting(false);
                        }
                        setEventEnded(res.payload?.message);
                        setBanner(res.payload?.message[0]?.event?.banner);
                        setDate(res.payload?.message[0]?.event?.start_date);
                        setTitle(res.payload?.message[0]?.event?.title);
                        setSpin(false);
                      }
                    });
                  }}
                />
              </>
            )}
          </div>

          <div
            className="control-boxes-box tabs-control-room-main"
            style={{ height: height }}
          >
            <Tabs
              className="tabs-control-room chat-tab-data"
              defaultActiveKey="3"
              onChange={onKeyChange}
            >
              <TabPane
                // tab="Chat"
                key="1"
                tab={
                  multipleHost || multipleHost === undefined ? (
                    <Tooltip
                      title={
                        meeting && meetingData.length > 0
                          ? ""
                          : producer
                            ? "Start Meeting to interact with your audience!"
                            : "Start Hosting to interact with your audience!"
                      }
                    >
                      <span>Chat</span>
                    </Tooltip>
                  ) : live === "live" ||
                    meetingData[0]?.event_status === "live" ? (
                    <span>Chat</span>
                  ) : (
                    <Tooltip title={"Go Live to interact with your audience!"}>
                      <span>Chat</span>
                    </Tooltip>
                  )
                }
                disabled={
                  multipleHost || multipleHost === undefined
                    ? meeting && meetingData.length > 0
                      ? false
                      : true
                    : live === "live" || meetingData[0]?.event_status === "live"
                      ? false
                      : true
                }
                className={`${meeting && meetingData.length > 0 ? "" : "chat-tab-txt"
                  }`}
              >
                <Chat
                  eventId={id}
                  chat={chat}
                  meeting={meeting}
                  height={height}
                  getEventById={getEventById}
                  chatRoom={chatRoom}
                  comments={comments}
                  setComments={setComments}
                  connected={connected}
                />
                {/* {meeting && meetingData.length > 0 ? (
                  <Chat />
                ) : (
                  <div className="upcoming-box-main no-bg">
                    <p className="upcoming-para">
                      {producer
                        ? 'Go live to interact with your audience!'
                        : 'Event not started by producer'}
                    </p>
                  </div>
                )} */}
              </TabPane>

              <TabPane tab="Products" key="3">
                {/* <h4 className="hd-tab">Products Listing</h4> */}
                <div
                  className="control-products-listing-main-wrapper scrollbar-style overflow-y-auto  "
                  style={{ maxHeight: height - 90 }}
                >
                  {getEventById?.payload?.message[0]?.event?.products.length ? (
                    getEventById?.payload?.message[0]?.event?.products.map(
                      (item, i) => {
                        return (
                          <>
                            <div className="control-products-listing-main ">
                              <div className="control-products-listing">
                                <div className="control-product-image">
                                  <img
                                    src={item.mediaUrl}
                                    alt={item.title}
                                    className=""
                                  />
                                </div>

                                <div className="control-product-content">
                                  <div className="product-left w-100">
                                    <div className="control-product-title">
                                      {item.title}
                                    </div>
                                    <div className="control-product-price justify-content-between d-flex">
                                      <span>${item.price}</span>
                                      <span><b>Stock: </b>{item?.variants?.reduce((acc, item) => {
                                        return (acc = acc + item.inventory_quantity);
                                      }, 0)}</span>
                                    </div>
                                  </div>
                                  <div className="product-right">
                                    <div className="product-quantity">
                                      {/* Qty: 150 */}{" "}
                                    </div>
                                    {/* <div className="control-product-copy">
                                      <button
                                        class="default-btn small-btn fw-normal"
                                        onClick={() =>
                                          productUrl(item.ProductUrl)
                                        }
                                      >
                                        Copy Url
                                      </button>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                              {/* <Link to="#" className="block-link"></Link> */}
                            </div>
                          </>
                        );
                      }
                    )
                  ) : (
                    <h5 className="text-center">No Product</h5>
                    // <Skeleton
                    //   avatar={{
                    //     size: "large",
                    //     shape: "circle",
                    //   }}
                    //   active
                    //   paragraph={{
                    //     rows: 2,
                    //   }}
                    // />
                  )}
                </div>
              </TabPane>
              {/* <TabPane tab="Poll" key="2">
                    <h4 className="hd-tab">Upcomings</h4>
                    <div className="upcoming-box-main">
                      <div className="upcoming-img">
                        <img className="img-fluid" src={live2} alt="live2" />
                      </div>
                      <p className="upcoming-para">
                        {" "}
                        You didn't create poll for this event, yet.
                      </p>
                      <Link
                        to="#"
                        className="default-btn d-flex m-auto  align-items-center justify-content-center m-0"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="nav-text">Create</span>
                      </Link>
                    </div>
                  </TabPane> */}
              {/* <TabPane tab=" Announcement " key="4">
                <h4 className="hd-tab">Upcomings</h4>
                <div className="upcoming-box-main">
                  <div className="upcoming-img">
                    <img className="img-fluid" src={live2} alt="live2" />
                  </div>
                  <p className="upcoming-para">
                    {' '}
                    You didn't create annoucement for this event, yet.
                  </p>
                  <Link
                    to="#"
                    className="default-btn d-flex m-auto  align-items-center justify-content-center m-0"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="nav-text ">Create</span>
                  </Link>
                </div>
              </TabPane> */}
            </Tabs>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <span className="spinclass loader-center">
        <Spin size="large" />
      </span>
    );
  }
}

export default connect(null, chatActions)(ControlRoom);
