import { combineReducers } from "redux";
import login from "./login/login.reducer";
import getBroadcastClient from "./broadcast/broadcastClient.reducer";
import parentCategory from "./category/parentCategory.reducer";
import subCategory from "./category/subCategory.reducer";
import creatEvent from "./event/createevent.reducer";
import getEvent from "./event/getEvent.reducer";
import getEventById from "./event/getEventById.reducer";
import deleteEvent from "./event/deleteevent.reducer";
import updateEvent from "./event/updateEvent.reducer";
import goLive from "./broadcast/goLive.reducer";
import stopLive from "./broadcast/stopLive.reducer";
import sendMetaData from "./broadcast/sendmetadata.reducer";
import getMetaData from "./broadcast/getmetadata.reducer";
import upcomingEvent from "./event/upcomingEvent.reducer";
import SaveControl from "./broadcast/savecontrol.reducer";
import getControl from "./broadcast/getcontrol.reducer";
import getVideoById from "./event/recordedEvent.reducer";
import SaveRole from "./role/saveRole.reducer";
import startMeeting from "./broadcast/startMeeting.reducer";
import endMeeting from "./broadcast/endMeeting.reducer";
import getShopifyDetails from "./shopify/shopify.reducer";
import getSalesForceDetails from "./salesForce/salesforce.reducer";
import getProduct from "./shopify/shopifyProduct.reducer";
import createShopify from "./shopify/shopifyPost.reducer";
import forgetPassword from "./forgetPassword/forgetPassword.reducer";
import resetPassword from "./resetPassword/resetPassword.reducer";
import shareUrls from "./shareUrls/liveUrl.reducer";
import country from "./country/country.reducer";
import state from "./state/state.reducer";
import city from "./city/city.reducer";
import brandRegister from "./register/register.reducer";
import getSales from "./sales/getSales.reducer";
import getOrder from "./sales/getOrder.reducer";
import createChat from "./chat/chatToken";
import publishEvent from "./event/publishevent.reducer";
import tags from "./mention/tag.reducer";
import commentTags from "./mention/commentTag.reducer";
import captionsTags from "./mention/captionTag.reducer";
import hashtag from "./hashtags/hashtag";
import hashtags from "./hashtags/hashtags";
import posts from "./subscription/post.reducer";
import profile from "./searchProfile/addProfile.reducer";
import profiles from "./searchProfile/profiles.reducer";
import mobileDropdown from "./mobileDropDown/mobileDropDown.reducer";
import categories from "./category/categories.reducer";
import userInfo from "./userInfo/userInfo.reducer";
import promoRequest from "./promoRequest/promoRequest.reducer";
import subcategories from "./BioSubCategory/BioSubCategory.redcuer";
import allCategory from "./category/allCategory.reducer";
import saveCategory from "./category/saveCategory.reducer";
import verifyCategory from "./category/verifyCategory.reducer";
import getInstagramUrl from "./instagramConnection/getInstagramUrl.reducer";
import getInstagramData from "./instagramConnection/getInstagramData.reducer";
import updateAccessToken from "./instagramConnection/updateAccessToken.reducer";
import disconnectInstagram from "./instagramConnection/disconnectInstagram.reducer";
import getChat from "./chat/getChat";
import saveChat from "./chat/saveChat";
import getSocialStoreSales from "./sales/getSocialStoreSales.reducer";
import ReferralStats from "./sales/getReferralStats.reducer";
import refreshProd from "./skuManage/refreshProd.reducer";
import externalStream from "./Stream/stream.reducer";
import getAllStream from "./Stream/getAllstream.reducer";
import deleteStream from "./Stream/deleteStream.reducer";
import editStream from "./Stream/editStream.reducer";
import KbSetup from "./kbSetup/kbSetup.reducer";
import dashboardPayloadSocial from "./dashboard/dashboard.reducer";
import affiliateCards from "./kbSetup/affiliateCard.reducer";
import affiliatePayment from "./kbSetup/affiliatePayment.reducer";
import affiliateBalance from "./kbSetup/affiliateBalance.reducer";
import publishMedia from "./instagramConnection/publishMedia.reducer";
import getInfluencerVideo from "./influencerReview/getInfluencerVideo";
import linkInfluencerVideo from "./influencerReview/linkInfluencerVideo";
import getReviewControlData from "./influencerReview/getReviewControlData";
import brandRecordedEvents from "./event/getBrandRecordEvent.reducer ";
import getBrandPublishEvents from "./event/getBrandPublishEvent.reducer ";
import monitorProfileUser from "./hashtags/getUserProfile";
import customerRegister from "./register/customerRegister.reducer";
import checkInstagramUser from "./instagramConnection/checkInstagramUser.reducer";
import socialMediaFollowers from "./event/socialFollowersreducer ";
import getAllCreatorData from "./influencerReview/getAllCreatorData";
import createShow from "./event/createShow.reducer";
import getShowRecordEvents from "./event/getShowRecordedEvent.reducer";
import getShowById from "./event/getShowById.reducer";
import publishShow from "./event/showPublish.reducer";
import validate from "./validate/validate";
import referralStats from "./referralStats/referralStats.reducer";
import summaryReferral from "./referralStats/referralSummary.reducer";
import creatorReferral from "./creatorStats/creatorSummary.reducer";
import creatorStats from "./creatorStats/creatorStats.reducer";
import detailsOrder from "./purchaseHistory/orderDetail.reducer";
import marketFeeView from "./marketFee/marketFee.reducer";
import shopifyCustomerorder from "./purchaseHistory/shopify.getCustomerorder.reducer";
import reactS3BucketData from "./s3/reactS3.reducer";
import newGeneralCategories from "./newCategories/generalCategory.reducer";
import newCategories from "./newCategories/newCategory.reducer";
import newSubCategories from "./newCategories/subCategory.reducer";
import savedGeneralCategories from "./newCategories/savedGeneralCategory.reducer";
import savedCategory from "./newCategories/savedCategory.reducer";
import savedSubCategory from "./newCategories/savedSubCategory.reducer";
import orderReview from "./SaleOrderView/saleOrderView.reducer";
import detailsReview from "./SaleOrderView/detailReview.reducer";
import activeInventory from "./Inventory/inventoryActive.reducer";
import disabledInventory from "./Inventory/inventoryDisabled.reducer";
import replacedInventory from "./Inventory/inventoryReplaced.reducer";
import soldOutInventory from "./Inventory/inventorySoldOut.reducer";
import contact from "./contact/contact.reducer";
import getReviewById from "./influencerReview/getReviewId.reducer";
import uploadDetail from "./uploads3/uploads3";
import uploadDetailReview from "./uploads3/uploads3Review";
import getReviewChat from "./chat/getReviewChat";
import getStructureFees from "./feeStructure/getFeeStructure";
import getChatLog from "./chat/getChatLog";
import bannedUsersLists from "./chatMod/bannedUsersList.reducer";
import brandFollows from "./brandFollowers/brandFollowers.reducer";
import boostedReviews from "./boost/brandFollowers.reducer";
import getInventoryProducts from "./Inventory/inventoryProducts.reducer";
import getAllInventoryProducts from "./Inventory/allInventoryProducts.reducer";
import getAllInfluencers from "./influencerReview/getAllInfluencers";
import instaReels from "./instagramReels/instaReels";
import getInstaReelData from "./instagramReels/getInstaReelData";
import getReelReviewControlData from "./instagramReels/getReelReviewData";
import getReelDelete from "./instagramReels/getReelDelete";
import instaReelsData from "./instaReels/instaReels.reducer";
import hitInstaAPI from "./instaReels/hitApi.reducer";
import shippingSetup from "../reducers/shippingSetups/shipping.reducer";
import getAllShipping from "../reducers/shippingSetups/allShipping.reducer"
import agreementMsg from "../reducers/agreement/agreement.reducer";
import stripeConnect from "./connectStripe/stripeConnect.reducer";
import stripeBalance from "./connectStripe/stripeBalance.reducer";
import stripeReport from "./connectStripe/stripeReport.reducer";
import stripePayout from "./connectStripe/stripePayout.reducer";
import checkInstagramConnection from "./instagramConnection/checkInstagramConnection.reducer";
import createMagento from "./magento/magentoPost.reducer";
import getMagentoDetails from "./magento/magentoDetail.reducer";
import getDiscountList from "./discounts/discountList.reducer";
import getInventoryProductsBySkuAndTitle from "./Inventory/inventoryProductsBySkuAndTitle.reducer";
import getCustomShopDetails from "./customShop/getCustomShopDetails"
import addCustomProducts from "./customProduct/addCustomProducts";
import stripeCardDetails from "./stripeCardDetails/stripeCardDetails";
import getPromotedReviewControlData from "./influencerReview/getPromotedReviewControlData";
import getPromotionImpression from "./influencerReview/getPromotionImpression";
import createAd from "./uploadAd/uploadAd";
import getInfluencerVideoWithoutProduct from "./influencerReview/getInfluencerVideoWithoutProduct";
import getReviewControlDataWithoutProduct from "./influencerReview/getReviewControlDataWithoutProduct";
import getSiteCoreDetails from "./sitecore/sitecore";
import getSellerCenterDetails from "./sellerCenter/sellercenter.reducer";
import scGetAllProducts from "./scGetAllProducts/scGetAllProducts";
import scGetOneProductDetail from "./scGetOneProductDetail/scGetOneProductDetail";
import connectSellerCenterDetail from "./connectSellerCenterDetail/connectSellerCenterDetail.reducer";
import scGetAllOrders from "./scGetAllOrders/scGetAllOrders";
import scGetOneOrderDetail from "./scGetOneOrderDetail/scGetOneOrderDetail";
import getExternalEvent from "./externalEvent/getExternalEvent.reducer";
import getExternalEventById from "./externalEvent/getExternalEventById.reducer";
import getSquareSpaceDetails from "./squarespace/squarespace";


export default combineReducers({
  login,
  getBroadcastClient,
  parentCategory,
  subCategory,
  creatEvent,
  getEvent,
  getEventById,
  deleteEvent,
  updateEvent,
  goLive,
  stopLive,
  sendMetaData,
  getMetaData,
  upcomingEvent,
  SaveControl,
  getControl,
  getVideoById,
  SaveRole,
  startMeeting,
  endMeeting,
  getShopifyDetails,
  getProduct,
  createShopify,
  forgetPassword,
  resetPassword,
  shareUrls,
  country,
  state,
  city,
  brandRegister,
  getSales,
  myChatToken: createChat,
  getOrder,
  publishEvent,
  tags,
  commentTags,
  captionsTags,
  hashtag,
  hashtags,
  posts,
  profile,
  profiles,
  mobileDropdown,
  categories,
  userInfo,
  promoRequest,
  subcategories,
  allCategory,
  saveCategory,
  verifyCategory,
  getInstagramUrl,
  getInstagramData,
  updateAccessToken,
  disconnectInstagram,
  getChat,
  saveChat,
  getSocialStoreSales,
  ReferralStats,
  refreshProd,
  externalStream,
  getAllStream,
  deleteStream,
  editStream,
  KbSetup,
  dashboardPayloadSocial,
  affiliateCards,
  affiliatePayment,
  affiliateBalance,
  publishMedia,
  getInfluencerVideo,
  linkInfluencerVideo,
  getReviewControlData,
  brandRecordedEvents,
  getBrandPublishEvents,
  monitorProfileUser,
  customerRegister,
  checkInstagramUser,
  socialMediaFollowers,
  getAllCreatorData,
  createShow,
  getShowRecordEvents,
  getShowById,
  publishShow,
  validate,
  referralStats,
  summaryReferral,
  creatorReferral,
  creatorStats,
  detailsOrder,
  marketFeeView,
  shopifyCustomerorder,
  reactS3BucketData,
  newGeneralCategories,
  newCategories,
  newSubCategories,
  savedGeneralCategories,
  savedCategory,
  savedSubCategory,
  orderReview,
  detailsReview,
  activeInventory,
  disabledInventory,
  soldOutInventory,
  replacedInventory,
  contact,
  getReviewById,
  uploadDetail,
  uploadDetailReview,
  getReviewChat,
  getStructureFees,
  getChatLog,
  bannedUsersLists,
  brandFollows,
  boostedReviews,
  getInventoryProducts,
  getAllInventoryProducts,
  getAllInfluencers,
  instaReels,
  getInstaReelData,
  getReelReviewControlData,
  getReelDelete,
  instaReelsData,
  hitInstaAPI,
  shippingSetup,
  getAllShipping,
  getSalesForceDetails,
  agreementMsg,
  stripeConnect,
  stripeBalance,
  stripeReport,
  stripePayout,
  checkInstagramConnection,
  createMagento,
  getMagentoDetails,
  getDiscountList,
  getInventoryProductsBySkuAndTitle,
  getCustomShopDetails,
  addCustomProducts,
  stripeCardDetails,
  getPromotedReviewControlData,
  getPromotionImpression,
  createAd,
  getInfluencerVideoWithoutProduct,
  getReviewControlDataWithoutProduct,
  getSiteCoreDetails,
  getSellerCenterDetails,
  scGetAllProducts,
  scGetOneProductDetail,
  connectSellerCenterDetail,
  scGetAllOrders,
  scGetOneOrderDetail,
  getExternalEvent,
  getExternalEventById,
  getSquareSpaceDetails
});
