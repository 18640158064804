import { SC_GET_ALL_PRODUCT } from "../../types/types";


export default function scGetAllProducts(state = [], action) {
    switch (action.type) {
        case SC_GET_ALL_PRODUCT:
            return action.payload
        default:
            return state;
    }
}
