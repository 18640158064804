import axios from "axios";
import {
  NEW_INFLUENCER,

} from "../types/types";

export const getInfluencers = () => (dispatch) => {
  return axios
    .post("reviews/getinfluencers")
    .then((res) => {
      dispatch({
        type: NEW_INFLUENCER,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

