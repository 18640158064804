import {
    GET_SQUARE_SPACE_DETAIL_REQUEST,
    GET_SQUARE_SPACE_DETAIL_SUCCESS,
    GET_SQUARE_SPACE_DETAIL_ERROR,
} from "../../types/types";
export default function getSquareSpaceDetails(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SQUARE_SPACE_DETAIL_REQUEST:
            return {
                loading: true,
            };
        case GET_SQUARE_SPACE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case GET_SQUARE_SPACE_DETAIL_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}