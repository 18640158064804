import axios from "axios";
import {
  GET_PRICE_POINT,

} from "../types/types";

export const getPricePoint = () => (dispatch) => {
  return axios
    .post("users/receive/pricepoint")
    .then((res) => {
      dispatch({
        type: GET_PRICE_POINT,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

