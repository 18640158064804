import React, { useState } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Select, Tabs } from 'antd';
import { Button } from 'antd';
import EventGridHost from '../../components/eventsGrid/eventGridHost';
// import GridListing from "../../components/gridListingBar/gridListingBar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrip, faBars, faPlus } from '@fortawesome/free-solid-svg-icons';

const { Option } = Select;
const handleChange = (value) => {
  
};

const { TabPane } = Tabs;

export default function HostEvents() {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [gridActive, setGridActive] = useState(true);
  const [eventTitle, setEventTitle] = useState(
    searchParams.get('type') === null ? 'Upcoming' : searchParams.get('type')
  );
  const [eventStatus, setEventStatus] = useState(123);

  const onChange = (key) => {
    setEventTitle(key);
  };

  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set('type', key);
    window.history.replaceState(null, null, url);
    setEventTitle(key);
    setEventStatus(event.timeStamp);
  };

  return (
    <>
      <div className="grid-listing-area flex-column  flex-sm-row align-items-start align-items-sm-center mb-30">
        <div className="grid-listing-left flex-grow-1">
          <Tabs
            // defaultActiveKey="Upcoming"
            defaultActiveKey={eventTitle}
            // onChange={onChange}
            className="events-tabs-main"
            onTabClick={callbackTabClicked}
          >
            <TabPane tab="Upcoming" key="Upcoming"></TabPane>
            <TabPane tab="Live" key="Live Events"></TabPane>
            <TabPane tab="Recorded" key="Recorded"></TabPane>
            {/* <TabPane tab="Expired" key="Expired">
            </TabPane> */}
          </Tabs>
        </div>
      </div>

      <div className="title-area mb-10">
        <h1>{eventTitle}</h1>
      </div>
      <EventGridHost
        className={gridActive}
        title={eventTitle}
        // status="scheduled,live"
        eventStatus={eventStatus}
      />
    </>
  );
}
