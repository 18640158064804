import React, { useState } from 'react';
import { Tabs } from 'antd';
import UploadVideo from './uploadVideo';
import AllVideos from './allVideos';
import "../../css/review.scss";
import '../../css/analytics.scss';
import "../..";
import LinkReels from './linkReels';
import PendingReels from './pendingReels';
import ReviewControl from './reviewControl';
import ReviewPublish from './reviewPublish';
import ReviewDeleted from './reviewDeleted';


const { TabPane } = Tabs;

export default function InstagramReels() {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [title, setTitle] = useState(
    searchParams.get('type') === null ? 'notlinked' : searchParams.get('type')
  );
  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set('type', key);
    window.history.replaceState(null, null, url);
    setTitle(key);
  };

  return (
    <>
      <div className="grid-listing-area flex-column tabs-overflow-scroll scrollbar-style flex-sm-row align-items-start align-items-sm-center mb-30">
        <div className="grid-listing-left flex-grow-1">
          <Tabs
            // defaultActiveKey="allvideos"
            defaultActiveKey={title}
            // onChange={onChange}
            className="events-tabs-main"
            onTabClick={callbackTabClicked}
          >
            <TabPane tab="Instagram Video" key="notlinked"></TabPane>
            {/* <TabPane tab="All Videos" key="allvideos"></TabPane> */}
            <TabPane tab="Link Products" key="pendinglinking"></TabPane>
            <TabPane tab="Unpublished" key="unpublished"></TabPane>
            <TabPane tab="Published" key="published"></TabPane>
            <TabPane tab="Archive Videos" key="deleted"></TabPane>
            {/* <TabPane tab="Analytic" key="analytics"></TabPane> */}
          </Tabs>
        </div>
      </div>

      {/* <div className="title-area">
        <h1>{eventTitle}</h1>
      </div> */}

      <div className={`upcoming-events-wrapper mb-30`}>
        {title === 'notlinked' && <LinkReels />}
        {/* {title === 'allvideos' && <AllVideos />} */}
        {title === 'pendinglinking' && <PendingReels />}
        {title === 'unpublished' && <ReviewControl />}
        {title === 'published' && <ReviewPublish />}
        {title === 'deleted' && <ReviewDeleted />}
        {/* {title === 'analytics' && "analytics"} */}
      </div>
    </>
  );
}
