import {
  POST_DISCOUNT_REQUEST,
  POST_DISCOUNT_SUCCESS,
  POST_DISCOUNT_ERROR,
  GET_DISCOUNT_REQUEST,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNT_ERROR,
  DELETE_DISCOUNT_REQUEST,
  DELETE_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT_ERROR,
  UPDATE_DISCOUNT_REQUEST,
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_ERROR,
  ACTIVATION_DISCOUNT_REQUEST,
  ACTIVATION_DISCOUNT_SUCCESS,
  ACTIVATION_DISCOUNT_ERROR
} from '../types/types';
import axios from 'axios';

export const createDiscount = (data) => (dispatch) => {
  dispatch({ type: POST_DISCOUNT_REQUEST });
  return axios({
    method: 'POST',
    url: `discount/createDiscount`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_DISCOUNT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_DISCOUNT_ERROR,
        payload: error.response,
      });
    });
};

export const getDiscount = (page, limit, start_date, end_date, status, name) => (dispatch) => {
  dispatch({ type: GET_DISCOUNT_REQUEST });

  return axios({
    method: 'POST',
    url: `discount/getDiscounts?limit=${limit}&page=${page}`,
    data: {
      start_date,
      end_date,
      active: status,
      name
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_DISCOUNT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_DISCOUNT_ERROR,
        payload: error.response,
      });
    });
};

export const deleteDiscount = (id) => (dispatch) => {
  dispatch({ type: DELETE_DISCOUNT_REQUEST });
  return axios({
    method: 'POST',
    url: `discount/deleteDiscount`,
    data: {
      id
    }
  })
    .then((response) => {
      dispatch({
        type: DELETE_DISCOUNT_SUCCESS,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_DISCOUNT_ERROR,
        payload: error.response,
      });
    });
};

export const updateDiscount = (data) => (dispatch) => {
  dispatch({ type: UPDATE_DISCOUNT_REQUEST });
  return axios({
    method: 'POST',
    url: `discount/updateDiscount`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: UPDATE_DISCOUNT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_DISCOUNT_ERROR,
        payload: error.response,
      });
    });
};

export const discountActivation = (data) => (dispatch) => {
  dispatch({ type: ACTIVATION_DISCOUNT_REQUEST });
  return axios({
    method: 'POST',
    url: `discount/discountActivation`,
    data: data
  })
    .then((response) => {
      dispatch({
        type: ACTIVATION_DISCOUNT_SUCCESS,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return dispatch({
        type: ACTIVATION_DISCOUNT_ERROR,
        payload: error.response,
      });
    });
};