import {
    DELETE_STRIPE_CARD_REQUEST,
    DELETE_STRIPE_CARD_SUCCESS,
    DELETE_STRIPE_CARD_ERROR
} from '../types/types';
import axios from 'axios';

export const deleteStripeCard = (id) => (dispatch) => {
    dispatch({ type: DELETE_STRIPE_CARD_REQUEST });
    return axios({
        method: 'POST',
        url: `stripe/promoted/detachpaymentmethod`,
        data: {
            id
        }
    })
        .then((response) => {
            dispatch({
                type: DELETE_STRIPE_CARD_SUCCESS,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_STRIPE_CARD_ERROR,
                payload: error.response,
            });
        });
};