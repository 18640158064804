import {
  DEFAULT_STRIPE_CARD_REQUEST,
  DEFAULT_STRIPE_CARD_SUCCESS,
  DEFAULT_STRIPE_CARD_ERROR,
  UPDATE_STRIPE_CARD_REQUEST,
  UPDATE_STRIPE_CARD_SUCCESS,
  UPDATE_STRIPE_CARD_ERROR,
  MAKE_STRIPE_PAYMENT_REQUEST,
  MAKE_STRIPE_PAYMENT_SUCCESS,
  MAKE_STRIPE_PAYMENT_ERROR,
} from '../types/types';
import axios from 'axios';


export const makeDefaultStripeCard =
  (id) => (dispatch) => {
    dispatch({ type: DEFAULT_STRIPE_CARD_REQUEST });
    return axios({
      method: "POST",
      url: `stripe/promoted/setdefaultpaymentmethod`,
      data: {
        id
      },
    })
      .then((response) => {
        return dispatch({
          type: DEFAULT_STRIPE_CARD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: DEFAULT_STRIPE_CARD_ERROR,
          payload: error.response,
        });
      });
  };
export const updateStripeCard =
  (id, month, year) => (dispatch) => {
    dispatch({ type: UPDATE_STRIPE_CARD_REQUEST });
    return axios({
      method: "POST",
      url: `stripe/promoted/updatepaymentmethod`,
      data: {
        id,
        month,
        year
      },
    })
      .then((response) => {
        return dispatch({
          type: UPDATE_STRIPE_CARD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_STRIPE_CARD_ERROR,
          payload: error.response,
        });
      });
  };

export const makeStripePayment =
  () => (dispatch) => {
    dispatch({ type: MAKE_STRIPE_PAYMENT_REQUEST });
    return axios({
      method: "POST",
      // url: `stripe/promoted/makepaymentmethod`,
      url: `stripe/promoted/payment`,
    })
      .then((response) => {
        return dispatch({
          type: MAKE_STRIPE_PAYMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: MAKE_STRIPE_PAYMENT_ERROR,
          payload: error.response,
        });
      });
  };
