import {
    POST_AD_REQUEST,
    POST_AD_SUCCESS,
    POST_AD_ERROR,
} from "../../types/types";

export default function createAd(state = "", action) {
    const { type, payload } = action;
    switch (type) {
        case POST_AD_REQUEST:
            return {
                loading: true,
                success: true,
            };
        case POST_AD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                payload: payload,
            };

        case POST_AD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                payload: payload,
            };

        default:
            return state;
    }
}
