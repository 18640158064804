import { CUSTOMURL1 } from '../../config';
import {
  SC_UPDATE_FULFILLMENT
} from '../types/types';
import axios from 'axios';
const sc_token = JSON.parse(localStorage.getItem('sc_token'));


export const scUpdateFulfillment = (order_id, data, token) => (dispatch) => {
  return axios({
    method: 'PUT',
    url: `${CUSTOMURL1}fulfillment/${order_id}`,
    headers: { Authorization: `Bearer ${sc_token ? sc_token : token}` },
    data: data
  })
    .then((response) => {
      dispatch({
        type: SC_UPDATE_FULFILLMENT,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
