import { CUSTOMURL1 } from '../../config';
import {
  SC_GET_ONE_ORDER, SC_GET_ONE_ORDER_ERROR
} from '../types/types';
import axios from 'axios';
const sc_token = JSON.parse(localStorage.getItem('sc_token'));


export const scGetOneOrder = (order_id, token) => (dispatch) => {
  return axios({
    method: 'GET',
    url: `${CUSTOMURL1}orders/${order_id}`,
    headers: { Authorization: `Bearer ${sc_token ? sc_token : token}` },
  })
    .then((response) => {
      dispatch({
        type: SC_GET_ONE_ORDER,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return dispatch({
        type: SC_GET_ONE_ORDER_ERROR,
        payload: error.response,
      });
    });
};
