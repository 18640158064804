import { SC_GET_ONE_PRODUCT } from "../../types/types";


export default function scGetOneProductDetail(state = [], action) {
    switch (action.type) {
        case SC_GET_ONE_PRODUCT:
            return action.payload
        default:
            return state;
    }
}
