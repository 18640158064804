import { UNFOLLOW_BRAND } from '../types/types';
import axios from 'axios';

export const unFollow = (follower_id) => (dispatch) => {
    return axios({
      method: 'POST',
      url: `brand/reports/removeFollower`,
      data: {
        follower_id 
      }
    })
      .then((response) => {
        dispatch({
          type: UNFOLLOW_BRAND,
          payload: response.data,
        });
        return response.data
      })
      .catch((error) => {
        return error
      });
  };
  