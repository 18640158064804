import { GET_ORDER_DETAILS, GET_REVIEW_DETAILS_SUCCESS } from "../../types/types";
export default function detailsOrder(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_DETAILS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case GET_REVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    default:
      return state;
  }
}