import { BOOSTED_REVIEWS, BOOSTED_SHOWS, BOOST_EVENTS } from "../types/types";
import axios from "axios";

export const getBoostedReviews = (page, limit, from_date, to_date, influencer_id,status) => (dispatch) => {
    // console.log(status, 'status');
    return axios({
        method: "POST",
        url: `reviews/getpromotedlist`,
        params: {
            page,
            limit
        },
        data: {
            from_date,
            to_date,
            influencer_id,
            status
        }
    })
        .then((response) => {
            dispatch({
                type: BOOSTED_REVIEWS,
                payload: response.data,
            });
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};
