import {
  STRIPE_REDIRECT_URL
} from '../types/types';
import axios from 'axios';


export const addStripeCard = () => (dispatch) => {
  return axios({
    method: 'POST',
    url: `/stripe/promoted/makepaymentmethod`,
  })
    .then((response) => {
      dispatch({
        type: STRIPE_REDIRECT_URL,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
