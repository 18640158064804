import axios from "axios";
import {
  GET_ALL_CREATOR_ERROR,
  GET_ALL_CREATOR_REQUEST,
  GET_ALL_CREATOR_SUCCESS,
  GET_INFLUENCER_VIDEO_POST,
  GET_INFLUENCER_VIDEO_WITHOUT_PRODUCT_POST,
  GET_REVIEW_CONTROL_POST,
  GET_REVIEW_CONTROL_WITHOUT_PRODUCT_POST,
  GET_PROMOTED_REVIEW_CONTROL_POST,
  LINK_INFLUENCER_PRODUCT_ERROR,
  LINK_INFLUENCER_PRODUCT_REQUEST,
  LINK_INFLUENCER_PRODUCT_SUCCESS,
  LINK_INFLUENCER_WITHOUT_PRODUCT_ERROR,
  LINK_INFLUENCER_WITHOUT_PRODUCT_REQUEST,
  LINK_INFLUENCER_WITHOUT_PRODUCT_SUCCESS,
  LINK_INFLUENCER_PRODUCT_UPDATE_ERROR,
  LINK_INFLUENCER_PRODUCT_UPDATE_SUCCESS,
  LINK_INFLUENCER_WITHOUT_PRODUCT_UPDATE_REQUEST,
  LINK_INFLUENCER_WITHOUT_PRODUCT_UPDATE_ERROR,
  LINK_INFLUENCER_WITHOUT_PRODUCT_UPDATE_SUCCESS,
  LOCAL_CUSTOMER_ORDER_REQUEST, LOCAL_CUSTOMER_ORDER_SUCCESS, LOCAL_CUSTOMER_ORDER_ERROR,
  GET_REVIEW_DETAILS_REQUEST,
  GET_REVIEW_DETAILS_SUCCESS,
  GET_REVIEW_DETAILS_ERROR, GET_REVIEW_ID_ERROR, GET_REVIEW_ID_REQUEST, GET_REVIEW_ID_SUCCESS, GET_IMPRESSION
} from "../types/types";
const token = JSON.parse(localStorage.getItem('token'));
export const createReview = (data, orientation) => async () => {
  const body = new FormData();
  body.append("user_id", data.user_id);
  body.append("image", data.image);
  body.append("media_type", "VIDEO");
  body.append("followers", data.followers);
  body.append("username", data.username);
  body.append("orientation", orientation);
  if (data.source) {
    body.append("source", data.source);
  }

  const res = await axios.post(`reviews/uploadreview`, body);
  return res;
};

export const getInfluencerReview =
  (status, page = 1, limit = 8, startDate, endDate, influencerId, sort) =>
    async (dispatch) => {
      const res = await axios.post(
        `reviews/getall?status=${status}&limit=${limit}&page=${page}`,
        {
          from_date: startDate,
          to_date: endDate,
          influencer_id: influencerId,
          sort_by: sort,
        }
      );
      dispatch({
        type: GET_INFLUENCER_VIDEO_POST,
        payload: res.data.message,
      });
    };

export const linkInfluencerProduct = (data) => (dispatch) => {
  dispatch({ type: LINK_INFLUENCER_PRODUCT_REQUEST });
  return axios({
    method: "POST",
    url: `reviews/create`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: LINK_INFLUENCER_PRODUCT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: LINK_INFLUENCER_PRODUCT_ERROR,
        payload: error.response,
      });
    });
};
export const linkInfluencerProductUpdate = (data) => (dispatch) => {
  dispatch({ type: LINK_INFLUENCER_PRODUCT_UPDATE_SUCCESS });
  return axios({
    method: "POST",
    url: `reviews/update`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: LINK_INFLUENCER_PRODUCT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: LINK_INFLUENCER_PRODUCT_UPDATE_ERROR,
        payload: error.response,
      });
    });
};

export const getReviewControl =
  (
    ugc,
    page = 1,
    limit = 8,
    status,
    startDate,
    endDate,
    influencerId,
    gender, category, subcategory,
    sort,
    boostStatus,
    reviewID,
    campaign_status
  ) =>
    async (dispatch) => {
      const res = await axios.post(
        `reviews/getreviews?limit=${limit}&page=${page}`,
        // `reviews/getAllPromotedReviews?limit=${limit}&page=${page}`,
        {
          status: status,
          from_date: startDate,
          to_date: endDate,
          influencer_id: influencerId,
          gen_category_id: gender ? gender : "",
          category_id: category && category !== "all" ? category : "",
          sub_category_id: subcategory && subcategory !== "all" ? subcategory : "",
          sort_by: sort,
          review_counter: reviewID ? reviewID.replaceAll("-", "") : "",
          ugc: ugc,
          boost_status: boostStatus,
          campaign_status
        }
      );
      dispatch({
        type: GET_REVIEW_CONTROL_POST,
        payload: res.data.message,
      });
    };

export const reviewPublish = (data) => async () => {
  const res = axios.post(`reviews/publishandunpublish`, data);
  return res;
};

export const reviewDeleted = (data) => async () => {
  const res = axios.post(`reviews/delete`, data);
  return res;
};

export const getAllCreator = (data) => (dispatch) => {
  dispatch({ type: GET_ALL_CREATOR_REQUEST });

  return axios({
    method: "POST",
    url: `reviews/getallinfluencers?limit=1000&page=1`,
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_CREATOR_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_CREATOR_ERROR,
        payload: error.response,
      });
    });
};

export const detailsReviewSec = (orderDetail) => (dispatch) => {
  dispatch({ type: GET_REVIEW_DETAILS_REQUEST });
  const sales_tracker_id = orderDetail?.sales_tracker_id;
  const sales_id = orderDetail?._id;
  if (orderDetail?.sales_tracker_id) {
    return axios({
      method: "POST",
      url: `brand/reports/getsalesdetails`,
      data: {
        sales_tracker_id
      }
    })
      .then((response) => {
        return dispatch({
          type: GET_REVIEW_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_REVIEW_DETAILS_ERROR,
          payload: error.response,
        });
      });
  }
  else {
    return axios({
      method: "POST",
      // url: `admin/reports/getSalesDetails`,
      url: `brand/reports/getsalesdetails`,
      data: {
        sales_id
      }
    })
      .then((response) => {
        return dispatch({
          type: GET_REVIEW_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_REVIEW_DETAILS_ERROR,
          payload: error.response,
        });
      });
  }
};


export const reviewOrders =
  (page, limit, review_id, event_id) => (dispatch) => {
    dispatch({ type: LOCAL_CUSTOMER_ORDER_REQUEST });
    return axios({
      method: 'POST',
      url: `brand/reports/getsalesreport?page=${page}&limit=${limit}`,
      // headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        review: review_id,
        event: event_id
      },
    })
      .then((response) => {
        return dispatch({
          type: LOCAL_CUSTOMER_ORDER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: LOCAL_CUSTOMER_ORDER_ERROR,
          payload: error.response,
        });
      });
  };

export const getReviewBy_Id = (id) => (dispatch) => {
  dispatch({ type: GET_REVIEW_ID_REQUEST });

  return axios({
    method: 'GET',
    url: `reviews/getonereview/${id}`,
    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_REVIEW_ID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_REVIEW_ID_ERROR,
        payload: error.response,
      });
    });
};


export const creatorReview = (data) => async (dispatch) => {
  const res = await axios.post(`reviews/bulkuploadreviews`, data);
  return res.data;
};

export const showErrMessageReviews = (data, TOKEN) => async (dispatch) => {
  const res = await axios({
    url: 'reviews/updatebulkreviewserrormsg',
    method: "post",
    headers: { Authorization: `Bearer ${TOKEN ? TOKEN : token}` },
    data: { data }
  })
  return res.data;
};


export const getPromotedReviewControl =
  (
    ugc,
    page = 1,
    limit = 8,
    status,
    startDate,
    endDate,
    influencerId,
    gender, category, subcategory,
    sort,
    boostStatus,
    reviewID,
    campaign_status,
    without_product
  ) =>
    async (dispatch) => {
      const res = await axios.post(
        // `reviews/getreviews?limit=${limit}&page=${page}`,
        `reviews/getAllPromotedReviews?limit=${limit}&page=${page}`,
        {
          status: status,
          from_date: startDate,
          to_date: endDate,
          influencer_id: influencerId,
          gen_category_id: gender ? gender : "",
          category_id: category && category !== "all" ? category : "",
          sub_category_id: subcategory && subcategory !== "all" ? subcategory : "",
          sort_by: sort,
          review_counter: reviewID ? reviewID.replaceAll("-", "") : "",
          ugc: ugc,
          boost_status: boostStatus,
          campaign_status,
          without_product
        }
      );
      dispatch({
        type: GET_PROMOTED_REVIEW_CONTROL_POST,
        payload: res.data.message,
      });
    };

export const getImpressionCriteria = () => async (dispatch) => {
  const res = await axios.post(`reviews/impressionCriteria`);
  dispatch({
    type: GET_IMPRESSION,
    payload: res.data,
  });
};


export const creatorReviewWithoutProduct = (data) => async (dispatch) => {
  const res = await axios.post(`reviews/uploadReviewsWithOutProduct`, data);
  return res.data;
};

export const getInfluencerReviewWithoutProduct =
  (status, page = 1, limit = 8, startDate, endDate, influencerId, sort) =>
    async (dispatch) => {
      const res = await axios.post(
        `reviews/getWithOutProductReviews?status=${status}&limit=${limit}&page=${page}`,
        {
          from_date: startDate,
          to_date: endDate,
          influencer_id: influencerId,
          sort_by: sort,
        }
      );
      dispatch({
        type: GET_INFLUENCER_VIDEO_WITHOUT_PRODUCT_POST,
        payload: res.data.message,
      });
    };

export const linkInfluencerWithoutProduct = (data) => (dispatch) => {
  dispatch({ type: LINK_INFLUENCER_WITHOUT_PRODUCT_REQUEST });
  return axios({
    method: "POST",
    url: `reviews/createReviewWithOutProduct`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: LINK_INFLUENCER_WITHOUT_PRODUCT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: LINK_INFLUENCER_WITHOUT_PRODUCT_ERROR,
        payload: error.response,
      });
    });
};


export const getReviewControlWithoutProduct =
  (
    ugc,
    page = 1,
    limit = 8,
    status,
    startDate,
    endDate,
    influencerId,
    gender, category, subcategory,
    sort,
    boostStatus,
    reviewID,
    campaign_status
  ) =>
    async (dispatch) => {
      const res = await axios.post(
        `reviews/getAllReviewsWithOutProduct?limit=${limit}&page=${page}`,
        // `reviews/getAllPromotedReviews?limit=${limit}&page=${page}`,
        {
          status: status,
          from_date: startDate,
          to_date: endDate,
          influencer_id: influencerId,
          gen_category_id: gender ? gender : "",
          category_id: category && category !== "all" ? category : "",
          sub_category_id: subcategory && subcategory !== "all" ? subcategory : "",
          sort_by: sort,
          review_counter: reviewID ? reviewID.replaceAll("-", "") : "",
          ugc: ugc,
          boost_status: boostStatus,
          campaign_status
        }
      );
      dispatch({
        type: GET_REVIEW_CONTROL_WITHOUT_PRODUCT_POST,
        payload: res.data.message,
      });
    };

export const linkInfluencerWithoutProductUpdate = (data) => (dispatch) => {
  dispatch({ type: LINK_INFLUENCER_WITHOUT_PRODUCT_UPDATE_REQUEST });
  return axios({
    method: "POST",
    url: `reviews/updateReviewWithoutProduct`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: LINK_INFLUENCER_WITHOUT_PRODUCT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: LINK_INFLUENCER_WITHOUT_PRODUCT_UPDATE_ERROR,
        payload: error.response,
      });
    });
};