import { CUSTOMURL1 } from '../../config';
import {
  SC_STATUS_PRODUCT
} from '../types/types';
import axios from 'axios';
const sc_token = JSON.parse(localStorage.getItem('sc_token'));


export const scStatusProduct = (ids, status, token) => (dispatch) => {
  return axios({
    method: 'PUT',
    url: `${CUSTOMURL1}products/status/${ids}`,
    headers: { Authorization: `Bearer ${sc_token ? sc_token : token}` },
    // params: {
    //   ids
    // },
    data: {
      status,
    }
  })
    .then((response) => {
      dispatch({
        type: SC_STATUS_PRODUCT,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
